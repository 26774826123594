import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MtplProcess } from './MtplProcess';

export interface IMtplProcessStep {
  id: string;
  title: string;
  routePath: string;
}
export interface IMtplProcessStepController extends IMtplProcessStep {
  inEdit: Observable<boolean>;
  next(): void;
  prev(): void;
  edit(): void;
  stopEdit(): void;
  returnToPageAfterEdit: Observable<string>;
}

export class MtplProcessStep implements IMtplProcessStep {

  public id: string;
  public title: string;
  public routePath: string;

  public inEdit: Observable<boolean> = this.process.stepEditing.pipe(map(editing => {
    if (editing && this.process.stepInEdit === this) {
      return true;
    } else {
      return false;
    }
  }));


  public returnToPageAfterEdit: Observable<string> = this.process.stepReturnToPageAfterEdit.pipe(map(returnToPage => {
    return returnToPage;
  }));


  public constructor(config: IMtplProcessStep, private process: MtplProcess<any>) {
    this.id = config.id;
    this.title = config.title;
    this.routePath = config.routePath;
  }

  public next(): void {
    this.process.goToNextStep(this);
  }

  public prev(): void {
    this.process.goToPrevtStep(this);
  }

  public edit(returnToPageAfterEdit?: IMtplProcessStepController): void {
    this.process.editStep(this, returnToPageAfterEdit);
  }

  public stopEdit(): void {
    this.process.exitStepEdit();
  }
}
