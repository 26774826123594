import { ActivatedRoute } from '@angular/router';
import { BusinessType } from '@atlas/businesstypes';
import { map, takeUntil } from 'rxjs/operators';

import { FormGroupManager } from '../services/form-group-manager/FormGroupManager';
import { ScreenStepComponent } from './ScreenStepComponent';

export type ObjectPkCompareDefinition = string;
export enum FormFieldValueCompareTypes {
  Object = 1,
  EQUAL_NULL_UNDEFINED_EMPTY_STRING = 2,
}
export type FunctionCompareDefinition = (initValue: any, currentValue: any, fullInitValue?: any, fullCurrentValue?: any) => boolean;
export type FieldCompareDefinition = ObjectPkCompareDefinition | FunctionCompareDefinition | FormFieldValueCompareTypes;
export interface FormDataBase {
  [name: string]: any
}
export interface ValueObject {
  [name: string]: any
}

export class DeepMergeObject {
  public constructor(public object: Object) { };
}

export interface ChangeMonitor {
}

class ChangeMonitorClass implements ChangeMonitor {

}

export abstract class ScreenFormComponent<FormData extends FormDataBase = any> extends ScreenStepComponent {

  protected _stepForm: FormGroupManager<FormData>;
  public get stepForm(): FormGroupManager<FormData> {
    return this._stepForm;
  }
  public set stepForm(value: FormGroupManager<FormData>) {
    this._stepForm = value;
    if (value) {
      this.stepForm.setDestroyed(this.destroyed$);
    }
  }

  public hasEditChange: boolean;

  public constructor(
    route: ActivatedRoute,
  ) {
    super(route);
  }

  protected compareObjects(
    initValue: ValueObject,
    formValues: ValueObject,
    fieldComparers?: { [name: string]: FieldCompareDefinition },
  ): boolean {
    // console.log("==> ", initValue, formValues, fieldComparers);

    if (!initValue) {
      return true;
    } else {
      const valueKeys = Object.keys(formValues).sort();
      const defaultKeys = Object.keys(initValue).sort();

      const areKeysTheSame = valueKeys.length === defaultKeys.length
        && valueKeys.every((value, index) => value === defaultKeys[index]);

      if (!areKeysTheSame) {
        return false;
      }
      // console.log("valueKeys ==> ", valueKeys);

      return valueKeys.every(key => {
        if (fieldComparers && key in fieldComparers) {
          if (typeof fieldComparers[key] === 'string') {
            // Equal if both are undefined or null
            if (
              (
                (formValues[key] === undefined || formValues[key] === null)
                &&
                (initValue[key] === undefined || initValue[key] === null)
              )
            ) {
              return true;
            }

            // Expect objects with equal PK
            const pk = fieldComparers[key] as string;
            return (
              formValues[key] !== undefined && formValues[key] !== null
              && initValue[key] !== undefined && initValue[key] !== null
              && typeof formValues[key] === 'object'
              && typeof initValue[key] === 'object'
              && pk in formValues[key]
              && pk in initValue[key]
              && formValues[key][pk] === initValue[key][pk]
            );
          } else if (fieldComparers[key] === FormFieldValueCompareTypes.Object) {
            // Equal if both are undefined, null or the same object reference
            if (
              (
                (formValues[key] === undefined || formValues[key] === null)
                &&
                (initValue[key] === undefined || initValue[key] === null)
              )
              || (typeof formValues[key] === 'object' && typeof initValue[key] === 'object' && formValues[key] === initValue[key])
            ) {
              return true;
            }

            return formValues[key] !== undefined && formValues[key] !== null
              && initValue[key] !== undefined && initValue[key] !== null
              && typeof formValues[key] === 'object' && typeof initValue[key] === 'object'
              && this.compareObjects(initValue[key], formValues[key])
          } else if (fieldComparers[key] === FormFieldValueCompareTypes.EQUAL_NULL_UNDEFINED_EMPTY_STRING) {
            const formVal = formValues[key] === undefined ? '' : (formValues[key] === null ? '' : formValues[key]);
            const initVal = initValue[key] === undefined ? '' : (initValue[key] === null ? '' : initValue[key]);

            return formVal === initVal;
          } else {
            // function compare
            const compareFn = fieldComparers[key] as FunctionCompareDefinition;
            return compareFn(initValue[key], formValues[key], initValue, formValues);
          }
        } else {
          if (formValues[key] instanceof BusinessType) {
            return initValue[key] instanceof BusinessType && formValues[key].equals(initValue[key]);
          } else {
            return formValues[key] === initValue[key]
          }
        }
      });
    }
  }

  protected merge(formValue: FormData, initValuePatch: Partial<FormData>): FormData {
    const deepMerges: { [name: string]: DeepMergeObject } = {};
    const patch: Partial<FormData> = {};

    if (initValuePatch) {
      Object.keys(initValuePatch).forEach(key => {
        const val = initValuePatch[key] as any;
        if (val instanceof DeepMergeObject) {
          deepMerges[key] = val;
        } else {
          patch[key as keyof FormData] = val;
        }
      })
    }
    const initValue: FormData = { ...this.stepForm.value, ...patch };
    Object.keys(deepMerges).forEach(key => {
      const val = deepMerges[key];
      initValue[key as keyof FormData] = { ...initValue[key], ...val.object }
    })

    return initValue;
  }

  public startChangeMonitor(initValuePatch?: Partial<FormData>, fieldComparers?: { [name: string]: FieldCompareDefinition }): ChangeMonitor {
    if(this.stepForm && this.stepForm.value){
      const initValue: FormData = this.merge(this.stepForm.value, initValuePatch || {});

      this.stepForm.formGroup.valueChanges
        .pipe(
          map((formValues: FormData) => {
            return this.compareObjects(initValue, formValues, fieldComparers);
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe(areEqual => this.hasEditChange = !areEqual)

    }

    return new ChangeMonitorClass();
  }

}
