import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncPinSquareSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationFuncPinSquare]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M5 17l-3 6h20l-3-6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M19 8c0 4.32-7 11.375-7 11.375S5 12.32 5 8c0-4.43 3.617-7 7-7s7 2.57 7 7z"></svg:path><svg:circle fill="none" stroke-miterlimit="10" cx="12" cy="8" r="2"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncPinSquareSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncPinSquareSvgComponent,
  ],
  exports: [
    InformationFuncPinSquareSvgComponent,
  ],
})
export class InformationFuncPinSquareSvgModule {}
