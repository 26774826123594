export type GetCoverPackagesResponse = GetCoverPackagesResponseCoverPackage[];

export enum TagType {
    'MOST_POPULAR' = 'MOST_POPULAR',
    'BEST_VALUE' = 'BEST_VALUE',
}

export interface GetCoverPackagesResponseCover {
    id: number;
    title: string;
    insuredAmount: number;
    insuredAmountCurrency: string;
    subtitle: string;
    description?: string;
    optional: boolean;
    tags: {
        type: TagType | keyof typeof TagType;
    }[],
    messages: {
        type: string,
        code: string,
        event: string,
    }[],
}

export interface GetCoverPackagesResponseCoverPackage {
    id: number;
    title: string;
    subtitle: string;
    description: string;
    tags: {
        type: TagType | keyof typeof TagType;
    }[],
    covers: GetCoverPackagesResponseCover[];
}
