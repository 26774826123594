import {HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {PathResolver as AtlasPathResolver} from '@atlas-angular/connector';
import {Call} from '@atlas/convertor';
import {isAuthenticatedBifrostCall} from '@bifrost/core';
import {Configuration} from '@hermes/configuration';
import {isAuthenticatedHermesCall, isLogonHermesCall} from '@hermes/core';

import {HttpMethods} from './http';
import {isAuthenticatedCall} from './path-identifier';

export class BackendConfiguration extends Configuration {
  public serviceUrl: string;
  public saiUrl: string;
}

@Injectable()
export class PathResolver implements AtlasPathResolver {
  public constructor(
      private readonly _configuration: BackendConfiguration,
  ) {}

  public resolve(call: Call<any, any>): HttpRequest<null>|null {
    if (isAuthenticatedCall(call.identifier) || isAuthenticatedHermesCall(call.identifier) ||
        isAuthenticatedBifrostCall(call.identifier)) {
      return this._createRequest(this._configuration.serviceUrl, call.identifier.service);
    } else if (isLogonHermesCall(call.identifier)) {
      return this._createRequest(this._configuration.saiUrl, call.identifier.service);
    }
    return null;
  }

  private _createRequest(baseUrl: string, service: string): HttpRequest<null> {
    const url = `${baseUrl}${service}/1?cb=${Date.now()}`;
    return new HttpRequest(HttpMethods.Post, url, null);
  }
}
