import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncLightbulbIdeaSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationFuncLightbulbIdea]',
  template: '<svg:path d="M17.19 13a4.87 4.87 0 1 0-6.69 4.51v1.58h3.65v-1.6A4.85 4.85 0 0 0 17.19 13z" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M14.15 19.07v2.43H10.5v-2.43" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" d="M2.54 11.96h1.72"></svg:path><svg:path fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" d="M5.31 5.27l1.22 1.22"></svg:path><svg:path fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" d="M12 2.5v1.72"></svg:path><svg:path fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" d="M18.69 5.27l-1.22 1.22"></svg:path><svg:path fill="none" stroke="#00aeef" stroke-linecap="round" stroke-linejoin="round" d="M21.46 11.96h-1.72"></svg:path>',

  styles: [
  ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncLightbulbIdeaSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncLightbulbIdeaSvgComponent,
  ],
  exports: [
    InformationFuncLightbulbIdeaSvgComponent,
  ],
})
export class InformationFuncLightbulbIdeaSvgModule {}
