import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import moment from 'moment';
import { INSURANCE, POLICY_BTN } from '../../../constant/config/config'
import {DOCUMENT_FILE_NAME} from '../../../constant/config/config'
import { FieldPairTranslitControllerService } from '../../services/field-pair-translit-controller.service';
import { FormGroupManager } from '../../services/form-group-manager/FormGroupManager';
import { FormGroupManagerFactoryService } from '../../services/form-group-manager/form-group-manager-factory.service';
import { Date as AtlasDate } from '@atlas/businesstypes';
import { EgnValidatorService } from '../../../flow/validation/egn/egn-validator.service';

import { TransliterateService } from '../../services/transliterate.service';
import { TravelCalculationService } from '../../services/travel-price-calculation.service';
import { ApiService } from '../../../backend/api.service';
import { ASSET_PATH } from '../../../constant/config/config';

import { Decimal } from '@atlas/businesstypes';
import { TravelCovers,TravelUpdatedCovers,ResponseCovers,TravelPriceCalculationUserInput} from '../../shared/model/TravelPriceCalModel';
import { AppendAddressFieldsService } from '../../../flow/services/append-address-fields.service';
import { LocalizationService } from '../../../flow/services/localization.service';
import { localeValPipe } from '../../../flow/pipes/localeVal.pipe';

@Component({
  selector: 'systemcode-mtpl-policy-details',
  templateUrl: './mtpl-policy-details.component.html',
  styleUrls: ['./mtpl-policy-details.component.scss'],
})
export class MtplPolicyDetailsComponent implements OnInit, OnChanges {
  public lang: string | null;

  @Input()
  public policyDetails: any;

  @Input()
  public buttonView?: string;

  @Output()
  public actionEmitter: EventEmitter<{regNo: string; certNo: any; actionType: string; }> = new EventEmitter<{regNo: string; certNo: any; actionType: string; }>();

  @Output()
  public pdfGenerator: EventEmitter<{pdfType?: string; fileName?: string; pdfBody?: { field?: string; value?: string}[]}> = new EventEmitter<{pdfType?: string; fileName?: string; pdfBody?: { field?: string; value?: string}[]}>();

  public bannerMsg: string = '';
  public msgType: string = '';
  public dueInstalmentsCnt = 0;
  public coveredBy: any[] = [];
  public instalmentsData: any[] = [];
  public rowColorCss: string = '#003665';
  public renewButtonCheck: boolean = false;
  public insuringPartyCovered: boolean = false;
  public endDateString: AtlasDate;
  public tableColumns: any[] = [];
    public stepForm: FormGroupManager<any> = this.formFactory.createFormManager<any>({
      nameInEnglishPersonal: new FormControl(undefined),
      namePersonal : new FormControl(),
      nameInEnglishVehicle: new FormControl(undefined),
      nameVehicle : new FormControl(),
    })

  public RENEW_BTN = POLICY_BTN.RENEW_BTN;
  public VIEW_POLICY = POLICY_BTN.VIEW_POLICY;
  public PAY_INSTALMENT = POLICY_BTN.PAY_INSTALMENT;
  public PRINT_POLICY = POLICY_BTN.PRINT_POLICY;

  public tiDataEnNames:string[] = [];
  public isPropertyPolicy: boolean;
  public propertyType: string;
  public tiAddOnCovers:TravelCovers[] = [];
  public tiMainCovers:TravelCovers[] = [];
  public tiUpdatedMainCovers: TravelUpdatedCovers[]= [];
  public tiReasonForTravel:string;
  public insurPartyAddress: string;
  public vehicleOwnerAddress: string;
  public localeVal = new localeValPipe(new LocalizationService)

  public constructor(
    private fieldPairTranslitController: FieldPairTranslitControllerService,
    private transService: TransliterateService,
    private egnService: EgnValidatorService,
    private travel: TravelCalculationService,
    private apiService: ApiService,
    public addressService: AppendAddressFieldsService,
    private formFactory: FormGroupManagerFactoryService) {
      this.lang = sessionStorage.getItem('lang');
  }
  public ngOnChanges(): void {
    this.renewButtonCheck = this.renewDateCheck();
  }

  public ngOnInit() {
    this.lang = sessionStorage.getItem('lang');
    this.tableColumns = [
      { id: 11, headerName: 'No', headerValue: 'No' },
      { id: 21, headerName: 'Due date', headerValue: 'DueDate' },
      { id: 31, headerName: 'Status', headerValue: 'Status' },
      { id: 41, headerName: 'Paid date', headerValue: 'PaidDate' },
      { id: 51, headerName: 'Amount', headerValue: 'Amount' },
      { id: 51, headerName: 'Download', headerValue: 'Download' },
    ];
    if (this.policyDetails) {
      this.instalmentsData = this.policyDetails.Instalments;
      this.isPropertyPolicy = (this.policyDetails.PropertyDetails && this.policyDetails.PropertyDetails.PolicyNo) ? true : false;
      this.coveredByCheck();
      this.renewButtonCheck = this.renewDateCheck();
      this.fieldPairTranslitController.attach(
        this.stepForm.controls.namePersonal,
        this.stepForm.controls.nameInEnglishPersonal,
        new ReplaySubject(1),
      );
      this.fieldPairTranslitController.attach(
        this.stepForm.controls.nameVehicle,
        this.stepForm.controls.nameInEnglishVehicle,
        new ReplaySubject(1),
      );

      this.stepForm.patchValue({
        namePersonal: this.policyDetails.PersonalDetails.FullName,
        nameVehicle: this.policyDetails.VehicleOwner.FullName,
      })

      if(this.policyDetails.ContactDetails){
        const contactDetails = this.policyDetails.ContactDetails;
        const iStreet = this.addressService.appendQuarterStreet(contactDetails.Street, contactDetails.StreetNumber);
            const iBEA = this.addressService.appendBlockEntranceApartment(contactDetails.Block, contactDetails.Entrance, contactDetails.Apartment);
            const icity = contactDetails.City + ', ' + contactDetails.Zipcode;
            this.insurPartyAddress = iStreet +'\n'+ (iBEA ? iBEA + '\n' : '') + icity;
      }

      const productName = this.getProductName(this.policyDetails.ProductCode)

      if(productName==='MTPL' && this.policyDetails.VehicleOwnerAddress){
        const vehicleOwnerDetails = this.policyDetails.VehicleOwnerAddress;
        const iStreet = this.addressService.appendQuarterStreet(vehicleOwnerDetails.Street, vehicleOwnerDetails.StreetNumber);
            const iBEA = this.addressService.appendBlockEntranceApartment(vehicleOwnerDetails.Block, vehicleOwnerDetails.Entrance, vehicleOwnerDetails.Apartment);
            const icity = vehicleOwnerDetails.City + ', ' + vehicleOwnerDetails.Zipcode;
            this.vehicleOwnerAddress = iStreet +'\n'+ (iBEA ? iBEA + '\n' : '') + icity;
      }

      if(productName === 'TRAVEL'){

        let tiData = this.policyDetails.TravelInsureds;
        
        if(tiData){
          tiData.forEach((tiObj:any) => {
            let enName = this.transService.trans({ source: tiObj.fullName, isVechicleRegNumber: false});
            this.tiDataEnNames.push(enName);
            // console.log("BulgarianName:",tiObj.fullName,"EnglishName:",enName)
          });
        }

        let tiCoversData = this.policyDetails.SelectedCoverPackage;
        const covers = tiCoversData.Covers;

        if(tiCoversData && covers){
          covers.forEach((cover:TravelCovers)=> {
            if(cover.Optional===true){
                this.tiAddOnCovers.push(cover);
            }else{
                this.tiMainCovers.push(cover);
            }
          });
          this.parsePriceCalculationRequest();
        }
      }

    }
    this.insuredPeopleCoverd();


  }


  public async parsePriceCalculationRequest(){
    let priceCalRequest:TravelPriceCalculationUserInput;
    const lang = sessionStorage.getItem('lang');
    const REASON_FOR_TRAVEL_MAPPING_RESPONSE = await this.getReasonForTravelMappingData().toPromise();
    const TRAVEL_MAPPING_RESPONSE = await this.getTravelMappingData().toPromise(); // the response has datatype: TravelMappingResponseObj

    const packageDetails = this.policyDetails.SelectedCoverPackage;
    const insuredPeopleDetails = this.policyDetails.TravelInsureds;
    const tripDetails = this.policyDetails.TripDetails;
    const policyDateDetails = this.policyDetails.PolicyDates;

    // reason for travel
    const newObjType = insuredPeopleDetails.length===1? '8182' : '8181';
    const newReasonForTravel = tripDetails.reasonForTravel;
    const newTravelType:string = insuredPeopleDetails.length===1? 'SingleTravel' : 'GroupTravel';
    const newTravelCount = Number(tripDetails.daysAbroad)===0? 'Once' : 'Multiple';
    let newObjIds = TRAVEL_MAPPING_RESPONSE[newReasonForTravel][newTravelType][newTravelCount];

    const newReasonForTravelId = newReasonForTravel.slice(-1);
    if(newReasonForTravelId && REASON_FOR_TRAVEL_MAPPING_RESPONSE && lang){
      this.tiReasonForTravel = REASON_FOR_TRAVEL_MAPPING_RESPONSE[newReasonForTravelId][lang].objectName;
    }

    const newObjKind:string = newObjIds.ObjKind;
    const newObjSubKind:string = newObjIds.ObjSubKind;

    if(packageDetails && insuredPeopleDetails && newObjIds){
      priceCalRequest = {
        packageID: packageDetails.Title[0],
        objectType: newObjType,
        objectKind: newObjKind,
        objSubType: newObjSubKind,
        typeOfWorkAbroad: Number(tripDetails.typeOfWorkAbroad),
        travelToUS: tripDetails.travelToUS[0],
        maxDaysAbroad: Number(tripDetails.daysAbroad),
        insrBegin: this.travel.getTravelPriceCalFomateDate(policyDateDetails.StartDate),
        insrEnd: this.travel.getTravelPriceCalFomateDate(policyDateDetails.EndDate),
      }
      this.getPackagePrice(priceCalRequest);
    }
  }

  public getPackagePrice(calculationUserInput:TravelPriceCalculationUserInput){
  
    this.travel.priceRecalculation(calculationUserInput).subscribe((result)=>{
        const responseCovers : ResponseCovers[] = result.data.travelParticipants[0].travelCovers;
        this.tiMainCovers.forEach((mainCover:TravelCovers)=>{
            let updatedCover:TravelUpdatedCovers;
            const matchedCover:ResponseCovers | undefined = responseCovers.find((cover:ResponseCovers)=>cover.coverID === Number(mainCover.Id));
            if(matchedCover){
                updatedCover = {...mainCover , InsuredValue : matchedCover.insuredValue , Currency : matchedCover.currency };
                this.tiUpdatedMainCovers.push(updatedCover);
            }
        })
        console.log("required covers",this.tiUpdatedMainCovers,this.tiMainCovers,this.tiAddOnCovers);
    });

  }

  public getTravelMappingData() {
    return this.apiService.getJSON(ASSET_PATH.TRAVEL_MAPPING_RESPONSE_JSON);
  }

  public getReasonForTravelMappingData() {
    return this.apiService.getJSON(ASSET_PATH.REASON_FOR_TRAVEL_MAPPING_JSON);
  }

  public convertAmoutFont(price: any) {
    return new Decimal(price + '');
  }

  public insuredPeopleCoverd() {
    const insuringParty = this.policyDetails.PersonalDetails.EGN
    this.insuringPartyCovered = this.policyDetails.TravelInsureds.some((insuredPeople:any) => insuredPeople.pid === insuringParty)
  }

  public emitAction(value: string) {

    if(value === this.PAY_INSTALMENT) {
      this.actionEmitter.emit({
        regNo: this.policyDetails.PolicyNumber,
        certNo: this.policyDetails,
        actionType: value
      });
    } else if (value === this.PRINT_POLICY) {
      this.actionEmitter.emit({
        regNo: this.policyDetails.PolicyNumber,
        certNo: '',
        actionType: value
      });
    } else {
      this.actionEmitter.emit({
        regNo: this.policyDetails.VehicleInformation.VehicleRegistrationNumber,
        certNo: this.policyDetails.VehicleInformation.VehicleCertificateNumber,
        actionType: value
      });
    }
  }

  public downloadReceipt(row: any, rowIndex: string, rowLength: string) {
    this.pdfGenerator.emit(this.mtplPDFReceipt(row, rowIndex, rowLength));
  }

  public getProductName(productCode: string): any {
    const productName =  this.getKeyByValue(INSURANCE, parseInt(productCode, 10));
    return productName;
  }

  public getKeyByValue(object: any, value: number) {
    return Object.keys(object).find(key => object[key] === value);
  }


  private mtplPDFReceipt(row: any, rowIndex: string, rowLength: string): {lang?:string ;pdfType?: string; fileName?: string; pdfBody?: { field?: string; value?: string}[]} {
    let values:{ field?: string; value?: string}[] = [];

    if (this.policyDetails['productCode'] == INSURANCE.MTPL) {
      values.push({
        field: this.localeVal.transform('Insurance type',this.lang),
        value: this.lang == 'en' ? 'Motor Third Party Liability': 'Застраховка “Гражданска отговорност” на автомобилистите'
      });
    } else if (this.policyDetails['productCode'] == INSURANCE.TRAVEL) {
      values.push({
        field: this.localeVal.transform('Insurance type',this.lang),
        value: this.lang == 'en' ? 'Travel Insurance' : 'Застраховка за пътуване'
      });
    } else if(this.policyDetails['productCode'] == INSURANCE.Property_Insurance) {
      values.push({
        field: this.localeVal.transform('Insurance type',this.lang),
        value: this.lang == 'en' ? 'Property Insurance' : 'Застраховка на имущество'
      });
    }
    return {
      lang: this.lang? this.lang : undefined,
      pdfType: 'PAYMENT_RECEIPT',
      fileName: DOCUMENT_FILE_NAME.PAYMENT_RECEIPT,
      pdfBody: [
        {
          field: this.lang == 'en' ? 'Full name' : 'пълно име',
          value: this.policyDetails['PersonalDetails']['FullName']
        },
        {
          field: this.lang == 'en' ? 'EGN' : 'ЕГН',
          value: this.policyDetails['PersonalDetails']['EGN']
        },
        ...values,
        {
          field: this.lang == 'en' ? 'Policy no' : 'Номер на полица',
          value: this.policyDetails['PolicyNumber']
        },
        {
          field: this.lang == 'en' ? 'Period covered' : 'Покрит период',
          value: row['CoveredFrom'] && row['CoveredBy'] ? `${row['CoveredFrom']} - ${row['CoveredBy']}` : ''
        },
        {
          field: this.lang == 'en' ? 'Installment' : 'Вноска',
          value: `${rowIndex} of ${rowLength}`
        },
        {
          field: this.lang == 'en' ? 'Date & Time' : 'Дата и час',
          value: row['PaidDateTime'] ? row['PaidDateTime'] : ''
        },
        {
          field: this.lang == 'en' ? 'Transaction id' : 'Транзакция номер',
          value: row['TransactionId'] ? row['TransactionId'] : ''
        },
        {
          field: this.lang == 'en' ? 'Paid' : 'Платено',
          value: row['PaidAmount'] ? row['PaidAmount'] : ''
        }
      ]
    };
  }

  public coveredByCheck() {
    if (this.instalmentsData.length > 0){
      this.instalmentsData.forEach((list: any) => {
        if (list.Status === 'Paid') {
          this.coveredBy.push(list.CoveredBy);
        }
        if (list.Status === 'Due') {
          this.dueInstalmentsCnt++;
        }
      });
    }
  this.coveredBy = this.coveredBy.pop();
  }

  public getFormattedDate(dateVal: Date): string {
    const momentVariable = moment(dateVal, 'YYYY-MM-DD HH:mm:ss');
    const stringVal = momentVariable.format('Do MMMM YYYY');
    return stringVal;
  }

  public renewDateCheck():boolean {
    let endDate = this.policyDetails.PolicyDates.EndDate.split('/').reverse().join('-');
    if(this.policyDetails.isPolicyTerminated){
      endDate = this.policyDetails.DateCovered.split('/').reverse().join('-');
    }
    const dateOfExpiry = new Date(endDate);
    if(this.buttonView && this.buttonView === 'Archive') {
      this.msgType = 'informative';
      this.bannerMsg = 'Expired at ' + this.getFormattedDate(dateOfExpiry);
      return true;
    }
    // Renew Button 60 Days check
    const currentDate = new Date();
    dateOfExpiry.setDate(dateOfExpiry.getDate() - 60);
    if (currentDate >= dateOfExpiry) {
      this.msgType = 'warning';
      this.bannerMsg = 'Your policy will be expiring soon. Renew your policy now.';
      return true;
    }
    this.msgType = '';
    this.bannerMsg = '';
    return false;
  }

  public checkProduct(productCode:number){
    if(productCode!==INSURANCE.TRAVEL){
      return true;
    }else{
      return false;
    }
  }

  public getCellValue(row: any, column: any, rowIndex: number) {
    const downloadReceiptValue = this.localeVal.transform('Download Receipt', this.lang);
    let cellValue = row[column.headerValue] + '';
    if (column.headerValue === 'Download') {
      cellValue = (column.headerValue === 'Download' && row.Status === 'Paid') ? downloadReceiptValue : '--';
    }
    cellValue = (column.headerValue === 'PaidDate' && cellValue === '--') ? '--' : cellValue;
    return cellValue;
  }

  public getRowColorCss(row: any){
    if ( row.Status === 'Paid') {
      this.rowColorCss = '#003665';
    } else if ( row.Status === 'Due'){
      this.rowColorCss = '#D64040';
    } else {
      this.rowColorCss = '#D6DFE6';
    }
    return this.rowColorCss;
  }

  public checkEgn(idNumber: string){
    if(idNumber){
      return this.egnService.isValid(idNumber);
    }
    return null
  }

  public emitPropertyType(event: any){
    this.propertyType = event && event.Name ? event.Name: '';
  }
}
