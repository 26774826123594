import {ChangeDetectionStrategy, Component, Output, EventEmitter, Input} from '@angular/core';
import {Boolean, Text, Hidden} from '@atlas/businesstypes';
import {
  FileUploaderController,
  FileUploaderSlideInConfig,
  Reference,
} from '@hermes/file-uploader';
import {MessagingService} from '@hermes/messaging';
import { TalonResponse } from '../../../model/mtpl/TalonResponse';
import { TALON_ACTION } from '../../../constant/config/config';
import { ApiService } from '../../../backend/api.service';
import { LocalizationService } from '../../../flow/services/localization.service';
import { localeValPipe } from '../../../flow/pipes/localeVal.pipe';




@Component({
  selector: 'systemcode-mtpl-talon-file-uploader',
  templateUrl: './mtpl-talon-file-uploader.component.html',
  styleUrls: ['./mtpl-talon-file-uploader.component.css'],
  providers: [FileUploaderController],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MtplTalonFileUploaderComponent{

  public fileUploaderSelector = {disabled: new Boolean(false)};
  public filesToUpload = 1;
  public uploadedFiles: Reference[] = [];
  public uploadedFilesBasic: Reference[] = [];
  public front: string = TALON_ACTION.FRONT_SIDE;
  public back: string = TALON_ACTION.BACK_SIDE;
  public capturedImageSide : string= '';
  private lang = sessionStorage.getItem('lang');
  private constraintText = new localeValPipe(new LocalizationService()).transform('Only PNG or JPG. max. 10MB',this.lang);
  private uploadFileTitle =  new localeValPipe(new LocalizationService()).transform('Upload Image',this.lang);
  public isDisableBackSide: boolean=false;

  public slideInConfig: FileUploaderSlideInConfig = {
    title: new Text(this.uploadFileTitle),
    maxNumberOfFiles: 1,
    maxTotalFileSize: 10 * 1024 * 1024, // byte to 10mb
    dropZones: [
      {
        constraintText : new Text(this.constraintText),
      },
    ],
    afterDownload: (reference: Reference) => {
      console.log('on download', this.slideInConfig.title, reference);
    },
    afterUpload: (references: Reference[]) => {
      console.log('on upload', this.slideInConfig.title, references, this.uploadedFilesBasic);
      if(references && references.length>0){
        const idRef: Hidden = references[0].id;
        const nameRef: Text = references[0].name;
        const typeRef: Text = references[0].type;
        const talonUploadResponse : TalonResponse = {
          action: TALON_ACTION.UPLOAD,
          imageSide: this.capturedImageSide,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        }
        this.emitUploadedFile.emit(talonUploadResponse);
      }else{
        this.addErrorMessageIn5Seconds();
      }
    },
    afterDelete: (reference: Reference) => {
      console.log('on delete', this.slideInConfig.title, reference);

      if(reference){
        const idRef: Hidden = reference.id;
        const nameRef: Text = reference.name
        const typeRef: Text = reference.type;
        const talonUploadResponse : TalonResponse = {
          action: TALON_ACTION.DELETE,
          imageSide: this.capturedImageSide,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        }
        this.emitUploadedFile.emit(talonUploadResponse);
      }

    },
  };

  @Output('emitUploadedFile') emitUploadedFile = new EventEmitter<TalonResponse>();

  @Input()
  public set frontSideImageData(frontSideImageData: TalonResponse){
    if(frontSideImageData){
      const idRef: Hidden = new Hidden(frontSideImageData.id);
      const nameRef: Text = new Text(frontSideImageData.name);
      const typeRef: Text = new Text(frontSideImageData.type);

      this.uploadedFiles = Object.assign([], [{
          id: idRef,
          name: nameRef,
          type: typeRef,
        }]);
        console.log(this.uploadedFiles[0]);
    }

  }

  @Input()
  public set backSideImageData(backSideImageData: TalonResponse){
    if(backSideImageData){
      const idRef: Hidden = new Hidden(backSideImageData.id);
      const nameRef: Text = new Text(backSideImageData.name);
      const typeRef: Text = new Text(backSideImageData.type);

      this.uploadedFilesBasic = Object.assign([], [{
          id: idRef,
          name: nameRef,
          type: typeRef,
        }]);
      console.log(this.uploadedFilesBasic[0]);
    }

  }

  @Input()
  public set disableBackSideTalon(disableBackSideTalon: boolean){
    this.isDisableBackSide = disableBackSideTalon;
  }

  public constructor(
    private readonly messagingService: MessagingService,
    public apiService: ApiService
  ) { }

  public ngOnInit() {
  }


  public aggregatedUploadedFiles(): Reference[] {

    return [
      ...this.uploadedFiles,
      ...this.uploadedFilesBasic,
    ];
  }

  public addErrorMessageIn5Seconds() {
    setTimeout(() => {
      this.messagingService.add({
        text: 'Error message',
        type: 'error',
        actionButtonText: 'ok',
      });
    }, 5000);
  }

  public updateFlag(flag: string){
    this.capturedImageSide = flag;
    this.apiService.chooseFileSubject.next(TALON_ACTION.TALON);
  }

}
