import { Injectable } from '@angular/core';

import { PartyType } from '../../model/PartyType';
import { EgnValidatorService } from '../validation/egn/egn-validator.service';
import { EikValidatorService } from '../validation/eik/eik-validator.service';
import { LnchValidatorService } from '../validation/lnch/lnch-validator.service';

@Injectable({
  providedIn: 'root',
})
export class PartyIdService {

  public constructor(
    private egn: EgnValidatorService,
    private eik: EikValidatorService,
    private lnch: LnchValidatorService,
  ) { }

  public getType(val: string): PartyType | undefined {
    if (val.replace(/0/g, '').trim() === '') {
      return undefined;
    }

    if (this.egn.isValidFormat(val)) {
      if (this.egn.isValid(val)) {
        return PartyType.PERSON;
      } else if (this.lnch.isValidFormat(val) && this.lnch.isValid(val)) {
        return PartyType.FOREIGNER;
      }
    } else if (this.eik.isValidFormat(val)) {
      if (this.eik.isValid(val)) {
        return PartyType.COMPANY;
      }
    } else if (this.lnch.isValidFormat(val) && this.lnch.isValid(val)) {
      return PartyType.FOREIGNER;
    }

    return undefined;
  }

}
