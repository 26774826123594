import {InjectionToken} from '@angular/core';

export enum ApplicationType {
  MOBILE = 'Mobile',
  TOUCH = 'Touch',
}

export interface Application {
  id: string;
  channelId: string;
  type: ApplicationType;
  module: any;
  name: string;
}

export const APPLICATION_TYPE =
    new InjectionToken<ApplicationType>('@systemcode/core application type');
