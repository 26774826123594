export const CONSTANTS = {
    'API_RESPONSE': {
        'INVALID_USER': 'Email / Password is incorrect',
        'USER_NOT_FOUND': 'User not found',
        'DISABLED_USER': 'Disabled User',
        'INTERNAL_SERVER_ERROR': 'Internal Server Error',
        'UNAUTHORIZED': 'Unauthorized',
        'TOKEN_EXPIRED': 'Token Expired',
        'TOKEN_AUTHENTICATION_FAILED': 'Failed to authenticate Token',
        'NO_TOKEN': 'No token provided',
    },
};

export const DELIVERY_ADDRESS = {
    'MTPL': '0',
    'STAND_ALONE_PAY': '1',
}

export const COMMON_CONSTANTS = {
    'CURRENCY' : 'BGN',
}
