import { CommonModule } from '@angular/common';
import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CoreModule, ModalsModule, PropertyRouteGuard } from '@systemcode/core';

import { PropertyInsuranceProcessService } from './core/services/property-insurance-process.service';
import { PropertyInsuranceService } from './core/services/property-insurance.service';
import { StepResolverService } from './core/services/step.resolver';
import { PropertyInsuranceRoutingModule } from './property-insurance-routing.module';
import { PropertyInsuranceComponent } from './property-insurance.component';
import { PropertyMaiaModule } from './property-maia.module';
import { PropertyCalculatorModule } from './screens/property-calculator/property-calculator.module';
import { PropertyPurchaseModule } from './screens/property-purchase/property-purchase.module';
import { BASE_ROUTE, StarterService } from './starter.service';
// import { PropertyPriceCalculationService } from './core/services/property-price-calculation.service';

export function routepropertyinsurance(path: string) {
  return {
    path,
    loadChildren: '@systemcode/property-insurance#øPropertyInsuranceModule',
    canLoad: [PropertyRouteGuard]
  };
}


@NgModule({
  declarations: [PropertyInsuranceComponent],
  imports: [
    CommonModule,
    PropertyInsuranceRoutingModule,
    PropertyMaiaModule,
    PropertyCalculatorModule,
    PropertyPurchaseModule,
    CoreModule,
    ModalsModule,
  ],
  providers: [
    PropertyInsuranceService,
    PropertyInsuranceProcessService,
    StepResolverService,
  ],
})
export class øPropertyInsuranceModule { }

@NgModule({
  providers: [StarterService],
})
export class PropertyInsuranceModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: PropertyInsuranceModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import PropertyInsuranceModule directly, use PropertyInsuranceModule.forPath() instead`);
    }
  }
}

