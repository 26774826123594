import {PathIdentifier as AtlasPathIdentifier} from '@atlas/convertor';

export const systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE = 'systemcode';

export abstract class PathIdentifier implements AtlasPathIdentifier {
  public type: string;

  public constructor(public service: string, public version?: string) {}
}

export class AuthenticatedPathIdentifier extends PathIdentifier {
  public type = systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE;
}

export function isAuthenticatedCall(identifier: AtlasPathIdentifier):
    identifier is AuthenticatedPathIdentifier {
  return identifier.type === systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE;
}
