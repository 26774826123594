import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, map, startWith, takeUntil } from 'rxjs/operators';
import { B2C_DASHBOARD_PATH_FUNCTION  } from './app-routing.module';
// import { environment } from '././environment';
import {environment} from '../environments/environment';
import Bowser from "bowser";

@Component({
  selector: 'systemcode-app',
  templateUrl: './app.component.html',
  styles: [':host {display: block;}'],
})
export class AppComponent implements OnDestroy, OnInit {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public isIE: boolean = false;
  public isHeaderVisible: boolean = true;
  public isBackButtonVisible: boolean = true;
  public isCloseButtonVisible: boolean = true;
  public closeButtonContainComponentArray = [
    'e-signature-notification',
    'payment-result?success=0',
    'payment-result?success=1',
    'travel-payment-result',
    'property-payment-success'
  ];
  public urlEndpoint: string = '';
  public environmentName = '';
  public environmentUrl = 'Debug api';

  public constructor(
    private readonly _location: Location,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    ) {

      if (environment.production) {
        console.log = function () {};
    }
    this.checkBrowser();
    // this.environmentName = environment.environmentName;
    // this.environmentUrl = environment.apiUrl;

    // console.log('environmentName', this.environmentName, this.environmentUrl);
    console.log('Location', window.location.pathname, window.location.href, window.location.host, window.location.hostname);
    this.updateLang();
    this._router.events.pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        const locationStrArray = window.location.href.toString().split('/')
        console.log(locationStrArray);
        this.urlEndpoint = '';
        if (locationStrArray.length > 0) {
          const hostUrl = locationStrArray[0] + '//' + window.location.host;
          console.log('configured url', hostUrl);
          this.urlEndpoint = locationStrArray[locationStrArray.length - 1];
          sessionStorage.setItem('current_end_point', this.urlEndpoint);
          sessionStorage.setItem('path_name', window.location.pathname);
        }
        const url: string = event.url.toString();
        const urlArray = url.split('/');
        if (urlArray.length >= 1) {
          (urlArray[1] === 'en') ? sessionStorage.setItem('lang', urlArray[1]) : sessionStorage.setItem('lang', 'bg')
        }
        if (url === '/') {
          this._router.navigateByUrl('/' + B2C_DASHBOARD_PATH_FUNCTION);
        }
        if (locationStrArray.length > 3) {
          const path = locationStrArray[3];
          const oldLang = sessionStorage.getItem('old_lang');
          if (path==='en') {
            console.log("en");
            sessionStorage.setItem('lang', 'en');
          } else if ( path === 'payment?success=1' && oldLang){
            console.log("oldlang",path,oldLang);
            sessionStorage.setItem('lang', oldLang);
          } else {
            console.log("bg");
            sessionStorage.setItem('lang', 'bg');
          }
        }
      });
  }
  
  public updateLang(){
    const locationStrArray = window.location.href.toString().split('/')
    console.log(locationStrArray);
    if (locationStrArray.length > 3) {
      const path = locationStrArray[3];
      const oldLang = sessionStorage.getItem('old_lang');
      if (path==='en') {
        console.log("en");
        sessionStorage.setItem('lang', 'en');
      } else if ( path === 'payment?success=1' && oldLang){
        console.log("oldlang",path,oldLang);
        sessionStorage.setItem('lang', oldLang);
      } else {
        console.log("bg");
        sessionStorage.setItem('lang', 'bg');
      }
    }
  } 

  public ngOnInit(): void {

    const innerWidth = window.innerWidth;

    this._router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let r = this._activatedRoute;
          while (r.firstChild != null) {
            r = r.firstChild;
          }

          let rs = r.snapshot;

          while (rs.parent != null) {
            if ('isHeaderVisible' in rs.data) {
              return rs.data['isHeaderVisible'] as boolean
            }
            rs = rs.parent;
          }

          return true;
        }),
      ).subscribe((val: any) => this.isHeaderVisible = val)

    this._router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let r = this._activatedRoute;
          while (r.firstChild != null) {
            r = r.firstChild;
          }

          let rs = r.snapshot;

          while (rs.parent != null) {
            if ('isBackButtonVisible' in rs.data) {
              return rs.data['isBackButtonVisible'] as boolean
            }
            rs = rs.parent;
          }

          return true;
        }),
      ).subscribe((val: any) => {
        this.isBackButtonVisible = val;
        const userData = sessionStorage.getItem('userData');
        // console.log(userData)
        if (userData && this.urlEndpoint === 'vehicle-owner' || userData && this.urlEndpoint === 'pinsured-people'){
            this.isBackButtonVisible = false;
        }
      })


    this._router.events
      .pipe(
        takeUntil(this.destroyed$),
        filter((event: any) => event instanceof NavigationEnd),
        startWith(null),
        map(() => {
          let r = this._activatedRoute;
          while (r.firstChild != null) {
            r = r.firstChild;
          }

          let rs = r.snapshot;

          while (rs.parent != null) {
            if ('isCloseButtonVisible' in rs.data) {
              return rs.data['isCloseButtonVisible'] as boolean
            }
            rs = rs.parent;

          }

          return true;
        }),
      ).subscribe((val: any) => {
        this.isCloseButtonVisible = val;
        this.closeButtonContainComponentArray.forEach((element: any) => {
          if (this.urlEndpoint === element && innerWidth < 730) {
            this.isCloseButtonVisible = true;
          }
        });

      })


  }

  public checkBrowser() {
    // this.isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const browser = Bowser.getParser(window.navigator.userAgent);
    this.isIE = browser.isBrowser('Internet Explorer');
    // console.log(`The current browser name is "${browser.getBrowserName()}"`);
  }

  public goBack(): void {
    this._location.back();
    if (this.urlEndpoint === 'personal'){
      this._location.back()
    }
  }

  public close(): void {
    const back_to_origin = sessionStorage.getItem('back_to_origin');
    const loogedinUserData = sessionStorage.getItem('userData')
    if (back_to_origin) {
      console.log(back_to_origin)
      const urlArray = back_to_origin.split('/');
      const navigateUrl = (urlArray[1] === 'en') ? back_to_origin.slice(3) : back_to_origin;
      console.log(navigateUrl)
      if(loogedinUserData && navigateUrl){
        sessionStorage.removeItem('policy-payment')
        sessionStorage.removeItem('userAddresses')
        sessionStorage.removeItem('user-policy-payment')

        sessionStorage.removeItem('mtpl');
        sessionStorage.removeItem('mtpl-process');
        sessionStorage.removeItem('userAddresses')

        sessionStorage.removeItem('PropertyInsuranceData');
        sessionStorage.removeItem('pi-process');
        sessionStorage.removeItem('Property_SignUp_Data');

        sessionStorage.removeItem('travel');
        sessionStorage.removeItem('RENEW_PI_DATA');
        sessionStorage.removeItem('travel-process');
        sessionStorage.removeItem('confirmationContent');

        sessionStorage.removeItem('claims')


        sessionStorage.removeItem('sendEmailResult');
        sessionStorage.removeItem('paymentFailureMsg');
        sessionStorage.removeItem('loginFlag');
        sessionStorage.removeItem('landingFromLogin');
        sessionStorage.removeItem('back_to_origin');
      }else
      {
        sessionStorage.clear();
      }

      this._router.navigateByUrl(navigateUrl);

      // if(navigateUrl === '/dashboard/policies/active' || navigateUrl === '/dashboard/home/user-info') {
      //   sessionStorage.removeItem('mtpl');
      //   sessionStorage.removeItem('PropertyInsuranceData');
      //   sessionStorage.removeItem('travel');
      //   sessionStorage.removeItem('RENEW_PI_DATA');

      //   this._router.navigateByUrl(navigateUrl);
      // }
      // else {
      //   this._router.navigateByUrl(navigateUrl);
      //   sessionStorage.removeItem('back_to_origin');
      //   sessionStorage.removeItem('mtpl');
      //   sessionStorage.removeItem('PropertyInsuranceData');
      //   sessionStorage.removeItem('travel');
      //   sessionStorage.removeItem('RENEW_PI_DATA');
      //   sessionStorage.removeItem('flow');
      //   sessionStorage.removeItem('AuthBridgeData')
      //   sessionStorage.clear();
      // }
    } else {
      sessionStorage.clear();
      this._router.navigateByUrl('dashboard');
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
