import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';

import { StepperComponent } from './components/stepper/stepper.component';
import { MaiaModule } from './maia.module';
import { PolicyPaymentRoutingModule } from './policy-payment-routing.module';
import { PolicyPaymentComponent } from './policy-payment.component';
import { InstalmentPaymentModule } from './screens/instalment-payment/instalment-payment.module';
import { PaymentProcessService } from './services/payment-process.service';
import { StepResolverService } from './services/step.resolver';
import { BASE_ROUTE, StarterService } from './starter.service';


export function routepolicypayment(path: string) {
  return {
    path,
    loadChildren: '@systemcode/policy-payment#øPolicyPaymentModule',
  };
}

@NgModule({
  imports: [
    PolicyPaymentRoutingModule,
    InstalmentPaymentModule,
    MaiaModule,
  ],
  providers: [
    PaymentProcessService,
    StepResolverService,
  ],
  declarations: [
    StepperComponent,
    PolicyPaymentComponent,
  ],
})
export class øPolicyPaymentModule { }

@NgModule({
  providers: [StarterService],
})
export class PolicyPaymentModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: PolicyPaymentModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
        PaymentProcessService,
        StepResolverService,
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import PolicyPaymentModule directly, use PolicyPaymentModule.forPath() instead`);
    }
  }
}
