import { Injectable } from '@angular/core';

import { EsignatureService } from '../../esignature/services/esignature.service';
import { ConfirmationPopupService } from '../../modals/services/confirmation-popup.service';
import { BoricaVposTransactionTypes } from '../../model/dto/BoricaVposTransactionTypes';
import { VposPaymentFormComponent } from '../../payment/components/vpos-payment-form/vpos-payment-form.component';
import { VposPaymentData } from '../../payment/model/vpos/vpos-payment-data';
import { VposSessionData } from '../../payment/model/vpos/vpos-session-data';
import { VposTransactionAmountData } from '../../payment/model/vpos/vpos-transaction-amount-data';
import { VposTransactionOrderData } from '../../payment/model/vpos/vpos-transaction-order-data';
import { VposTransactionSecurityData } from '../../payment/model/vpos/vpos-transaction-security-data';
import { VposService } from '../../payment/services/vpos.service';
import { PolicyProcessData } from '../../product-workflow/model/policy/PolicyProcessData';
import { IProductWorkflowService } from '../../product-workflow/model/product-workflow-service';
import { ScreenManagersModule } from '../screen-managers.module';
import { MissingSignatureError } from './model/missing-signature-error';
import { IPropertyPaymentScreen } from './property-payment-screen';
import { CONFIGS, INSURANCE } from '../../constant/config/config';
import { SomethingWentWrongService } from '../../modals/services/something-went-wrong.service';
import { localeValPipe } from '../../flow/pipes/localeVal.pipe';
import { LocalizationService } from '../../flow/services/localization.service';
import { PartyIdService } from '../../flow/services/party-id.service';
import { PartyType } from '../../model/PartyType';
import { BtrustAccountData } from '../../payment/model/btrust/btrust-account-data';




@Injectable({
  providedIn: ScreenManagersModule
})
export class PaymentScreenVposManagerService {

  screen: IPropertyPaymentScreen;
  productWorkflow: IProductWorkflowService;

  private cache: {vpos: VposPaymentData | undefined} = {
    vpos: undefined,
  }

  constructor(
    private vposService: VposService,
    private esignatureService: EsignatureService,
    private confirmationService: ConfirmationPopupService,
    public somethingWentWrongService: SomethingWentWrongService,
    public partyIdService:PartyIdService,
  ) { }

  public async fetchVposData(): Promise<VposPaymentData | undefined> {
    if (! this.cache.vpos) {
      this.cache.vpos = await this.vposService.getPaymentMethodInformation();
    }

    return this.cache.vpos;
  }

  public async getTransactionSecurityData(tokenReqData: { amount: number, trtype: number, order: number }): Promise<VposTransactionSecurityData> {
    return await this.vposService.getTransactionSecurityData(tokenReqData);
  }

  public async submitClick(paymentComponent: VposPaymentFormComponent, amount: number, isCardOwner: boolean, lang: string, productCode: number,isEidentificationRequired:boolean) {
    if (isCardOwner) {
      try {
        await this.submitVpos(paymentComponent, amount, isCardOwner, lang);
      } catch (e) {
        this.screen.navigateToErrorScreen();
      }
    } else {

      const pid = this.screen.getEsignatureHolderPID();

      if (! pid) {
        throw new Error('User ID not found');
      }

      const pidType = this.partyIdService.getType(pid);
      let entityType:string = '';

      if (! pidType) {
        throw new Error('User ID Type not found');
      }

      if(pidType === PartyType.PERSON){
        entityType = 'EGN'
      }else if(pidType === PartyType.FOREIGNER){
        entityType = 'LNC'
      }

      const hasValidsignature = await this.esignatureService.hasValidsignature(pid,entityType);
      const btrustAccountData: BtrustAccountData = {
        hasBtrustAccount:hasValidsignature
      }
      this.productWorkflow.setBtrustAccountData(btrustAccountData);

      if (isEidentificationRequired){
          let continueConfirmed : boolean =false;
          if(productCode === INSURANCE.MTPL){
            const title = new localeValPipe(new LocalizationService()).transform('e-Signature Notice',lang.toLocaleLowerCase());
            continueConfirmed = await this.confirmationService.confirm(
              {
                type: 'credit_debit_card',
                alertMessage: 'If you are not the card holder you will be asked to sign the policy with electronic signature!.',
                buttonText: 'Continue',
                buttonType: 'primary',
              },
              {
                title: title,
                footerInScrollArea: true
              }
            );
          }else{
            continueConfirmed = true;
          }

          if (continueConfirmed) {
              this.submitVpos(paymentComponent, amount, isCardOwner, lang)
          }
          
      }else {
        if(productCode === INSURANCE.TRAVEL){
          this.submitVpos(paymentComponent, amount, isCardOwner, lang);
        }
        else{
          if (hasValidsignature) {
            let continueConfirmed : boolean =false;
            if(productCode === INSURANCE.MTPL){
              const title = new localeValPipe(new LocalizationService()).transform('e-Signature Notice',lang.toLocaleLowerCase());
              continueConfirmed = await this.confirmationService.confirm(
                {
                  type: 'credit_debit_card',
                  alertMessage: 'If you are not the card holder you will be asked to sign the policy with electronic signature!.',
                  buttonText: 'Continue',
                  buttonType: 'primary',
                },
                {
                  title: title,
                  footerInScrollArea: true
                }
              );
            }else{
              continueConfirmed = true;
            }

            if (continueConfirmed) {
                this.submitVpos(paymentComponent, amount, isCardOwner, lang)
            }
          } else {
            throw new MissingSignatureError();
          }
        }
      }

    }
  }

  private async submitVpos(paymentComponent: VposPaymentFormComponent, amount: number, isCardOwner: boolean, lang: string) {
      const paymentData = await this.fetchVposData();

      if (! paymentData) {
        throw new Error('Payment data not found!');
      }

      const registerData = await this.screen.registerPolicyPayment();

      if (!registerData || !registerData.success || ! registerData.data || ! registerData.data.vposNewPolicyCreationData) {
        //TODO: Add message to the user
        this.somethingWentWrongService.showInternalServerDialog();
        throw new Error('Invoice data not recieved correctly!');
      }

      const processId = registerData.data.policy_creation_process_id;
      const eIdentifyId = registerData.data.eIdentificationId;
      const orderId = parseInt(registerData.data.vposNewPolicyCreationData.order)

      const vposData: VposSessionData = {
        'cardowner': isCardOwner,
      }

      const policyProcessData: PolicyProcessData = {
        policy_creation_process_id: processId,
        eIdentificationId: eIdentifyId
      }
      const amountFloat = parseFloat(amount.toString()).toFixed(2);
      const formatedAmount: number = +amountFloat;
      const amountData: VposTransactionAmountData = {
        amount: amountFloat,
        trType: BoricaVposTransactionTypes.RESERVE_AMOUNT.toString(),
        currency: CONFIGS.CURRENCY,
        lang: lang,
      };
      const orderData: VposTransactionOrderData = {
        order: orderId.toString(),
        customBoricaOrderId: `${orderId}AAAABBBBCCCCDDDD`,
      }

      this.productWorkflow.setPolicyProcessData(policyProcessData);
      this.productWorkflow.setVposData(vposData);
      this.productWorkflow.setEpayData(undefined);
      this.productWorkflow.setInvoiceData({invoice: orderId.toString(), amount: formatedAmount, currency: CONFIGS.CURRENCY});

      const tokenData = {amount: formatedAmount, trtype: BoricaVposTransactionTypes.RESERVE_AMOUNT, order: orderId};
      const securityData = await this.getTransactionSecurityData(tokenData);

      await paymentComponent.submit({
        ...paymentData,
        ...orderData,
        ...amountData,
        ...securityData,
      });
  }

}
