import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncCrosshairSvgModule
 */
@Component({
  selector: 'svg[systemcodeActionFuncCrosshair]',
  template: '<svg:path d="M20.8 11.6h-1.2c-.2-3.9-3.3-7-7.2-7.2V3.2c0-.2-.2-.4-.4-.4s-.4.2-.4.4v1.2c-3.9.2-7 3.3-7.2 7.2H3.2c-.2 0-.4.2-.4.4s.2.4.4.4h1.2c.2 3.9 3.3 7 7.2 7.2v1.2c0 .2.2.4.4.4s.4-.2.4-.4v-1.2c3.9-.2 7-3.3 7.2-7.2h1.2c.2 0 .4-.2.4-.4s-.2-.4-.4-.4zm-8.4 7.2v-1.2c0-.2-.2-.4-.4-.4s-.4.2-.4.4v1.2c-3.4-.2-6.1-2.9-6.3-6.3h1.2c.2 0 .4-.2.4-.4s-.2-.4-.4-.4H5.2c.2-3.4 2.9-6.1 6.3-6.3v1.2c0 .2.2.4.4.4s.4-.2.4-.4V5.2c3.4.2 6.1 2.9 6.3 6.3h-1.2c-.2 0-.4.2-.4.4s.2.4.4.4h1.2c0 3.5-2.8 6.3-6.2 6.5z"></svg:path><svg:circle cx="12" cy="12" r="1.2"></svg:circle>',

  styles: [
    ':host { fill: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncCrosshairSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncCrosshairSvgComponent,
  ],
  exports: [
    ActionFuncCrosshairSvgComponent,
  ],
})
export class ActionFuncCrosshairSvgModule {}
