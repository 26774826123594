import { Injectable } from '@angular/core';

import { PolicyNumberType } from '../../model/PolicyNumberType';
import { EgnValidatorService } from '../validation/egn/egn-validator.service';
import { EikValidatorService } from '../validation/eik/eik-validator.service';
import { LnchValidatorService } from '../validation/lnch/lnch-validator.service';

@Injectable({
    providedIn: 'root',
})
export class PolicyNumberValidatorService {
    private readonly INSIS9 = /^\d{15}(\/\d{1,2})?$/;
    private readonly INSIS10 = /^\d{16}(\/\d{1,2})?$/;
    private readonly MTPL = /^BG\/06\/\d{12}$/;
    private readonly MTPL_97 = /^BG\/97\/\d{12}$/;
    public constructor(private egn: EgnValidatorService,
        private eik: EikValidatorService,
        private lnch: LnchValidatorService) { }

    public getType(val: string): PolicyNumberType | undefined {
        if (val.replace(/0/g, '').trim() === '') {
            return undefined;
        }

        if (this.egn.isValidFormat(val)) {
            if (this.egn.isValid(val)) {
                return PolicyNumberType.PERSON;
            } else if (this.lnch.isValidFormat(val) && this.lnch.isValid(val)) {
                return PolicyNumberType.FOREIGNER;
            }
        } else if (this.eik.isValidFormat(val)) {
            if (this.eik.isValid(val)) {
                return PolicyNumberType.COMPANY;
            }
        } else if (this.lnch.isValidFormat(val) && this.lnch.isValid(val)) {
            return PolicyNumberType.FOREIGNER;
        }
        else if (val && val.match(this.INSIS9)) {
            return PolicyNumberType.INSIS9;
        }
        else if (val && val.match(this.INSIS10)) {
            return PolicyNumberType.INSIS10;
        }
        else if (val.length === 18 && (val.match(this.MTPL) || val.match(this.MTPL_97) )) {
            return PolicyNumberType.MTPL;
        }
        return undefined;
    }
}
