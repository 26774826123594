<ng-container>
    {{alertMessage | localeVal:lang}}
</ng-container>


<ng-container *maiaPopUpFooter>
    <div *ngIf="isTwoButtonPresent">
        <button maiaButton [maiaButtonType]="declineButtonType"  (click)="cancel()">{{declineButtonName | localeVal:lang}}</button>
        <button maiaButton [maiaButtonType]="acceptButtonType" (click)="continue()">{{acceptButtonName | localeVal:lang}}</button>
    </div>
    <div *ngIf="!isTwoButtonPresent">
        <button maiaButton [maiaButtonType]="acceptButtonType" (click)="continue()">{{acceptButtonName | localeVal:lang}}</button>
    </div>
    
</ng-container>