import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {FlowChapterState} from './flow-chapter-state.enum';

/**
 * @ngModule FlowChapterModule
 */
@Component({
  selector: 'maia-flow-chapter-group',
  templateUrl: './flow-chapter-group.component.html',
  styleUrls: ['./flow-chapter-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowChapterGroupComponent {
  private _currentStep = 0;
  private _chapters: string[];

  @Input()
  public set currentStep(step: number) {
    if (step == null) {
      return;
    }

    this._currentStep = step;
  }

  public get currentStep() {
    return this._currentStep;
  }

  @Input()
  public set chapters(chapters: string[]) {
    if (!chapters || !chapters.length) {
      return;
    }

    this._chapters = chapters.slice();
  }

  public get chapters() {
    return this._chapters;
  }

  /**
   * @returns the current state of a step for a given index
   *
   * @param index
   */
  public getStepState(index: number) {
    if (index > this.currentStep) {
      return FlowChapterState.DISABLED;
    }

    if (index === this.currentStep) {
      return FlowChapterState.ACTIVE;
    }

    return FlowChapterState.COMPLETED;
  }
}
