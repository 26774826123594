export const environment = {
    production: false,
    company: {code: '0001', name: 'KBC'},     // default company
    language: {name: 'English', code: 'en'},  // default language
    API_BASE_URL : 'https://testendpoints.dzi.bg',
    FE_BASE_URL : 'https://testb2c.dzi.bg',
    API_URL: '/api/v1/',
    EPAY_URL: 'https://devep2.datamax.bg/ep2/epay2_demo/',
    RECAPTCHA_V3_SITEKEY: '6Lf_uicbAAAAAMJ31w1t3uschBC6A4tIdIzN812s',
    URL_TRAVEL_INSURANCE_DOC_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/IPID-Travel.pdf',
    URL_TRAVEL_INSURANCE_DOC_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/IPID-Travel-EN.pdf',
    URL_PROPERTY_INSURANCE_DOC_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/IPID-Property.pdf',
    URL_GTC_DOC_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/GTC.pdf',
    URL_TRAVEL_GTC_DOC_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/Travel-GTC.pdf',
    URL_TRAVEL_GTC_DOC_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/Travel-GTC-EN.pdf',
    URL_PROPERTY_GTC_DOC_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/Property-GTC.pdf',
    URL_RAOD_ASSIST_DOC_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/RA.pdf',
    URL_MTPL_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/MTPL.pdf',
    URL_GTC_RA_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/RA-GTC.pdf',
    URL_GTC_RA_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/RA-GTC-EN.pdf',
    URL_GTC_DOC_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/GTC-EN.pdf',
    URL_PROPERTY_INSURANCE_DOC_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/IPID-Property-EN.pdf',
    URL_PROPERTY_GTC_DOC_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/Property-GTC-EN.pdf',
    URL_MTPL_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/MTPL_EN.pdf',
    URL_RAOD_ASSIST_DOC_EN_LINK: 'https://dzi-np-data-static.s3.eu-central-1.amazonaws.com/assets/static/RA_EN.pdf',
    VPOS_SERVICE_EMAIL : 'e2econsult.bg@gmail.com',
    LANDING_PAGE: {
        enablePropertyLink: true,
        enableTravelLink: true,
        enableClaimsLink: true,
        enableLangSelector: true
      },
      ROUTING: {
        enablePropertyRouting: true,
        enableTravelRouting: true,
        enableClaimsRouting: true,
        enableENRouting: true
      },
      LOGIN_DASHBOARD: {
        enablePropertyLink: true,
        enableTravelLink: true,
        enablePropertyTab: true,
        enableTravelTab: true,
        enableQLNotification: true,
        enableQLCasco: true,
        enableQLPropertyDamageNotice: true,
        enableQLClaimStatus: true,
        enableLangSelector: true,
        enableclaimsMenu: true,
        enableFooter: true
    },
    UBB:{
      enableEnglishVersion: true
    },
    TALONE_SCANNING:{
      enableTalonScanning: true
    },
    CONSENT_MANAGEMENT: {
      enableConsentManagement: true,
    },
    E_IDENTIFICATION:{
      enableEidentification: true,
      EIDENTIFY_CALLBACK_URL: 'e-identification/receive-borica-status-update'
    },
    APIS_INTEGRATION:{
      enableAPIS: true
    },
    CLAIMS_NOTIFICATION: {
      enableClaimsNotificationOthers: true,
      enableClaimsNotificationCL: true,
      enableModifiedApplicant: false,
    },
    ENHANCED_ECOMMERCE: {
      enableGTMEvent: false,
    }
}
