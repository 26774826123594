import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprLargeBuildingSvgModule
 */
@Component({
  selector: 'svg[systemcodePlacesExprLargeBuilding]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path class="st0" d="M9.76 5.68h28.48v36.56H9.76z" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M15.6 11.76h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M15.6 17.84h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M15.6 24h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M15.6 30.08h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M26.72 11.76h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M26.72 17.84h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M26.72 24h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M26.72 30.08h5.68" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M27.04 42.24v-6.08h-6.08v6.08" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprLargeBuildingSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprLargeBuildingSvgComponent,
  ],
  exports: [
    PlacesExprLargeBuildingSvgComponent,
  ],
})
export class PlacesExprLargeBuildingSvgModule {}
