import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprConstructionSignSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprConstructionSign]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M11.2 40.8V30.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M16 30.4v10.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M36.8 30.4v10.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M32 40.8V30.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M8 40.8h11.2" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M28.8 40.8H40" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M16 12V7.2h-4.8V12" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M36.8 12V7.2H32V12" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" data-cap="butt" data-color="color-2" d="M11.2 12l14.4 14.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" data-cap="butt" data-color="color-2" d="M6.4 16l10.4 10.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" data-cap="butt" data-color="color-2" d="M31.2 12l10.4 10.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" data-cap="butt" data-color="color-2" d="M22.4 12l14.4 14.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M6.4 12h35.2v14.4H6.4z" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprConstructionSignSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprConstructionSignSvgComponent,
  ],
  exports: [
    InformationExprConstructionSignSvgComponent,
  ],
})
export class InformationExprConstructionSignSvgModule {}
