import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FlagUtilBulgariaSvgModule
 */
@Component({
  selector: 'svg[systemcodeFlagUtilBulgaria]',
  template: '<svg:defs><svg:path id="svgLqgMpN7cUMQMG6TG5R9jp1r/Pw63RZESbsiAB+wskkk=" d="M0 0h48v48H0z"></svg:path></svg:defs><svg:clipPath id="svgUFEbFF68xkm/FtJqHLKqEqKFJfZGGbRfh7aylqdUF9k="><svg:use href="#svgLqgMpN7cUMQMG6TG5R9jp1r/Pw63RZESbsiAB+wskkk=" overflow="visible"></svg:use></svg:clipPath><svg:g clip-path="url(#svgUFEbFF68xkm/FtJqHLKqEqKFJfZGGbRfh7aylqdUF9k=)"><svg:path fill="#FFFFFF" d="M0 0h48v48H0z"></svg:path><svg:path fill="#496E2D" d="M0 0h48v48H0z"></svg:path><svg:path fill="#F0F0F0" d="M0 0h48v16H0z"></svg:path><svg:path fill="#D80027" d="M0 32h48v16H0z"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'xlink': 'http://www.w3.org/1999/xlink',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlagUtilBulgariaSvgComponent {
}

@NgModule({
  declarations: [
    FlagUtilBulgariaSvgComponent,
  ],
  exports: [
    FlagUtilBulgariaSvgComponent,
  ],
})
export class FlagUtilBulgariaSvgModule {}
