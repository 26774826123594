import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationExprVanSvgModule
 */
@Component({
  selector: 'svg[systemcodeTransportationExprVan]',
  template: '<svg:path d="M40 20.54l-9.63-.09a1.79 1.79 0 0 1-1.6-1.35l-2-8.19" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M31.43 32.44h-14" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M8.88 32.44H5V10.91h26.77A3.61 3.61 0 0 1 35 12.85l4.64 7 2.62 3.3a3.66 3.66 0 0 1 .74 2.29v7h-4" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M35.23 29.32a3.89 3.89 0 1 1-3.88 3.88 3.93 3.93 0 0 1 3.88-3.88z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M12.93 29.32a3.89 3.89 0 1 1-3.88 3.88 3.93 3.93 0 0 1 3.88-3.88z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationExprVanSvgComponent {
}

@NgModule({
  declarations: [
    TransportationExprVanSvgComponent,
  ],
  exports: [
    TransportationExprVanSvgComponent,
  ],
})
export class TransportationExprVanSvgModule {}
