import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'systemcode-payment-redirect',
  templateUrl: './payment-redirect.component.html',
  styleUrls: ['./payment-redirect.component.scss'],
})
export class PaymentRedirectComponent implements OnInit, OnDestroy {

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(
    private route:ActivatedRoute,
  ) {}

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.destroyed$)).subscribe(queryParams => {
      let mtplStringValue :string | null = sessionStorage.getItem('mtpl');
      let travelStringValue :string | null = sessionStorage.getItem('travel');
      let PropertyInsuranceDataStringValue :string | null = sessionStorage.getItem('PropertyInsuranceData');
      let policyPaymentStringValue :string | null = sessionStorage.getItem('policy-payment');
      const lang = sessionStorage.getItem('old_lang');
      //const langPath = lang === 'en' ? '/en' : '';
      //this.onLangChange(lang);
      //sessionStorage.setItem('lang',lang.toLowerCase());

      let mtplObject :object | undefined;
      let travelObject :object | undefined;
      let PropertyInsuranceDataObject :object | undefined;
      let policyPaymentObject :object | undefined;

      if(mtplStringValue){
        mtplObject = JSON.parse(mtplStringValue);
      }
      if(travelStringValue){
        travelObject = JSON.parse(travelStringValue);
      }
      if(PropertyInsuranceDataStringValue){
        PropertyInsuranceDataObject = JSON.parse(PropertyInsuranceDataStringValue);
      }
      if(policyPaymentStringValue){
        policyPaymentObject = JSON.parse(policyPaymentStringValue);
      }

      // console.log("session values after parsing",mtplObject,travelObject,PropertyInsuranceDataObject,policyPaymentObject);
      
      const locationStrArray = window.location.href.toString().split('/')
      const hostUrl = locationStrArray[0] + '//' + window.location.host;
      console.log(lang)
      console.log(hostUrl)

      if (queryParams.success === '1') {
        if (mtplObject && Object.keys(mtplObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/mtpl/purchase-policy/payment?success=1`);
          }else{
            window.location.replace(hostUrl+`/mtpl/purchase-policy/payment?success=1`);
          }
          // this.router.navigateByUrl('/mtpl/purchase-policy/payment?success=1');
        } else if (travelObject && Object.keys(travelObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/travel/purchase-policy/travel-payment?success=1`);
          }else{
            window.location.replace(hostUrl+`/travel/purchase-policy/travel-payment?success=1`);
          }
          // this.router.navigateByUrl('/travel/purchase-policy/travel-payment?success=1');
        } else if (PropertyInsuranceDataObject && Object.keys(PropertyInsuranceDataObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/property-insurance/purchase-policy/payment?success=1`);
          }else{
            window.location.replace(hostUrl+`/property-insurance/purchase-policy/payment?success=1`);
          }
          // this.router.navigateByUrl('/property-insurance/purchase-policy/payment?success=1');
        } else if (policyPaymentObject && Object.keys(policyPaymentObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/policy-payment/payment-result?success=1`);
          }else{
            window.location.replace(hostUrl+`/policy-payment/payment-result?success=1`);
          }
          // this.router.navigateByUrl('/policy-payment/payment-result?success=1');
          
        }
      } else if (queryParams.success === '0') {
        if (mtplObject && Object.keys(mtplObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/mtpl/purchase-policy/payment-result?success=0`);
          }else{
            window.location.replace(hostUrl+`/mtpl/purchase-policy/payment-result?success=0`);
          }
          // this.router.navigateByUrl('/mtpl/purchase-policy/payment-result?success=0');
        } else if (travelObject && Object.keys(travelObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/travel/purchase-policy/travel-payment-result?success=0`);
          }else{
            window.location.replace(hostUrl+`/travel/purchase-policy/travel-payment-result?success=0`);
          }
          // this.router.navigateByUrl('/travel/purchase-policy/travel-payment-result?success=0');
        } else if (PropertyInsuranceDataObject && Object.keys(PropertyInsuranceDataObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/property-insurance/purchase-policy/payment-result?success=0`);
          }else{
            window.location.replace(hostUrl+`/property-insurance/purchase-policy/payment-result?success=0`);
          }
          // this.router.navigateByUrl('/property-insurance/purchase-policy/payment-error');
        } else if (policyPaymentObject && Object.keys(policyPaymentObject).length > 0) {
          if(lang !=='bg'){
            window.location.replace(hostUrl+`/${lang}/policy-payment/payment-result?success=0`);
          }else{
            window.location.replace(hostUrl+`/policy-payment/payment-result?success=0`);
          }
          // this.router.navigateByUrl('/policy-payment/payment-result?success=0');
        }
      }
    });
  }
}
