import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { Params, Router } from '@angular/router';

import { ApiService } from '../../../backend/api.service';
import { CONFIGS } from '../../../constant/config/config';
import { GenericResponse } from '../../../model/api/GenericResponse';
import { ESignEmitResult } from '../../../model/dto/e-sign/ESignEmitResult';
import { ESignReqestModel } from '../../../model/dto/e-sign/ESignReqestModel';
import { PaymentFailureDto } from '../../../model/dto/PaymentFailureDto';
import { EmptyDataAlertModel } from '../../../product-workflow/model/EmptyDataAlertModel';
import { ValidPolicyCheckData } from '../../model/valid-policy-check-data';

@Component({
  selector: 'e-signature-notification',
  templateUrl: './e-signature-notification.component.html',
  styleUrls: ['./e-signature-notification.component.scss'],
})
export class ESignatureNotificationComponent implements OnInit {

  @Input()
  public alertNavigatePath: string;

  @Input()
  public tryAgainLink: string;

  public eSignReqestModel : ESignReqestModel;

  @Input()
  public set eSignReqModel(eSignReqestModel : ESignReqestModel){
    console.log("##############core-esign-verification",eSignReqestModel)
    if (Object.keys(eSignReqestModel).length > 0) {
      this.eSignReqestModel=eSignReqestModel;
    }
  }

  @Input()
  public validPolicyCheckData: ValidPolicyCheckData;

  @Output()
  public navigateToSuccess = new EventEmitter();

  @Output()
  public navigateToError = new EventEmitter<PaymentFailureDto>();

  @Output()
  public offerDetailsFetched = new EventEmitter<unknown>();

  @Output()
  public policyNumberFetched = new EventEmitter<unknown>();

  public reason: string;

  public queryParams: Params;
  public emptyDataAlertModel: EmptyDataAlertModel;
  public showEmptyAlertScreen: boolean = false;
  public expiryAlerMsg = 'The Link to your proposal has expired';
  public headingAlertMessge = 'Link Expired';
  public policyNumberObj : {policy_number : string} ; 

  public constructor(
    public router: Router,
    public _ngZone: NgZone,
    public datePipe: DatePipe,
    public api: ApiService,
  ) {}

  public async ngOnInit() {
    if (this.validPolicyCheckData) {
      await this.checkValidPolicyLink(this.validPolicyCheckData);
    }
  }

  private async checkValidPolicyLink(data: ValidPolicyCheckData) {
    const validPolicyReq = {
      'encryptedPolicyNumber': data.policyNumber,
      'encryptedTimestamp': data.timestamp,
      'encryptedOfferId': data.offerId,
    }

    try {
      const result = await this.api.checkValidPolicyLinkApi(validPolicyReq).toPromise();
      if (result.success) {
        this.policyNumberObj = result.data;
        this.policyNumberFetched.emit(this.policyNumberObj.policy_number);
        await this.fetchAllDataFromServer(data.offerId);
      } else {
        this.headingAlertMessge = 'Link Expired';
        if (result.expiryDate) {
          const eDate = result.expiryDate;
          const expDate = new Date(eDate.replace(/-/g, '/'));
          const expirydate_full = this.datePipe.transform(expDate, 'dd/MM/yyyy (HH:mm)');
          this.expiryAlerMsg = this.expiryAlerMsg + ' on ' + expirydate_full;
        } else {
          this.expiryAlerMsg = 'Its Not a valid link.'
        }
        this.linkExpiredScreen();
      }
    } catch (error) {
      this.reason = error;
      this.headingAlertMessge = 'No Data Found';
      this.expiryAlerMsg = 'Its Not a valid link.'
      this.linkExpiredScreen();
    }
  }

  public linkExpiredScreen() {
    this.emptyDataAlertModel = {
      'type': 'summaryLinkExpiry',
      'imageUrl': '../../assets/img/invalid_link_data.svg',
      'headingAlertMessge': this.headingAlertMessge,
      'alertMessage': this.expiryAlerMsg,
      'messgaeBarBgColor': '#EEF6FA',
      'messageTextColor': '#003665',
      'buttonText': 'Create a new policy',
    };
    this.showEmptyAlertScreen = true;
  }

  public async fetchAllDataFromServer(offerId: any) {
      const result = await this.api.get<GenericResponse<{offer_details: unknown}>>(`mtpl/get-offer-details/${encodeURIComponent(offerId)}`).toPromise();
      if (result.success === true && result.hasOwnProperty('data')) {
        this.offerDetailsFetched.emit(result.data.offer_details);
      } else if (result.success === false) {
        this.headingAlertMessge = 'Well, that’s awkward...',
        this.expiryAlerMsg = 'Its Not a valid link.',
        this.linkExpiredScreen();
      } else {
        //TODO: redirect to generic error screen
      }
  }

  public emitESignResult(event: ESignEmitResult){
    if(event && event.status === CONFIGS.E_SIGN_SUCCESS){
      this.navigateToSuccess.emit();
    }else if (event && event.status === CONFIGS.E_SIGN_FAIL){
      this.navigateToError.emit(event.failureResponseData);
    }
  }

  public navigateTo(urlPath: any): any {
    this.router.navigateByUrl(urlPath)
  }

  public alertNavigate() {
    this.router.navigateByUrl(this.alertNavigatePath);
  }
}
