export enum RoutePaths {
  TI = 'travel',
  TI_INSURED_PEOPLE = 'insured-people',
  TI_INSURED_PEOPLE_LIST = 'insured-people-list',
  TI_TRIP_DETAILS = 'trip-details',
  TI_TRAVEL_COVERS = 'travel-covers',
  TI_ADD_ONS = 'add-ons',
  TI_TRAVEL_SUMMARY = 'travel-summary',

  // Purchase Policy
  PURCHASE_POLICY = 'purchase-policy',
  TI_INSURING_PARTY = 'insuring-party',
  TI_CONTACT_DETAILS = 'contact-details',
  TI_PINSURED_PEOPLE = 'pinsured-people',
  TI_PSUMMARY_DETAILS = 'psummary-details',
  TI_TRAVEL_PAYMENT = 'travel-payment',
  TI_TRAVEL_ESIGNATURE= 'e-signature',
  TI_PAY_RESULT = 'travel-payment-result',
  TI_ESIGNATURE = 'e-signature',
  TI_ESIGN_NOTIFY ='e-signature-notification',
  TI_EIDENTIFICATION = 'e-identification-success',
  TI_EIDENTITY_NOTIFY ='e-identification-notification',
  TI_POLICY_CREATION_ERROR = 'payment-error',

}
