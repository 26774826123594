import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule NavigationFuncCrossSvgModule
 */
@Component({
  selector: 'svg[systemcodeNavigationFuncCross]',
  template: '<svg:path d="M19.9 4.1L4.1 19.9" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M19.9 19.9L4.1 4.1" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncCrossSvgComponent {
}

@NgModule({
  declarations: [
    NavigationFuncCrossSvgComponent,
  ],
  exports: [
    NavigationFuncCrossSvgComponent,
  ],
})
export class NavigationFuncCrossSvgModule {}
