import { AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { City } from '../../model/geo/City';
import { LinkedPairController } from './LinkedPairController';

export class CityAndPostCodeController extends LinkedPairController<City> {

  public constructor(
    cityControl: AbstractControl,
    postCodeControl: AbstractControl,
    destroyed$?: Subject<any>,
  ) {
    super(cityControl, postCodeControl, 'postal_code', destroyed$);
  }

}
