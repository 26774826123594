import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { PartyIdService } from '../../services/party-id.service';


export function egnEikLnch(partyId: PartyIdService): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
        if (
            control.value && !partyId.getType(control.value)
        ) {
            return { invalidNumber: true };
        }
        return null;
    }
}
