export interface LocaleConfig {
    [key: string]: {
        en: string;
        bg: string;
    }
}


export const Config = {
    "Home": {
        en: "Home",
        bg: "Начална страница"
    },
    "Payments": {
        en: "Payments",
        bg: "Плащания"
    },
    "All policies": {
        en: "All policies",
        bg: "Полици"
    },
    "Claims": {
        en: "Claims",
        bg: "Щети"
    },
    "Settings": {
        en: "Settings",
        bg: "Настройки"
    },
    "Total amount of payment": {
        en: "Total amount of payment",
        bg: "Обща сума на плащането"
    },
    "For selected instalments": {
        en: "For selected instalments",
        bg: "За избрани вноски"
    },
    "Search Policy": {
        en: "Search Policy",
        bg: "Политика за търсене"
    },
    "Instalments": {
        en: "Instalments",
        bg: "Вноски"
    },
    "Delivery": {
        en: "Delivery",
        bg: "Доставка"
    },
    "Summary": {
        en: "Summary",
        bg: "Резюме"
    },
    "Payment": {
        en: "Payment",
        bg: "Плащане"
    },
    "Instalment": {
        en: "Instalment",
        bg: "Вноска"
    },
    "Due date": {
        en: "Due date",
        bg: "Краен срок за плащане"
    },
    "Status": {
        en: "Status",
        bg: "Статус"
    },
    "Amount": {
        en: "Amount",
        bg: "Сума"
    },
    "Currency": {
        en: "Currency",
        bg: "Валута"
    },
    "Insured People": {
        en: "Insured People",
        bg: "Застраховани лица"
    },
    "Trip Details": {
        en: "Trip Details",
        bg: "Данни за пътуването"
    },
    "Covers": {
        en: "Covers",
        bg: "Покрития"
    },
    "Additional Covers": {
        en: "Additional Covers",
        bg: "Допълнителни покрития"
    },
    "Insuring Party": {
        en: "Insuring Party",
        bg: "Застраховащ"
    },
    "Contact Details": {
        en: "Contact Details",
        bg: "Данни за контакт"
    },
    "Up to 16 years old": {
        en: "Up to 16 years old",
        bg: "до 16-годишна възраст"
    },
    "16 to 65 years of age": {
        en: "16 to 65 years of age",
        bg: "от 16 до 65-годишна възраст"
    },
    "65 to 70 years of age": {
        en: "65 to 70 years of age",
        bg: "от 65 до 70-годишна възраст"
    },
    "Your changes has been made from other devices/browsers and if you would like to proceed further.": {
        en: "Your changes has been made from other devices/browsers and if you would like to proceed further.",
        bg: "Вашите промени бяха направени от други устройства/ браузъри и ако желаете можете да продължите нататък."
    },
    "Back":{
        en:"Back",
        bg:"Назад"
    },
    "Cancel":{
        en:"Cancel",
        bg:"Отмени"
    },
    "Continue":{
        en:"Continue",
        bg:"Продължи"
    },
    "close":{
        en:"close",
        bg:"затвори"
    },
    "delete":{
        en:"delete",
        bg:"изтрий"
    },
    "Not now":{
        en:"Not now",
        bg:"Не сега"
    },
    "Email Sent Successfully":{
        en:"Email Sent Successfully",
        bg:"Имейлът е изпратен успешно"
    },
    "Vehicle": {
        en: "Vehicle",
        bg: "Превозно средство"
    },
    "Policy Details": {
        en: "Policy Details",
        bg: "Данни за полицата"
    },
    "Personal info": {
        en: "Personal info",
        bg: "Лични данни"
    },
    "Company Details": {
        en: "Company Details",
        bg: "Данни за фирмата"
    },
    "People": {
        en: "People",
        bg: "Лица"
    },
    "Person": {
        en: "Person",
        bg: "Лице"
    },
    "to": {
        en: "to",
        bg: "до"
    },
    "You have no": {
        en: "You have no",
        bg: "Вие нямате"
    },
    "Vehicle Owner": {
        en: "Vehicle Owner",
        bg: "Собственик на МПС"
    },
    "Talon": {
        en: "Talon",
        bg: "Талон"
    },
    "No additional covers included":{
        en: "No additional covers included",
        bg: "Не са включени допълнителни покрития"
    },
    "Please enter the details of the owner / insurer according to the vehicle registration certificate.": {
        en: "Please enter the details of the owner / insurer according to the vehicle registration certificate.",
        bg: "Моля въведете данни за собственик/застраховащ съгласно свидетелство за регистрация на МПС (талон)."
    },
    "Select a Vehicle":{
        en: "Select a Vehicle",
        bg: "Избери МПС"
    },
    "New Vehicle": {
        en: "New Vehicle",
        bg: "Ново МПС"
    },
    "Enter new vehicle details below": {
        en: "Enter new vehicle details below",
        bg: "Въведете данните на новото МПС по-долу"
    },
    "No Due Instalments": {
        en: "No Due Instalments",
        bg: "Няма дължими вноски"
    },
    "Payment Successful":{
        en: "Payment Successful",
        bg: "Плащането е извършено успешно"
    },
    "Policy Created": {
        en: "Policy Created",
        bg: "Полицата е създадена"
    },
    "Go to home page": {
        en: "Go to home page",
        bg: "Към начална страница"
    },
    "Payment Failed": {
        en: "Payment Failed",
        bg: "Плащането е неуспешно"
    },
    "Change Payment Method": {
        en: "Change Payment Method",
        bg: "Променете метода на плащане"
    },
    "Contact Support": {
        en: "Contact Support",
        bg: "Свържете се с нас"
    },
    "Complete profile":{
        en: "Complete profile",
        bg: "Попълнете профила"
    },
    "Your MTPL policy was successfully created and sent to your email": {
        en: "Your MTPL policy was successfully created and sent to your email",
        bg: "Вашата полица “Гражданска отговорност” бе успешно създадена и изпратена на имейла Ви."
    },
    "We created your profile automatically. You can complete it right away!": {
        en: "We created your profile automatically. You can complete it right away!",
        bg: "Създайте профил за лесно управление на Вашите активности и полици в портала, като въведете парола!"
    },
    "Your Standard travel insurance policy was successfully created and sent to your email'": {
        en: "Your Standard travel insurance policy was successfully created and sent to your email'",
        bg: "Вашата стандартна полица “Застраховка за пътуване” бе успешно създадена и изпратена на имейла Ви."
    },
    " travel insurance policy was successfully created and sent to your email": {
        en: " insurance policy was successfully created and sent to your email",
        bg: " полица “Застраховка за пътуване” бе успешно създадена и изпратена на имейла Ви."
    },
    "Property Insurance Policy was successfully created and sent to your email.": {
        en: "Property Insurance Policy was successfully created and sent to your email.",
        bg: "Имуществената застраховка е създадена успешно и изпратена на вашия имейл."
    },
    "Your payment could not be processed":{
        en:"Your payment could not be processed",
        bg:"Плащането Ви не може да бъде обработено."
    },
    "Please consider paying with another payment method.":{
        en:"Please consider paying with another payment method.",
        bg:"Моля, изберете друг метод на плащане."
    },
    "We couldn’t charge your account":{
        en:"We couldn’t charge your account",
        bg:"Не можахме да таксуваме сметка ви"
    },
    "Your":{
        en:"Your",
        bg:"Вашият"
    },
    "Due Instalments": {
        en: "Due Instalments",
        bg: "Дължими вноски"
    },
    "Billing history": {
        en: "Billing history",
        bg: "История на фактурирането"
    },
    "No results found": {
        en: "No results found",
        bg: "Не бяха намерени резултати"
    },
    "it seems we can't show results. adjust your selection and try again": {
        en: "it seems we can't show results. adjust your selection and try again",
        bg: "Не открихме резултати по зададените критерии. Променете критериите и опитайте отново."
    },
    "Debit/Credit card":{
        en:"Debit/Credit card",
        bg:"Дебитна/Кредитна карта"
    },
    "Search for policy": {
        en: "Search for policy",
        bg: "Потърси полица"
    },
    "Active": {
        en: "Active",
        bg: "Активна"
    },
    "Archive": {
        en: "Archive",
        bg: "Архив"
    },
    "Draft": {
        en: "Draft",
        bg: "Чернова"
    },
    "Personal Data": {
        en: "Personal Data",
        bg: "Лични данни"
    },
    "Insurance Objects": {
        en: "Insurance Objects",
        bg: "Застрахователни обекти"
    },
    "Delivery Addresses": {
        en: "Delivery Addresses",
        bg: "Адреси за доставка "
    },
    "Account Settings": {
        en: "Account Settings",
        bg: "Настройки на профила"
    },
    "Password": {
        en: "Password",
        bg: "Парола"
    },
    "Notifications": {
        en: "Notifications",
        bg: "Уведомления"
    },
    "Profile": {
        en: "Profile",
        bg: "Профил"
    },
    "Account": {
        en: "Account",
        bg: "Сметка"
    },
    "Vehicles": {
        en: "Vehicles",
        bg: "Превозни средства"
    },
    "Property": {
        en: "Property",
        bg: "Имущество"
    },
    "Create new": {
        en: "Create new",
        bg: "Сключи застраховка"
    },
    "Coming Soon": {
        en: "Coming Soon",
        bg: "Очаквайте скоро"
    },
    "MTPL": {
        en: "MTPL",
        bg: "Гражданска отговорност"
    },
    "MTPL Insurance": {
        en: "MTPL Insurance",
        bg: "Гражданска отговорност"
    },
    "Property Insurance": {
        en: "Property Insurance",
        bg: "Имущество"
    },
    "Travel Insurance": {
        en: "Travel Insurance",
        bg: "Помощ при пътуване"
    },
    "Property Insurance Package Name": {
        en: "- Property Insurance",
        bg: "- застраховка на имущество “ДЗИ - Комфорт за дома“"
    },
    "Credit/Debit Card":{
        en:"Credit/Debit Card",
        bg:"Кредитна/дебитна карта"
    },
    "ePay": {
        en: "ePay",
        bg: "Epay"
    },
    "Travel": {
        en: "Travel",
        bg: "Пътуване"
    },
    "Expires on": {
        en: "Expires on",
        bg: "Изтича на"
    },
    "Expired at":{
        en: "Expired at",
        bg: "Изтекла е на"
    },
    "Policies":{
        en:"Policies",
        bg:"Полици"
    },
    "Download":{
        en:"Download",
        bg:"Сваляне"
    },
    "Paid date":{
        en:"Paid date",
        bg:"Дата на плащане"
    },
    "Your policy will be expiring soon. Renew your policy now.":{
        en:"Your policy will be expiring soon. Renew your policy now.",
        bg:"Вашата полица изтича скоро. Подновете полицата сега."
    },
    "Payment Details":{
        en:"Payment Details",
        bg:"Данни за плащане"
    },
    "Select payment option":{
        en:"Select payment option",
        bg:"Изберете опция за плащане"
    },
    "Property Details": {
        en:"Property Details",
        bg:"Данни за имуществото"
    },
    "Cover Package": {
        en:"Cover Package",
        bg:"Пакет с покритие"
    },
    "Property Owner": {
        en:"Property Owner",
        bg:"Собственик на имуществото"
    },
    "Property Address":{
        en:"Property Address",
        bg:"Адрес на имуществото"
    },
    "Beneficiary":{
        en:"Beneficiary",
        bg:"Ползващо лице"
    },
    "Children":{
        en:"Children",
        bg:"Деца"
    },
    "Adults":{
        en:"Adults",
        bg:"Лица в активна възраст"
    },
    "Elders":{
        en:"Elders",
        bg:"Лица в пенсионна възраст"
    },
    "child":{
        en:"child",
        bg:"дете"
    },
    "adult":{
        en:"adult",
        bg:"възрастен"
    },
    "Adult":{
        en:"Adults",
        bg:"Възрастни"
    },
    "elder":{
        en:"elder",
        bg:"лице над 65 г"
    },
    "16 to 65 years old":{
        en:"16 to 65 years old",
        bg:"от 16 до 65-годишна възраст"
    },
    "65 to 70 years old":{
        en:"65 to 70 years old",
        bg:"от 65 до 70-годишна възраст"
    },
    "Send to email":{
        en:"Send to email",
        bg:"Изпрати на имейл"
    },
    "Payment of Instalments": {
        en:"Payment of Instalments",
        bg:"Плащане на вноски"
    },
    "Policy creation": {
        en:"Policy creation",
        bg:"Създаване на полица"
    },
    "Go to":{
        en:"Go to",
        bg:"Отиди на"
    },
    "Personal Details":{
        en:"Personal Details",
        bg:"Лични данни"
    },
    "Company Name":{
        en:"Company Name",
        bg:"Име на фирмата"
    },
    "Full Name":{
        en:"Full Name",
        bg:"Пълно име"
    },
    "Company Name in English":{
        en:"Company Name in English",
        bg:"Име на фирмата на английски език"
    },
    "Name in English":{
        en:"Name in English",
        bg:"Пълно име на латиница"
    },
    "Representative LNCH":{
        en:"Representative LNCH",
        bg:"Представител ЛНЧ"
    },
    "Representative EGN":{
        en:"Representative EGN",
        bg:"Представител ЕГН"
    },
    "Contact Person LNCH":{
        en:"Contact Person LNCH",
        bg:"Лице за контакт ЛНЧ"
    },
    "Contact Person EGN":{
        en:"Contact Person EGN",
        bg:"Лице за контакт ЕГН"
    },
    "If you are not the card holder you will be asked to sign the policy with electronic signature!.":{
        en:"If you are not the card holder you will be asked to sign the policy with electronic signature!.",
        bg:"Ако картодържателят не сте Вие, ще бъдете помолен/а да подпишете полицата с електронен подпис!."
    },
    "e-Signature Notice": {
        en:"e-Signature Notice",
        bg:"Уведомление за електронен подпис "
    },
    "Are you sure want to delete this draft?":{
        en:"Are you sure want to delete this draft?",
        bg:"Сигурни ли сте, че искате да изтриете тази чернова?"
    },
    "Your Password has been changed successfully...!":{
        en:"Your Password has been changed successfully...!",
        bg:"Вашата парола бе променена успешно...!"
    },
    "The EGN you entered in the insuring party is not identical to your profile. You can continue as a guest or login and loose your progress.": {
        en:"The EGN you entered in the insuring party is not identical to your profile. You can continue as a guest or login and loose your progress.",
        bg:"ЕГН-то, което въведохте за застраховащ не съответства на това в профила Ви. Можете да продължите като гост или да се логнете в профила си и да изтриете вече въведената информация."
    },
    "The LNCH you entered in the insuring party is not identical to your profile. You can continue as a guest or login and loose your progress.": {
        en:"The LNCH you entered in the insuring party is not identical to your profile. You can continue as a guest or login and loose your progress.",
        bg:"ЛНЧ, който въведохте за застраховащ не съответства на този в профила Ви. Можете да завършите процеса като гост или да се логнете в профила си, но ще изгубите дотук въведената информация."
    },
    "The EIK you entered in the insuring party is not identical to your profile. You can continue as a guest or login and loose your progress.": {
        en:"The EIK you entered in the insuring party is not identical to your profile. You can continue as a guest or login and loose your progress.",
        bg:"ЕИК, който въведохте за застраховащ не съответства на този в профила Ви. Можете да завършите процеса като гост или да се логнете в профила си, но ще изгубите дотук въведената информация."
    },
    "Entered Details Successfully Updated":{
        en:"Entered Details Successfully Updated",
        bg:"Въведените данни са актуализирани успешно"
    },
    "Payment Gateway under progress": {
        en:"Payment Gateway under progress",
        bg:"В момента платежният портал обработва информацията"
    },
    "MTPL Calculator": {
        en: "MTPL Calculator",
        bg: "Калкулатор за гражданска отговорност"
    },
    "Purchase MTPL Policy": {
        en: "Purchase MTPL Policy",
        bg: "Купи полица “Гражданска отговорност”"
    },
    "Property Calculator":{
        en:"Property Calculator",
        bg:"Калкулатор за застраховка на имущество “ДЗИ - Комфорт за дома“"
    },
    "Purchase Property Policy":{
        en:"Purchase Property Policy",
        bg:"Купи застраховка на имущество “ДЗИ - Комфорт за дома“"
    },
    "Renew Policy":{
        en:"Renew Policy",
        bg:"Поднови полица"
    },
    "property policy":{
        en:"Property policy",
        bg:"Имуществена застраховка"
    },
    "Yearly":{
        en:"Yearly",
        bg:"Годишно"
    },
    "Termless":{
        en:"Termless",
        bg:"Безсрочна"
    },
    "Every 6 months":{
        en:"Every 6 months",
        bg:"На всеки 6 месеца"
    },
    "Every 3 months":{
        en:"Every 3 months",
        bg:"На всеки 3 месеца"
    },
    "Monthly":{
        en:"Monthly",
        bg:"Месечно"
    },
    "Add person":{
        en:"Add Person",
        bg:"Добави лице"
    },
    "Add": {
        en: "Add",
        bg: "Добави"
    },
    "Remove person":{
        en:"Remove Person",
        bg:"Премахни лице"
    },
    "per instalment":{
        en:"per instalment",
        bg:"на вноска"
    },
    "Buildings that are under construction or rough construction, those that are not put into regular operation":{
        en:"Buildings that are under construction or rough construction, those that are not put into regular operation",
        bg:"Сгради, които са на етап изграждане или груб строеж, както и ако не са въведени в редовна експлоатация;"
    },
    "Buildings with poor construction, including vans, caravans, tents":{
        en:"Buildings with poor construction, including vans, caravans, tents",
        bg:"Сгради с паянтова конструкция, включително фургони, каравани, палатки;"
    },
    "Sites with Production and Economic purpose, Farm and Agricultural buildings, Goods, Materials and Agricultural Products":{
        en:"Sites with Production and Economic purpose, Farm and Agricultural buildings, Goods, Materials and Agricultural Products",
        bg:"Обекти с производствено и стопанско предназначение, стопански и селскостопански сгради, стоки, материали и селскостопанска продукция;"
    },
    "Movable property stored in basements, attics":{
        en:"Movable property stored in basements, attics",
        bg:"Движимо имущество, съхранявано в мазета, тавани;"
    },
    "Documents, cash, photographs, precious metals, precious stones. Objects of: artistic, historical, philatelic or numestic value. Antiques or products of intellectual property":{
        en:"Documents, cash, photographs, precious metals, precious stones. Objects of: artistic, historical, philatelic or numestic value. Antiques or products of intellectual property",
        bg:"Документи, пари в наличност, фотоснимки, благородни метали, скъпоценни камъни, предмети с художествена, историческа, филателна и нумизматична стойност, антикварни предмети, продукти на интелектуалната собственост;"
    },
    "Weapons, ammunition, explosives, narcotics and opiates and all kinds of substances prohibited by the law, specified in the General terms and conditions.":{
        en:"Weapons, ammunition, explosives, narcotics and opiates and all kinds of substances prohibited by the law, specified in the General terms and conditions.",
        bg:"Оръжия, боеприпаси, експлозиви, наркотични вещества и опиати, всякакъв вид забранени от закона субстанции и др. посочени в Общите условия."
    },
    "Sorry for the inconvenience, we are unable to find a suitable payment option for your travel.":{
        en:"Sorry for the inconvenience, we are unable to find a suitable payment option for your travel.",
        bg:"Извиняваме се за неудобството, но не можем да намерим подходяща опция за плащане за пътуването Ви."
    },
    "No Price Details Option Available":{
        en:"No Price Details Option Available",
        bg:"Няма налична опция с детайли за цената"
    },
    "removing":{
        en:"removing",
        bg:"премахване"
    },
    "adding":{
        en:"adding",
        bg:"добавяне"
    },
    "Try Again":{
        en:"Try Again",
        bg:"Опитайте отново"
    },
    "Available in":{
        en:"Available in",
        bg:"Включено в"
    },
    "Apartment":{
        en:"Apartment",
        bg:"Апартамент"
    },
    "House":{
        en:"House",
        bg:"Къща"
    },
    "Villa":{
        en:"Villa",
        bg:"Вила"
    },
    "Flat (Apartment)":{
        en:"Flat (Apartment)",
        bg:"Апартамент"
    },
    "Household Property":{
        en:"Household Property",
        bg:"Домакинска собственост"
    },
    "Property All":{
        en:"Property All",
        bg:"Всички имоти"
    },
    "Insured Person":{
        en:"Insured Person",
        bg:"Застраховано лице"
    },
    "Contact Us":{
        en:"Contact Us",
        bg:"Свържете се с нас"
    },
    "Upload Image":{
        en:"Upload Image",
        bg:"Качете изображение"
    },
    "Link Expired":{
        en:"Link Expired",
        bg:"Линкът е изтекъл"
    },
    "No Data Found":{
        en:"No Data Found",
        bg:"Не са намерени данни "
    },
    "Well, that’s awkward...":{
        en:"Well, that’s awkward...",
        bg:"Ами, странно е ..."
    },
    "Leased Vehicles":{
        en:"Leased Vehicles",
        bg:"Превозни средства на лизинг"
    },
    "Invalid Insuring party":{
        en:"Invalid Insuring party",
        bg:"Невалиден застраховащ"
    },
    "No Instalment Option Available":{
        en:"No Instalment Option Available",
        bg:"Няма налична опция за вноски"
    },
    "Something Went Wrong!":{
        en:"Something Went Wrong!",
        bg:"Нещо се обърка!"
    },
    "Policy Draft Saved":{
        en:"Policy Draft Saved",
        bg:"черновата на полицата е запазена"
    },
    "The Link to your proposal has expired":{
        en:"The Link to your proposal has expired",
        bg:"Линкът към офертата Ви е изтекъл"
    },
    " on ":{
        en:" on ",
        bg:" На "
    },
    "Its Not a valid link.":{
        en:"Its Not a valid link.",
        bg:"Това не е валиден линк."
    },
    "The Link to your summary has expired":{
        en:"The Link to your summary has expired",
        bg:"Линкът към резюмето Ви е изтекъл"
    },
    "Single payment":{
        en:"Single payment",
        bg:"Еднократно плащане"
    },
    "Two payments":{
        en:"Two payments",
        bg:"Две плащания"
    },
    "Four payments":{
        en:"Four payments",
        bg:"Четири плащания"
    },
    "First instalment":{
        en:"First instalment",
        bg:"Първа вноска"
    },
    "Second instalment":{
        en:"Second instalment",
        bg:"Втора вноска"
    },
    "Third instalment":{
        en:"Third instalment",
        bg:"Трета вноска"
    },
    "Fourth instalment":{
        en:"Fourth instalment",
        bg:"Четвърта вноска"
    },
    "Fifth instalment":{
        en:"Fifth instalment",
        bg:"Пета вноска"
    },
    "Sixth instalment":{
        en:"Sixth instalment",
        bg:"Шеста вноска"
    },
    "Seventh instalment":{
        en:"Seventh instalment",
        bg:"Седма вноска"
    },
    "Eighth instalment":{
        en:"Eighth instalment",
        bg:"Осма вноска"
    },
    "Ninth instalment":{
        en:"Ninth instalment",
        bg:"Девета вноска"
    },
    "Tenth instalment":{
        en:"Tenth instalment",
        bg:"Десета вноска"
    },
    "Eleventh instalment":{
        en:"Eleventh instalment",
        bg:"Единадесета вноска"
    },
    "Twelfth instalment":{
        en:"Twelfth instalment",
        bg:"Дванайста вноска"
    },
    "For leased vehicles no offer can be submitted online. The offer may be received at DZI office":{
        en:"For leased vehicles no offer can be submitted online. The offer may be received at DZI office",
        bg:"За превозни средства на лизинг не може да бъде издадена оферта онлайн. Оферта може да Ви бъде изготвена в офис на ДЗИ."
    },
    "For the same vehicle in DZI there is an active Casco policy with different data about the vehicle or owner. The change in the data will be reflected in an annex to the Casco policy. The annex must be signed in a DZI office or by electronic signature.":{
        en: "For the same vehicle in DZI there is an active Casco policy with different data about the vehicle or owner. The change in the data will be reflected in an annex to the Casco policy. The annex must be signed in a DZI office or by electronic signature.",
        bg: "За същото МПС в ДЗИ има активна полица Каско с различни данни за МПС или собственик. Промяната на данните ще бъде отразена с анекс към полицата Каско. Анексът трябва да бъде подписан в офис на ДЗИ.Застраховка 'Гражданска отговорност' на автомобилистите може да бъде издадена онлайн, след потвърждение от Ваша страна за издаване на анекс."
    },
    "Something went wrong and we cannot proceed with the process.Please try again later.":{
        en:"Something went wrong and we cannot proceed with the process.Please try again later.",
        bg:"Нещо се обърка и не можем да продължим с процеса. Моля, опитайте по-късно."
    },
    "Go to Forgot Password":{
        en:"Go to Forgot Password",
        bg:"Отидете на забравена парола"
    },
    "Create a new policy":{
        en:"Create a new policy",
        bg:"Създайте нова полица"
    },
    "Try a different Vehicle":{
        en:"Try a different Vehicle",
        bg:"Опитайте с различно МПС"
    },
    "Go to dashboard":{
        en:"Go to dashboard",
        bg:"Върнете се в началната страница"
    },
    "Remove":{
        en:"Remove",
        bg:"Премахнете"
    },
    "This draft will expire on":{
        en:"This draft will expire on",
        bg:"Тази чернова изтича на"
    },
    "Next installment due on":{
        en:"Next installment due on",
        bg:"Следващата вноска е дължима на"
    },
    "warning":{
        en:"warning",
        bg:"предупреждение"
    },
    "informative":{
        en:"informative",
        bg:"информативен"
    },
    "Invalid Owner Data":{
        en:"Invalid Owner Data",
        bg:"Невалидни данни за собственика"
    },
    "Confirmation": {
        en:"Confirmation",
        bg:"Потвърждение"
    },
    "Annex Notice": {
        en:"Annex Notice",
        bg:"Уведомлление за анекс"
    },
    "Sorry for the inconvenience, we are unable to find a suitable instalment option for your property.": {
        en:"Sorry for the inconvenience, we are unable to find a suitable instalment option for your property.",
        bg:"Извиняваме се за неудобството, но не можем да намерим подходяща опция за вноска по Вашата имуществена застраховка."
    },
    "Discrepancy in EGN":{
       en:"Discrepancy in EGN",
       bg:"Несъответствие в ЕГН"
    },
    "Discrepancy in EIK":{
        en:"Discrepancy in EIK",
        bg:"Несъответствие в ЕИК"
     },
     "Discrepancy in LNCH":{
        en:"Discrepancy in LNCH",
        bg:"Несъответствие в ЛНЧ"
     },
     "404 - Page not found":{
         en:"404 - Page not found",
         bg:"404 - Страницата не е намерена"
     },
     "Sorry! The page you were looking for doesn’t exist, isn’t available or was loading incorrectly.":{
         en:"Sorry! The page you were looking for doesn’t exist, isn’t available or was loading incorrectly.",
         bg:"Съжаляваме! Страницата, която търсите не съществува, не е налична или не се зарежда."
     },
     "Back to dashboard":{
         en:"Back to dashboard",
         bg:"Обратно към контролното табло"
     },
    "There are no due instalments for": {
        en:"There are no due instalments for",
        bg:"Няма дължими вноски за"
    },
    "No Insurance Objects": {
        en:"No Insurance Objects",
        bg:"Няма застрахователни обекти за Вас"
    },
    "There is no Insurance Objects for you": {
        en:"There is no Insurance Objects for you",
        bg:"Няма застрахователни обекти за Вас"
    },
    "No Payment of Instalment": {
        en:"No Payment of Instalment",
        bg:"Няма плащане на вноска"
    },
    "There is no due or upcoming instalments for any of your active policies": {
        en:"There is no due or upcoming instalments for any of your active policies",
        bg:"Няма дължими или предстоящи вноски по никоя от вашите активни полици"
    },
    "Claim": {
        en: "Claim",
        bg: "Застрахователна претенция"
    },
    "Submit or check a": {
        en: "Submit or check a",
        bg: "Подайте или проверете"
    },
    "Payment Receipt": {
        en: "Payment Receipt",
        bg: "Разписка"
    },
    "Policy Document": {
        en: "Policy Document",
        bg: "Полица"
    },
    "Talon Annual Inspection": {
        en: "Talon Annual Inspection",
        bg: "Талон ГТП"
    },
    "Questionnaire": {
        en: "Questionnaire",
        bg: "Въпросник"
    },
    "Certificates": {
        en: "Certificates",
        bg: "Сертификати"
    },
    "List": {
        en: "List",
        bg: "Списък"
    },
    "General terms and conditions": {
        en: "General terms and conditions",
        bg: "Общи условия"
    },
    "GC Document": {
        en: "GC Document",
        bg: "Документ Зелена Карта"
    },
    "Policy not signed": {
        en: "Policy not signed",
        bg: "Полицата не е подписана"
    },
    "Policy was rejected": {
        en: "Policy was rejected",
        bg: "Полицата беше отказана"
    },
    "Policy not created": {
        en: "Policy not created",
        bg: "Полицата не беше създадена"
    },
    "It appears you have declined to sign your policy or the session expired.": {
        en: "It appears you have declined to sign your policy or the session expired.",
        bg: "Изглежда ,че сте отказали да подпишете полицата или сесията Ви е изтекла."
    },
    "Please contact our support for further assistance.": {
        en: "Please contact our support for further assistance.",
        bg: "Моля свържете с обслужващия център за съдействие."
    },
    "Please try again later or contact our support for further assistance.": {
        en: "Please try again later or contact our support for further assistance.",
        bg: "Моля опитайте по-късно или се свържете с наши обслужващ център за съдействие."
    },
    "We couldn’t create your policy at the moment and your card was not charged.": {
        en: "We couldn’t create your policy at the moment and your card was not charged.",
        bg: "Не успяхме да създадем Вашата полица и затова от Вашата карта не бяха прехвърлени средства."
    },
    "Please try again later or contact our support.": {
        en: "Please try again later or contact our support.",
        bg: "Моля опитайте по-късно или се свържете с наши обслужващ център."
    },
    "We couldn’t find your document to initiate the signing process.": {
        en: "We couldn’t find your document to initiate the signing process.",
        bg: "Не можахме да намерим Вашия документ, за да стартираме процедура по подписване."
    },
    "Due to some internal server problem, We couldn’t complete the signing process.": {
        en: "Due to some internal server problem, We couldn’t complete the signing process.",
        bg: "Поради провлеми със сървъра, не успяхме да завършим процеса за подписване."
    },
    "Technical error. The service is temporarily unavailable": {
        en: "Technical error. The service is temporarily unavailable",
        bg: "Техническа грешка. Услугата е временно недостъпна."
    },
    "Technical error. The service is temporarily unavailable. The blocked amount will be returned to your bank account automatically in a couple of hours. For support and additional information please contact our Contact Center on 0700 16 166. We apologize for the inconvenience": {
        en: "Technical error. The service is temporarily unavailable. The blocked amount will be returned to your bank account automatically in a couple of hours. For support and additional information please contact our Contact Center on 0700 16 166. We apologize for the inconvenience",
        bg: "Възникна неочаквана грешка. Mоля, опитайте отново. Имайте предвид че сумата, която беше заявена от Вашата карта, ще Ви бъде възстановена автоматично в рамките на няколко часа. За съдействие и информация можете да се свържете с Контактен център за клиенти на телефон 0700 16 166. Извиняваме се за причиненото неудобство."
    },
    "Technical error. The service is temporarily unavailable. For support and reverting already paid amounts please contact our Contact Center on 0700 16 166. We apologize for the inconvenience.": {
        en: "Technical error. The service is temporarily unavailable. For support and reverting already paid amounts please contact our Contact Center on 0700 16 166. We apologize for the inconvenience.",
        bg: "Възникна неочаквана грешка. Mоля, опитайте отново. За съдействие и връщане на платените суми се свържете с Контактен център за клиенти на телефон 0700 16 166. Извиняваме се за причиненото неудобство."
    },
    "Technical error. The request cannot be performed.": {
        en: "Technical error. The request cannot be performed.",
        bg: "Техническа грешка, заявкате не може да бъде изпълнена."
    },
    "Seems, Your session has been expired.": {
        en: "Seems, Your session has been expired.",
        bg: "Изглежда, че сесията Ви е изтекла."
    },
    "Try registering with B-Trust app or pay with your own Credit/Debit card.": {
        en: "Try registering with B-Trust app or pay with your own Credit/Debit card.",
        bg:"Моля, регистрирайте се в приложението на B-Trust или платете със собствена кредитна/дебитна карта."
    },
    "Try registering with B-Trust app": {
        en: "Try registering with B-Trust app",
        bg:"Моля, регистрирайте се в приложението на B-Trust"
    },
    "Do you want to delete this vehicle information?": {
        en:"Do you want to delete this vehicle information?",
        bg:"Искате ли да изтриете тази информация за превозното средство?"
    },
    "Do you want to delete this property information?": {
        en:"Do you want to delete this property information?",
        bg:"Искате ли да изтриете тази информация за имота?"
    },
    "Do you want to delete this people information?": {
        en:"Do you want to delete this people information?",
        bg:"Искате ли да изтриете тази информация за застрахованите лица?"
    },
    "Delete Vehicle": {
        en:"Delete Vehicle",
        bg:"Изтрий превозно средство"
    },
    "Delete Property": {
        en:"Delete Property",
        bg:"Изтрий Имот"
    },
    "Delete People": {
        en:"Delete People",
        bg:"Изтрий лице"
    },
    "Warning": {
        en:"Warning",
        bg:"Внимание"
    },
    "Back to Search": {
        en:"Back to Search",
        bg:"Обратно към Търсене"
    },
    "None": {
        en: "None",
        bg: "Нито едно"
    },
    "Only PDF or JPG, max. 10MB": {
        en: "Only PDF or JPG, max. 10MB" ,
        bg: "Само PDF или JPG. макс. 10MB"
    },
    "PREM_INFO_MSG": {
        en: "Premiums indicated are final and include Contribution to the Guarantee and insurance fund , sticker and 2% VAT on insurance premiums." ,
        bg: "Посочените премии са окончателни и включват вноски за Гаранционен и Обезпечителен фонд, стикер и 2% данък върху застрахователните премии."
    },
    "ACCIDENT_ASSISTANCE_MSG": {
        en: "Road Accident assistance, 24 hours / 7 days a week - free service." ,
        bg: "Съдействие при ПТП, 24 часа / 7 дни в седмицата - безплатна услуга."
    },
    "SINGLE_PAYMENT_PREM_MSG": {
        en: "Motorcycle or moped or tricycle or luggage and camping trailers premium can only be paid in single payment." ,
        bg: " Не се предлага разсрочване на застрахователната премия за мотоциклети, мотопеди, триколки и багажни и къмпинг ремаркета."
    },
    "AGE15_DISCOUNT_MSG": {
        en: "Discount has been applied for vehicles over 15 years of age." ,
        bg: "Приложена е отстъпка за превозни средства на възраст над 15 години."
    },
    "AGE20_DISCOUNT_MSG": {
        en: "Discount has been applied for vehicles over 20 years of age." ,
        bg: "Приложена е отстъпка за превозни средства на възраст над 20 години."
    },
    "CASCO_PLUS_DISCOUNT_MSG": {
        en: "Discount for insurance with 'Casco+' insurance in DZI has been applied." ,
        bg: 'Приложена е отстъпка за валидна застраховка "Каско+" за същото МПС в ДЗИ.'
    },
    "up to": {
        en: "up to",
        bg: "до"
    },
    "Loading": {
        en: "Loading",
        bg: "Моля, изчакайте..."
    },
    "Annual Technical Inspection (GTP) Voucher.":{
        en: "Annual Technical Inspection (GTP) Voucher." ,
        bg: "Талон за Годишен технически преглед (ГТП)."
    },
    "EGN":{
        en:"EGN",
        bg:"ЕГН"
    },
    "LNCH":{
        en:"LNCH",
        bg:"Личен номер на чужденец (ЛНЧ)"
    },
    "EIK":{
        en:"EIK",
        bg:"Единен идентификационен код на фирма (ЕИК)"
    },
    "Download Receipt":{
        en: "Download Receipt",
        bg: "Изтеглете разписка"
    },
    "for the first instalment":{
        en:"for the first instalment",
        bg:"за първа вноска"
    },
    "For vehicles with registration number":{
        en:"For vehicles with registration number",
        bg:"За превозно средство с регистрационен номер"
    },
    "No offer can be submitted online. The offer may be received at DZI office":{
        en:"no offer can be submitted online. The offer may be received at DZI office.",
        bg:"оферта може да бъде изготвена в офис на ДЗИ."
    },
    "Login anyways":{
        en:"Login anyways",
        bg:"Вход"
    },
    "Continue as Guest":{
        en:"Continue as Guest",
        bg:"Продължете като гост"
    },
    "Motor Third Party Liability": {
        en: "Motor Third Party Liability",
        bg: "Застраховка “Гражданска отговорност” на автомобилистите"
    },
    "You save": {
        en: "You save",
        bg: "Спестявате"
    },
    "DZI Client Portal - Home": {
        en: "DZI Client Portal - Home",
        bg: "ДЗИ Клиентски портал - начало"
    },
    "Yes": {
        en: "Yes",
        bg: "Да"
    },
    "No": {
        en: "No",
        bg: "Не"
    },
    "Multiple": {
        en: "Multiple",
        bg: "Многократно"
    },
    "Once": {
        en: "Once",
        bg: "Еднократно"
    },
    "Annual":{
        en:"Annual",
        bg:"Едногодишнa"
    },
    "Business":{
        en:"Business",
        bg:"Служебен"
    },
    "Finish":{
        en:"Finish",
        bg:"завършек"
    },
    "Go back":{
        en:"Go back",
        bg:"Върнете се"
    },
    "Your policy is being created":{
        en:"Your policy is being created",
        bg:"Вашата полица се създава"
    },
    "e-signature Notice":{
        en: "e-signature Notice",
        bg: "Известие за електронен подпис (КЕП)"
    },
    "e-signature":{
        en: "e-signature ",
        bg: "електронен подпис (КЕП)"
    },
    "Your payment was completed successfully. To continue with the policy creation you will need to proceed with e-identification. The provided information will be used for your policy creation":{
        en: "Your payment was completed successfully. To continue with the policy creation you will need to proceed with e-identification. The provided information will be used for your policy creation",
        bg: "Вашето плащане завърши успешно. За да продължите със създаването на полица, следва да преминете през процес за електронна идентификация. Информацията, която предоставите ще бъде използвана при създаване на полицата."

    },
    "Your e-identification was completed successfully. To continue with the policy creation you will need to proceed with e-signature":{
        en: "Your e-identification was completed successfully. To continue with the policy creation you will need to proceed with e-signature",
        bg: "Процесът по електронна идентификация приключи успешно. За да продължите с процеса по създаване на полица следва да подпишете документите с електронен подпис (КЕП)."
    },
    "Your e-signature was successfully completed.":{
        en: "Your e-signature was successfully completed.",
        bg: "Вашият електронен подпис беше положен успешно."
    },
    "Please use the provided web identification service to continue the purchase. It will require a couple of minutes, and you will need a personal ID card.":{
        en: "Please use the provided web identification service to continue the purchase. It will require a couple of minutes, and you will need a personal ID card.",
        bg: "Моля, използвайте предложената уеб идентификация, за да завършите покупката. Процесът ще отнеме няколко минути и ще имате нужда от Вашата лична карта."
    },
    "It appears you have declined to continue with the e-identification process or the session expired. Your card was not charged. Please contact our support for further assistance.":{
        en: "It appears you have declined to continue with the e-identification process or the session expired. Your card was not charged. Please contact our support for further assistance.",
        bg: "Изглежда, че отказахте да продължите с процеса по електронна идентификация или сесията Ви е изтекла. Моля свържете с нашия отдел по поддръжка, ако имате нужда от съдействие."
    },
    "Cancel e-identification?":{
        en: "Cancel e-identification?",
        bg: "Отказвате електронната идентификация?"
    },
    "Cancel e-signature?":{
        en: "Cancel e-signature?",
        bg: "Отказвате електронния подпис?"
    },
    "If you continue with cancellation the policy will not be created":{
        en: "If you continue with cancellation the policy will not be created",
        bg: "Ако потвърдите отказа, Вашата полица няма да бъде създадена."
    },
    "Redirecting you to the payment gateway":{
        en: "Redirecting you to the payment gateway",
        bg: "Пренасочваме Ви към екрана за плащане"
    },
    "Your payment is being processed Do not close or refresh this window":{
        en: "Your payment is being processed Do not close or refresh this window",
        bg: "В момента Вашето плащане се обработва, моля не затваряйте и не опреснявайте прозореца."
    },
    "e-identification is in process":{
        en: "e-identification is in process",
        bg: "Тече процес по електронна идентификация"
    },
    "e-identification in process":{
        en: "e-identification in process",
        bg: "Тече процес по електронна идентификация"
    },
    "e-signature in process":{
        en: "e-signature in process",
        bg: "Тече процес по електронно подписване"
    },
    "Finalizing your purchase will require an e-Identification and e-Signature of the policy. The process will require a couple of minutes, and you will need your personal identification document.":{
        en: "Finalizing your purchase will require an e-Identification and e-Signature of the policy. The process will require a couple of minutes, and you will need your personal identification document.",
        bg: "За финализиране на Вашата покупка са необходими електронна идентификация и електронен подпис (КЕП). Тези процеси ще отнемат няколко минути и може да имате нужда от документ за самоличност."
    },
    "In case you’re not the owner of the credit/debit card,you’ll be asked to electronically sign the policy using B-Trust. If you don’t own a B-Trust signature, please pay with your own card or ePay. ":{
        en: "In case you’re not the owner of the credit/debit card,you’ll be asked to electronically sign the policy using B-Trust. If you don’t own a B-Trust signature, please pay with your own card or ePay. ",
        bg: "В случай, че не сте собственик на  кредитната/дебитната карта, ще бъдете поканен да подпишете полицата с B-trust. В случай, че не притежавате електронен подпис, моля платете със собствена карта или чрез epay."
    },
    "e-identification":{
        en: "e-identification",
        bg: "електронна идентификация"
    },
    "e-identification Notice":{
        en: "e-identification Notice",
        bg: "Известие за електронна идентификация"
    },
    "Insurance type":{
        en:"Insurance type",
        bg:"Вид застраховка"
    },
    "Best Value":{
        en:"Best Value",
        bg:"Най-добро предложение"
    },
    "Go to Home":{
        en: "Go to Home",
        bg: "Към началната страница"
    },
    "New Property": {
        en: "New Property",
        bg: "Нов Имот"
    },
    "Enter new property details below": {
        en: "Enter new property details below",
        bg: "Попълнете детайли за имота по-долу"
    },
    "Downgrade to": {
        en: "Downgrade to",
        bg: "Избиране на по-нисък пакет"
    },
    "Upgrade to": {
        en: "Upgrade to",
        bg: "Избиране на по-висок пакет"
    },
    "To finalize your purchase you will need to proceed with e-signature":{
        en: "To finalize your purchase you will need to proceed with e-signature",
        bg: "За да продължите със създаването на полица, следва да подпишете с електронен подпис B-Trust"
    },
    "Months":{
        en:"Months",
        bg:"Месеца"
    },
    "12 Months":{
        en:"12 Months",
        bg:"12 месеца"
    },
    "6 Months":{
        en:"6 Months",
        bg:"6 Месеци"
    },
    "3 Months":{
        en:"3 Months",
        bg:"3 Месеци"
    },
    "e-Signature process in progress. Please wait...":{
        en:"e-Signature process in progress. Please wait...",
        bg:"Тече процес по електронно подписване. Моля изчакайте …"
    },
    "Identification failed":{
        en:"Identification failed",
        bg:"Неуспешна идентификация"
    },
    "We couldn’t complete the identification process at the moment. Transaction to be reversed within 2-3 days. If you have any queries please contact our 24/7 support":{
        en:"We couldn’t complete the identification process at the moment. Transaction to be reversed within 2-3 days. If you have any queries please contact our 24/7 support",
        bg:"В момента не можахме да завършим процеса на идентификация. Транзакцията се отменя в рамките на 2-3 дни. Ако имате въпроси, моля, свържете се с нашата денонощна поддръжка"
    },
    "It appears you have declined to sign your policy or the session expired. Please contact our support for further assistance.":{
        en:"It appears you have declined to sign your policy or the session expired. Please contact our support for further assistance.",
        bg:"Изглежда, че сте отказали да подпишете полицата си или сесията е изтекла. Моля, свържете се с нашата поддръжка за допълнителна помощ."
    },
    "Due":{
        en:"Due",
        bg:"Краен срок"
    },
    "Upcoming":{
        en:"Upcoming",
        bg:"Предстои"
    },
    "Annex":{
        en:"Annex",
        bg:"Анекс"
    },
    "Talon Scanning":{
      en:"Talon Scanning",
      bg:"Сканиране на талон"
  },
  "Claim Creation failed":{
    en:"Claim Creation failed",
    bg:"Проблем при създаване на щета"
  },
  "Please call 070016166":{
    en:"Please call 070016166",
    bg:"Моля обадете се 070016166"
  },
  "Applicant Details":{
    en:"Applicant Details",
    bg:"Данни на заявителя"
  },
  "Vehicle & Event Details":{
    en:"Vehicle & Event Details",
    bg:"Описание на превозното средство и събитието"
  },
  "Upload":{
    en:"Upload",
    bg:"Прикачете"
  },
  "Upload Documents":{
    en:"Upload Documents",
    bg:"Прикачване на документ"
  },
  "Upload Photos":{
    en:"Upload Photos",
    bg:"Прикачване на снимки"
  },
  "Event Details":{
    en:"Event Details",
    bg:"Детайли за събитие"
  },
  "Casco Claim Notification":{
    en:"Casco Claim Notification",
    bg:"Известие за щета по КАСКО+"
  },
  "Property Claim Notification":{
    en:"Property Claim Notification",
    bg:"Известие за Имуществена щета"
  },
  "Claims Other Notification":{
    en:"Claims Other Notification",
    bg:"Щети"
  },
  "View Less":{
    en:"View Less",
    bg:"Покажи по-малко"
  },
  "View Details":{
    en:"View Details",
    bg:"Покажи детайли"
  },
  "Claims Check":{
    en:"Claims Check",
    bg:"Проверка на щета"
  },
  "No Claim Data Available":{
    en:"No Claim Data Available",
    bg:"Няма данни за щета"
  },
  "Sorry for the inconvenience, we are unable to find a data for this policy/claim number":{
    en:"Sorry for the inconvenience, we are unable to find a data for this policy/claim number",
    bg:"Съжаляваме за неудобството, не можем да намерим данни за този номер на полица и/или щета"
  },
  "Only PDF or PNG or JPEG or GIF max. 10MB":{
    en:"Only PDF or PNG or JPEG or GIF max. 10MB",
    bg:"Само PDF или PNG или JPEG или GIF макс. 10MB"
  },
  "Repeat the action if you would like to upload more than 1 file. Only PDF or PNG or JPEG or GIF max. 10MB":{
    en:"Repeat the action if you would like to upload more than 1 file. Only PDF or PNG or JPEG or GIF max. 10MB",
    bg:"Повторете за прикачване на повече от един файл. Само PDF или PNG или JPEG или GIF макс. 10MB"
  },
  "Only PNG or JPEG or GIF max. 10MB":{
    en:"Only PNG or JPEG or GIF max. 10MB",
    bg:"Само PNG или JPEG или GIF макс. 10MB"
  },
  "Repeat the action if you would like to upload more than 1 file. Only PNG or JPEG or GIF max. 10MB":{
    en:"Repeat the action if you would like to upload more than 1 file. Only PNG or JPEG or GIF max. 10MB",
    bg:"Повторете за прикачване на повече от един файл. Само PNG или JPEG или GIF макс. 10MB"
  },
  "Agree to all":{
    en:"Agree to all",
    bg:"Съгласен съм с всички"
  },
  "Agree to all, I am aware that I am responsible for providing incorrect data in accordance with the Criminal Code of the Republic of Bulgaria.":{
    en:"Agree to all, I am aware that I am responsible for providing incorrect data in accordance with the Criminal Code of the Republic of Bulgaria.",
    bg:"Съгласен съм с всичко, Известно ми е, че за предоставяне на неверни данни нося отговорност съгласно Наказателния Кодекс на Република България."
  },
  "Claim Notification Submitted":{
    en:"Claim Notification Submitted",
    bg:"Известието за щета е изпратено"
  },
  "Congrats! Your CASCO+ Accidental Policy claims has been notified succesfully.":{
    en:"Congrats! Your CASCO+ Accidental Policy claims has been notified succesfully.",
    bg:"Поздравления! Вашето известие за щети по КАСКО+ е подадено успешно."
  },
  "Your property damage claims has been notified succesfully. We will complete the claim registration process shortly.":{
    en:"Your property damage claims has been notified succesfully. We will complete the claim registration process shortly.",
    bg:"Поздравления! Вашето известие за щета по Имущество е подадено успешно."
  },
  "No Claim Notification found":{
    en:"No Claim Notification found",
    bg:"Няма намерено известие за щета"
  },
  "There is no archive Claim notification for any of your policies":{
    en:"There is no archive Claim notification for any of your policies",
    bg:"Няма архивирани известия за щети по вашите полици"
  },
  "New Claims Notification":{
    en:"New Claims Notification",
    bg:"Уведомление з анова щета"
  },
  "New Claim Notification":{
    en:"New Claim Notification",
    bg:"Ново известие за щета"
  },
  "All Claims Notification":{
    en:"All Claims Notification",
    bg:"Всички известия за щети"
  },

  "Archive Notifications":{
    en:"Archive Notifications",
    bg:"Архив известия"
  },
  "Archived Notifications":{
    en:"Archived Notifications",
    bg:"Архивирано известия"
  },
  "Policy Creation in progress. Please wait":{
    en:"Policy Creation in progress. Please wait",
    bg:"Тече процес по създаване на полица, Моля изчакайте. "
  },
  "Driver Details":{
    en:"Driver Details",
    bg:"Детайли на водача"
  },
  "e-Signature Required":{
    en:"e-Signature Required",
    bg:"Задължително е подписването с електронен подпис"
  },
  "Type of Incident":{
    en:"Type of Incident",
    bg:"[TR]Type of Incident"
  },
  "Claim number":{
    en:"Claim number",
    bg:"Номер на щета"
  },
  "Policy number":{
    en:"Policy number",
    bg:"Номер на полица"
  },
  "Casco+":{
    en:"Casco+",
    bg:"Каско+"
  },
  "Price Recalculating":{
    en:"Price Recalculating",
    bg:"Преизчисляване на цена"
  },
  "Please upload all the photos showing the damage on vehicle":{
    en:"Please upload all the photos showing the damage on vehicle",
    bg:"Моля прикачете всички снимки, свързани със събитието"
  },
  "Please upload all the photos showing the damage on property":{
    en:"Please upload all the photos showing the damage on property",
    bg:"Моля, прикачете всички снимки, които показват вредите на имущество"
  },
  "We experience technical problems please try again later":{
    en:"We experience technical problems please try again later",
    bg:"Възникна техническа грешка, моля опитайте отново по-късно."
  },
  "The bank card must be registered for secure online payments, following the process set by the issuing bank":{
    en:"The bank card must be registered for secure online payments, following the process set by the issuing bank.",
    bg:"Банковата карта, с която ще платите, трябва да бъде регистрирана за платежни операции в интернет от банката - издател."
  },
  "Information!":{
    en:"Information!",
    bg:"Информация!"
  },
  "Claim status check":{
    en:"Claim status check",
    bg:"Проверка на статус по щета"
  },
  "minute": {
    en: "minute",
    bg: "минута"
  },
  "minutes": {
    en: "minutes",
    bg: "минути"
  },
  "day": {
    en: "day",
    bg: "ден"
  },
  "days": {
    en: "days",
    bg: "дни"
  },
  "month": {
    en: "month",
    bg: "месец"
  },
  "months": {
    en: "months",
    bg: "месеца"
  },
  "year": {
    en: "year",
    bg: "година"
  },
  "years": {
    en: "years",
    bg: "години"
  },
  "Session": {
    en: "Session",
    bg: "Сесия"
  },
  "Casco": {
    en: "Casco",
    bg: "Каско"
  },
  "Casco online claim notification": {
    en: "Casco online claim notification",
    bg: "Каско онлайн щети"
  },
  "Property online claim notification": {
    en: "Property online claim notification",
    bg: "Имущество онлайн щети"
  },
  "Health portal": {
    en: "Health portal",
    bg: "Здравен портал"
  },
  "SuperDoc – book a medical examination": {
    en: "SuperDoc – book a medical examination",
    bg: "СуперДоктор - запишете преглед"
  },
  "Healee – online medical consultation": {
    en: "Healee – online medical consultation",
    bg: "Healee - Онлайн медицински консултации"
  },
  "Personal data protection": {
    en: "Personal data protection",
    bg: "Защита на лични данни "
  },
  "Use existing terms": {
    en: "Use existing terms",
    bg: "При същите условия"
  },
  "General Term and Conditions": {
    en: "General Term and Conditions",
    bg: "Общи условия"
  },
  "Privacy settings": {
    en: "Privacy settings",
    bg: "Настройки за поверителността "
  },
  "go.dzi.bg is best viewed in modern browsers": {
    en: "go.dzi.bg is best viewed in modern browsers",
    bg: "go.dzi.bg се достъпва най-добре през модерни браузъри"
  },
  "go.dzi.bg has been crafted to work on modern browsers and does not work as intended on legacy browsers": {
    en: "go.dzi.bg has been crafted to work on modern browsers and does not work as intended on legacy browsers",
    bg: "go.dzi.bg е създаден да работи с модерни браузъри и не работи по предназначение с браусъри извън поддръжка"
  },
  "Cookies": {
    en: "Cookies",
    bg: "Бисквитки"
  },
  "Contacts": {
    en: "Contacts",
    bg: "Контакти"
  },
  "Name": {
    en: "Name",
    bg: "Име"
  },
  "Supplier": {
    en: "Supplier",
    bg: "Доставчик"
  },
  "Goal": {
    en: "Goal",
    bg: "Цел"
  },
  "Validity": {
    en: "Validity",
    bg: "Валидност"
  },
  "Type": {
    en: "Type",
    bg: "Тип"
  },
  "Strictly necessary cookies": {
    en: "Strictly necessary cookies",
    bg: "Строго необходими бисквитки"
  },
  "Statistical cookies": {
    en: "Statistical cookies",
    bg: "Статистически бисквитки"
  },
  "Marketing related": {
    en: "Marketing related",
    bg: "Маркетинг"
  },
  "Records the visitor's consent connected to cookies and related technologies for this domain.": {
    en: "Records the visitor's consent connected to cookies and related technologies for this domain.",
    bg: "Клиентските съгласия свързани с бисквитките се записват."
  },
  "Registers a unique identifier which is used to generate statistics about how a user uses the site.": {
    en: "Registers a unique identifier which is used to generate statistics about how a user uses the site.",
    bg: "Регистрира уникален идентификатор, който се използва за генериране на статистика за това как потребителят използва сайта."
  },
  "Sets x-access-token in headers to access the API’s calls": {
    en: "Sets x-access-token in headers to access the API’s calls",
    bg: "Поставя x-access-токън, за да се осъществяват заявките към API"
  },
  "To get the user acceptance from the cookies confirmation page to get into the landing page": {
    en: "To get the user acceptance from the cookies confirmation page to get into the landing page",
    bg: "Записва съгласията на посетителя относно бисквитките и сходните на тях технологии за този домейн."
  },
  "The functionality is to store and count pageviews.": {
    en: "The functionality is to store and count pageviews.",
    bg: "Регистрира уникален идентификатор, който се използва да се генерира статистическа информация за това как посетителят използва сайта."
  },
  "The functionality is to store a unique user ID.": {
    en: "The functionality is to store a unique user ID.",
    bg: "Регистрира уникален потребителски идентификатор"
  },
  "The functionality is to read and filter requests from bots.": {
    en: "The functionality is to read and filter requests from bots.",
    bg: "Чете и филтрира заявки от ботове"
  },
  "Hotjar cookie that is set when the customer first lands on a page with the Hotjar script. It is used to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.": {
    en: "Hotjar cookie that is set when the customer first lands on a page with the Hotjar script. It is used to persist the Hotjar User ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.",
    bg: "Присъжда уникален потребителси идентификатор, който позволява поведението на един и същ потребител в последващи сесии на сайта да се регистрира на съответния потребител, а не на друг."
  },
  "This cookie is set to let Hotjar know whether that user is included in the data sampling defined by  site's pageview limit.": {
    en: "This cookie is set to let Hotjar know whether that user is included in the data sampling defined by  site's pageview limit.",
    bg: "Използва се за целите на статистически анализ, свързан с извадка от данни за прегледа на страници от потребителите"
  },
  "This cookie is used to detect the first pageview session of a user. This is a True/False flag set by the cookie.": {
    en: "This cookie is used to detect the first pageview session of a user. This is a True/False flag set by the cookie.",
    bg: "Записва първата страница, видяна от потребителя по време на сесията."
  },
  "Used by Google DoubleClick to register and report the website user's actions after viewing or clicking on the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.": {
    en: "Used by Google DoubleClick to register and report the website user's actions after viewing or clicking on the advertiser's ads with the purpose of measuring the efficacy of an ad and to present targeted ads to the user.",
    bg: "Използва се от Google DoubleClick да се регистрират и отчитат действията на посетителя на уебсайт, след като види или кликне реклама с цел измерване на ефикасността ѝ, както и с цел показване на персонализирани реклами на посетителя."
  },
  "The functionality is to store and track conversions.": {
    en: "The functionality is to store and track conversions.",
    bg: "Регистрира конверсии"
  },
  "The functionality is to store and track visits across websites.": {
    en: "The functionality is to store and track visits across websites.",
    bg: "Проследява и записва посетените от потребителите сайтове"
  },
  "Provides information about how the website is being used by the end-user and ads that the user may have seen before entering the website.": {
    en: "Provides information about how the website is being used by the end-user and ads that the user may have seen before entering the website.",
    bg: "Предоставя информация относно начина на ползване на уебсайта от крайния потребител и реклами, които потребителят може би е виждал преди влизане в сайта."
  },
  "Used by DoubleClick to collect information on the user's interests and serve relevant ads.": {
    en: "Used by DoubleClick to collect information on the user's interests and serve relevant ads.",
    bg: "Използва се от DoubleClick за събиране на информация относно интересите на потребителя и показване на подходящи реклами."
  },
  "Individual Clients": {
    en: "Individual Clients",
    bg: "Индивидуални клиенти"
  },
  "Claims Handling": {
    en: "Claims Handling",
    bg: "Обработка на щети"
  },
  "Useful Information": {
    en: "Useful Information",
    bg: "Полезна информация"
  },
  "Cookies settings": {
    en: "Cookies settings",
    bg: "Настройки за бисквитки"
  },
  "cargo": {
    en: "cargo",
    bg: "товар"
  },
  "object": {
    en: "object",
    bg: "обект"
  },
  "object/person": {
    en: "object/person",
    bg: "обект/лице"
  },
  "DZI Transport Claim Notification":{
      en:"DZI Transport Claim Notification",
      bg:"Известие за щета по транспортни застраховки"
  },

  "DZI Транспортно Claim Notification":{
    en:"DZI Transport Claim Notification",
    bg:"Известие за щета по транспортни застраховки"
  },
  "DZI Транспортно застраховане Claim Notification":{
    en:"DZI Transport Insurance Claim Notification",
    bg:"Известие за щета по Транспортно застраховане"
  },
  "DZI Liability Claim Notification":{
    en:"DZI Liability Claim Notification",
    bg:"Известие за щета по застраховка Отговорности"
  },
  "DZI Отговорности Claim Notification":{
    en:"DZI Liability Claim Notification",
    bg:"Известие за щета по застраховка Отговорности"
  },
  "DZI Agriculture Claim Notification":{
    en:"DZI Agricuture Claim Notification",
    bg:"Известие за щета по Земеделски застраховки"
  },
  "DZI Земеделско Claim Notification":{
    en:"DZI Agricuture Claim Notification",
    bg:"Известие за щета по Земеделски застраховки"
  },
  "Transport Claim Notification":{
      en:"Transport Claim Notification",
      bg:"Известие за щета"
  },
  "Транспортно Claim Notification":{
    en:"Transport Claim Notification",
    bg:"Известие за щета"
},
  "Транспортно застраховане Claim Notification":{
    en:"Transport Insurance Claim Notification",
    bg:"Известие за щета по"
  },
  "Liability Claim Notification":{
    en:"Liability Claim Notification",
    bg:"Известие за щета"
  },
  "Отговорности Claim Notification":{
    en:"Liability Claim Notification",
    bg:"Известие за щета"
  },
  "Agriculture Claim Notification":{
    en:"Agricuture Claim Notification",
    bg:"Известие за щета"
  },
  "Земеделско Claim Notification":{
    en:"Agricuture Claim Notification",
    bg:"Известие за щета"
  },
  "Damaged Object / Injured Person": {
    en:"Damaged Object / Injured Person",
    bg:"Увреден обект/увредено лице"
  },
  "Upload File":{
      en:"Upload File",
      bg:"Прикачете файл"
  },
  "Congrats! Your Транспортно Claims has been notified succesfully":{
      en:"Congrats! Your Transport Claims has been notified succesfully",
      bg:"Поздравления!Вашето известие за щета по транспортни застраховки е подадено успешно!"
  },
  "Congrats! Your Отговорности Claims has been notified succesfully":{
      en:"Congrats! Your Liability Claims has been notified succesfully",
      bg:"Поздравления!Вашето известие за щета по застраховка Отговорности е подадено успешно!"
  },
  "Congrats! Your Земеделско Claims has been notified succesfully":{
      en:"Congrats! Your Agriculture Claims has been notified succesfully",
      bg:"Поздравления!Вашето известие за щета по Земеделски застраховки е подадено успешно!"
  },
    "Congrats! Your Транспортно застраховане Claims has been notified succesfully":{
        en:"Congrats! Your Transport insurance claims has been notified succesfully",
        bg:"Поздравления! Вашето известие за щета по Транспортно застраховане е подадено успешно!"
    },
  "Damaged Cargo":{
      en:"Damaged Cargo",
      bg:"Увреден товар"
  },
  "Damaged Object/ Injured Person":{
      en:"Damaged Object/ Injured Person",
      bg:"Увреден обект/увредено лице"
  },
  "Damaged Object":{
    en:"Damaged Object",
    bg:"Увреден обект"
  },
  "Notification Created":{
    en:"Notification Created",
    bg:"Известието беше създадено"
},
"Archived":{
    en:"Archived",
    bg:"Архивирано"
},
"Transport":{
    en:"Transport",
    bg:"Транспортно"
},
"Liability":{
    en:"Liability",
    bg:"Отговорности"
},
"Agriculture":{
    en:"Agriculture",
    bg:"Земеделско"
},
"Awaiting for approval from DZI":{
      en:"Awaiting for approval from DZI",
      bg:"Чака одобрение от ДЗИ"
},
"CASCO":{
    en:"CASCO",
    bg:"Каско"
},
"• Approved : ":{
    en:"• Approved : ",
    bg:"•Одобрено : "
},
"• Declined : ":{
    en:"• Declined : ",
    bg:"•Отказано : "
},
"DECLINED":{
    en:"DECLINED",
    bg:"ОТКАЗАНО"
},
"ACCEPTED":{
    en:"ACCEPTED",
    bg:"ПРИЕТО"
},
"Claim no: ":{
    en:"Claim no: ",
    bg:"Щета №: "
},
"Proposed Trusted Garage for your Repairs":{
    en:"Proposed Trusted Garage for your Repairs",
    bg:"[TR]Proposed Trusted Garage for your Repairs"
},
"Document List":{
    en:"Document List",
    bg:"[TR]Document List"
},
"Paid":{
    en:"Paid",
    bg:"Платено"
},
"Damage":{
    en:"Damage",
    bg:"[TR]Damage"
},
"Glass Breakage":{
    en:"Glass Breakage",
    bg:"[TR]Glass Breakage"
},
"Damaged Property Type":{
    en:"Damaged Property Type",
    bg:"Тип на повреденото имущество"
},
"Self Damage Evaluation":{
    en:"Self Damage Evaluation",
    bg:"[TR]Self Damage Evaluation"
},
"Photos of Damages":{
    en:"Photos of Damages",
    bg:"[TR]Photos of Damages"
},
"Would you like to proceed with self damage evaluation. You need to be next to your car, with a good lightening?":{
    en:"Would you like to proceed with self damage evaluation. You need to be next to your car, with a good lightening?",
    bg:"[TR]Would you like to proceed with self damage evaluation. You need to be next to your car, with a good lightening?"
},
"Would you like to proceed with photos uploaded. We advise you to proceed when you are able to make photos with good quality?":{
    en:"Would you like to proceed with photos uploaded. We advise you to proceed when you are able to make photos with good quality?",
    bg:"[TR]Would you like to proceed with photos uploaded. We advise you to proceed when you are able to make photos with good quality?"
},
"Please note that you have limited time to complete the damage evaluation.A link was to your e-mail so that you can return to the self damage evaluation process at a later stage":{
    en:"Please note that you have limited time to complete the damage evaluation.A link was to your e-mail so that you can return to the self damage evaluation process at a later stage",
    bg:"[TR]Please note that you have limited time to complete the damage evaluation.A link was to your e-mail so that you can return to the self damage evaluation process at a later stage"
},
"Please note that you have limited time to complete the photo upload. A link was sent to your e-mail so that you can return to the process at a later stage":{
    en:"Please note that you have limited time to complete the photo upload. A link was sent to your e-mail so that you can return to the process at a later stage",
    bg:"[TR]Please note that you have limited time to complete the photo upload. A link was sent to your e-mail so that you can return to the process at a later stage"
},
"BGN":{
    en:"BGN",
    bg:"[TR]BGN"
},
};
