import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { IMtplProcessStep, IMtplProcessStepController, MtplProcessStep } from './MtplProcessStep';

export class MtplProcess<MtplWorkflow> {

  protected stepsByRoute = new Map<string, MtplProcessStep>();
  protected stepsById = new Map<string, MtplProcessStep>();

  private onCurrentWorkflowChangeSubject = new BehaviorSubject<MtplWorkflow>(this.initWorkflow);
  public onCurrentWorkflowChange: Observable<MtplWorkflow> = this.onCurrentWorkflowChangeSubject;

  private onCurrentStepChangeSubject = new ReplaySubject<MtplProcessStep>();
  public onCurrentStepChange: Observable<MtplProcessStep> = this.onCurrentStepChangeSubject;

  private stepEditingSubject = new BehaviorSubject<boolean>(false);
  public stepEditing: Observable<boolean> = this.stepEditingSubject;

  private stepReturnToPageAfterEditSubject = new BehaviorSubject<string>('');
  public stepReturnToPageAfterEdit: Observable<string> = this.stepReturnToPageAfterEditSubject;

  private _returnToPageAfterEdit?: IMtplProcessStepController
  private _stepInEdit?: MtplProcessStep;
  public get stepInEdit(): MtplProcessStep | undefined {
    return this._stepInEdit;
  }

  public onStepperWorkflowSteps = combineLatest(
    this.onCurrentWorkflowChange,
    this.onCurrentStepChange,
  ).pipe(
    filter(([wf, step]) => !!wf && !!step),
    distinctUntilChanged(([wf1, step1], [wf2, step2]) => {
      return wf1 === wf2 && step1.id === step2.id;
    }),
    map(([wf, step]) => {
      return this.getWorkflowStepsFromWorkflowAndStep(wf, step);
    }),
  )

  private _currentWorkflow: MtplWorkflow = this.initWorkflow;
  public get currentWorkflow(): MtplWorkflow {
    return this._currentWorkflow;
  }
  public set currentWorkflow(value: MtplWorkflow) {
    this._currentWorkflow = value;
    this.onCurrentWorkflowChangeSubject.next(value);
  }

  private _currentStep?: MtplProcessStep;
  protected get currentStep(): MtplProcessStep | undefined {
    return this._currentStep;
  }
  protected set currentStep(value: MtplProcessStep | undefined) {
    this._currentStep = value;
    this.onCurrentStepChangeSubject.next(value);
  }

  public getStepByRoute(routePath: string): MtplProcessStep | undefined {
    return this.stepsByRoute.get(routePath);
  }

  public getCurrentStep(): MtplProcessStep | undefined {
    return this._currentStep;
  }

  public constructor(
    private router: Router,
    protected steps: IMtplProcessStep[] = [],
    protected workflows: { [name: string]: string[] },
    protected initWorkflow: MtplWorkflow,
  ) {
    steps.forEach((s) => {
      const step = new MtplProcessStep(s, this);
      this.stepsByRoute.set(step.routePath, step);
      this.stepsById.set(step.id, step);
      this.steps.push(step);
    });
  }

  protected getWorkflowStepsFromWorkflowAndStep(wf: MtplWorkflow, step: MtplProcessStep): MtplProcessStep[] {
    return this.getWorkflowSteps(wf);
  }

  public getCurrentWorkflowSteps(): MtplProcessStep[] {
    return this.getWorkflowSteps(this.currentWorkflow);
  }

  public getWorkflowSteps(workflow: MtplWorkflow): MtplProcessStep[] {
    return this.getWorkflowStepsList(this.workflows[workflow as any as string]);
  }

  private getWorkflowStepsList(list: string[]): MtplProcessStep[] {
    return list.map(id => {
      const s = this.stepsById.get(id);
      if (!s) {
        throw new Error('Missing steps');
      } else {
        return s;
      }
    });
  }

  public goToFirstStep() {
    if (this.steps.length === 0) {
      throw new Error('Steps not defined');
    }

    const firstStep = this.stepsById.get(this.workflows[this.initWorkflow as any as string][0]);
    if (!firstStep) {
      throw new Error('Step not found');
    }

    this.router.navigateByUrl(firstStep.routePath);
  }

  private navigateToStep(step: IMtplProcessStep) {
    // console.log('Next Step**********', step);
    this.router.navigateByUrl(step.routePath);
  }

  protected getCurrentWorkflowStepsList(): string[] {
    return this.workflows[this.currentWorkflow as any as string];
  }

  public hasPrevStep(step: IMtplProcessStep): boolean {
    const id = step.id;
    const workflowStepList = this.getCurrentWorkflowStepsList();

    const wfStepIndex = workflowStepList.indexOf(id);
    if (wfStepIndex === -1) {
      throw new Error(`Step ${step.id} not found`);
    }

    if (wfStepIndex === 0) {
      return false;
    } else {
      return true;
    }
  }

  public getPrevStep(step: IMtplProcessStep): IMtplProcessStep | undefined {
    const id = step.id;
    const workflowStepList = this.getCurrentWorkflowStepsList();

    const wfStepIndex = workflowStepList.indexOf(id);
    if (wfStepIndex === -1) {
      throw new Error(`Step ${step.id} not found`);
    }

    if (wfStepIndex === 0) {
      throw new Error(`Prev step for ${step.id} not found`);
    }

    return this.stepsById.get(workflowStepList[wfStepIndex - 1]);
  }

  public hasNextStep(step: IMtplProcessStep): boolean {
    const id = step.id;
    const workflowStepList = this.getCurrentWorkflowStepsList();

    const wfStepIndex = workflowStepList.indexOf(id);
    if (wfStepIndex === -1) {
      throw new Error(`Step ${step.id} not found`);
    }

    if (wfStepIndex >= workflowStepList.length) {
      return false;
    } else {
      return true;
    }
  }

  public getNextStep(step: IMtplProcessStep): IMtplProcessStep | undefined {
    const id = step.id;
    const workflowStepList = this.getCurrentWorkflowStepsList();

    const wfStepIndex = workflowStepList.indexOf(id);
    if (wfStepIndex === -1) {
      throw new Error(`Step ${step.id} not found`);
    }

    if (wfStepIndex >= workflowStepList.length) {
      throw new Error(`Next step for ${step.id} not found`);
    }

    return this.stepsById.get(workflowStepList[wfStepIndex + 1]);
  }

  public goToNextStep(step: IMtplProcessStep) {
    const nextStep = this.getNextStep(step);

    if (!nextStep) {
      throw new Error('Next step not found');
    }

    this.navigateToStep(nextStep);
  }

  public goToPrevtStep(step: IMtplProcessStep) {
    const prevStep = this.getPrevStep(step);

    if (!prevStep) {
      throw new Error('Prev step not found');
    }

    this.navigateToStep(prevStep);
  }

  public editStep(step: MtplProcessStep, returnToPageAfterEdit?: IMtplProcessStepController) {
    if (step) {
      this.enterStepEdit(step, returnToPageAfterEdit);
    } else {
      this.exitStepEdit();
    }
  }

  protected enterStepEditStateUpdate(step: MtplProcessStep, returnToPageAfterEdit?: IMtplProcessStepController) {
    this.stepEditingSubject.next(true);
    this._stepInEdit = step;
    this._returnToPageAfterEdit = returnToPageAfterEdit
    this.stepReturnToPageAfterEditSubject.next(returnToPageAfterEdit!.id);
  }

  protected enterStepEdit(step: MtplProcessStep, returnToPageAfterEdit?: IMtplProcessStepController) {
    this.enterStepEditStateUpdate(step, returnToPageAfterEdit);
    this.navigateToStep(step);
  }

  protected exitStepEditStateUpdate() {
    this._stepInEdit = undefined;
    this.stepEditingSubject.next(false);
    if (this._returnToPageAfterEdit) {
      this._returnToPageAfterEdit = undefined;
    }
  }

  public exitStepEdit() {
    const returnToPage = this._returnToPageAfterEdit;
    this.exitStepEditStateUpdate();
    if (returnToPage) {
      this.navigateToStep(returnToPage);
    }
  }

}
