import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonsModule } from '@maia/buttons';
import { DividerModule } from '@maia/divider';
import { SpinnersModule } from '@maia/spinners'
import { ProductWorkflowModule } from '../product-workflow/product-workflow.module';
import { FlowModule } from '../flow/flow.module';

import { EIdentificationNotificationComponent } from './components/e-identification-notification/e-identification-notification.component';
import { CommonEidentificationVerificationComponent } from './components/common-eidentification-verification/common-eidentification-verification.component';



@NgModule({
  declarations: [EIdentificationNotificationComponent, CommonEidentificationVerificationComponent],
  imports: [
    CommonModule,
    ButtonsModule,
    DividerModule,
    SpinnersModule,
    ProductWorkflowModule,
    FlowModule,
  ],
  exports:[EIdentificationNotificationComponent, CommonEidentificationVerificationComponent]
})
export class EidentificationModule { }
