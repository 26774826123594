import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlowModule } from '../flow/flow.module'
import { WebcamSnapshotComponent } from './component/webcam-snapshot/webcam-snapshot.component';
import { TalonScanUploadComponent } from './component/talon-scan-upload/talon-scan-upload.component';
import { TalonSlideinComponent } from './component/talon-slidein/talon-slidein.component';
import { ModalsModule as MaiaModalsModule } from '@maia/modals';

@NgModule({
  imports: [
    CommonModule,
    FlowModule,
    MaiaModalsModule
  ],
  declarations: [
    TalonScanUploadComponent,
    WebcamSnapshotComponent,
    TalonSlideinComponent,
  ],
  providers: [
  ],
  exports: [
    TalonScanUploadComponent,
    WebcamSnapshotComponent,
    TalonSlideinComponent,
  ],
  entryComponents:[
    TalonSlideinComponent
  ]
})
export class TalonScanningModule { }
