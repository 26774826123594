export enum RoutePaths {
  CL = 'claims/casco-claim',
  CL_APPLICANT_DETAILS = 'casco-applicant-details',
  CL_VEHICLE_EVENT_DETAILS = 'casco-vehicle-event-details',
  CL_EVENT_DETAILS = 'casco-event-details',
  CL_CASCO_UPLOADS = 'casco-uploads',
  CL_CASCO_SUMMARY = 'casco-summary',
  CP = 'claims/casco-property',
  CP_APPLICANT_DETAILS = 'property-applicant-details',
  CP_VEHICLE_EVENT_DETAILS = 'property-event-details',
  CP_EVENT_DETAILS = 'property-event',
  CP_CASCO_UPLOADS = 'property-uploads',
  CP_CASCO_SUMMARY = 'property-summary',
  CLAIMS_CHECK = 'claims-check',
  OTHERS = 'claims/others',
  OTHERS_APPLICANT_DETAILS = 'applicant-details',
  OTHER_EVENT_DETAILS = 'event-details',
  OTHER_UPLOADS = 'uploads',
  OTHER_SUMMARY_DETAILS = 'summary',
  CASCO_REG_MOBILE_UPLOAD = 'casco-reg-upload',
  PROPERTY_REG_MOBILE_UPLOAD = 'property-reg-upload',
}
