import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AutocompleteController, FilterDefinition } from '../../component-controllers/AutocompleteController';
import { ServerSideAutocompleteController } from '../../component-controllers/ServerSideAutocompleteController';


@Injectable()
export class AutocompleteFactoryService {

    public createAutocompleteController<Record>(
        source: Observable<Record[]>,
        destroyed$: Subject<any>,
        filter: FilterDefinition<Record>,
        init: boolean = true,
    ): AutocompleteController<Record> {
        const instance = new AutocompleteController<Record>(source, destroyed$, filter, true);
        if (init) {
            instance.init();
        }
        return instance;
    }

    public createAutocompleteWithServersideFilteringController<Record>(
        source: (q: string) => Observable<Record[]>,
        destroyed$: Subject<any>,
        init: boolean = true,
    ): ServerSideAutocompleteController<Record> {
        const instance = new ServerSideAutocompleteController<Record>(source, true);
        if (init) {
            instance.init();
        }
        return instance;
    }

}
