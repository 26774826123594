import { AbstractControl, AbstractControlOptions, AsyncValidatorFn, FormGroup, ValidatorFn } from '@angular/forms';
import { Date as AtlasDate } from '@atlas/businesstypes';
import { InputPhonenumberComponent, PhoneNumberValue } from '@maia/input-phonenumber';
import { AsyncSubject, combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter as filterRx, takeUntil } from 'rxjs/operators';

import { AutocompleteController, FilterDefinition } from '../../component-controllers/AutocompleteController';
import { CityAndPostCodeController } from '../../component-controllers/CityAndPostCodeController';
import { DatePickerController } from '../../component-controllers/DatePickerController';
import {
  PhoneNumberValueController,
  PhoneNumberValueControllerOptions,
} from '../../component-controllers/PhoneNumberValueController';
import { ServerSideAutocompleteController } from '../../component-controllers/ServerSideAutocompleteController';
import { AutocompleteFactoryService } from '../component-controllers/autocomplete-factory.service';
import { FieldPairTranslitControllerService } from '../field-pair-translit-controller.service';

export type FormDefinition<FormDataType> = {
  readonly [P in keyof FormDataType]: AbstractControl;
}

export enum SHOW_ERROR_CHECK {
  DIRTY_OR_TOUCHED = 'DIRTY_OR_TOUCHED',
  TOUCHED = 'TOUCHED',
}

export class FormGroupManager<FormDataType> {

  private _formGroup: FormGroup;
  private fieldPairTranslitControllerService: FieldPairTranslitControllerService;
  private destroyed$: Subject<void>;
  private autocompleteFactory: AutocompleteFactoryService;
  private autocompletes = new Map<keyof FormDataType, AutocompleteController<any> | ServerSideAutocompleteController<any>>();

  private onPatchSubject = new ReplaySubject<Partial<FormDataType>>(1);
  protected onPatch: Observable<Partial<FormDataType>> = this.onPatchSubject;

  private onSetDestroyedSubject = new AsyncSubject<void>();
  protected onSetDestroyed: Observable<void> = this.onSetDestroyedSubject;

  private fieldShowErrorCheckMods = new Map<keyof FormDataType, SHOW_ERROR_CHECK>();

  public constructor(controls: FormDefinition<FormDataType>,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    this._formGroup = new FormGroup(controls, validatorOrOpts, asyncValidator);
  }

  public get formGroup(): FormGroup {
    return this._formGroup;
  }

  public get value(): FormDataType {
    return this._formGroup.value as FormDataType;
  }

  public get valid(): boolean {
    return this._formGroup.valid;
  }

  public get controls(): {
    [key: string]: AbstractControl;
  } {
    return this._formGroup.controls;
  }

  public patchValue(data: Partial<FormDataType>) {
    this._formGroup.patchValue(data);
    this.onPatchSubject.next(data);
  }

  public reset(): void {
    this._formGroup.reset();
  }

  public setFieldPairTranslitControllerService(service: FieldPairTranslitControllerService) {
    this.fieldPairTranslitControllerService = service;
  }

  public setAutocompleteFactory(af: AutocompleteFactoryService) {
    this.autocompleteFactory = af;
  }

  public setDestroyed(destroyed$: Subject<any>) {
    this.destroyed$ = destroyed$;
    this.onSetDestroyedSubject.next();
    this.onSetDestroyedSubject.complete();
  }

  public setControlShowErrorCheck(name: keyof FormDataType, errorCheck: SHOW_ERROR_CHECK) {
    this.fieldShowErrorCheckMods.set(name, errorCheck);
  }

  public hasToShowErrors(name: keyof FormDataType): boolean {
    // if (this.autocompletes.has(name)) {
    //   const ac = this.autocompletes.get(name);
    //   if (ac && !ac.loaded) {
    //     return false;
    //   }
    // }
    //commenting this code for BP-8448 Bug request to show the city validation error

    let mode = SHOW_ERROR_CHECK.DIRTY_OR_TOUCHED;
    if (this.fieldShowErrorCheckMods.has(name)) {
      mode = this.fieldShowErrorCheckMods.get(name) || mode;
    }

    switch (mode) {
      case SHOW_ERROR_CHECK.DIRTY_OR_TOUCHED:
        return this.controls[name as any].invalid && (this.controls[name as any].dirty || this.controls[name as any].touched);
        break;
      case SHOW_ERROR_CHECK.TOUCHED:
        return this.controls[name as any].invalid && this.controls[name as any].touched;
        break;
    }
  }

  public hasToShowErrorsForAny(...names: (keyof FormDataType)[]): boolean {
    return names.some(name => this.hasToShowErrors(name));
  }

  public hasError(name: keyof FormDataType, errorName: string): boolean {
    return this.controls[name as any].hasError(errorName);
  }

  public attachTransliteratePairs<Key extends keyof FormDataType>(
    control1OrPairs: AbstractControl | AbstractControl[][] | Key | Key[][],
    control2?: AbstractControl | Key,
  ) {
    this.onSetDestroyed.subscribe(() => {
      if (Array.isArray(control1OrPairs)) {
        control1OrPairs.forEach((pair: AbstractControl[] | Key[]) => {
          if (pair.length !== 2) {
            throw new Error('Invalid pair');
          }

          this.attachTransliteratePairs(pair[0], pair[1]);
        })
      } else {
        if (!control2) {
          throw new Error('control2 is expected');
        }

        let control2ToUse: AbstractControl;
        if (control2 instanceof AbstractControl) {
          control2ToUse = control2
        } else {
          control2ToUse = this._formGroup.controls[control2.toString()];
        }

        let control1ToUse: AbstractControl;
        if (control1OrPairs instanceof AbstractControl) {
          control1ToUse = control1OrPairs
        } else {
          control1ToUse = this._formGroup.controls[control1OrPairs.toString()];
        }

        this.fieldPairTranslitControllerService.attach(
          control1ToUse,
          control2ToUse,
          this.destroyed$,
        );
      }
    })
  }

  public createAutocompleteWithServersideFiltering<Record>(
    name: keyof FormDataType,
    source: (q: string) => Observable<Record[]>,
    init: boolean = true,
  ): ServerSideAutocompleteController<Record> {
    const a = this.autocompleteFactory.createAutocompleteWithServersideFilteringController<Record>(source, this.destroyed$, false);
    this.autocompletes.set(name, a);

    this.onSetDestroyed.subscribe(() => {
      if (init) {
        a.init();
      }

      this.onPatch.pipe(
        filterRx(data => name in data),
        takeUntil(this.destroyed$)
      ).subscribe(async (patchData) => {
        a.setList([patchData[name] as any as Record]);
        a.disableTypeahead();
        try {
          this.controls[name as any].setValue(patchData[name]);
          a.enableTypeahead();
        } catch (e) {
          a.enableTypeahead();
          throw e;
        }
      })
    });

    return a;
  }

  public createAutocomplete<Record>(
    name: keyof FormDataType,
    source: Observable<Record[]>,
    filter: FilterDefinition<Record>,
    pkField: string | undefined = undefined,
    init: boolean = true,
  ): AutocompleteController<Record> {
    const a = this.autocompleteFactory.createAutocompleteController<Record>(source, this.destroyed$, filter, false);
    this.autocompletes.set(name, a);

    this.onSetDestroyed.subscribe(() => {
      a.setDestroyed(this.destroyed$);
      if (init) {
        a.init();
      }

      combineLatest(
        this.onPatch.pipe(filterRx(data => name in data)),
        this.onSetDestroyed,
        a.onLoad,
      ).pipe(
        takeUntil(this.destroyed$),
      ).subscribe(([val, _, list]) => {
        setTimeout(() => {
          let v;
          if (pkField) {
            v = list.find((item: any) =>
              pkField in item
              && name in val && val[name] && pkField in val[name]
              && item[pkField] === (val[name] as any)[pkField]);
          } else {
            v = val[name];
          }

          this.controls[name as any].setValue(v);
        })
      })
    });

    return a;
  }

  public createDatePicker(
    name: keyof FormDataType,
    defaultDate?: AtlasDate,
  ): DatePickerController {
    const control = this.controls[name as any];
    const picker = new DatePickerController(control, defaultDate);

    this.onSetDestroyed.subscribe(() => {
      this.onPatch
        .pipe(
          filterRx(data => name in data),
          takeUntil(this.destroyed$),
        )
        .subscribe((val) => {
          const dateValue = val[name] as any as string | AtlasDate;

          if (dateValue !== undefined && dateValue !== null) {
            if (dateValue instanceof AtlasDate) {
              picker.value = new AtlasDate(dateValue['internalValue']);
            } else if (typeof dateValue === 'object' && 'internalValue' in dateValue) {
              picker.value = new AtlasDate(dateValue['internalValue']);
            } else if (typeof dateValue === 'string') {
              picker.value = new AtlasDate(dateValue);
            } else {
              console.warn('Invalid date value');
            }
          }
        })
    })

    return picker;
  }

  public createCityAndPostCode(
    city: keyof FormDataType,
    postCode: keyof FormDataType,
  ): CityAndPostCodeController {

    const c = new CityAndPostCodeController(
      this.controls[city as string],
      this.controls[postCode as string],
    );

    this.onSetDestroyed.subscribe(() => {
      c.setDestroyed(this.destroyed$);
      c.init();
    });

    return c;
  }

  public createPhoneNumberValue(
    name: keyof FormDataType,
    component: InputPhonenumberComponent,
    options?: PhoneNumberValueControllerOptions,
  ): PhoneNumberValueController {

    const c = new PhoneNumberValueController(
      component,
      options,
    );

    this.onSetDestroyed.subscribe(() => {
      this.onPatch
        .pipe(
          filterRx(data => name in data),
          takeUntil(this.destroyed$),
        )
        .subscribe((val: FormDataType) => {
          const phoneVal = val[name] as any as PhoneNumberValue;
          c.value = phoneVal;
        })
    })

    return c;
  }
}
