import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MtplProcessService as MtplProcessServiceBase } from '@systemcode/core';

import { steps } from '../config/steps-workflow/steps';
import { workflows } from '../config/steps-workflow/workflows';
import { PolicyPaymentWorkflow } from '../model/PolicyPaymentWorkflow';

const SESSION_KEY = 'policy-payment';

@Injectable()
export class PaymentProcessService extends MtplProcessServiceBase<PolicyPaymentWorkflow> implements OnDestroy {

  public constructor(
    router: Router,
  ) {
    super(
      router,
      steps,
      workflows,
      PolicyPaymentWorkflow.POLICY_PAYMENT,
      SESSION_KEY,
    );
  }

}
