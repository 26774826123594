import { Component, OnInit, } from '@angular/core';
import { ModalContentComponent, ModalControl } from '@maia/modals';

@Component({
  selector: 'systemcode-something-went-wrong',
  templateUrl: './something-went-wrong.component.html',
  styleUrls: ['./something-went-wrong.component.scss']
})
export class SomethingWentWrongComponent extends ModalContentComponent<void> implements OnInit {

  constructor(
    public control: ModalControl<void>,
  ) {
    super();
  }

  ngOnInit() {
  }

}
