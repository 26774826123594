import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CoreModule, TravelRouteGuard } from '@systemcode/core';

import { CoreServiceModule } from './core/core-service.module';
import { StepResolverService } from './core/services/step.resolver';
import { TravelCalculatorService } from './core/services/travel-calculator.service';
import { TravelPriceCalculationService } from './core/services/travel-price-calculation.service';
import { TravelProcessService } from './core/services/travel-process.service';
import { TravelCalculatorModule } from './screens/travel-calculator/travel-calculator.module';
import { TravelPurchasePolicyModule } from './screens/travel-purchase-policy/travel-purchase-policy.module';
import { BASE_ROUTE, StarterService } from './starter.service';
import { TravelInsuranceRoutingModule } from './travel-insurance-routing.module';
import { TravelInsuranceComponent } from './travel-insurance.component';

export function routetravelinsurance(path: string) {
  return {
    path,
    loadChildren: '@systemcode/travel-insurance#øTravelInsuranceModule',
    canLoad: [TravelRouteGuard],
    //canActivate: [TravelRouteGuard]
  };
}

@NgModule({
  imports: [
    CoreModule,
    TravelInsuranceRoutingModule,
    TravelCalculatorModule,
    TravelPurchasePolicyModule,
    CoreServiceModule,
  ],
  providers: [
    TravelCalculatorService,
    TravelPriceCalculationService,
    TravelProcessService,
    StepResolverService
  ],
  declarations: [
    TravelInsuranceComponent,
  ],
})

export class øTravelInsuranceModule { }

@NgModule({
  providers: [StarterService],
})
export class TravelInsuranceModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: TravelInsuranceModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import NicknameGeneratorModule directly, use NicknameGeneratorModule.forPath() instead`);
    }
  }
}
