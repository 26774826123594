import { ValidatorFn, Validators } from '@angular/forms';

import { nonBlank } from '../language/non-blank.validator';
import { nonCyrillicLetters } from '../language/non-cyrillic-letters.validator';
import { nonLatinLetters } from '../language/non-latin-letters.validator';

export function personName(lang?: 'bg' | 'en'): ValidatorFn {
    let result: ValidatorFn | null;

    switch (lang) {
        case 'bg':
            result = Validators.compose([
                nonBlank,
                nonLatinLetters,
                Validators.maxLength(100),
            ]);
            break;
        case 'en':
            result = Validators.compose([
                nonBlank,
                nonCyrillicLetters,
                Validators.maxLength(100),
            ]);
            break;
        default:
            result = Validators.compose([
                nonBlank,
                Validators.maxLength(100),
            ]);
            break;
    }

    if (result !== null) {
        return result;
    } else {
        return () => null;
    }
}
