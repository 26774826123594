import { AbstractControl, ValidationErrors } from '@angular/forms';

export function nonBlank(control: AbstractControl): ValidationErrors | null {
    const isValid = /^\s+$/.test((control.value === null || control.value === undefined) ? '' : control.value) === false;
    if (!isValid) {
        return {
            nonBlank: true,
        }
    } else {
        return null;
    }
}
