import { AbstractControl } from '@angular/forms';
import { AsyncSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class LinkedPairController<Record> {

  protected destroyed$: Subject<void>;
  private onSetDestroyedSubject = new AsyncSubject<void>();
  protected onSetDestroyed: Observable<void> = this.onSetDestroyedSubject;

  public constructor(
    protected sourceControl: AbstractControl,
    protected targetControl: AbstractControl,
    protected sourceFieldName: keyof Record,
    destroyed$?: Subject<any>,
  ) {
    if (destroyed$) {
      this.setDestroyed(destroyed$);
    }
  }

  public setDestroyed(destroyed$: Subject<any>) {
    this.destroyed$ = destroyed$;
    this.onSetDestroyedSubject.next();
    this.onSetDestroyedSubject.complete();
  }

  public init() {
    this.onSetDestroyed.subscribe(() => {
      const currentValue = this.sourceControl.value as Record;
      if (currentValue) {
        this.setPostcode(currentValue);
      }

      this.sourceControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe((val: Record) => {
        this.setPostcode(val);
      })
    });
  }

  private setPostcode(rec: Record) {
    let postCodeValue: string | undefined = undefined;
    if (rec && this.sourceFieldName in rec) {
      postCodeValue = rec[this.sourceFieldName] as any as string;
    }

    this.targetControl.patchValue(postCodeValue);
  }

}
