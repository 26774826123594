import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesFuncOfficeSvgModule
 */
@Component({
  selector: 'svg[systemcodePlacesFuncOffice]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.96 19.77H2.5V12h3.46"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M18.05 19.77h3.45V12h-3.45"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.95 4.23h12.09v15.55H5.95z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M9.41 6.82h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.73 6.82h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M9.41 9.41h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.73 9.41h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M9.41 12h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.73 12h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M9.41 14.59h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.73 14.59h.86"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M13.29 19.77v-2.59h-2.58v2.59"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesFuncOfficeSvgComponent {
}

@NgModule({
  declarations: [
    PlacesFuncOfficeSvgComponent,
  ],
  exports: [
    PlacesFuncOfficeSvgComponent,
  ],
})
export class PlacesFuncOfficeSvgModule {}
