import { Injectable } from '@angular/core';
import { AbstractControlOptions, AsyncValidatorFn, ValidatorFn } from '@angular/forms';

import { AutocompleteFactoryService } from '../component-controllers/autocomplete-factory.service';
import { FieldPairTranslitControllerService } from '../field-pair-translit-controller.service';
import { FormDefinition, FormGroupManager } from './FormGroupManager';

@Injectable({
    providedIn: 'root',
})
export class FormGroupManagerFactoryService {

    public constructor(
        private fieldPairTranslitController: FieldPairTranslitControllerService,
        private autocompleteFactory: AutocompleteFactoryService,
    ) { }

    public createFormManager<FormDataType>(controls: FormDefinition<FormDataType>,
        validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
        asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
    ): FormGroupManager<FormDataType> {
        const fgm = new FormGroupManager<FormDataType>(controls, validatorOrOpts, asyncValidator);
        fgm.setFieldPairTranslitControllerService(this.fieldPairTranslitController);
        fgm.setAutocompleteFactory(this.autocompleteFactory);
        return fgm;
    }

}
