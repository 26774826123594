import { OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, Router } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MtplProcess } from '../process/MtplProcess';
import { IMtplProcessStep, IMtplProcessStepController, MtplProcessStep } from './MtplProcessStep';

interface MtplProcessSession {
  edit: boolean,
  step: string | null,
  returnToStep: string | null,
}

export class MtplProcessService<Workflow> extends MtplProcess<Workflow> implements OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public constructor(
    router: Router,
    steps: IMtplProcessStep[] = [],
    workflows: { [name: string]: string[] },
    initWorkflow: Workflow,
    protected sessionKey: string,
  ) {
    super(router, steps, workflows, initWorkflow);

    this.restoreFromGlobalStorage();

    let snapshot: ActivatedRouteSnapshot | undefined;
    router.events.pipe(
      filter(event => event instanceof ActivationEnd || event instanceof NavigationEnd),
      takeUntil(this.destroyed$),
    ).subscribe((activationOrNavigationEndEvent: ActivationEnd | NavigationEnd) => {
      if (activationOrNavigationEndEvent instanceof ActivationEnd && !snapshot) {
        snapshot = activationOrNavigationEndEvent.snapshot;
      }
      if (activationOrNavigationEndEvent instanceof NavigationEnd && snapshot) {
        const url = '/' + snapshot.pathFromRoot
          .filter(v => v.routeConfig)
          .map(v => v.routeConfig!.path)
          .filter(v => !!v)
          .join('/');

        const step = this.stepsByRoute.get(url);
        this.currentStep = step;
        snapshot = undefined;
      }
    })
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  protected enterStepEdit(step: MtplProcessStep, returnToPageAfterEdit?: IMtplProcessStepController) {
    super.enterStepEdit(step, returnToPageAfterEdit);
    const status: MtplProcessSession = { edit: true, step: step.id, returnToStep: returnToPageAfterEdit ? returnToPageAfterEdit.id : null };
    sessionStorage.setItem(this.sessionKey, JSON.stringify(status));
  }

  public exitStepEdit() {
    super.exitStepEdit();
    const status: MtplProcessSession = { edit: false, step: null, returnToStep: null };
    sessionStorage.setItem(this.sessionKey, JSON.stringify(status));
  }

  public restoreFromGlobalStorage() {
    let status: MtplProcessSession = { edit: false, step: null, returnToStep: null };
    const mtplJson = sessionStorage.getItem(this.sessionKey);
    if (mtplJson) {
      status = JSON.parse(mtplJson) as MtplProcessSession;
    }

    if (status.edit) {
      const step = status.step ? this.stepsById.get(status.step) : undefined;
      const returnStep = status.returnToStep ? this.stepsById.get(status.returnToStep) : undefined;

      if (step && returnStep) {
        this.enterStepEditStateUpdate(step, returnStep);
      }
    } else {
      this.exitStepEditStateUpdate();
    }
  }
}
