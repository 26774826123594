import { Pipe, PipeTransform } from '@angular/core';
import { Date } from '@atlas/businesstypes';

@Pipe({ name: 'toAtlasDate' })
export class ToAtlasDatePipe implements PipeTransform {

    public transform(value: string): Date {
        return new Date(value);
    }

}
