import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MtplProcessStep } from '../process/MtplProcessStep';


declare global {
  interface Window { dataLayer: any[]; }
}


export abstract class ScreenStepComponent {

  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  public step: MtplProcessStep;

  public constructor(
    private route: ActivatedRoute,
  ) {
    this.route.data
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: { step: MtplProcessStep }) => {
        this.step = data.step
      });
  }

  protected abstract saveMtplData(): void;

    public saveAndContinue() {
    this.saveMtplData();
    this.step.next();
  }

  public cancelEdit() {
    if(window && window.dataLayer && Array.isArray(window.dataLayer)) {
      window.dataLayer.push(
        {
          'event': 'editCancel',
        });
    }
    this.step.stopEdit();
  }

  public saveEdit() {
    if(window && window.dataLayer && Array.isArray(window.dataLayer)) {
      window.dataLayer.push(
        {
          'event': 'editSave',
        });
    }
    this.saveMtplData();
    this.step.stopEdit();
  }
}
