import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule as HermesCoreModule } from '@hermes/core';
import { TransactionModule } from '@hermes/transaction';
import { ActionsModule } from '@maia/actions';
import { AdditionalInformationModule as MaiaAdditionalInformationModule } from '@maia/additional-information';
import { AvatarsModule as MaiaAvatarsModule } from '@maia/avatars';
import { ButtonsModule, PullTabModule as MaiaPullTabModule } from '@maia/buttons';
import { ComparisonPanelModule as MaiaComparisonPanelModule } from '@maia/comparison-panel';
import { DatePickersModule } from '@maia/datepickers';
import { DisplaysModule } from '@maia/displays';
import { DividerModule as MaiaDividerModule } from '@maia/divider';
import { EmptyStateModule as MaiaEmptyStateModule } from '@maia/empty-state';
import { FlexGridModule as MaiaFlexGridModule } from '@maia/flex-grid';
import { FlowProgressWithRoutingModule } from '@maia/flow-progress';
import { FormsModule as MaiaFormsModule } from '@maia/forms';
import { HighlightPanelModule as MaiaHighlightPanelModule } from '@maia/highlight-panel';
import {
  //μCountryFlagsSvgModule,
  //μHighlightPanelSvgModule,
  μSystemMessagesSvgModule,
  μTablesSvgModule,
  μTextListSvgModule,
} from '@maia/icons';
import { InputAutocompleteModule as MaiaInputAutocompleteModule } from '@maia/input-autocomplete';
import { InputPhonenumberModule as MaiaInputPhonenumberModule } from '@maia/input-phonenumber';
import { InputSelectModule as MaiaInputSelectModule } from '@maia/input-select';
import { InputStepperModule as MaiaInputStepperModule } from '@maia/input-stepper';
import { LoadingOverlayModule as MaiaLoadingOverlayModule } from '@maia/loading-overlay';
import { MessagesModule as MaiaMessages } from '@maia/messages';
import { NameValueCollectionsModule as MaiaNameValueCollectionsModule } from '@maia/name-value-collections';
import { PanelsModule as MaiaPanelsModule } from '@maia/panels';
import { PopUpsModule as MaiaPopUpsModule } from '@maia/pop-ups';
import { PriceBlockModule as MaiaPriceBlockModule } from '@maia/price-block';
import { RadioButtonsModule as MaiaRadioButtonsModule } from '@maia/radio-buttons';
import { SelectModule as MaiaSelectModuke } from '@maia/select';
import { SelectTileModule as MaiaSelectTileModule } from '@maia/select-tile';
import { SlideInsModule as MaiaSlideInsModule } from '@maia/slide-ins';
import { SpinnersModule, SpinnersModule as MaiaSpinnersModule } from '@maia/spinners';
import { StampsModule as MaiaStampsModule } from '@maia/stamps';
import { SwitchesModule } from '@maia/switches';
import { TablesModule as MaiaTablesModule } from '@maia/tables';
import { TermsAndConditionsModule } from '@maia/terms-and-conditions';
import { TextAreaModule as MaiaTextAreaModule } from '@maia/text-area';
import { TextListsModule as MaiaTextListsModule } from '@maia/text-lists';
import { TilesModule as MaiaTilesModule } from '@maia/tiles';
import { TimepickersModule as MaiaTimepickersModule } from '@maia/timepickers';
import { CoreModule, FlowModule } from '@systemcode/core';
import { GenericSvgModule } from '@systemcode/icons';

import { ClaimsFlowProgressComponent } from '../stepper/claims-flow-progress.component';
import { TabsModule as MaiaTabsModule } from '@maia/tabs';
import { QRCodeModule } from 'angularx-qrcode';


@NgModule({
  declarations: [
    ClaimsFlowProgressComponent,
  ],
  imports: [
    ActionsModule,
    AngularFormsModule,
    ButtonsModule,
    CommonModule,
    CoreModule,
    DatePickersModule,
    DisplaysModule,
    FlowProgressWithRoutingModule,
    GenericSvgModule,
    HermesCoreModule,
    HttpClientModule,
    MaiaAdditionalInformationModule,
    MaiaComparisonPanelModule,
    MaiaDividerModule,
    MaiaEmptyStateModule,
    MaiaFlexGridModule,
    MaiaFormsModule,
    MaiaHighlightPanelModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaInputSelectModule,
    MaiaMessages,
    MaiaNameValueCollectionsModule,
    MaiaPopUpsModule,
    MaiaPriceBlockModule,
    MaiaRadioButtonsModule,
    MaiaSelectModuke,
    MaiaSelectTileModule,
    MaiaSlideInsModule,
    MaiaSpinnersModule,
    MaiaTilesModule,
    ReactiveFormsModule,
    SpinnersModule,
    SwitchesModule,
    TermsAndConditionsModule,
    TransactionModule,
    //μHighlightPanelSvgModule,
    MaiaLoadingOverlayModule,
    MaiaTextListsModule,
    μTextListSvgModule,
    μSystemMessagesSvgModule,
    MaiaStampsModule,
    MaiaTextAreaModule,
    MaiaPanelsModule,
    MaiaTablesModule,
    μTablesSvgModule,
    MaiaPullTabModule,
    MaiaInputStepperModule,
    //μCountryFlagsSvgModule,
    MaiaAvatarsModule,
    MaiaTimepickersModule,
    MaiaTabsModule,
    QRCodeModule,
    FlowModule
  ],
  exports: [
    ActionsModule,
    AngularFormsModule,
    ButtonsModule,
    CommonModule,
    CoreModule,
    DatePickersModule,
    DisplaysModule,
    FlowProgressWithRoutingModule,
    GenericSvgModule,
    HermesCoreModule,
    HttpClientModule,
    HttpClientModule,
    MaiaAdditionalInformationModule,
    MaiaComparisonPanelModule,
    MaiaDividerModule,
    MaiaEmptyStateModule,
    MaiaFlexGridModule,
    MaiaFormsModule,
    MaiaHighlightPanelModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaInputSelectModule,
    MaiaMessages,
    MaiaNameValueCollectionsModule,
    MaiaPopUpsModule,
    MaiaPriceBlockModule,
    MaiaRadioButtonsModule,
    MaiaSelectModuke,
    MaiaSelectTileModule,
    MaiaSlideInsModule,
    MaiaSpinnersModule,
    MaiaTilesModule,
    ReactiveFormsModule,
    SpinnersModule,
    SwitchesModule,
    TermsAndConditionsModule,
    TransactionModule,
    //μHighlightPanelSvgModule,
    ClaimsFlowProgressComponent,
    MaiaLoadingOverlayModule,
    MaiaTextListsModule,
    μTextListSvgModule,
    μSystemMessagesSvgModule,
    MaiaStampsModule,
    MaiaTextAreaModule,
    MaiaPanelsModule,
    MaiaTablesModule,
    μTablesSvgModule,
    MaiaPullTabModule,
    MaiaInputStepperModule,
    //μCountryFlagsSvgModule,
    MaiaAvatarsModule,
    MaiaTimepickersModule,
    MaiaTabsModule,
    QRCodeModule,FlowModule
  ],
  entryComponents: [

  ],
})
export class CoreServiceModule { }
