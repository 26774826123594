import {CallOptions, CallType} from '@atlas/convertor';
import {TypedJSONCall} from '@atlas/convertor-typed-json';

import {HttpMethods} from './http';
import {systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE} from './path-identifier';

export class BackendCall<I, O> extends TypedJSONCall<I, O> {
  public identifier = {service: '', type: '', method: ''};
  public options: CallOptions = {callType: CallType.READ};

  public constructor(
      service: string,
      type = systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE,
      headers: any = [],
      method = HttpMethods.Post,
  ) {
    super();
    this.identifier.service = service;
    this.identifier.type = type;
    this.options.headers = headers;
    this.identifier.method = method;
  }
}
