import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ContactSlideService } from '../../../modals/services/contact-slide.service';


@Component({
  selector: 'systemcode-policy-failure',
  templateUrl: './payment-failure.component.html',
  styleUrls: ['./payment-failure.component.scss'],
})
export class PaymentFailureComponent implements OnInit {

  public title: string;
  public content1: string;
  public content2: string;
  public primaryButtonLink: string;
  public primaryButtonText: string;
  public secondaryButtonLink: string;
  public secondaryButtonText: string;
  public lang: string | null;

  @Input() public paymentFailureData: any;

  public constructor(private router: Router,
    public contactSlideService: ContactSlideService) {
    this.lang = sessionStorage.getItem('lang');
  }

  public ngOnInit() {
    this.lang = sessionStorage.getItem('lang');
    // console.log('paymentFailureData', this.paymentFailureData);
    this.title = this.paymentFailureData.title;
    this.content1 = this.paymentFailureData.content1;
    this.content2 = this.paymentFailureData.content2;
    this.primaryButtonLink = this.paymentFailureData.primaryButtonLink;
    this.primaryButtonText = this.paymentFailureData.primaryButtonText;
    this.secondaryButtonLink = this.paymentFailureData.secondaryButtonLink;
    this.secondaryButtonText = this.paymentFailureData.secondaryButtonText;
  }

  public navigateToScreen(path: any) {
    if(path){
      this.router.navigateByUrl(path);
    }else
    {
      this.contactSlideService.openContacInfoSlideIn();
    }

  }

}
