import { Injectable, InjectionToken } from '@angular/core';

// import {Router} from '@angular/router';



export const BASE_ROUTE = new InjectionToken<string>('BASE_ROUTE');

/**
 * A starter service is used to start a function. Always use the starter service to navigate to this
 * route instead of e.g. the `[routerLink]` directive.
 *
 * There are a couple of reasons for this service to exist:
 *
 * - It allows passing on input in the same call that routes to the function. This means the code
 *   that jumps to this function cannot forget to pass on input, or pass input of a wrong type.
 * - It hides the internal routing of the function. This simple function only has one route, but
 *   suppose we want to add extra screens later on and the entry point of the function changes from
 *   `/` to `/main`. This service handles that change, but outside code that jumps to the page by
 *   using the router directly is now broken.
 */
@Injectable()
export class StarterService {
  public constructor(
    // private router: Router,
    // private readonly zone: NgZone,
    // @Inject(BASE_ROUTE) private baseRoute: string,
  ) { }

  /**
   * Starts the given mode of this function for the given input.
   */
  public start(screen: string): void {
    // if (screen === RoutePaths.MtplStep1Screen) {
    //   // the "start" process should be executed inside a Zone since the app.isStable
    //   // observable runs outside of the Angular zone.
    //   // See: https://angular.io/api/core/ApplicationRef#usage-notes
    //   this.zone.run(() => {
    //     this.router.navigate([this.baseRoute, RoutePaths.MtplStep1Screen]);
    //   });
    // }
  }
}
