import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@maia/buttons';
import { ModalsModule as MaiaModalsModule } from '@maia/modals';
import { PopUpsModule as MaiaPopUpsModule } from '@maia/pop-ups';
import { NoticeComponent } from './notice/notice.component';
import { FlowModule } from '../flow/flow.module';
import { ContactSlideComponent } from './contact-slide/contact-slide.component';
import { SomethingWentWrongComponent } from './something-went-wrong/something-went-wrong.component';
import { ProcessNoticeComponent } from './process-notice/process-notice.component';

@NgModule({
  imports: [
    CommonModule,
    MaiaPopUpsModule,
    MaiaModalsModule,
    ButtonsModule,
    FlowModule,

  ],
  declarations: [
    NoticeComponent,
    ContactSlideComponent,
    SomethingWentWrongComponent,
    ProcessNoticeComponent
  ],
  exports: [
    NoticeComponent,
    ContactSlideComponent,
    ProcessNoticeComponent
  ],
  entryComponents: [
    NoticeComponent,
    ContactSlideComponent,
    SomethingWentWrongComponent,
    ProcessNoticeComponent,
    ProcessNoticeComponent
  ]
})
export class ModalsModule { }
