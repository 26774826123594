import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { CONSTANTS } from '../constant/api';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    public constructor(
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if (err.status === 401 && err.statusText === CONSTANTS.API_RESPONSE.UNAUTHORIZED
                //&& err.error && err.error.message === CONSTANTS.API_RESPONSE.TOKEN_EXPIRED
                ) {
                // auto logout if 401 response returned from api
                // location.reload(true);
                // console.log('Oops!', err.error.message);
            }
            let error = err;
            if (err.status == 404 && window.location.href.includes('receive-borica-vpos-payment-result')) {
                console.log("Wrong url - error interceptor.");
                setTimeout(() => {
                    window.location.reload();
                });
            }
            /*
            if (err.status === 503) {
                console.log("Unfortunately..we have reached here - error interceptor.");
                error = 'Something went wromg!';
                setTimeout(() => {
                    window.location.reload();
                });
            }
            */
            if (err.status === 0 || err.status === 'Unreachable') {
                // error = 'Please check your internet connection';
                error = err;
            } else if (err.status === 500) {
                error = err;
            }
            return throwError(error);
        }));
    }


}