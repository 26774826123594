import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class AppendAddressFieldsService {


  public constructor(
  ) { }

  public appendBlockEntranceApartment(block: string, entrance: string, apartment: string){
    let combinedAddress: string ='';
    if(block && entrance && apartment){
      combinedAddress = block+', '+entrance+', '+apartment;
    }else if(block && entrance){
      combinedAddress = block+', '+entrance;
    }else if(block && apartment){
      combinedAddress =  block+', '+apartment;
    }else if(entrance && apartment){
      combinedAddress = entrance+', '+apartment;
    }else if(block){
      combinedAddress = block;
    }else if(entrance){
      combinedAddress = entrance;
    }else if(apartment){
      combinedAddress = apartment;
    }
    return combinedAddress;
  }

  public appendQuarterStreet(street: string, streetNumber: string | undefined, quarter?: string) {
    let quarterStreetAndStrNo: string = '';

    if(quarter){
      quarterStreetAndStrNo = quarter;
    }

    if(street && streetNumber){
      quarterStreetAndStrNo = (quarterStreetAndStrNo) ? (quarterStreetAndStrNo+', '+street + ', '+ streetNumber) : (street +', '+ streetNumber);
    }else if(street){
      quarterStreetAndStrNo = (quarterStreetAndStrNo) ? (quarterStreetAndStrNo+', '+street) : (street );
    }else if(streetNumber){
      quarterStreetAndStrNo = (quarterStreetAndStrNo) ? (quarterStreetAndStrNo+', '+streetNumber) : (streetNumber );
    }
    return quarterStreetAndStrNo;
  }

  public appendCityPostcode(title: string, postCode:string) {
    let cityTitlePostcode: string = '';

    let cityTitleString = title;
    let cityPostCode = postCode;
    let cityTitleArray = cityTitleString.split(",");

    if(cityTitleArray.length===1){
      cityTitlePostcode = cityTitleString +', '+ cityPostCode;
    }else{
      cityTitlePostcode = cityTitleString;
    }
    return cityTitlePostcode;
  }
}
