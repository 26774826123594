import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileExprDocumentAddSvgModule
 */
@Component({
  selector: 'svg[systemcodeFileExprDocumentAdd]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)"><svg:path d="M24.856 2.335v11.818h11.817" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M36.673 19.144v-4.991L24.856 2.335H1.221v43.33h19.5" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></svg:path><svg:path d="M36.673 31.768v7.581" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></svg:path><svg:path d="M40.462 35.558h-7.579" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></svg:path><svg:circle cx="36.673" cy="35.558" r="10.107" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
    'data-color': 'color-2',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileExprDocumentAddSvgComponent {
}

@NgModule({
  declarations: [
    FileExprDocumentAddSvgComponent,
  ],
  exports: [
    FileExprDocumentAddSvgComponent,
  ],
})
export class FileExprDocumentAddSvgModule {}
