import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CoreModule,ClaimRouteGuard , FlowModule} from '@systemcode/core';

import { B2cClaimsRoutingModule } from './b2c-claims-routing.module';
import { B2cClaimsComponent } from './b2c-claims.component';
import { CoreServiceModule } from './core/core-service.module';
import { B2cClaimsService } from './core/services/b2c-claims.service';
import { ClaimsProcessService } from './core/services/claims-process.service';
import { StepResolverService } from './core/services/step.resolver';
import { CascoClaimModule } from './screens/casco-claim/casco-claim.module';
import { BASE_ROUTE, StarterService } from './starter.service';





export function routeclaimsinsurance(path: string) {
  return {
    path,
    loadChildren: '@systemcode/b2c-claims#øB2cClaimsModule',
    canLoad: [ClaimRouteGuard],
  };
}

@NgModule({
  imports: [
    CoreModule,
    B2cClaimsRoutingModule,
    CascoClaimModule,
    CoreServiceModule,
    FlowModule
  ],
  providers: [
    B2cClaimsService,
    ClaimsProcessService,
    StepResolverService,
  ],
  declarations: [
    B2cClaimsComponent,
  ],
})

export class øB2cClaimsModule { }

@NgModule({
  providers: [StarterService],
})
export class B2cClaimsModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: B2cClaimsModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import NicknameGeneratorModule directly, use NicknameGeneratorModule.forPath() instead`);
    }
  }
}
