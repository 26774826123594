import {Injectable} from '@angular/core';
import {Connector} from '@atlas-angular/connector';

import {BackendCall} from './backend-call';
import {systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE} from './path-identifier';

@Injectable({
  providedIn: 'root',
})
export class BackendConnector<I, O> {
  public constructor(
      private readonly _connector: Connector,
  ) {}

  public callService(service: string, input: I): any {
    const backendCall =
        new BackendCall<I, O>(service, systemcode_AUTHENTICATED_PATH_IDENTIFIER_TYPE);
    return this._connector.prepare(backendCall, input);
  }
}
