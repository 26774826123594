<div>
    <div>
        <svg maia-icon class="phone-icon" systemcodeCommunicationExprPhone></svg>
        <div class="section-head-div">
            <label class="demo-kdl-font-normal-bold" style="font-weight: normal;" i18n="@@systemcode-dashboard-callus">Call us on <span class="demo-kdl-font-normal-bold">0700 16 166</span></label>
        </div>
        <label i18n="@@systemcode-dashboard-contactinfo">
            The round-the-clock contact center of DZI serves customer inquiries and provides telephone assistance in emergency situations:
        </label>
        <ul maiaTextList id="maia-text-list-4" [iconType]="'checkmark'">
            <li maiaTextListItem><label class="kdl-font-small-light" i18n="@@systemcode-dashboard-contactinfohrs">24 hours a day</label> </li>
            <li maiaTextListItem><label class="kdl-font-small-light" i18n="@@systemcode-dashboard-contactinfodays">7 days a week</label></li>
            <li maiaTextListItem><label class="kdl-font-small-light" i18n="@@systemcode-dashboard-contactinfoweeks">52 weeks a year</label></li>
        </ul>
    </div>

    <maia-divider style="margin: 24px 0px;"></maia-divider>
    <div>
            <svg maia-icon class="phone-icon" systemcodeInformationFuncPinSquare></svg>
            <div class="section-head-div" >
                <label class="demo-kdl-font-normal-bold" style="font-weight: normal;" i18n="@@systemcode-dashboard-nearbyoffc">Find a nearby office </label>
            </div>
            <label class="kdl-font-small" style="padding-top: 0px; color: #6686A3; font-weight: normal;" i18n="@@systemcode-dashboard-selectcity">Select your city</label>
            <form maiaForm [formGroup]="stepForm.formGroup">

            <maia-input-group class="location-input">
                  <maia-input-autocomplete  name="city" formControlName="city" (typeAhead)="cityAutocomplete.filterTypeahead($event)">
                      <input autocomplete="dzi-off" maiaInput i18n-placeholder="@@systemcode-mtpl-label-city" placeholder="City" />

                      <maia-option *ngFor="let c of cityAutocomplete.list | async" [value]="c">
                        <maia-option-highlight [label]="c.title"></maia-option-highlight>
                      </maia-option>
                      <button maiaInputGroupButtonAddon>
                        <svg systemcodeActionFuncCrosshair></svg>
                      </button>
                  </maia-input-autocomplete>
              </maia-input-group>

            <button maiaButton maiaButtonType="primary" [disabled]="!stepForm.valid" i18n="@@systemcode-dashboard-findoffc" (click)="navigateToExternalLocationUrl()">Find Office</button>
          </form>
    </div>

    <maia-divider style="margin: 24px 0px;"></maia-divider>

    <div>
        <svg maia-icon class="phone-icon" systemcodeCommunicationExprMail></svg>
        <div class="section-head-div">
            <label class="demo-kdl-font-normal-bold" style="font-weight: normal;" i18n="@@systemcode-dashboard-sendusmssg" >Send us a message</label>
        </div>
        <label class="kdl-font-small-light" i18n="@@systemcode-dashboard-additionaltext">
            Some additional text for user orientation. What, how and why he can write
        </label>
        <div style="margin-top: 16px;">
            <button maiaButton maiaButtonType="secondary" (click)="navigateToExternalurl()" i18n="@@systemcode-dashboard-writemssg">Write a message</button>
        </div>

    </div>
</div>
