import { Component, OnInit, Input } from '@angular/core';
import { LoaderDto } from '../../../model/dto/LoaderDto';

@Component({
  selector: 'systemcode-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnInit {
  public lang=sessionStorage.getItem('lang')
  public loadingMsg: string = 'Loading'
  public loaderCss: string = 'normal'
  public loderShowCss: string = 'app-loader'

  @Input()
  public set loaderMesage(loaderMesage: LoaderDto){
    if (loaderMesage){
      this.loadingMsg = loaderMesage.loader_message;
      this.loaderCss = loaderMesage.loader_css;
      this.loderShowCss = loaderMesage.loader_shown_css;
    }
  }



  public constructor() {

  }

  public ngOnInit() {
    // console.log('loaderMesage', this.loaderMesage);
    // if (this.loaderMesage){
    //   this.loadingMsg = this.loaderMesage.loader_message;
    //   this.loaderCss = this.loaderMesage.loader_css;
    // }
  }

}
