export interface EnvConfig {
    API_BASE_URL: string;
    FE_BASE_URL: string;
    API_URL: string;
    EPAY_URL: string;
    URL_TRAVEL_INSURANCE_DOC_LINK: string;
    URL_PROPERTY_INSURANCE_DOC_LINK: string;
    URL_PROPERTY_INSURANCE_DOC_EN_LINK: string;
    URL_GTC_DOC_LINK: string;
    URL_GTC_DOC_EN_LINK: string;
    URL_TRAVEL_GTC_DOC_LINK: string;
    URL_PROPERTY_GTC_DOC_LINK: string;
    URL_PROPERTY_GTC_DOC_EN_LINK: string;
    URL_RAOD_ASSIST_DOC_LINK: string;
    URL_RAOD_ASSIST_DOC_EN_LINK: string;
    URL_GTC_RA_EN_LINK: string;
    URL_TRAVEL_INSURANCE_DOC_EN_LINK: string;
    URL_TRAVEL_GTC_DOC_EN_LINK: string;
    URL_MTPL_LINK: string;
    URL_MTPL_EN_LINK: string;
    URL_GTC_RA_LINK: string;
    VPOS_SERVICE_EMAIL: string;
    EIDENTIFY_CALLBACK_URL: string,
    EIDENTIFY_CANCEL_URL: string,
    EIDENTIFY_SUCCESS_URL: string,
    LANDING_PAGE: {
      enablePropertyLink: boolean;
      enableTravelLink: boolean;
      enableClaimsLink: boolean;
      enableLangSelector: boolean;
    },
    ROUTING: {
      enablePropertyRouting: boolean;
      enableTravelRouting: boolean;
      enableClaimsRouting: boolean;
      enableENRouting: boolean;
    },
    LOGIN_DASHBOARD: {
      enablePropertyLink: boolean;
      enableTravelLink: boolean;
      enablePropertyTab: boolean;
      enableTravelTab: boolean;
      enableQLNotification: boolean;
      enableQLCasco: boolean;
      enableQLPropertyDamageNotice: boolean;
      enableQLClaimStatus: boolean;
      enableLangSelector: boolean;
      enableclaimsMenu: boolean;
      enableFooter: boolean;
    },
    UBB:{
      enableEnglishVersion: boolean;
    },
    TALONE_SCANNING:{
      enableTalonScanning: boolean;
    },
    CONSENT_MANAGEMENT: {
      enableConsentManagement: boolean;
    },
    CLAIMS_NOTIFICATION: {
      enableClaimsNotificationOthers: boolean;
      enableClaimsNotificationCL: boolean;
      enableModifiedApplicant: boolean,
    },
    E_IDENTIFICATION:{
      enableEidentification: boolean;
      EIDENTIFY_CALLBACK_URL: string;
    },
    APIS_INTEGRATION: {
      enableAPIS: boolean;
    },
    ENHANCED_ECOMMERCE: {
      enableGTMEvent: boolean;
    }
}

export const TRAVEL_PACKAGES = ['L','S','V','P'];

export const CONFIGS = {
    'TITLE': 'DZI-MTPL',
    'QUERY_LIMIT': 10,
    'CURRENCY': 'BGN',
    'MTPL_PURCHASE_GENERAL_TERM': 'https://www.kbc.ie/terms-and-conditions',
    'MTPL_CALC_SUMMARY_EDIT': 1,
    'MTPL_PURCHASE_OVERVIEW_EDIT': 2,
    'DELIVERY_CURRENT_ADDRESS': 'currentAddress',
    'DELIVERY_OTHER_ADDRESS': 'otherAddress',
    'PAGE_NOT_FOUND_TITLE':'DZI Page Not Found',
    'CLAIMS': 'Claims',
    'E_SIGN_SUCCESS':'success',
    'E_SIGN_FAIL':'fail',
    'PHONE_NUMBER_PREFIX': '+359',
    'PROPERTY_SIGN_UP_DATA':'Property_SignUp_Data',
    'MTPL_INSIS_SOURCE':'INSIS10',
    'PROPERTY_SLIDER_MAX_LIMIT': 5000000
};

export const FLOW = {
  'UBB': 'UBB'
}

export const ASSET_PATH = {
    'USER_DASHBORD_HEADER_JSON_FIRST_RELEASE':'../assets/json-file/UserDashboardHeadersMenuFirstRelease.json',
    'USER_DASHBORD_HEADER_JSON':'../assets/json-file/UserDashboardHeadersMenu.json',
    'PROPERTY_TYPE_JSON':'../assets/json-file/PropertyDataList.json',
    'PAGE_NOT_FOUND_JSON_PATH':'../assets/json-file/PageNotFound.json',
    'PROPERTY_TERM_DURATION_JSON':'../assets/json-file/PropertyTermDuration.json',
    'TRAVEL_MAPPING_RESPONSE_JSON':'../assets/json-file/TravelMappingResponse.json',
    'TYPE_OF_TRAVEL_MAPPING_JSON':'../assets/json-file/TypeOfTravelMapping.json',
    'TYPE_OF_WORK_ABROAD_MAPPING_JSON':'../assets/json-file/TypeOfWorkAbroadMapping.json',
    'REASON_FOR_TRAVEL_MAPPING_JSON':'../assets/json-file/ReasonForTravelMapping.json',
    'YOU_ARE_OPTION_EN_JSON_PATH':'../assets/json-file/OtherApplicantList.json',
    'YOU_ARE_OPTION_BG_JSON_PATH':'../assets/json-file/OtherApplicantListBG.json',
    'OTHER_CLAIMS_QUESTIONS_JSON_PATH':'../assets/json-file/OtherClaimsQuestions.json',
    'OTHER_CLAIMS_QUESTIONS_BG_JSON_PATH':'../assets/json-file/OtherClaimsQuestionsBg.json',
    'CLAIMS_INCIDENT_JSON_PATH':'../assets/json-file/ClaimIncidentList.json',
    'INCIDENT_WHILE_DRIVING_JSON_PATH':'../assets/json-file/IncidentWhileDriiving.json',
    'INCIDENT_WHILE_PARKED_JSON_PATH':'../assets/json-file/IncidentWhileParked.json',
    'CLAIMS_FRONT_JSON_PATH':'../assets/json-file/FrontViewList.json',
    'CLAIMS_REAR_JSON_PATH':'../assets/json-file/RearViewList.json',
    'GARAGE_DETAILS_JSON_PATH':'../assets/json-file/GarageDetails.json',
    'CAUSE_OF_EVENT_JSON_PATH':'../assets/json-file/CauseOfEvent.json',
}

export const LANGUAGE = {
    'ENGLISH': 'en',
    'BULGARIAN': 'bg',
};

export const EPAY_MTPL = {
    'PAYMENT_SUCCESS_URL':  '/mtpl/purchase-policy/payment?success=1',
    'PAYMENT_FAILURE_URL':  '/mtpl/purchase-policy/payment-result?success=0',
    'PAYMENT_SUCCESS_URL_EN': '/en/mtpl/purchase-policy/payment?success=1',
    'PAYMENT_FAILURE_URL_EN': '/en/mtpl/purchase-policy/payment-result?success=0',
};

export const EPAY_POLICY_PAYMENT = {
  'PAYMENT_SUCCESS_URL':  '/policy-payment/payment-result?success=1',
  'PAYMENT_FAILURE_URL':  '/policy-payment/payment-result?success=0',
  'PAYMENT_SUCCESS_URL_EN': '/en/policy-payment/payment-result?success=1',
  'PAYMENT_FAILURE_URL_EN':  '/en/policy-payment/payment-result?success=0',
};

export const EPAY_TRAVEL_INSURANCE = {
  'PAYMENT_SUCCESS_URL': '/travel/purchase-policy/travel-payment?success=1',
  //'PAYMENT_FAILURE_URL':  '/travel/purchase-policy/payment-result?success=0', Commented due to BUG-ID BP-9566
  'PAYMENT_FAILURE_URL':  '/travel/purchase-policy/travel-payment-result?success=0',
  'PAYMENT_SUCCESS_URL_EN': '/en/travel/purchase-policy/travel-payment?success=1',
  //'PAYMENT_FAILURE_URL_EN':  '/en/travel/purchase-policy/payment-result?success=0', Commented due to BUG-ID BP-9566
  'PAYMENT_FAILURE_URL_EN':  '/en/travel/purchase-policy/travel-payment-result?success=0',
};

export const BORICA_INSIS_ERROR = {
    'IN_PROGRESS': 'IN_PROGRESS',
    'SIGNED': 'SIGNED',
    'SIGNED_BY_CLIENT': 'SIGNED_BY_CLIENT',
    'REJECTED': 'REJECTED',
    'ERROR':'ERROR'
}

export const LANDING_FROM_MAIL = {
    'MTPL_ADDITIONAL_COVERS': 'additional_cover',
    'MTPL_CALC_SUMMARY_TYPE': 'calculator_summary',
    'MTPL_PURCHAGE_SUMMARY_TYPE': 'overview',
    'PROP_ADDITIONAL_COVERS': 'additional_cover',
    'PROP_CALC_SUMMARY_TYPE': 'calculator_summary',
    'PROP_PURCHAGE_SUMMARY_TYPE': 'overview',
    'TRAVEL_CALC_SUMMARY_TYPE': 'calculator_summary',
    'TRAVEL_PURCHAGE_SUMMARY_TYPE': 'overview',
};

export const DUE_INSTALMENT_SCREEN = {
    'STAND_ALONE_PAY': 'STAND_ALONE_PAY',
    'USER_DUE_INSTALMENT': 'USER_DUE_INSTALMENT',
};

export const INSURANCE: Record<string, number> = {
    'MTPL': 4600,
    'CASCO': 4700,
    'Property_Insurance': 1112,
    'TRAVEL': 8100,
    'INSURED_PEOPLE': 1,
    'MTPL_INSIS9': 4307,
    'CLAIM': 4200,
    'Transport': 5100,
    'Liability': 2001,
    'Agriculture': 6001,
};

export enum PaymentMethod {
    'VPOS' = 'VPOS',
    'Epay' = 'Epay',
}

export enum EIdentificationStatusEnum {
  'ERROR' =  'ERROR',
  'IDENTIFICATION_ERROR' = 'IDENTIFICATION_ERROR',
  'SIGNATURE_ERROR' = 'SIGNATURE_ERROR',
  'NOT_STARTED' =  'NOT_STARTED',
  'IDENTIFICATION_IN_PROGRESS' = 'IDENTIFICATION_IN_PROGRESS',
  'IDENTIFICATION_NOT_VERIFIED' = 'IDENTIFICATION_NOT_VERIFIED',
  'IDENTIFICATION_VERIFIED' = 'IDENTIFICATION_VERIFIED',
  'SIGNATURE_IN_PROGRESS' = 'SIGNATURE_IN_PROGRESS',
  'SIGNATURE_NOT_VERIFIED' =  'SIGNATURE_NOT_VERIFIED',
  'SIGNATURE_VERIFIED' = 'SIGNATURE_VERIFIED',
  'COMPLETED' = 'COMPLETED',
  'IDENTIFICATION_TIMEOUT' = 'IDENTIFICATION_TIMEOUT',
  'SIGNATURE_TIMEOUT' = 'SIGNATURE_TIMEOUT',
  'IDENTIFICATION_CANCELLED' =  'IDENTIFICATION_CANCELLED',
  'SIGNATURE_CANCELLED' = 'SIGNATURE_CANCELLED',
}

export type ApiProductCodes = 4600 | 1112 | 8100;
export type ApiPaymentMethods = 'VPOS' | 'Epay';

export const POLICY_BTN = {
     'RESUME_BTN' : 'Resume',
     'DISCARD_BTN' : 'Discard',
     'RENEW_BTN' : 'Renew',
     'VIEW_POLICY' : 'View Policy',
     'PAY_INSTALMENT': 'Pay Instalment',
     'PRINT_POLICY': 'Print Policy',
     'CLAIM_BTN'  : 'Claim'
}


export const EPAY_PROPERTY = {
    'PAYMENT_SUCCESS_URL': '/property-insurance/purchase-policy/payment?success=1',
    'PAYMENT_FAILURE_URL': '/property-insurance/purchase-policy/payment-result?success=0',
    'PAYMENT_SUCCESS_URL_EN': '/en/property-insurance/purchase-policy/payment?success=1',
    'PAYMENT_FAILURE_URL_EN': '/en/property-insurance/purchase-policy/payment-result?success=0',
};

export const RESTRICTED_VEHICLE = [
    '7','12'
]

export const TALON_ACTION = {
  'UPLOAD': 'upload',
  'DELETE': 'delete',
  'FRONT_SIDE': 'front',
  'BACK_SIDE':'back',
  'TALON': 'talon',
  'CLAIM_DOCUMENT': 'claim-document',
  'CLAIM_PHOTOS': 'claim-photos',
  'IMAGE_TYPE':'image/jpeg',
  'BACK_SIDE_IMAGE_NAME':'talon_back_side.jpg',
  'BG_TALON':'back_side',
  'TALON_SOURCE':'b2c',
  'TALON_IMAGE_PATH':'api/v1/mtpl/talon-image-download',
  'DOCU_PATH':'api/v1/claims/documents'
}

export const POLICY_CREATION_PROCESS_CONFIG = {
    CHECK_POLICY_CREATION_STATUS_CHECK_INTERVAL: 10 * 1000,
    CHECK_POLICY_CREATION_STATUS_TIMEOUT_INTERVAL: 10 * 60 * 1000,
}

export const BTRUST_IDENTIFICATION_PROCESS_CONFIG = {
  CHECK_BTRUST_IDENTIFICATION_STATUS_CHECK_INTERVAL: 10 * 1000,
  CHECK_BTRUST_IDENTIFICATION_STATUS_TIMEOUT_INTERVAL: 60 * 60 * 1000,
}

export const BTRUST_ESIGNATURE_PROCESS_CONFIG = {
  CHECK_BTRUST_ESIGNATURE_STATUS_CHECK_INTERVAL: 10 * 1000,
  CHECK_BTRUST_ESIGNATURE_STATUS_TIMEOUT_INTERVAL: 310 * 1000,
}

export const WEB_IDENTIFICATION_PROCESS_CONFIG = {
  CHECK_WEB_IDENTIFICATION_STATUS_CHECK_INTERVAL: 10 * 1000,
  CHECK_WEB_IDENTIFICATION_STATUS_TIMEOUT_INTERVAL: 2 * 60 * 1000,
}

export const WEB_ESIGNATURE_PROCESS_CONFIG = {
  CHECK_WEB_ESIGNATURE_STATUS_CHECK_INTERVAL: 10 * 1000,
  CHECK_WEB_ESIGNATURE_STATUS_TIMEOUT_INTERVAL: 5 * 60 * 1000,
}

export const PROPERTY_PAYMENT_POLICY_TERM = {
  ANNUAL: 'Annual',
  TERMLESS: 'Termless',
}


export const LOADER_MSG = {
  LOADING: 'Loading',
  PRICE_RECALC: 'Price Recalculating'
}

export const RELATIONSHIP = ['Family Numbers', 'None'];


export enum DownloadFileType {
  MAIN_POLICY = 'mainPolicy',
  PAYMENT_RECEIPT = 'paymentReceipt',
  MTPL_GTC = 'mtplGTC',
  MTPL_GC = 'mtplGC',
  MTPL_TALON = 'mtplTalon',
  PROPERTY_OU = 'propertyOU',
  PROPERTY_QUESTIONNAIRE = 'propertyQuestionnaire',
  TRAVEL_QUESTIONARY = 'travelQuestionary',
  TRAVEL_CERTIFICATE = 'travelCertificate',
  TRAVEL_INSUREDS = 'travelInsureds',
}


export enum DOCUMENT_FILE_NAME {

  MAIN_POLICY = 'Policy Document',
  PAYMENT_RECEIPT = 'Payment Receipt',
  MTPL_GTC = 'General terms and conditions',
  MTPL_GC = 'GC Document',
  MTPL_TALON = 'Talon Annual Inspection',
  PROPERTY_OU = 'General terms and conditions',
  PROPERTY_QUESTIONNAIRE = 'Questionnaire',
  TRAVEL_QUESTIONARY = 'Questionnaire',
  TRAVEL_CERTIFICATE = 'Certificates',
  TRAVEL_INSUREDS = 'List',
}


export enum OTHER_PROPERTY_TYPE {
  HOUSE_HOLD_PROPERTY =  '113.1.1'
}


export enum PAYMENT_ERROR_CONFIG {
  CONTACT_SUPPORT = 'Contact Support',
  GO_TO_HOME = 'Go to home page',
  GO_TO_HOME_GUEST_URL = 'dashboard/insurance-services',
  GO_TO_HOME_LOGGED_USER_URL = 'dashboard/home/user-info',
}


export const USER_WORKFLOW : Record<string, string> = {
  FLOW : 'application_flow',
  GUEST_TO_GUEST : 'guest_to_guest',
  GUEST_TO_USER : 'guest_to_user',
  USER_TO_USER : 'user_to_user',
}


export const CAMERA_FACING_MODE : Record<string, string> = {
  USER_MODE : 'user',
  ENVIRONMENT_MODE : 'environment',
}

export const CLAIM_EDIT : Record<string, any> = {
  APPLICANT : 'applicant',
  DRIVER : 'driver',
  INSURED_PERSON:'insured-person',
  INSURED_PERSON_HEADING:'Insured Person Details',
  DRIVER_HEADING:'Driver Details',
  APPLICANT_HEADING:'Applicant Details',
  EVENT : 'event',
  VEHICLE : 'vehicle',
  POLICY_DETAIL:'policy-detail',
  MAX_HR: 23,
  MAX_MIN:59,
  UPLOAD : 'Upload Files',
  CLIAM_FILE_SIZE:10 * 1024 * 1024,
  CLAIM_MAX_FIle : 50
}


export const CLAIM_STATUS : Record<string, string> = {
  NEW:'NEW',
  ACCEPTED:'ACCEPTED',
  DECLINED:'DECLINED',
  PAID:'PAID',
  NOT_STARTED:'NOT_STARTED'
}

export const CLAIM_TYPE : Record<string, string> = {
  ACTIVE:'ACTIVE',
  ARCHIVE:'ARCHIVE',
}


export const SELF_DAMAGE_BTN: Record<string, number>={
  PROCEED: 100,
  RETURN_TO_LATER: 200,
  SKIP: 300
}
