import { NgModule } from '@angular/core';

import { EsignatureModule } from '../esignature/esignature.module';
import { ModalsModule } from '../modals/modals.module';
import { PaymentModule } from '../payment/payment.module';


@NgModule({
  imports: [
    ModalsModule,
    PaymentModule,
    EsignatureModule,
  ],
  declarations: [
  ],
  providers: [
  ],
  exports: [
  ],
})
export class ScreenManagersModule { }
