import { AbstractControl, ValidationErrors } from '@angular/forms';
import { Boolean } from '@atlas/businesstypes';

export function requiredCheck(control: AbstractControl): ValidationErrors | null {
    const val: boolean = (control.value instanceof Boolean) ? control.value.asBoolean() : control.value;

    if (val !== true) {
        return {
            requiredCheck: true,
        }
    } else {
        return null;
    }
}
