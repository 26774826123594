import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClearSessionService {


  constructor() { }


  public clearPolicySessionData() {
    sessionStorage.removeItem('mtpl');
    sessionStorage.removeItem('mtpl-process');
    sessionStorage.removeItem('userAddresses')

    sessionStorage.removeItem('PropertyInsuranceData');
    sessionStorage.removeItem('pi-process');
    sessionStorage.removeItem('Property_SignUp_Data');

    sessionStorage.removeItem('travel');
    sessionStorage.removeItem('RENEW_PI_DATA');
    sessionStorage.removeItem('travel-process');
    sessionStorage.removeItem('confirmationContent');


    sessionStorage.removeItem('policy-payment')
    sessionStorage.removeItem('userAddresses')
    sessionStorage.removeItem('user-policy-payment')

    sessionStorage.removeItem('sendEmailResult');
    sessionStorage.removeItem('paymentFailureMsg');
    sessionStorage.removeItem('loginFlag');
    sessionStorage.removeItem('landingFromLogin');
    sessionStorage.removeItem('back_to_origin');
  }

  public clearAll() {
    sessionStorage.clear();
  }

}
