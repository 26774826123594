import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncDensityLowSvgModule
 */
@Component({
  selector: 'svg[systemcodeActionFuncDensityLow]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path class="st0" d="M4.8 18.56h14.4" stroke-width="1.04" stroke-linecap="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M4.8 12h14.4" stroke-width="1.04" stroke-linecap="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M4.8 5.44h14.4" stroke-width="1.04" stroke-linecap="round" stroke-miterlimit="10"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncDensityLowSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncDensityLowSvgComponent,
  ],
  exports: [
    ActionFuncDensityLowSvgComponent,
  ],
})
export class ActionFuncDensityLowSvgModule {}
