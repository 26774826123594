import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MtplTalonFileUploaderComponent } from './component/mtpl-talon-file-uploader/mtpl-talon-file-uploader.component';
import { ClaimsFileUploaderComponent } from './component/claims-file-uploader/claims-file-uploader.component';
import {FileUploaderModule} from '@hermes/file-uploader';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule as HermesCoreModule } from '@hermes/core';
import { FormsModule as MaiaFormsModule } from '@maia/forms';
import { FileUploadPathResolver } from './services/file-upload.path-resolver';
import { PATH_RESOLVER_TOKEN} from '@atlas-angular/connector';
import {FileUploaderTypedJsonModule} from '@hermes/file-uploader/typed-json';
import { OtherClaimsFileUploaderComponent } from './component/other-claims-file-uploader/other-claims-file-uploader.component';
import { FlowModule } from '../flow/flow.module';


@NgModule({
  declarations: [MtplTalonFileUploaderComponent, ClaimsFileUploaderComponent, OtherClaimsFileUploaderComponent],
  imports: [
    CommonModule,
    FileUploaderModule,
    AngularFormsModule,
    ReactiveFormsModule,
    HermesCoreModule,
    MaiaFormsModule,
    FlowModule,
    FileUploaderTypedJsonModule.forRoot(),
  ],
  exports:[
    MtplTalonFileUploaderComponent,
    ClaimsFileUploaderComponent,
    OtherClaimsFileUploaderComponent
  ],
  providers: [
    { provide: PATH_RESOLVER_TOKEN, useClass: FileUploadPathResolver, multi: true },
  ]
})
export class DziFileUploaderModule { }
