import {Injectable} from '@angular/core';

import {IPartyIdValidator} from '../IPartyIdValidator';
import {InvalidEikError} from './InvalidEikError';

@Injectable({
  providedIn: 'root',
})
export class EikValidatorService implements IPartyIdValidator {

  private readonly EIK_9 = /^\d{9}$/;
  private readonly EIK_13 = /^\d{13}$/;

  private readonly FIRST_SUM_9DIGIT_WEIGHTS   = [ 1, 2, 3, 4, 5, 6, 7, 8 ];
  private readonly SECOND_SUM_9DIGIT_WEIGHTS  = [ 3, 4, 5, 6, 7, 8, 9, 10 ];
  private readonly FIRST_SUM_13DIGIT_WEIGHTS  = [ 2, 7, 3, 5 ];
  private readonly SECOND_SUM_13DIGIT_WEIGHTS = [ 4, 9, 5, 7 ];

  public isValidFormat(val: string): boolean {
    return val.match(this.EIK_9) !== null || val.match(this.EIK_13) !== null;
  }

  public isValid(val: string): boolean {
    if (! this.isValidFormat(val)) {
      return false;
    }

    let isValidChecksum = false;

    if (val.length === 9) {
      isValidChecksum = this.checkChecksumForNineDigitsEIK(val);
    } else {
      isValidChecksum = this.checkChecksumForThirteenDigitsEIK(val);
    }

    return isValidChecksum;
  }

  private checkChecksumForNineDigitsEIK(eik: string): boolean {
    const digits = Array.from(eik).map(char => parseInt(char, 10));

    try {
      const ninthDigit = this.calculateNinthDigitInEIK(digits);
      return ninthDigit === digits[8];
    } catch (e) {
      if (e instanceof InvalidEikError) {
        return false;
      } else {
        throw e
      }
    }
  }

  private checkChecksumForThirteenDigitsEIK(eik: string): boolean {
    const digits = Array.from(eik).map(char => parseInt(char, 10));

    try {
      const thirteenDigit = this.calculateThirteenthDigitInEIK(digits);
      return thirteenDigit === digits[12];
    } catch (e) {
      if (e instanceof InvalidEikError) {
        return false;
      } else {
        throw e
      }
    }
  }

  private calculateNinthDigitInEIK(digits: number[]): number {
    let sum = 0;
    for (let i = 0; i < 8; i++) {
      sum = sum + (digits[i] * this.FIRST_SUM_9DIGIT_WEIGHTS[i]);
    }

    const remainder = sum % 11;
    if (remainder !== 10) {
      return remainder;
    }

    // remainder= 10
    let secondSum = 0;
    for (let i = 0; i < 8; i++) {
      secondSum = secondSum + (digits[i] * this.SECOND_SUM_9DIGIT_WEIGHTS[i]);
    }

    const secondRem = secondSum % 11;
    if (secondRem !== 10) {
      return secondRem;
    }

    // secondRemainder= 10
    return 0;
  }

  private calculateThirteenthDigitInEIK(digits: number[]): number {
    const ninthDigit = this.calculateNinthDigitInEIK(digits);
    if (ninthDigit !== digits[8]) {
      // Incorrect 9th digit in EIK-13.
      throw new InvalidEikError();
    }

    // 9thDigit is a correct checkSum. Continue with 13thDigit
    let sum = 0;
    for (let i = 8, j = 0; j < 4; i++, j++) {
      sum = sum + (digits[i] * this.FIRST_SUM_13DIGIT_WEIGHTS[j]);
    }

    const remainder = sum % 11;
    if (remainder !== 10) {
      return remainder;
    }

    // remainder= 10
    let secondSum = 0;
    for (let i = 8, j = 0; j < 4; i++, j++) {
      secondSum = secondSum + (digits[i] * this.SECOND_SUM_13DIGIT_WEIGHTS[j]);
    }

    const secondRem = secondSum % 11;
    if (secondRem !== 10) {
      return secondRem;
    }

    // secondRemainder= 10
    return 0;
  }


}
