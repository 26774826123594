import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, } from 'rxjs/operators';
import { ENV } from '../core.module';

import { ASSET_PATH, CONFIGS, EnvConfig } from '../constant/config/config';
import { SkipLoaderInterceptorHeader } from '../flow/helpers/http.loader.interceptor';
import { GenericResponse } from '../model/api/GenericResponse';
import { CityDto } from '../model/dto/CityDto';
import { DocumentSigningStatusModel } from '../model/dto/e-sign/DocumentSigningStatusModel';
import { ESignReqestModel } from '../model/dto/e-sign/ESignReqestModel';
import { PaymentFailureDto } from '../model/dto/PaymentFailureDto';


@Injectable(
  {providedIn: 'root'}
)
export class ApiService {


  public mtplCalcData: any;
  // private _jsonURL = '../assets/json-file/UserDashboardHeadersMenu.json';

  public chooseFileSubject = new BehaviorSubject({});
  public propertyJSONPATH = ASSET_PATH.PROPERTY_TYPE_JSON;

  public constructor(
    private http: HttpClient,
    @Inject(ENV) private env: EnvConfig,
  ) { }

  public API_HOST_URL = this.env.API_BASE_URL + this.env.API_URL;
  private formatErrors(error: any) {
    return throwError(error);
  }


  public getJSON(localPath: any): Observable<any> {
    const headers: {[header: string]: string} = {};
    headers[SkipLoaderInterceptorHeader] = '';
    return this.http.get(localPath, { headers: headers }).pipe(map((res: Response) => {
      return res;
    }),
      catchError(this.formatErrors.bind(this)),
    );
  }


  public get<Result = any>(path: string, params?: any, pagination: boolean = false, skipLoader: boolean = false): Observable<Result> {

    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });

      if (pagination) {
        if (!params || !('itemsPerPage' in params) || !params['itemsPerPage']) {
          queryParams = queryParams.append('itemsPerPage', `${CONFIGS.QUERY_LIMIT}`);
        }
      }
    }

    const headers: {[header: string]: string} = {};
    if (skipLoader) {
      headers[SkipLoaderInterceptorHeader] = '';
    }

    return this.http.get(`${this.API_HOST_URL}${path}`, { params: queryParams, headers }).pipe(map((res: Response) => {
      return res;
    }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public getWithParams<Result = any>(path: string, params?: any, pagination: boolean = false, skipLoader: boolean = false): Observable<Result> {

    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any) => {
        queryParams = queryParams.append(value[0], value[1]);
      });

      if (pagination) {
        if (!params || !('itemsPerPage' in params) || !params['itemsPerPage']) {
          queryParams = queryParams.append('itemsPerPage', `${CONFIGS.QUERY_LIMIT}`);
        }
      }
    }

    const headers: {[header: string]: string} = {};
    if (skipLoader) {
      headers[SkipLoaderInterceptorHeader] = '';
    }

    return this.http.get(`${this.API_HOST_URL}${path}`, { params: queryParams, headers }).pipe(map((res: Response) => {
      return res;
    }),
      catchError(this.formatErrors.bind(this)),
    );
  }


  public put(path: string, body: object = {}, params: object = {}): Observable<any> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }

    return this.http.put(`${this.API_HOST_URL}${path}`, JSON.stringify(body), { params: queryParams }).pipe(map((res: Response) => {
      return res;
    }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public post<Result = any>(path: string, body: object = {}, header?: any, params: object = {}, skipLoader: boolean = false): Observable<Result> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }

    const headers: {[header: string]: string} = header ? {...header} : {};
    if (skipLoader && ! (SkipLoaderInterceptorHeader in headers)) {
      headers[SkipLoaderInterceptorHeader] = '';
    }

    //const headers: {[header: string]: string} = {};
    //if (skipLoader) {
    //  headers[SkipLoaderInterceptorHeader] = '';
    //}

    return this.http.post(`${this.API_HOST_URL}${path}`, JSON.stringify(body), { params: queryParams, headers } ).pipe(map((res: Response) => {
      return res;
    }),
      catchError(this.formatErrors.bind(this)),
    );
  }

  public generatePDF(path: string, body: object = {}, opts: object = {}): Observable<any> {
    return this.http.post(`${this.API_HOST_URL}${path}`, JSON.stringify(body), { ...opts }).pipe(map((res: Response) => {
      return res;
    }),
      catchError(this.formatErrors.bind(this))
    );
  }

  public postFiles(path: string, body: any, header?: any, params: object = {}): Observable<any> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }
    return this.http.post(`${this.API_HOST_URL}${path}`, body, { headers: header, params: queryParams }).pipe(map((res: Response) => {
      return res;
    }), catchError(this.formatErrors.bind(this)));
  }

  public delete(path: string, params: object = {}): Observable<any> {
    let queryParams = new HttpParams();
    if (Array.isArray(params)) {
      params.forEach((value: any, key: any) => {
        queryParams = queryParams.append(key, value);
      });
    }
    return this.http.delete(`${this.API_HOST_URL}${path}`, { params: queryParams }).pipe(map((res: Response) => {
      return res;
    }), catchError(this.formatErrors.bind(this)));
  }

  public buildUrlWithParameters(baseUrl: string, params: (string|number)[]): string {
    if (params.length === 0) {
      return baseUrl;
    } else {
      return baseUrl + '/' + params.map(p => encodeURIComponent(p)).join('/');
    }
  }

  public getNotFoundData(): Observable<PaymentFailureDto> {
    return this.getJSON(ASSET_PATH.PAGE_NOT_FOUND_JSON_PATH);
  }

  public getPlaceOfResidence(q?: string, skipLoader: boolean = false): Observable<{ data: CityDto[] }> {
    let url = 'mtpl/cities';
    if (q) {
      url += '?q=' + q;
    }
    return this.get(url, undefined, undefined, skipLoader);
  }

  public startESigning(eSignReqModel: ESignReqestModel): Observable<GenericResponse<unknown[]>> {
    return this.post<GenericResponse<unknown[]>>('e-signature/start-signing-process', eSignReqModel, {});
  }

  public checkDocIdEsignStatus(docIdReq: any, skipLoader = false): Observable<GenericResponse<DocumentSigningStatusModel[]>>  {
    return this.post('e-signature/check-document-signing-status', docIdReq, {}, undefined, skipLoader);
  }

  public checkValidPolicyLinkApi(validPolicyReq: any) {
    return this.post('e-signature/check-to-restart-signing-process', validPolicyReq, {});
  }


  public getPropertyTypeList() {
    return this.getJSON(this.propertyJSONPATH);
  }

  // public uploadImageToPrim(imageUploadReq: any): Observable<any> {
  //   return this.http.post('api/process_snapshot', imageUploadReq)
  // }



}

