import {ChangeDetectionStrategy, Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {Boolean, Text, Hidden} from '@atlas/businesstypes';
import {
  FileUploaderController,
  FileUploaderSlideInConfig,
  Reference,
} from '@hermes/file-uploader';
import {MessagingService} from '@hermes/messaging';
import { ClaimsUploadResponse } from '../../../model/claims/ClaimsUploadResponse';
import { TALON_ACTION, CLAIM_EDIT } from '../../../constant/config/config';
import { ApiService } from '../../../backend/api.service';
import {localeValPipe} from '../../../flow/pipes/localeVal.pipe';
import {LocalizationService} from '../../../flow/services/localization.service';
import {ProductCode} from '../../../constant/claims-other.constants';

@Component({
  selector: 'systemcode-other-claims-file-uploader',
  templateUrl: './other-claims-file-uploader.component.html',
  styleUrls: ['./other-claims-file-uploader.component.css'],
  providers: [FileUploaderController],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtherClaimsFileUploaderComponent implements OnInit{

  public fileUploaderSelector = {disabled: new Boolean(false)};
 // public filesToUpload = 5;
  public uploadedFiles: Reference[] = [];
  public uploadedFilesBasic: Reference[] = [];
  public uploadedFilesLimitless: Reference[] = [];
  public docments: string = TALON_ACTION.CLAIM_DOCUMENT;
  public photos: string = TALON_ACTION.CLAIM_PHOTOS;
  private lang = sessionStorage.getItem('lang');
  private uploadFileTitle =  new localeValPipe(new LocalizationService()).transform('Upload File', this.lang);
  private uploadDocTitle =  new localeValPipe(new LocalizationService()).transform('Upload Photos', this.lang);
  private constraintText = new localeValPipe(new LocalizationService()).transform('Repeat the action if you would like to upload more than 1 file. Only PDF or PNG or JPEG or GIF max. 10MB', this.lang);
  private constraintImgText = new localeValPipe(new LocalizationService()).transform('Repeat the action if you would like to upload more than 1 file. Only PNG or JPEG or GIF max. 10MB', this.lang);
  public transportLabel:string = new localeValPipe(new LocalizationService()).transform('cargo',this.lang);
  public liabilityLabel:string = new localeValPipe(new LocalizationService()).transform('object/person',this.lang);
  public agricultureLabel:string = new localeValPipe(new LocalizationService()).transform('object',this.lang);
  public damagedLabel:string = new localeValPipe(new LocalizationService()).transform('cargo',this.lang);
  public claim_type_code:string;

  public slideInConfig_doc: FileUploaderSlideInConfig = {
    title: new Text(this.uploadFileTitle),
   maxNumberOfFiles: CLAIM_EDIT.CLAIM_MAX_FIle,
    mimeTypes:  new RegExp('/|^text\/|^image\/|^application\/pdf|^application\/msword|^application\/vnd.openxmlformats-officedocument.wordprocessingml.document|^application\/msexcel|^application/vnd.openxmlformats-officedocument.spreadsheetml.sheet$/'),
    maxTotalFileSize: 16000000, // byte to 10mb
    maxFileSize: CLAIM_EDIT.CLIAM_FILE_SIZE,
    dropZones: [
      {
        constraintText:  new Text(this.constraintText),
      },
    ],
    afterDownload: (reference: Reference) => {
      console.log('on download', this.uploadFileTitle, reference);
    },
    afterUpload: (references: Reference[]) => {
      console.log('on upload', this.uploadFileTitle, references);
      if (references && references.length > 0){
        const idRef: Hidden = references[0].id;
        const nameRef: Text = references[0].name;
        const typeRef: Text = references[0].type;
        const cascoUploadResponse: ClaimsUploadResponse = {
          action: TALON_ACTION.UPLOAD,
          imageSide: this.docments,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        }
        this.emitUploadedFile.emit(cascoUploadResponse);
      }else{
        this.addErrorMessageIn5Seconds();
      }
    },
    afterDelete: (reference: Reference) => {
      console.log('on delete', this.slideInConfig_doc.title, reference);

      if (reference){
        const idRef: Hidden = reference.id;
        const nameRef: Text = reference.name;
        const typeRef: Text = reference.type;
        const cascoUploadResponse: ClaimsUploadResponse = {
          action: TALON_ACTION.DELETE,
          imageSide: this.docments,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        }
        this.emitUploadedFile.emit(cascoUploadResponse);
      }

    },
  };

  public slideInConfig: FileUploaderSlideInConfig = {
    title: new Text(this.uploadDocTitle),
   maxNumberOfFiles: CLAIM_EDIT.CLAIM_MAX_FIle,
    maxTotalFileSize: 16000000, // byte to 10mb,
    maxFileSize: CLAIM_EDIT.CLIAM_FILE_SIZE,
    dropZones: [
      {
        constraintText: new Text(this.constraintImgText),
      },
    ],
    afterDownload: (reference: Reference) => {
      console.log('on download', this.slideInConfig.title, reference);
    },
    afterUpload: (references: Reference[]) => {
      console.log('on upload', this.slideInConfig.title, references);
      if (references && references.length > 0){
        const idRef: Hidden = references[0].id;
        const nameRef: Text = references[0].name;
        const typeRef: Text = references[0].type;
        const cascoUploadResponse: ClaimsUploadResponse = {
          action: TALON_ACTION.UPLOAD,
          imageSide: this.photos,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        }
        this.emitUploadedFile.emit(cascoUploadResponse);
      }else{
        this.addErrorMessageIn5Seconds();
      }
    },
    afterDelete: (reference: Reference) => {
      console.log('on delete', this.slideInConfig.title, reference);

      if (reference){
        const idRef: Hidden = reference.id;
        const nameRef: Text = reference.name;
        const typeRef: Text = reference.type;
        const cascoUploadResponse: ClaimsUploadResponse = {
          action: TALON_ACTION.DELETE,
          imageSide: this.photos,
          id: idRef['encryptedValue'],
          name: nameRef['internalValue'],
          type: typeRef['internalValue'],
        }
        this.emitUploadedFile.emit(cascoUploadResponse);
      }

    },
  };

  @Output('emitUploadedFile') public emitUploadedFile = new EventEmitter<ClaimsUploadResponse>();

  @Input()
  public set documentData(documentData: any){
    if(documentData.length > 0){
      documentData.forEach((item: any) => {
        const idRef: Hidden = new Hidden(item.id);
        const nameRef: Text = new Text(item.name);
        const typeRef: Text = new Text(item.type);
        this.uploadedFiles.push({id:idRef,name:nameRef,type:typeRef});
      });
        console.log(this.uploadedFiles);
    }
  }

  @Input()
  public set photoData(photoData: any){
    if(photoData.length > 0){
      photoData.forEach((item: any) => {
        const idRef: Hidden = new Hidden(item.id);
        const nameRef: Text = new Text(item.name);
        const typeRef: Text = new Text(item.type);
        this.uploadedFilesBasic.push({id:idRef,name:nameRef,type:typeRef});
      });
      console.log(this.uploadedFilesBasic);
    }
  }

  @Input()
  public set claimTypeCode(claimTypeCode: string){
    if(claimTypeCode.length > 0){
      this.claim_type_code = claimTypeCode;
    }
  }

  public constructor(
    private readonly _messagingService: MessagingService,
    private readonly _apiService: ApiService,
  ) { }

  public ngOnInit(){
    this.updateLabel();
  }

  public updateLabel(){
    if(this.claim_type_code === ProductCode.Transport){
      this.damagedLabel = this.transportLabel;
    }else if(this.claim_type_code === ProductCode.Liability){
      this.damagedLabel = this.liabilityLabel;
    }else if(this.claim_type_code === ProductCode.Agriculture){
      this.damagedLabel = this.agricultureLabel;
    }
  }

  public addErrorMessageIn5Seconds() {
    setTimeout(() => {
      this._messagingService.add({
        text: 'Error message',
        type: 'error',
        actionButtonText: 'ok',
      });
    }, 5000);
  }

  public updateFlag(flag: string){
    this._apiService.chooseFileSubject.next(flag);
  }
}
