import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PATH_RESOLVER_TOKEN } from '@atlas-angular/connector';
import { HeaderModule } from '@bifrost/headers';
import { CoreModule as HermesCoreModule } from '@hermes/core';
import {HeadersModule} from '@hermes/core/headers';
import { MessagingModule } from '@hermes/messaging';
import { ButtonsModule as MaiaButtonsModule } from '@maia/buttons';
import { CoreServicesModule } from '@maia/core';
import { DropdownsModule } from '@maia/dropdowns';
import { LayoutModule } from '@maia/layout';
import { ModalsModule } from '@maia/modals';
import { PopUpsModule as MaiaPopUpsModule } from '@maia/pop-ups';
import { SystemMessagesModule } from '@maia/system-messages';
import { AuthenticationBridgeModule } from '@systemcode/authentication-bridge';
import { B2cClaimsModule } from '@systemcode/b2c-claims';
import { B2cDashboardModule } from '@systemcode/b2c-dashboard';
import {BackendConfiguration, CoreModule, PathResolver} from '@systemcode/core';
import { NicknameGeneratorModule } from '@systemcode/nickname-generator';
import { PolicyPaymentModule } from '@systemcode/policy-payment';
import { PropertyInsuranceModule } from '@systemcode/property-insurance';
import { TravelInsuranceModule } from '@systemcode/travel-insurance';

import { environment } from '../environments/environment';
import {
  AppRoutingModule,
  AUTHENTICATION_BRIDGE_FUNCTION,
  B2C_DASHBOARD_PATH_FUNCTION,
  CLAIMS_PATH_FUNCTION,
  MTPL_PATH_FUNCTION,
  PP_PATH_FUNCTION,
  PROPERTY_INSURANCE_PATH_FUNCTION,
  TRAVEL_INSURANCE_PATH_FUNCTION,
} from './app-routing.module';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import {ConfigurationModule, provideConfiguration} from "@hermes/configuration";

import {AuthorisationModule} from '@hermes/authorisation';
import {DeeplinkingService} from '@hermes/deeplinking';
import {FileUploaderCallFactory, FileUploaderModule} from '@hermes/file-uploader';
import {OpenResourcesModule} from '@hermes/open-resources';
import {TransactionModule} from '@hermes/transaction';
import {CordovaStorageModule} from '@hermes-cordova/storage';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    RecaptchaV3Module,
    BrowserAnimationsModule,
    CoreModule.forRoot(environment),
    HeaderModule,
    HeadersModule.forRoot(),

    MaiaButtonsModule,
    MaiaPopUpsModule,
    DropdownsModule,
    ModalsModule,
    LayoutModule,
    SystemMessagesModule,

    CoreServicesModule.forRoot(),
    MessagingModule.forRoot(),
    HermesCoreModule.forRoot(environment.company, environment.language),
    AuthorisationModule,
    ConfigurationModule,
    FileUploaderModule,
    // HermesCoreModule,
    MessagingModule,
    OpenResourcesModule,
    TransactionModule,
    ConfigurationModule,
    AuthenticationBridgeModule.forPath(AUTHENTICATION_BRIDGE_FUNCTION),
    NicknameGeneratorModule.forPath(MTPL_PATH_FUNCTION),
    PolicyPaymentModule.forPath(PP_PATH_FUNCTION),
    B2cDashboardModule.forPath(B2C_DASHBOARD_PATH_FUNCTION),
    TravelInsuranceModule.forPath(TRAVEL_INSURANCE_PATH_FUNCTION),
    PropertyInsuranceModule.forPath(PROPERTY_INSURANCE_PATH_FUNCTION),
    B2cClaimsModule.forPath(CLAIMS_PATH_FUNCTION),
    AppRoutingModule,
    CordovaStorageModule.forCordova(),
  ],
  providers: [
    provideConfiguration('./assets/backend.json', BackendConfiguration),
    { provide: PATH_RESOLVER_TOKEN, useClass: PathResolver, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.RECAPTCHA_V3_SITEKEY },
    DeeplinkingService,
    FileUploaderCallFactory
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
