import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprOfficeSvgModule
 */
@Component({
  selector: 'svg[systemcodePlacesExprOffice]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)"><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 19L13 4h22l12 15"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M44.294 21.941V44H3.706V21.941"></svg:path><svg:path d="M27.529 36.059a3.529 3.529 0 1 0-7.058 0V44h7.058z" fill="none" stroke-linejoin="round" stroke-width="2"></svg:path><svg:circle cx="24" cy="25.471" r="2.647" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" data-color="color-2"></svg:circle><svg:path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M34.588 26.353H39v4.412h-4.412z" data-color="color-2"></svg:path><svg:path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M34.588 35.176H39v4.412h-4.412z" data-color="color-2"></svg:path><svg:path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M9 26.353h4.412v4.412H9z" data-color="color-2"></svg:path><svg:path stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M9 35.176h4.412v4.412H9z" data-color="color-2"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M44.294 21.941H31.941L24 14l-7.941 7.941H3.706"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
    'data-color': 'color-2',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprOfficeSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprOfficeSvgComponent,
  ],
  exports: [
    PlacesExprOfficeSvgComponent,
  ],
})
export class PlacesExprOfficeSvgModule {}
