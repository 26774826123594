import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneNumber } from '@atlas/businesstypes';
import { InputPhonenumberComponent } from '@maia/input-phonenumber';
import { takeUntil, map } from 'rxjs/operators';

import { CityDto } from '../../../model/dto/CityDto';
import { PhoneNumberValueController } from '../../component-controllers/PhoneNumberValueController';
import { DELIVERY_ADDRESS } from '../../constant/api';
import { FocusManagerService } from '../../services/focus-manager.service';
import { FormGroupManagerFactoryService } from '../../services/form-group-manager/form-group-manager-factory.service';
import { SHOW_ERROR_CHECK } from '../../services/form-group-manager/FormGroupManager';
import { bgPhoneNumberValidator } from '../../validation/phone/bg-phone-number.validator';
import { FormFieldValueCompareTypes, ScreenFormComponent } from '../ScreenFormComponent';
import { ApiService } from '../../../backend/api.service';
import { PhoneNumberValue } from '../../../model/dto/CreatePropertyPolicy';
import { CityAndPostCodeController } from '../../component-controllers/CityAndPostCodeController';
import { EnvConfig } from '../../../constant/config/config';
import { ENV } from '../../../core.module';

declare global {
  interface Window { dataLayer: any[]; }
}

// new
//

@Component({
  selector: 'systemcode-delivery-detail-guest',
  templateUrl: './delivery-detail-guest.component.html',
  styleUrls: ['./delivery-detail-guest.component.css'],
})
export class DeliveryDetailGuestComponent extends ScreenFormComponent implements OnInit, OnDestroy {

  public isDeliveryType = 'Business';
  public deliveryDays: Number = 0;
  public isStandAlonePayment: boolean = false;

  // new
  public isUserLoggedin: boolean = false;
  public loggedinUserData: any;
  public showForm: boolean = false;
  public nContactGroup: FormGroup
  public isDeliveryAddress = 0;
  public isDeliveryAddressSessioned : number|'newAddress';
  public profileAddressesEdited :  boolean[]
  public cityData?: CityDto;
  public cities: CityDto[] = [];
  public DELIVERY_ADDRESS = DELIVERY_ADDRESS


  public cpcController : CityAndPostCodeController;
  public cityAutocomplete;


  @ViewChild('cityInput', { static: false, read: ElementRef })
  public set cityInput(el: ElementRef) {
    this.fm.attachControl('mtpl-purchase-person-contact-details', el);
  }

  private phoneNumberController: PhoneNumberValueController;

  @ViewChild('phoneInput', { static: false })
  protected set phoneInput(val: InputPhonenumberComponent) {
    if (val && !this.phoneNumberController) {
      setTimeout(() => this.phoneNumberController = this.stepForm.createPhoneNumberValue('phone', val, { disableCountryCode: true }))
    }
  };

  @Input()
  public initialData: any;

  @Input()
  public language: string;

  @Output()
  public save = new EventEmitter();

  @Output()
  public emitDeliveryDays = new EventEmitter();

  @Input()
  public deliveryFor: any;

  // new
  @Input()
  public addressArray: any;

  // @Input()
  // public citiesArray: any;
  //

  public constructor(
    private fm: FocusManagerService,
    private formFactory: FormGroupManagerFactoryService,
    route: ActivatedRoute,
    private router: Router,
    public apiService: ApiService,
    @Inject(ENV) public env: EnvConfig,
  ) {
    super(route);
    this.stepForm = this.formFactory.createFormManager<any>({
      // new
      isDeliveryAddress: new FormControl(0),
      //
      fullName: new FormControl(undefined, [Validators.required]),
      city: new FormControl(undefined, [Validators.required]),
      postCode: new FormControl(undefined, [Validators.required]),
      street: new FormControl(undefined, [Validators.required]),
      streetNumber: new FormControl(undefined, [Validators.required]),
      block: new FormControl(),
      entrance: new FormControl(),
      apartment: new FormControl(),
      phone: new FormControl(undefined, [
        Validators.required,
        bgPhoneNumberValidator({ allowZero: true }),
      ]),
      isDeliveryType: new FormControl(undefined,[Validators.required]),
      additionalNotes: new FormControl(),
    });
    this.cpcController = this.stepForm.createCityAndPostCode('city', 'postCode');
    this.cityAutocomplete = this.stepForm.createAutocompleteWithServersideFiltering<CityDto>(
      'city',
      (q: string) => this.apiService.getPlaceOfResidence(q, true).pipe(map(res => res.data)),
    )
    // new
    this.nContactGroup = this.stepForm.formGroup as FormGroup;
    //
    this.stepForm.setControlShowErrorCheck('phone', SHOW_ERROR_CHECK.TOUCHED);

  }

  public ngOnInit() {

    this.profileAddressesEdited = Array(this.addressArray.length).fill(false)
    // only if user is lodded in
    const userData = sessionStorage.getItem('userData');
    if (userData) {

      // this.addressArray.forEach((element: any, index: number) => {
      //   const city = this.citiesArray.find((options: any) => options.id === element.city);
      //   if (city) {
      //     this.addressArray[index].city = city
      //     this.startChangeMonitor(undefined, { city: 'id' });
      //   }
      // });

      if (history.state.data) {
          const index = history.state.arrayIndex
          const editeddata = history.state.data
          this.addressArray[index] = editeddata
          if(history.state.profileAddressesEdited){
            this.profileAddressesEdited = history.state.profileAddressesEdited;
            this.profileAddressesEdited[index] = true;
          }
          sessionStorage.removeItem('userAddresses')
          sessionStorage.setItem('userAddresses', JSON.stringify(this.addressArray))
      }
      this.isUserLoggedin = true;
      this.loggedinUserData = userData;
      this.nContactGroup.controls['isDeliveryAddress'].setValidators([Validators.required]);
      this.nContactGroup.controls['isDeliveryAddress'].updateValueAndValidity();

      if (this.stepForm.controls['isDeliveryAddress'] !== undefined) {
        this.stepForm.controls['isDeliveryAddress'].valueChanges
          .pipe(
            takeUntil(this.destroyed$),
          )
          .subscribe((result: any) => {
            if (result === 'newAddress') {
              this.nContactGroup.controls['fullName'].setValidators([Validators.required]);
              this.nContactGroup.controls['city'].setValidators([Validators.required]);
              this.nContactGroup.controls['postCode'].setValidators([Validators.required]);
              this.nContactGroup.controls['street'].setValidators([Validators.required]);
              this.nContactGroup.controls['streetNumber'].setValidators([Validators.required]);
              this.nContactGroup.controls['phone'].setValidators([
                Validators.required,
                bgPhoneNumberValidator({ allowZero: true }),
              ]);
              this.nContactGroup.controls['isDeliveryType'].setValidators([Validators.required]);

              setTimeout(() => {
                this.stepForm.controls.city.valueChanges.pipe(
                  takeUntil(this.destroyed$),
                ).subscribe(value => {
                  if (value !== undefined && value  !== null) {
                    this.checkDelivery(value);
                  }
                })
              });
            }
            else {
              setTimeout(() => {
                if (this.addressArray[result].city)  {
                  this.checkDelivery(this.addressArray[result].city)
                }
              });

              this.nContactGroup.controls['fullName'].setValidators(null);
              this.nContactGroup.controls['city'].setValidators(null);
              this.nContactGroup.controls['postCode'].setValidators(null);
              this.nContactGroup.controls['street'].setValidators(null);
              this.nContactGroup.controls['streetNumber'].setValidators(null);
              this.nContactGroup.controls['phone'].setValidators(null);
              this.nContactGroup.controls['isDeliveryType'].setValidators(null);
            }
            this.nContactGroup.controls['fullName'].updateValueAndValidity();
            this.nContactGroup.controls['city'].updateValueAndValidity();
            this.nContactGroup.controls['postCode'].updateValueAndValidity();
            this.nContactGroup.controls['street'].updateValueAndValidity();
            this.nContactGroup.controls['streetNumber'].updateValueAndValidity();
            this.nContactGroup.controls['phone'].updateValueAndValidity();
            this.nContactGroup.controls['isDeliveryType'].updateValueAndValidity();
          })
      }

    } else {
      this.isUserLoggedin = false;
      this.addressArray = [];
      this.stepForm.controls.city.valueChanges.pipe(
        takeUntil(this.destroyed$),
      ).subscribe(value => {
        if (value !== undefined) {
          this.checkDelivery(value);
        }
      })
    }

    if (this.deliveryFor === DELIVERY_ADDRESS.MTPL) {
      this.isStandAlonePayment = true;
    }
    if (this.isUserLoggedin === false) {

      const data = this.initialData;
      if (data) {
        if (data.deliveryDetailsStep) {
          const deliveryStepData = data.deliveryDetailsStep;
          this.isDeliveryType = deliveryStepData.isDeliveryType;
          this.stepForm.patchValue(deliveryStepData);
          this.updatePhoneNoAndOthers(deliveryStepData);
        }
        else if (data.personalContactDetailsStep && data.personalDetailsStep) {
          const personalData = data.personalDetailsStep;
          let fullName = '';
          if (this.language === 'bg') {
            fullName = personalData.firstName + ' ' + personalData.middleName + ' ' + personalData.lastName;
          } else {
            fullName = personalData.firstNameEn + ' ' + personalData.middleNameEn + ' ' + personalData.lastNameEn;
          }
          this.stepForm.patchValue({
            fullName: fullName,
          });

          const contactDetilData = data.personalContactDetailsStep;
          this.stepForm.patchValue(contactDetilData);
          this.updatePhoneNoAndOthers(contactDetilData);
        }
        else if (data.personalContactDetailsStep && data.foreignerDetailsStep) {
          const foreignerData = data.foreignerDetailsStep;
          let fullName = '';
          if (this.language === 'bg') {
            fullName = foreignerData.firstName  + (foreignerData.middleName ? ' ' + foreignerData.middleName : '') + ' ' + foreignerData.lastName;
          } 
          else {
            fullName = foreignerData.firstNameEn  + (foreignerData.middleNameEn ? ' ' + foreignerData.middleNameEn : '') + ' ' + foreignerData.lastNameEn;
          }
          this.stepForm.patchValue({
            fullName: fullName,
          });

          const contactDetilData = data.personalContactDetailsStep;
          this.stepForm.patchValue(contactDetilData);
          this.updatePhoneNoAndOthers(contactDetilData);
        } 
        else if (data.companyDetailsStep) {
          const companyData = data.companyDetailsStep;
          let fullName = '';
          if (this.language === 'bg') {
            fullName = companyData.companyName;
          } else {
            fullName = companyData.companyNameEn;
          }
          this.stepForm.patchValue({
            fullName: fullName,
            city: companyData.city,
            street: companyData.street,
            streetNumber: companyData.streetNumber,
            block: companyData.block,
            entrance: companyData.entrance,
            apartment: companyData.apartment,
            phone: companyData.phone,
          });
          this.updatePhoneNoAndOthers(companyData);
        } else if (data.delivery) {
          const deliveryData = data.delivery;

          this.stepForm.patchValue({
           fullName: deliveryData.fullName,
           street: deliveryData.street,
           streetNumber: deliveryData.streetNumber,
           block: deliveryData.block,
           entrance: deliveryData.entrance,
           apartment: deliveryData.apartment,
           phone: deliveryData.phone,
           isDeliveryAddress: deliveryData.isDeliveryAddress,
           isDeliveryType: deliveryData.isDeliveryType,
           deliveryDays: deliveryData.deliveryDays,
         });

         if(deliveryData.city){
           this.stepForm.patchValue({
             city: deliveryData.city,
             postCode: deliveryData.postCode,
           });
         }
          setTimeout(() => {
            this.startChangeMonitor(undefined, { city: 'id' });
          });
        }
      }

    } else {
      const data = this.initialData;
      if (data) {
        if (data.deliveryDetailsStep) {
          const deliveryStepData = data.deliveryDetailsStep;
          this.isDeliveryType = deliveryStepData.isDeliveryType;
          this.isDeliveryAddress = deliveryStepData.isDeliveryAddress;
          this.isDeliveryAddressSessioned = deliveryStepData.isDeliveryAddress;
          this.stepForm.patchValue({ isDeliveryAddress: deliveryStepData.isDeliveryAddress });
          // this.updatePhoneNoAndOthers(deliveryStepData);
          this.startChangeMonitor(undefined); 
          if (deliveryStepData.isDeliveryAddress == 'newAddress') {
            this.showFormContents(true)
            this.stepForm.patchValue(deliveryStepData);
            this.updatePhoneNoAndOthers(deliveryStepData);
          }
        }
      }
    }
  }

  public updatePhoneNoAndOthers(data: any) {
    const init: { phone?: PhoneNumber } = {};
    if (data.phone && !(data.phone instanceof PhoneNumber)) {
      init['phone'] = new PhoneNumber(data.phone.prefix + data.phone.number);
    }
    this.startChangeMonitor(init, {
      city: 'id',
      block: FormFieldValueCompareTypes.EQUAL_NULL_UNDEFINED_EMPTY_STRING,
      entrance: FormFieldValueCompareTypes.EQUAL_NULL_UNDEFINED_EMPTY_STRING,
      apartment: FormFieldValueCompareTypes.EQUAL_NULL_UNDEFINED_EMPTY_STRING,
    });
    this.checkDelivery(data.city);
  }

  public checkDelivery(city: any) {
    if(city){
    //   if (city.title === 'Sofia' || city.postal_code === '1000') { // need to check with postal_code
    //     this.deliveryDays = 1
    //   } else {
    //     this.deliveryDays = 2
    //   }
    // }else{
      this.deliveryDays = 2
    }
    this.emitDeliveryDays.emit({'deliveryDays': this.deliveryDays});
  }

  protected saveMtplData(): void {
    if(this.env.ENHANCED_ECOMMERCE.enableGTMEvent){
      this.triggerGTMEvent();
    }
    // new
    const id = this.stepForm.controls['isDeliveryAddress'].value
    if (this.isUserLoggedin === true && id !== 'newAddress') {


      this.stepForm.patchValue({
        isDeliveryAddress: this.isDeliveryAddress,
        fullName: this.addressArray[id].full_name,
        city: this.addressArray[id].city,
        postCode: this.addressArray[id].post_code,
        street: this.addressArray[id].street,
        streetNumber: this.addressArray[id].street_number,
        block: this.addressArray[id].block,
        entrance: this.addressArray[id].entrance,
        apartment: this.addressArray[id].apartment,
        phone: this.addressArray[id].contact_number,
        isDeliveryType: this.addressArray[id].type_of_address,
        additionalNotes: this.addressArray[id].additional_notes,
      })

    }
    //
   // console.log(this.)
    // new
    if (this.isUserLoggedin === true && id !== 'newAddress') {
      const val = this.stepForm.value;
      console.log('final value', val, this.deliveryDays, this.addressArray[id].id)
      this.save.next({
        ...val,
        phone: this.addressArray[id].contact_number,
        deliveryDays: this.deliveryDays,
        userDeliveryAddressId: this.addressArray[id].id,
      });
    }
    else {
      const val = this.stepForm.value;
      const phoneValue = { ...this.phoneNumberController.value };
      if (phoneValue.number && phoneValue.number.length === 10 && phoneValue.number[0] === '0') {
        phoneValue.number = phoneValue.number.substr(1);
      }
      this.save.next({
        ...val,
        phone: phoneValue,
        deliveryDays: this.deliveryDays,
        userDeliveryAddressId: null,
      });
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
  public showFormContents(value: boolean) {
    this.showForm = value;
  }

  public editAddress(b:  number){
    const data = sessionStorage.getItem('userData');
    if (data && this.deliveryFor === DELIVERY_ADDRESS.STAND_ALONE_PAY){
      console.log('going to payments flow')
      this.router.navigateByUrl('policy-payment/edit-delivery-details',
      {state: {data: this.addressArray[b], addressid: this.addressArray[b].id, arrayindex: b, profileAddressesEdited: this.profileAddressesEdited }})
    }else{
      this.router.navigateByUrl('/mtpl/purchase-policy/edit-delivery-address',
      {state: {data: this.addressArray[b], addressid: this.addressArray[b].id, arrayindex: b, profileAddressesEdited: this.profileAddressesEdited }})
    }
  }

  public blockEntAptNullCheck(address: any){
    const block = address.block;
    const ent =   address.entrance;
    const apt =   address.apartment;
    if (block && ent && apt){
      return  ', ' + block + ', ' + ent + ', ' + apt ;
    }else {
      let BEA = '';
      const beaArray = [block, ent, apt];
      beaArray.forEach((element: any) => {
        if (element && BEA.length === 0){
          BEA = ', ' + element ;
         }else if (element){
          BEA = BEA + ', ' + element ;
         }
      })
      return BEA;
    }
  }

  public clearFields(){
    this.stepForm.reset();
    const phone:PhoneNumberValue ={
      prefix : this.phoneNumberController.value.prefix,
      number: ''
    }
    this.phoneNumberController.value = phone;
  }

  public checkDisabled(){
    if(this.isDeliveryAddressSessioned !== 'newAddress' && this.isDeliveryAddress ===  this.isDeliveryAddressSessioned){
      const id = this.stepForm.controls['isDeliveryAddress'].value
      return this.profileAddressesEdited[id] ? false : true;
    }
    else{
      return (!this.stepForm.valid || !this.hasEditChange)
    }
  }

  public triggerGTMEvent(){
    window.dataLayer.push(
      {
        'event': 'deliveryAddressContinue',
      });
  }
}
