import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'systemcode-browser-support-warning',
  templateUrl: './browser-support-warning.component.html',
  styleUrls: ['./browser-support-warning.component.scss'],
})
export class BrowserSupportWarningComponent implements OnInit {

  public title: string;
  public content1: string;
  public content2: string;
  public lang: string | null;

  public constructor(
  ) {
    this.lang = sessionStorage.getItem('lang');
  }

  public ngOnInit() {
    this.lang = sessionStorage.getItem('lang');

    this.title = 'go.dzi.bg is best viewed in modern browsers';
    this.content1 = 'go.dzi.bg has been crafted to work on modern browsers and does not work as intended on legacy browsers';
  }
}
