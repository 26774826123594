import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule SocialExprPersonKeySvgModule
 */
@Component({
  selector: 'svg[systemcodeSocialExprPersonKey]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 4.8 4.8)"><svg:path class="st0" d="M19.9 22.4c-5.2 0-9.4-4.2-9.4-9.4v-1.9c0-5.2 4.2-9.4 9.4-9.4s9.4 4.2 9.4 9.4V13c0 5.2-4.2 9.4-9.4 9.4z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M31.6 29.4c-3.1-.7-7.1-1.4-11.7-1.4-5.9 0-10.7 1-14 2-2.8.8-4.7 3.4-4.7 6.3V43h23" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:path class="st0" d="M28.7 46.2H32l2.5-2.5v-2.5H37l2-2c.6.2 1.3.4 2.1.4 3.2 0 5.8-2.6 5.8-5.8S44.2 28 41 28s-5.8 2.6-5.8 5.8c0 .5.1 1 .2 1.5L28.7 42v4.2z" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:path><svg:circle class="st0" cx="41" cy="33.8" r="1.7" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialExprPersonKeySvgComponent {
}

@NgModule({
  declarations: [
    SocialExprPersonKeySvgComponent,
  ],
  exports: [
    SocialExprPersonKeySvgComponent,
  ],
})
export class SocialExprPersonKeySvgModule {}
