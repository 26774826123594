import { Injectable } from '@angular/core';
import { CityDto } from '../model/dto/CityDto';

@Injectable({
  providedIn: 'root'
})
export class CityParsingMappingService {

  public constructor() { }

  public parseAndMapLoggedInCity(userCityData : any) : CityDto | undefined{
    let cityData : CityDto | undefined
    if(userCityData){
      const language = sessionStorage.getItem('lang');
      cityData = {
        id : +userCityData['id'],
        postal_code: userCityData['postal_code'],
        title: (language === 'bg') ? userCityData.name_bg : userCityData.name_en
      };
    }
    return cityData;
  }
}
