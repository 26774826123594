import { AbstractControl } from '@angular/forms';
import { Date } from '@atlas/businesstypes';

export class DatePickerController {

  public selectedDate?: Date = undefined;

  public constructor(
    private control: AbstractControl,
    defaultDate?: Date,
  ) {
    if (defaultDate) {
      this.patchValue(defaultDate);
    }
  }

  public get value(): Date | undefined {
    return this.selectedDate;
  }

  public set value(val: Date | undefined) {
    this.patchValue(val);
  }

  private patchValue(d: Date | undefined) {
    this.selectedDate = d;
    this.control.patchValue(d);
    this.control.updateValueAndValidity();
  }

  public change(val: Date) {
    this.control.patchValue(val);
    this.control.updateValueAndValidity();
    this.touch();
  }

  public touch() {
    this.control.markAsTouched();
    this.control.markAsDirty();
  }
}
