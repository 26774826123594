import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@maia/buttons';

import { WorkflowAlertComponent } from './components/workflow-alert/workflow-alert.component';
import { GenericSvgModule } from '@systemcode/icons';
import { FlowModule } from '../flow/flow.module'

@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,
    GenericSvgModule,
    FlowModule
  ],
  declarations: [
    WorkflowAlertComponent,
  ],
  providers: [
  ],
  exports: [
    WorkflowAlertComponent,
  ],
})
export class ProductWorkflowModule { }
