import { ComponentFactory, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { PopUpController } from '@maia/pop-ups';
import { ModalsModule } from '../modals.module';
import { SomethingWentWrongComponent } from '../something-went-wrong/something-went-wrong.component';
import { localeValPipe } from '../../flow/pipes/localeVal.pipe';
import { LocalizationService } from '../../flow/services/localization.service';
import { LoggerFactory } from '@atlas-angular/logger';
import { Logger } from '@atlas/logger';

@Injectable({
  providedIn: ModalsModule
})
export class SomethingWentWrongService {

  private readonly confirmationFactory: ComponentFactory<SomethingWentWrongComponent>;
  private _logger: Logger;

  constructor(
    private popupController: PopUpController,
    loggerFactory: LoggerFactory,
    componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
  ) {
    this._logger = loggerFactory.createLogger('SomethingWentWrongComponent');
    this.confirmationFactory = componentFactoryResolver.resolveComponentFactory(SomethingWentWrongComponent);
  }

  public showInternalServerDialog() {
    const lang= sessionStorage.getItem('lang')
    const title = new localeValPipe(new LocalizationService()).transform('Something went wrong',lang);
    this.popupController
      .prepare(
        this.confirmationFactory,
        this._injector,
        {
          title: title,
          size: 'normal',
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
        },
      )
      .subscribe(
        { error: this._logger.error },
      );
    }
}
