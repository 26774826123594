// import { Component, OnInit } from '@angular/core';
import { Component, Input, ViewChild, TemplateRef, OnInit, EventEmitter, Output } from '@angular/core';
import { TileHeight, TileWidth } from '@maia/tiles';
import moment from 'moment';
import { localeValPipe } from './../../pipes/localeVal.pipe';
import { LocalizationService } from './../../services/localization.service';
import {INSURANCE, POLICY_BTN } from '../../../constant/config/config'
import { PropertyType } from '../../../model/dto/PropertyType';

@Component({
  selector: 'systemcode-common-policy',
  templateUrl: './common-policy.component.html',
  styleUrls: ['./common-policy.component.scss'],
})
export class ActivePolicyComponent implements OnInit {

  public currentWidth2: TileWidth = 'beam';
  public currentHeight2: TileHeight = 'auto';
  public messageText: string;
  public messageDesign: string;

  public RESUME_BTN = POLICY_BTN.RESUME_BTN;
  public DISCARD_BTN = POLICY_BTN.DISCARD_BTN;
  public RENEW_BTN = POLICY_BTN.RENEW_BTN;
  public VIEW_POLICY = POLICY_BTN.VIEW_POLICY;
  public PAY_INSTALMENT = POLICY_BTN.PAY_INSTALMENT;
  public lang: string | null;
  public PeopleCount: number

  @Output()
  public viewPolicy = new EventEmitter();

  @Input()
  public policyObject: any;

  @Input()
  public propertySelectionArray: PropertyType[] = [];


  @Input()
  public buttonView: string;

  public currentPolicyTemplate: TemplateRef<any>;
  @ViewChild('mtplTemplate', { static: true }) public mtplTemplate: TemplateRef<any>;
  @ViewChild('propertyTemplate', { static: true }) public propertyTemplate: TemplateRef<any>;


  public constructor() {
    this.lang = sessionStorage.getItem('lang');
  }

  public ngOnInit() {
    // for now only all policies are shown as MTPL policies
    this.currentPolicyTemplate = this.mtplTemplate;
    this.lang = sessionStorage.getItem('lang');
    // console.log(this.policyObject);
    this.Messagetext();

  }

  public Messagetext() {
    if (this.buttonView === 'Draft') {
      this.messageText = 'This draft will expire on';
      this.messageDesign = 'attention';
    } else if (this.buttonView === 'Active') {
      if (this.policyObject && this.policyObject['nextDueInstallment']) {
        this.messageText = 'Next installment due on';
        this.messageDesign = 'attention';
      } else {
        this.messageText = 'Expires on';
        this.messageDesign = 'negative';
      }
    } else {
      this.messageText = 'Expired at';
      this.messageDesign = 'informative';
    }
  }


  public getFormattedDate(dateVal: Date) {
    const momentVariable = moment(dateVal, 'YYYY-MM-DD HH:mm:ss');
    const stringVal = momentVariable.format('DD-MM-YYYY');
    return stringVal;
  }



  public view(btnType: any){
    const data = {
      'btnType': btnType,
      'policyObj': this.policyObject,
    }
    this.viewPolicy.emit(data);
  }

  public formatDate(dateString: any) {
    const date = new Date(dateString);
    if ((date instanceof Date && !isNaN(date.getTime()))) {
      const momentVariable = moment(date, 'YYYY-MM-DD HH:mm:ss');
      if(this.lang){
        momentVariable.locale(this.lang)
      }
      const stringvalue = momentVariable.format('Do MMMM YYYY');
      return stringvalue;
    }
    return dateString;
  }

  public installmentLabelVisible(): boolean {
    return (this.policyObject && this.policyObject['nextDueInstallment']) ? true : false ;
  }

  public policyExpiryLabelVisible(): boolean {
    return (this.policyObject && this.policyObject['nextDueInstallment']) ? false : this.expiryDateCheck(this.policyObject['validity']) ;
  }

  public checkProduct(productCode:string){
    if(Number(productCode)!==INSURANCE.TRAVEL){
      return true;
    }else{
      return false;
    }
  }
  
  public expiryDateCheck(validity: string) {
    const dateOfExpiry = new Date(validity);
    if ((dateOfExpiry instanceof Date && !isNaN(dateOfExpiry.getTime()))) {
      const currentDate = new Date();
      dateOfExpiry.setDate(dateOfExpiry.getDate() - 60);
      if (currentDate >= dateOfExpiry) {
        return true;
      }
    }
    return false;
  }

  public getKeyByValue(object: any, value: number) {
    return Object.keys(object).find(key => object[key] === value);
  }

  public getName(productCode: string): any {
    const productKey = this.getProductName(productCode);
    let productName = '';
    if (productKey === 'TRAVEL') {
      productName = 'Travel Insurance';
    } else {
      productName =  productKey ? productKey.replace('_', ' ') : '';
    }
    return new localeValPipe(new LocalizationService()).transform(productName, this.lang);
  }

  public getProductName(productCode: string): any {
    const productName =  this.getKeyByValue(INSURANCE, parseInt(productCode, 10));
    return productName;
  }

  public getPolicyTitle(policyObj: any){
    if(policyObj && policyObj.title && policyObj.productCode){
      if(policyObj.productCode !== INSURANCE.Property_Insurance+''){
        return policyObj.title;
      }else if(policyObj.productCode === INSURANCE.Property_Insurance+''){
        const propertType: PropertyType | undefined= this.propertySelectionArray.find((pType: PropertyType)=> pType.id === policyObj.typeId);
        return (propertType) ? propertType.name : ''
      }
    }
   return '';
  }
}
