import { Injectable, NgZone } from '@angular/core';

import { ApiService } from '../../backend/api.service';
import { POLICY_CREATION_PROCESS_CONFIG } from '../../constant/config/config';
import { wait } from '../../helpers/wait';
import { GenericResponse } from '../../model/api/GenericResponse';
import { CheckPolicyStatusData } from '../../model/dto/CheckPolicyStatusResponse';
import { IProductWorkflowService } from '../../product-workflow/model/product-workflow-service';
import { PolicyCreationError } from '../model/policy-creation-error';
import { ScreenManagersModule } from '../screen-managers.module';
import { IPropertyPaymentScreen } from './property-payment-screen';


@Injectable({
  providedIn: ScreenManagersModule
})
export class PolicyCreationStatusCheckService {

  screen: IPropertyPaymentScreen;
  productWorkflow: IProductWorkflowService;

  constructor(
    private api: ApiService,
    public _ngZone: NgZone,
  ) { }

  public async enterPolicyCreationStatusCheckMode() {

    try {
      this.screen.showLoader({
        loader_message: 'Your policy is being created',
        loader_css: 'big',
        loader_shown_css: 'app-loader',
      });
  
      const processId = this.productWorkflow.getPolicyCreationProcessId();
  
      if (processId) {
        // start in background
        // why this is needed, along with this._ngZone.run inside of the loop function?
        this._ngZone.runOutsideAngular(() => {
            this.startPolicyCheckLoop(processId)
        });
      }
      
    } catch (error) {
      console.log("##",error)
      // throw new Error('error');
    }
   
  }

  private processCheckPolicyCreationStatusResponse(result: {success: true, data: CheckPolicyStatusData}): {isCompleted: boolean} {
    const policyStatus = result.data;
    const isDoneSuccessfully = policyStatus.status && policyStatus.status === 'DONE' && policyStatus.result && policyStatus.result === 'SUCCESS';
    const isDoneFailed = policyStatus.status && policyStatus.status === 'DONE' && policyStatus.result && policyStatus.result === 'FAILED';

    if (isDoneSuccessfully) {
      if (policyStatus.policy) {
        const policyData = {
          'number': policyStatus.policy.number,
        };
        this.productWorkflow.setPolicyNumberData(policyData);
      }

      return {isCompleted: true}
    } else if (isDoneFailed) {
      throw new PolicyCreationError(policyStatus);
    } else {
      return {isCompleted: false}
    }
}

  private async startPolicyCheckLoop(policyProcessId: number) {
    let timeouted = false;
    const timeoutHanlde = setTimeout(() => timeouted = true, POLICY_CREATION_PROCESS_CONFIG.CHECK_POLICY_CREATION_STATUS_TIMEOUT_INTERVAL);

    try {
      while (! timeouted) {
        const result = await this.api.get<GenericResponse<never>>('mtpl/policy-creation-status/' + policyProcessId, undefined, undefined, true).toPromise();

        if (result.success) {
          const checkResult = this.processCheckPolicyCreationStatusResponse(result);

          if (checkResult.isCompleted) {
            clearTimeout(timeoutHanlde);
            console.log("policy creation IN", checkResult)
            this._ngZone.run(() => this.screen.navigateAfterPolicyCreatedSuccessfully());
            return;
          }

        } else {
          throw new Error();
        }

        if (! timeouted) {
          await wait(POLICY_CREATION_PROCESS_CONFIG.CHECK_POLICY_CREATION_STATUS_CHECK_INTERVAL);
        }
      }

      //timeouted
      this._ngZone.run(() => this.screen.navigateToTimeoutErrorPage());

    } catch (e) {
      clearTimeout(timeoutHanlde);
      this._ngZone.run(() => this.screen.navigateToPolicyCreationErrorPage(e));
    }
  }

}
