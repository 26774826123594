import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprUmbrellaSunSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprUmbrellaSun]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M24 9.6V6.4" stroke-width="1.6"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M24 9.6c-4.738 2.358-8 7.15-8 12.8V24" stroke-width="1.6"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M24 9.6c4.738 2.358 8 7.15 8 12.8V24" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M40 24c-.803-8.086-7.704-14.4-16-14.4S8.803 15.914 8 24h32z" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M16 41.6h16" stroke-width="1.6"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M24 24V9.6" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M24 41.6V28.8" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprUmbrellaSunSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprUmbrellaSunSvgComponent,
  ],
  exports: [
    InformationExprUmbrellaSunSvgComponent,
  ],
})
export class InformationExprUmbrellaSunSvgModule {}
