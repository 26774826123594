import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '../services/loader.service';

export const SkipLoaderInterceptorHeader: string = 'X-Skip-Loader';

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {

    // private requests: HttpRequest<any>[] = [];
    private count: number = 0;
    public constructor(
        private loaderService: LoaderService,
    ) { }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.headers.has(SkipLoaderInterceptorHeader)) {
          const headers = request.headers.delete(SkipLoaderInterceptorHeader);
          return next.handle(request.clone({ headers }));
      }
      this.count++;
      if (this.count === 1 ) {
        this.loaderService.showLoadingIcon(true);
        }

      return next.handle(request).pipe(
          finalize(() => {
              this.count--;
               // console.log('Response count===========>', this.count)
              if (this.count === 0) {
                this.loaderService.showLoadingIcon(false);
              } 

          }),
      );
  }

    // public removeRequest(req: HttpRequest<any>) {
    //     const i = this.requests.indexOf(req);
    //     if (i >= 0) {
    //       this.requests.splice(i, 1);
    //     }
    //     this.loaderService.isLoading.next(this.requests.length > 0);
    //   }

    //   public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //     this.requests.push(req);

    //     console.log('No of requests--->' + this.requests.length);

    //     this.loaderService.isLoading.next(true);
    //     return Observable.create((observer: any) => {
    //       const subscription = next.handle(req)
    //         .subscribe(
    //           event => {
    //             if (event instanceof HttpResponse) {
    //               this.removeRequest(req);
    //               observer.next(event);
    //             }
    //           },
    //           err => {
    //             // alert('error' + err);
    //             this.removeRequest(req);
    //             observer.error(err);
    //           },
    //           () => {
    //             this.removeRequest(req);
    //             observer.complete();
    //           });
    //       // remove request from queue when cancelled
    //       return () => {
    //         this.removeRequest(req);
    //         subscription.unsubscribe();
    //       };
    //     });
    //   }


}
