import { ComponentFactory, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { ModalResolution } from '@maia/modals';
import { PopUpController } from '@maia/pop-ups';
import { map } from 'rxjs/operators';

import { ModalsModule } from '../modals.module';
import { ConfirmationPopupConfig } from '../model/confirmation-popup-config';
import { ConfirmationPopupData } from '../model/confirmation-popup-data';
import { NoticeComponent } from '../notice/notice.component';

@Injectable({
  providedIn: ModalsModule
})
export class ConfirmationPopupService {

  private readonly confirmationFactory: ComponentFactory<NoticeComponent>;

  constructor(
    private popupController: PopUpController,
    componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
  ) {
    this.confirmationFactory = componentFactoryResolver.resolveComponentFactory(NoticeComponent);
  }

  public async confirm(data: ConfirmationPopupData, cfg: ConfirmationPopupConfig): Promise<boolean> {
    return this.popupController.prepare(
      this.confirmationFactory,
      this._injector,
      {
        title: cfg.title,
        size: cfg.size || 'normal',
        footerInScrollArea: cfg.footerInScrollArea,
      },
      {
        withVisibleBackdrop: true,
        withClickableBackdrop: true,
        input: data
      },
    )
      .pipe(
        map(res => res.resolution === ModalResolution.CONFIRMED)
      )
      .toPromise()
  }


}
