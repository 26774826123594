import { Inject, Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ENV } from '../../core.module';
import { EnvConfig } from '../../constant/config/config';


@Injectable()
export class TravelRouteGuard implements CanLoad {

  constructor(@Inject(ENV) private env: EnvConfig, private router: Router) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    const routeFlag = this.env.ROUTING.enableTravelRouting;
    if(routeFlag === false) {
      this.router.navigateByUrl('/dashboard');
    }
    return routeFlag;
  }

  // public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
  //   return this.env.ROUTING.enableTravelRouting;
  // }

}
