import { Component } from '@angular/core';
import { ModalContentComponent, ModalControl } from '@maia/modals';



@Component({
  selector: 'systemcode-send-email-result',
  templateUrl: './send-email-result.component.html',
  styleUrls: ['./send-email-result.component.css'],
})
export class SendEmailResultComponent extends ModalContentComponent<void> {

  public success: boolean = false;
  public msg: any;
  public lang: string | null;


  public constructor(
    public control: ModalControl<void>,
  ) {
    super();
    this.lang = sessionStorage.getItem('lang');
    const sendEmailResultString : string | null = sessionStorage.getItem('sendEmailResult');
    const result = sendEmailResultString ? JSON.parse(sendEmailResultString) : undefined;
    console.log(result);
    if (result) {
      this.success = result.success;
      if (result.content && result.content === 'Draft'){
        this.msg = 'Policy Draft Saved';
      }else {
        const message: any = (this.success) ? 'Email Sent Successfully' : result.error;
        if (typeof(message) === 'string'){
          this.msg = message;
        }else{
          this.msg = 'Something went wrong';
        }
      }
    }else{
      this.msg = 'Something went wrong';
    }

  }



}
