import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CurrencyFuncCoinsDollarSvgModule
 */
@Component({
  selector: 'svg[systemcodeCurrencyFuncCoinsDollar]',
  template: '<svg:g><svg:g><svg:g><svg:g><svg:ellipse cx="9.4" cy="5.5" rx="6.5" ry="3" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:ellipse><svg:path d="M7.5 12.5c-2.7-.4-4.7-1.5-4.7-2.9V5.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M15.9 5.5v2.4" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M7.3 16.6c-2.6-.4-4.5-1.5-4.5-2.8V9.6" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M9.4 21c-3.7 0-6.6-1.4-6.6-3v-4.2" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path></svg:g><svg:g><svg:circle cx="15.2" cy="15.5" r="6" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:circle><svg:path d="M16.6 13H15a1.2 1.2 0 0 0-1.2 1.2 1.2 1.2 0 0 0 1.2 1.3h.3a1.3 1.3 0 0 1 1.3 1.3 1.3 1.3 0 0 1-1.3 1.2h-1.5" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M15.2 18v1.1"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M15.2 11.8V13"></svg:path></svg:g></svg:g></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrencyFuncCoinsDollarSvgComponent {
}

@NgModule({
  declarations: [
    CurrencyFuncCoinsDollarSvgComponent,
  ],
  exports: [
    CurrencyFuncCoinsDollarSvgComponent,
  ],
})
export class CurrencyFuncCoinsDollarSvgModule {}
