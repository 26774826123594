import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClaimCheckCoreComponent } from './claim-check-core/claim-check-core.component';
import { FlowModule } from '../flow/flow.module';
import { ClaimDocumentComponent } from './claim-document/claim-document.component';



@NgModule({
  declarations: [ClaimCheckCoreComponent, ClaimDocumentComponent],
  imports: [
    CommonModule,
    FlowModule,
  ],
  exports:[
    ClaimCheckCoreComponent,
    ClaimDocumentComponent
  ]
})
export class ClaimCheckCoreModule { }
