import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { routeauthenticationbridge } from '@systemcode/authentication-bridge'
import { routenicknamegenerator } from '@systemcode/nickname-generator';
import { routepolicypayment } from '@systemcode/policy-payment';
import { routeb2cdashboard } from '@systemcode/b2c-dashboard';
import { routetravelinsurance } from '@systemcode/travel-insurance';
import { routepropertyinsurance } from '@systemcode/property-insurance';
import { routeclaimsinsurance } from '@systemcode/b2c-claims';
import { PaymentRedirectComponent } from '@systemcode/core';


export const AUTHENTICATION_BRIDGE_FUNCTION = 'auth-bridge';
export const MTPL_PATH_FUNCTION = 'mtpl';
export const PP_PATH_FUNCTION = 'policy-payment';
export const B2C_DASHBOARD_PATH_FUNCTION = 'dashboard';
export const TRAVEL_INSURANCE_PATH_FUNCTION = 'travel';
export const PROPERTY_INSURANCE_PATH_FUNCTION = 'property-insurance';
export const CLAIMS_PATH_FUNCTION = 'claims';

export const ROUTES: Routes = [
  routeauthenticationbridge(AUTHENTICATION_BRIDGE_FUNCTION),
  routenicknamegenerator(MTPL_PATH_FUNCTION),
  routepolicypayment(PP_PATH_FUNCTION),
  routeb2cdashboard(B2C_DASHBOARD_PATH_FUNCTION),
  routetravelinsurance(TRAVEL_INSURANCE_PATH_FUNCTION),
  routepropertyinsurance(PROPERTY_INSURANCE_PATH_FUNCTION),
  routeclaimsinsurance(CLAIMS_PATH_FUNCTION),
  { path: 'payment', component: PaymentRedirectComponent},
  { path: '**', redirectTo:'dashboard'}
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
