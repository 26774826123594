import { Component } from '@angular/core';
import { LoggerFactory } from '@atlas-angular/logger';
import { Logger } from '@atlas/logger';
import { ModalContentComponent, ModalControl } from '@maia/modals';

import { ConfirmationAlertDialogModel } from '../../model/ConfirmationAlertDialogModel';
import { B2cDashboardService } from '../../services/b2c-dashboard.service';

@Component({
  selector: 'systemcode-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css'],
})

export class NoticeComponent extends ModalContentComponent<boolean, ConfirmationAlertDialogModel> {

  public _logger: Logger;
  public alertMessage: string = '';
  public buttonType: string = '';
  public callbackReq: boolean = false;
  public navigationPath: string = '';
  public buttonText: string = '';
  public cancelButtonName: string = 'Back'
  public discardDraft = false;
  public id: number;
  public lang: string | null;
  public constructor(
    public control: ModalControl<boolean, ConfirmationAlertDialogModel>,
    loggerFactory: LoggerFactory,
    private dashboard: B2cDashboardService,
  ) {
    super();
    this.lang = sessionStorage.getItem('lang');
    this._logger = loggerFactory.createLogger('NoticeComponent');
    const data: ConfirmationAlertDialogModel | undefined = this.control.input;
    if (data !== undefined) {
      this.alertMessage = data.alertMessage;
      this.buttonText = data.buttonText
      this.buttonType = data.buttonType;
      this.callbackReq = data.callbackReq;
      this.navigationPath = data.navigatePathString;
      this.id = data.id ? data.id : -1;
      if (this.buttonType === 'delete') {
        this.cancelButtonName = data.secondaryButtonText;
        this.discardDraft = true;
      }
    }
  }

  public continue() {
    console.log('id', this.id);
    this.control.confirm(true);
    if (this.callbackReq && this.buttonType === 'delete') {
      this.dashboard.setConfirmationSubject({id: this.id});
    }
  }

}
