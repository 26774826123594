import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule CommunicationExprPhoneSvgModule
 */
@Component({
  selector: 'svg[systemcodeCommunicationExprPhone]',
  template: '<svg:path d="M30.28 28.18l-4 4a32.85 32.85 0 0 1-5.87-4.49 34.13 34.13 0 0 1-4.5-5.87l4-4a1.77 1.77 0 0 0 .36-2L16 6.05a1.71 1.71 0 0 0-2-1l-7.67 2a1.77 1.77 0 0 0-1.3 1.73 36.76 36.76 0 0 0 10.43 23.79A36.71 36.71 0 0 0 39.17 43a1.76 1.76 0 0 0 1.73-1.3l2-7.67a1.73 1.73 0 0 0-1-2l-9.71-4.21a1.73 1.73 0 0 0-1.91.36z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommunicationExprPhoneSvgComponent {
}

@NgModule({
  declarations: [
    CommunicationExprPhoneSvgComponent,
  ],
  exports: [
    CommunicationExprPhoneSvgComponent,
  ],
})
export class CommunicationExprPhoneSvgModule {}
