import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationFuncOpenHandsSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationFuncOpenHands]',
  template: '<svg:g><svg:g><svg:path d="M18.029 18.478V16.75l3.091-3.843a1.723 1.723 0 0 0 .38-1.08v-5.01a1.3 1.3 0 0 0-1.3-1.295h0a1.3 1.3 0 0 0-1.3 1.3h0V9.84" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M13.69 18.478V13.99a1.741 1.741 0 0 1 .509-1.228L17.161 9.8a1.227 1.227 0 0 1 1.739-.007V9.8h0a1.226 1.226 0 0 1 .006 1.735l-.006.007h0l-2.6 2.6" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M5.973 18.478V16.75l-3.091-3.843a1.72 1.72 0 0 1-.382-1.08v-5.01a1.3 1.3 0 0 1 1.3-1.295h0a1.3 1.3 0 0 1 1.3 1.3h0V9.84" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M10.308 18.478V13.99a1.74 1.74 0 0 0-.508-1.228L6.837 9.8A1.226 1.226 0 0 0 5.1 9.793V9.8h0a1.226 1.226 0 0 0-.006 1.735l.006.007h0l2.6 2.6" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path></svg:g></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationFuncOpenHandsSvgComponent {
}

@NgModule({
  declarations: [
    InformationFuncOpenHandsSvgComponent,
  ],
  exports: [
    InformationFuncOpenHandsSvgComponent,
  ],
})
export class InformationFuncOpenHandsSvgModule {}
