import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { VposTransactionData } from '../../model/vpos/vpos-transation-data';

function wait(timeout: number) {
  return new Promise((resolve) => {setTimeout(() => {resolve(null)}, timeout);});
}

@Component({
  selector: 'vpos-payment-form',
  templateUrl: './vpos-payment-form.component.html',
})
export class VposPaymentFormComponent implements OnInit {

  @Input()
  transactionData: VposTransactionData;

  @ViewChild('formVPos', {static: false}) public formVPos: HTMLFormElement;

  constructor() { }

  ngOnInit() {
  }

  async submit(transactionData: VposTransactionData) {
    this.transactionData = transactionData;
    await wait(0);
    this.formVPos.nativeElement.submit();
  }

}
