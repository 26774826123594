import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';

import { AuthenticationBridgeRoutingModule } from './authentication-bridge-routing.module';
import { AuthenticationBridgeComponent } from './authentication-bridge.component';
import { MaiaModule } from './maia.module';
//import { MaiaModule } from './maia.module';
import { BASE_ROUTE, StarterService } from './starter.service';

export function routeauthenticationbridge(path: string) {
  return {
    path,
    loadChildren: '@systemcode/authentication-bridge#øAuthenticationBridgeModule',
  };
}


export { MaiaModule } from './maia.module';

@NgModule({
  imports: [
    AuthenticationBridgeRoutingModule,
    MaiaModule,
  ],
  providers: [
  ],
  declarations: [
    AuthenticationBridgeComponent,
  ],
})
export class øAuthenticationBridgeModule { }

@NgModule({
  providers: [StarterService],
})
export class AuthenticationBridgeModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: AuthenticationBridgeModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import AuthenticationBridgeModule directly, use AuthenticationBridgeModule.forPath() instead`);
    }
  }
}
