import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonsModule } from '@maia/buttons';
import { DividerModule } from '@maia/divider';
import { SpinnersModule } from '@maia/spinners';

import { ProductWorkflowModule } from '../product-workflow/product-workflow.module';
import {
  CommonEsignVerificationComponent,
} from './components/common-esign-verification/common-esign-verification.component';
import { ESignatureNotificationComponent } from './components/e-signature-notification/e-signature-notification.component';


@NgModule({
  imports: [
    CommonModule,
    ButtonsModule,
    DividerModule,
    SpinnersModule,
    ProductWorkflowModule,
  ],
  declarations: [
    ESignatureNotificationComponent,
    CommonEsignVerificationComponent,
  ],
  providers: [
  ],
  exports: [
    ESignatureNotificationComponent,
    CommonEsignVerificationComponent,
  ],
})
export class EsignatureModule { }
