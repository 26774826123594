import {NgModule} from '@angular/core';

import { FlagUtilBulgariaSvgModule } from './@kdl/illustrations/flag-util-bulgaria.component';
import { ActionExprDownloadSvgModule } from './@kdl/icons/action-expr-download.component';
import { ActionFuncBinSvgModule } from './@kdl/icons/action-func-bin.component';
import { ActionFuncCrossMiniSvgModule } from './@kdl/icons/action-func-cross-mini.component';
import { ActionFuncCrosshairSvgModule } from './@kdl/icons/action-func-crosshair.component';
import { ActionFuncDensityLowSvgModule } from './@kdl/icons/action-func-density-low.component';
import { ActionFuncPencilSvgModule } from './@kdl/icons/action-func-pencil.component';
import { ActionFuncPlusSvgModule } from './@kdl/icons/action-func-plus.component';
import { AlertFuncCheckCircleSvgModule } from './@kdl/icons/alert-func-check-circle.component';
import { AlertFuncCheckSvgModule } from './@kdl/icons/alert-func-check.component';
import { AlertFuncExclamationCircleSvgModule } from './@kdl/icons/alert-func-exclamation-circle.component';
import { AlertFuncExclamationTriangleSvgModule } from './@kdl/icons/alert-func-exclamation-triangle.component';
import { AlertFuncInfoCircleSvgModule } from './@kdl/icons/alert-func-info-circle.component';
import { CommunicationExprMailSvgModule } from './@kdl/icons/communication-expr-mail.component';
import { CommunicationExprPhoneSvgModule } from './@kdl/icons/communication-expr-phone.component';
import { CurrencyFuncCoinsDollarSvgModule } from './@kdl/icons/currency-func-coins-dollar.component';
import { DevicesIndiQrScanSvgModule } from './@kdl/icons/devices-indi-qr-scan.component';
import { FileExprDocumentAddSvgModule } from './@kdl/icons/file-expr-document-add.component';
import { FileExprDocumentDownloadSvgModule } from './@kdl/icons/file-expr-document-download.component';
import { FileFuncCloudUploadSvgModule } from './@kdl/icons/file-func-cloud-upload.component';
import { FileFuncDocumentCheckSvgModule } from './@kdl/icons/file-func-document-check.component';
import { FileFuncDocumentSvgModule } from './@kdl/icons/file-func-document.component';
import { FileIndiDocumentsSwitchSvgModule } from './@kdl/icons/file-indi-documents-switch.component';
import { InformationExprCoinsSvgModule } from './@kdl/icons/information-expr-coins.component';
import { InformationExprConstructionSignSvgModule } from './@kdl/icons/information-expr-construction-sign.component';
import { InformationExprCreditCardSvgModule } from './@kdl/icons/information-expr-credit-card.component';
import { InformationExprDiamondSvgModule } from './@kdl/icons/information-expr-diamond.component';
import { InformationExprFallSvgModule } from './@kdl/icons/information-expr-fall.component';
import { InformationExprFastlaneSvgModule } from './@kdl/icons/information-expr-fastlane.component';
import { InformationExprFlowerShieldSvgModule } from './@kdl/icons/information-expr-flower-shield.component';
import { InformationExprNotepadCoinsSvgModule } from './@kdl/icons/information-expr-notepad-coins.component';
import { InformationExprPaymentSvgModule } from './@kdl/icons/information-expr-payment.component';
import { InformationExprShieldLockSvgModule } from './@kdl/icons/information-expr-shield-lock.component';
import { InformationExprUmbrellaSunSvgModule } from './@kdl/icons/information-expr-umbrella-sun.component';
import { InformationFuncCameraPlusSvgModule } from './@kdl/icons/information-func-camera-plus.component';
import { InformationFuncCoinsSvgModule } from './@kdl/icons/information-func-coins.component';
import { InformationFuncLightbulbIdeaSvgModule } from './@kdl/icons/information-func-lightbulb-idea.component';
import { InformationFuncOpenHandsSvgModule } from './@kdl/icons/information-func-open-hands.component';
import { InformationFuncPinSquareSvgModule } from './@kdl/icons/information-func-pin-square.component';
import { InformationFuncSearchSvgModule } from './@kdl/icons/information-func-search.component';
import { InformationIndiChecklistSvgModule } from './@kdl/icons/information-indi-checklist.component';
import { NavigationFuncArrowSvgModule } from './@kdl/icons/navigation-func-arrow.component';
import { NavigationFuncChevronSvgModule } from './@kdl/icons/navigation-func-chevron.component';
import { NavigationFuncCrossSvgModule } from './@kdl/icons/navigation-func-cross.component';
import { NavigationFuncLiveHelpSvgModule } from './@kdl/icons/navigation-func-live-help.component';
import { NavigationFuncOutlineArrowSvgModule } from './@kdl/icons/navigation-func-outline-arrow.component';
import { ObjectsExprContainerSvgModule } from './@kdl/icons/objects-expr-container.component';
import { PlacesExprHouseTreeSvgModule } from './@kdl/icons/places-expr-house-tree.component';
import { PlacesExprHouseSvgModule } from './@kdl/icons/places-expr-house.component';
import { PlacesExprLargeBuildingSvgModule } from './@kdl/icons/places-expr-large-building.component';
import { PlacesExprOfficeSvgModule } from './@kdl/icons/places-expr-office.component';
import { PlacesExprParkSvgModule } from './@kdl/icons/places-expr-park.component';
import { PlacesFuncOfficeSvgModule } from './@kdl/icons/places-func-office.component';
import { SocialExprPersonKeySvgModule } from './@kdl/icons/social-expr-person-key.component';
import { SocialExprPersonSvgModule } from './@kdl/icons/social-expr-person.component';
import { TransportationExprCarCrashSvgModule } from './@kdl/icons/transportation-expr-car-crash.component';
import { TransportationExprCarNewSvgModule } from './@kdl/icons/transportation-expr-car-new.component';
import { TransportationExprCarSvgModule } from './@kdl/icons/transportation-expr-car.component';
import { TransportationExprVanSvgModule } from './@kdl/icons/transportation-expr-van.component';
import { TransportationFuncCarSvgModule } from './@kdl/icons/transportation-func-car.component';
import { TransportationIndiCarCheckSvgModule } from './@kdl/icons/transportation-indi-car-check.component';

@NgModule({
  exports: [
    AlertFuncCheckSvgModule,
    TransportationExprCarNewSvgModule,
    AlertFuncInfoCircleSvgModule,
    AlertFuncExclamationCircleSvgModule,
    AlertFuncExclamationTriangleSvgModule,
    AlertFuncCheckCircleSvgModule,
    TransportationExprVanSvgModule,
    SocialExprPersonKeySvgModule,
    ActionFuncPencilSvgModule,
    InformationExprConstructionSignSvgModule,
    CommunicationExprPhoneSvgModule,
    CommunicationExprMailSvgModule,
    NavigationFuncArrowSvgModule,
    InformationExprCreditCardSvgModule,
    PlacesExprHouseSvgModule,
    NavigationFuncCrossSvgModule,
    NavigationFuncLiveHelpSvgModule,
    ActionFuncCrosshairSvgModule,
    InformationFuncPinSquareSvgModule,
    ActionFuncDensityLowSvgModule,
    NavigationFuncChevronSvgModule,
    InformationExprPaymentSvgModule,
    ActionExprDownloadSvgModule,
    PlacesFuncOfficeSvgModule,
    PlacesExprHouseTreeSvgModule,
    FileExprDocumentDownloadSvgModule,
    NavigationFuncOutlineArrowSvgModule,
    InformationExprFastlaneSvgModule,
    PlacesExprLargeBuildingSvgModule,
    InformationExprFlowerShieldSvgModule,
    InformationExprDiamondSvgModule,
    InformationExprUmbrellaSunSvgModule,
    InformationExprCoinsSvgModule,
    InformationExprShieldLockSvgModule,
    TransportationIndiCarCheckSvgModule,
    PlacesExprOfficeSvgModule,
    InformationExprFallSvgModule,
    InformationFuncSearchSvgModule,
    ActionFuncPlusSvgModule,
    FileIndiDocumentsSwitchSvgModule,
    FileFuncDocumentCheckSvgModule,
    FileExprDocumentAddSvgModule,
    InformationFuncLightbulbIdeaSvgModule,
    InformationFuncOpenHandsSvgModule,
    InformationIndiChecklistSvgModule,
    DevicesIndiQrScanSvgModule,
    FileFuncCloudUploadSvgModule,
    InformationFuncCameraPlusSvgModule,
    FlagUtilBulgariaSvgModule,
    FileFuncDocumentSvgModule,
    SocialExprPersonSvgModule,
    InformationExprNotepadCoinsSvgModule,
    PlacesExprParkSvgModule,
    CurrencyFuncCoinsDollarSvgModule,
    ObjectsExprContainerSvgModule,
    TransportationExprCarSvgModule,
    ActionFuncCrossMiniSvgModule,
    InformationFuncCoinsSvgModule,
    TransportationFuncCarSvgModule,
    ActionFuncBinSvgModule,
    TransportationExprCarCrashSvgModule,
  ],
})
export class GenericSvgModule {
}
