import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { EpayTransactionData } from '../../model/epay/epay-transation-data';

function wait(timeout: number) {
  return new Promise((resolve) => {setTimeout(() => {resolve(null)}, timeout);});
}

@Component({
  selector: 'epay-payment-form',
  templateUrl: './epay-payment-form.component.html',
})
export class EpayPaymentFormComponent implements OnInit {

  @Input()
  transactionData: EpayTransactionData;

  @ViewChild('formEpay', {static: false}) public formEpay: HTMLFormElement;

  constructor() { }

  ngOnInit() {
  }

  async submit(transactionData: EpayTransactionData) {
    this.transactionData = transactionData;
    await wait(0);
    this.formEpay.nativeElement.submit();
  }

}
