import { Component } from '@angular/core';
import { ModalContentComponent, ModalControl } from '@maia/modals';
import { ConfirmationPopupData } from '../model/confirmation-popup-data';

@Component({
  selector: 'systemcode-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css'],
})

export class NoticeComponent extends ModalContentComponent<boolean, ConfirmationPopupData> {

  public alertMessage: string = '';
  public buttonType: string = '';
  public callbackReq: boolean = false;
  public navigationPath: string = '';
  public buttonText: string = '';
  public cancelButtonName: string = 'Back'
  public type: string = '';
  public lang: string | null;
  public constructor(
    public control: ModalControl<boolean, ConfirmationPopupData>,
  ) {
    super();
    this.lang = sessionStorage.getItem('lang');
    const data = this.control.input;
    if (data !== undefined) {
      this.type = data.type;
      this.alertMessage = data.alertMessage;
      this.buttonText = data.buttonText
      this.buttonType = data.buttonType;
      if (this.buttonText === 'Agree') {
        this.cancelButtonName = 'Cancel'
      }
    }
  }

  public continue() {
    this.control.confirm(true);
  }

}
