import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[systemcodeCommonListener]',
})
export class CommonListenerDirective {

  @Output() public child: EventEmitter<any> = new EventEmitter<any>();

  public constructor() { }

  @HostListener('window:scroll', [])
  public checkScroll() {
    const isSticky = window.pageYOffset >= 250;
    this.child.emit(isSticky)
  }


}
