import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {CircleStepDisplayState} from '../../circle-step-display/circle-step-display-state.enum';
import {FlowChapterState} from '../flow-chapter-state.enum';

/**
 * @ngModule FlowChapterModule
 */
@Component({
  selector: 'maia-flow-chapter',
  templateUrl: './flow-chapter.component.html',
  styleUrls: ['./flow-chapter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowChapterComponent {
  private _state: FlowChapterState;

  /**
   * Converts FlowChapterState to matching CircleStepDisplayState
   */
  public get circleStepState() {
    switch (this._state) {
      case FlowChapterState.ACTIVE:
        return CircleStepDisplayState.ACTIVE;
      case FlowChapterState.COMPLETED:
        return CircleStepDisplayState.COMPLETED;
      case FlowChapterState.DISABLED:
        return CircleStepDisplayState.DISABLED;
    }
  }

  @HostBinding('class.active')
  public get isActive(): boolean {
    return this._state === FlowChapterState.ACTIVE;
  }
  @HostBinding('class.disabled')
  public get isDisabled(): boolean {
    return this._state === FlowChapterState.DISABLED;
  }

  @HostBinding('class.completed')
  public get isCompleted(): boolean {
    return this._state === FlowChapterState.COMPLETED;
  }

  @Input()
  public set state(state: FlowChapterState) {
    this._state = state;
  }

  @Input()
  public title: string;

  @Input()
  public chapterNumber: number;
}
