import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../backend/api.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import moment from 'moment';
import { SkipLoaderInterceptorHeader } from './http.loader.interceptor';


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

	private refreshInProgress = false;
  public language: string = '';
  public fileUploadPath: string[] =[
    'talon-image-upload',
    'documents',
    'images'
  ]

	public constructor(public apiService: ApiService, private router: Router) {}

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {};
    const token = req.headers.get('x-access-token');
    if (token) {
      headersConfig['x-access-token'] = token;
    }

    if(req.headers.has(SkipLoaderInterceptorHeader)){
      headersConfig[SkipLoaderInterceptorHeader] = '';
    }

    this.language = this.getLanguage();
    const URLPath = req.url.substring(req.url.lastIndexOf('/') + 1);
    const fileUploadPathIndex = this.fileUploadPath.findIndex(path=> path === URLPath.split('?')[0]);
    if (fileUploadPathIndex < 0) {
      headersConfig = {
        ...headersConfig,
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache',
        'Expires': '0',
        'Pragma': 'no-cache',
        'language': this.language,
        'timeout': 600000,
      };
    }


    const userData: any = this.getUserData();
    if (userData != null) {
      const token = userData['token'];
      if (token) {
        headersConfig['x-access-token'] = token;
        if (req.url.indexOf('b2c-revoke-token') !== -1 || req.url.indexOf('b2c-refresh-token') !== -1) {
          headersConfig['refresh-token'] = userData['refreshToken'];
          return this.handleRequest(req, next, headersConfig);
        }
        if (req.url.indexOf('b2c-reset-password') !== -1) {
          return this.handleRequest(req, next, headersConfig);
        }
        const accessExpired = this.isAccessTokenExpired();
        const refreshExpired = this.isRefreshTokenExpired();
        const userId = userData['id'];

        if(refreshExpired) {
          return this.apiService.delete(`auth/b2c-revoke-token/${userId}`).pipe(catchError(err => {
            return of('Error in revoking refresh token');
            }), tap(val => {
            sessionStorage.clear();
            }), switchMap(res => {
            return this.handleRequest(req, next, headersConfig);
            }));
        } else {
          if(accessExpired) {
            if (!this.refreshInProgress) {
              this.refreshInProgress = true;
              return this.apiService.get(`auth/b2c-refresh-token`).pipe(
                tap(value => {
                  if (value) {
                    this.refreshInProgress = false;
                    if(!value['success'] && value['message'] == 'INVALID_TOKEN') {
                      sessionStorage.removeItem('userData');
                      this.router.navigateByUrl('/mtpl/auth/login');
                    } else if(value['data']) {
                      this.saveTokens(value['data']);
                    }
                  }
                }),
                catchError((err) => {
                  sessionStorage.removeItem('userData');
                  this.router.navigateByUrl('/mtpl/auth/login');
                  return of('Error in refreshing token. Please login again.');
                }),
                switchMap((response) => {
                  if(response && response['data']) {
                    return this.handleRefreshedRequest(req, next, headersConfig);
                  } else {
                    return this.handleRequest(req, next, headersConfig);
                  }

                }),
              );
            } else {
              return this.handleRequest(req, next, headersConfig);
            }
            // return this.refreshSubject.pipe(
            //   filter(result => result !== null),
            //   take(1),
            //   switchMap((res) => {
            //     return this.handleRefreshedRequest(req, next, headersConfig);
            //   })
            // );
          } else {
            return this.handleRequest(req, next, headersConfig);
          }

        }
      }
    }

    return this.handleRequest(req, next, headersConfig);


	}

	private handleRefreshedRequest(req: HttpRequest<any>, next: HttpHandler, headersConfig: any) {
		const userData: any = this.getUserData();
		if (userData != null) {
			const token = userData['token'];
			headersConfig['x-access-token'] = token;
		}
		return this.handleRequest(req, next, headersConfig);
	}

	private handleRequest(req: HttpRequest<any>, next: HttpHandler, headersConfig: any) {
		const request = req.clone({ headers: new HttpHeaders(headersConfig) });
    return next.handle(request)
    // .pipe(
		// 	catchError((error: HttpErrorResponse) => {
		// 	  if (error && error.status === 401) {
		// 		return of('User unauthorized: Token Error') as Observable<any>;
		// 	  } else {
		// 		return throwError(error);
		// 	  }
		// 	})
		//   );
	}

	private saveTokens(userInfo: any) {
		const userData: any = this.getUserData();
		if(userData) {
			userData['token'] = userInfo['token'];
			userData['refreshToken'] = userInfo['refreshToken'];
			userData['token_expires_at'] = userInfo['token_expires_at'];
			userData['refresh_token_expires_at'] = userInfo['refresh_token_expires_at'];
			sessionStorage.setItem('userData', JSON.stringify(userData));
		}
	}

	private isAccessTokenExpired() {
		return moment().isAfter(this.getTokenExpiration('token'));
	}

	private isRefreshTokenExpired() {
		return moment().isAfter(this.getTokenExpiration('refreshToken'));
	}

	private getTokenExpiration(val: string) {
		const userData: any = this.getUserData();
		if(userData) {
			if(val == 'token') {
				return moment(userData['token_expires_at']);
			}
			if(val == 'refreshToken') {
				return moment(userData['refresh_token_expires_at']);
			}
		}
		return moment();
	}

	public getLanguage(): string {
		const lang = sessionStorage.getItem('lang');
		if (lang != null) {
			return lang;
		}
		return 'en';
	}

	public getUserData() {
		let userData = sessionStorage.getItem('userData');
		if (userData) {
			userData = JSON.parse(userData)
		}
		// console.log('restoreUserData->', userData);
		return userData;
	}
}
