<div class="flex-div">
    <svg *ngIf="success" style="color: #9CCB65; width: 35.2px; height: 35.2px;" systemcodeAlertFuncCheckCircle></svg>

    <img *ngIf="!success"  style="width: 35.2px; height: 35.2px;" src="../../assets/img/payment-failure.svg">

    <label style="margin-top: 22.4px;" class="demo-kdl-font-medium-bold">{{msg | localeVal:lang}}</label>

</div>

<ng-container *maiaPopUpFooter>
    <button maiaButton maiaButtonType="primary" style="min-width: 0px;" i18n="@@systemcode-mtpl-emailresult-close" (click)="this.control.confirm()">Close</button>

</ng-container>