import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprParkSvgModule
 */
@Component({
  selector: 'svg[systemcodePlacesExprPark]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path fill="none" stroke-miterlimit="10" d="M6.4 36.8h20.8" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M9.6 36.8v4.8" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M24 36.8v4.8" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" data-color="color-2" d="M35.2 41.6V22.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M9.6 25.6H24V32H9.6z" stroke-width="1.6"></svg:path><svg:path d="M39.237 16.24A7.958 7.958 0 0 0 40 12.8c0-3.534-2.149-6.4-4.8-6.4s-4.8 2.866-4.8 6.4a7.958 7.958 0 0 0 .763 3.44A8.816 8.816 0 0 0 28.8 22.4c0 4.418 2.866 8 6.4 8s6.4-3.582 6.4-8a8.816 8.816 0 0 0-2.363-6.16z" fill="none" stroke-miterlimit="10" data-color="color-2" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprParkSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprParkSvgComponent,
  ],
  exports: [
    PlacesExprParkSvgComponent,
  ],
})
export class PlacesExprParkSvgModule {}
