import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationIndiCarCheckSvgModule
 */
@Component({
  selector: 'svg[systemcodeTransportationIndiCarCheck]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M38.11 92.56v11.94H20.2V92.56"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M95.8 92.56v11.94H77.89V92.56"></svg:path><svg:path d="M101.76 92.56H14.24V71.43a7.68 7.68 0 0 1 1.74-5l6.21-7.7h71.62l6.21 7.7a7.68 7.68 0 0 1 1.74 5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:circle cx="85.85" cy="76.65" r="5.97" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:circle><svg:circle cx="30.15" cy="76.65" r="5.97" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M52.03 76.57h11.94"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M74.66 31.89l4.48 4.48 9.2-9.2"></svg:path><svg:path d="M81.37 52a20.14 20.14 0 0 1-20.14-20.11h0a12.42 12.42 0 0 1 .25-3H29.65a4 4 0 0 0-4 3.48l-3.46 26.38h71.62l-1.25-10A21.18 21.18 0 0 1 81.37 52z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path><svg:circle cx="81.37" cy="31.89" r="20.39" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:circle><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M52.03 83.57h11.94"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6" d="M51.88 69.57h11.94"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 116 116',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationIndiCarCheckSvgComponent {
}

@NgModule({
  declarations: [
    TransportationIndiCarCheckSvgComponent,
  ],
  exports: [
    TransportationIndiCarCheckSvgComponent,
  ],
})
export class TransportationIndiCarCheckSvgModule {}
