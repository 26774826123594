import { Component } from '@angular/core';
import { ModalContentComponent, ModalControl } from '@maia/modals';
import { ProcessNoticeConfirmationPopupData } from '../model/process-notice-confirmation-popup-data';

@Component({
  selector: 'systemcode-process-notice',
  templateUrl: './process-notice.component.html',
  styleUrls: ['./process-notice.component.scss']
})
export class ProcessNoticeComponent extends ModalContentComponent<boolean, ProcessNoticeConfirmationPopupData> {

  public type: string = '';
  public lang: string | null;

  public alertMessage: string = '';

  public acceptButtonType: string = '';
  public declineButtonType: string = '';
  
  public acceptButtonName: string = '';
  public declineButtonName: string = '';

  public isTwoButtonPresent:boolean = true;

  public constructor(
    public control: ModalControl<boolean, ProcessNoticeConfirmationPopupData>,
  ) {
    super();
    this.lang = sessionStorage.getItem('lang');

    const data = this.control.input;
    if (data !== undefined) {
      this.type = data.type;
      this.isTwoButtonPresent = (data.popupType === 'two-button') ? true : false ; 

      this.alertMessage = data.alertMessage;
      
      this.acceptButtonType = data.acceptButtonType;
      this.declineButtonType = data.declineButtonType;
     
      this.acceptButtonName = data.acceptButtonName;
      this.declineButtonName = data.declineButtonName;
    }
  }

  public continue():void{
    this.control.confirm(true);
  }

  public cancel():void{
    this.control.cancel();
  }

}
