import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprFlowerShieldSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprFlowerShield]',
  template: '<svg:path d="M38.38 7.62l-5.58 2.44c0 3.9 1.67 7.89 5.58 9.56C42.28 18 44 14 44 10.06z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M36.21 13.07l2.13 2.19 2.76-2.83" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M22.15 27.91c2.43-4.77.3-12.26-8.12-12.26a31.1 31.1 0 0 0-9 1.44s2 13 11 13a13 13 0 0 0 3.16-.34" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M24 35.51V33.6c0-7.75-12.64-13.66-12.64-13.66" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path id="svgBdc/tPDNK1Bc1Ix+0SX3Gpm44L9PO968+2yGMlVRNyY=" d="M31.22 17c-2.07 1.83-3.82 5.58-4.22 12.2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M27 29.2l.75.17c4.38 2.08 11.24 4.17 14.81 1.3s-2-8.46-5.34-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M24 40.38v-4.13c0-7.17 9.37-11.34 9.37-11.34" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprFlowerShieldSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprFlowerShieldSvgComponent,
  ],
  exports: [
    InformationExprFlowerShieldSvgComponent,
  ],
})
export class InformationExprFlowerShieldSvgModule {}
