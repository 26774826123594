import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { MtplProcessService } from './MtplProcessService';
import { MtplProcessStep } from './MtplProcessStep';

export class StepResolverService implements Resolve<MtplProcessStep | undefined> {
    public constructor(
        private process: MtplProcessService<any>,
    ) { }

    public resolve(snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): MtplProcessStep | undefined {
        const url = '/' + snapshot.pathFromRoot
            .filter(v => v.routeConfig)
            .map(v => v.routeConfig!.path)
            .filter(v => !!v)
            .join('/');

        const step = this.process.getStepByRoute(url);
        if (step) {
            return step;
        } else {
            return undefined;
        }
    }
}
