import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FocusManagerService {

  private focusedOnce = new Map<string, string[]>();

  public attachControl(stepName: string, controlEl: ElementRef) {
    if (controlEl) {
      const htmlEl = (controlEl.nativeElement as HTMLElement);
      const name = htmlEl.getAttribute('formcontrolname');

      if (name && (! this.focusedOnce.has(stepName) || this.focusedOnce.get(stepName)!.indexOf(name) === -1)) {
        setTimeout(() => {
          (controlEl.nativeElement as HTMLElement).focus();
          if (! this.focusedOnce.has(stepName)) {
            this.focusedOnce.set(stepName, []);
          }
          this.focusedOnce.get(stepName)!.push(name);
        })
      }
    }
  }

}
