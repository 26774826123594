import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ConnectorModule } from '@atlas-angular/connector';
import { LoggerModule as AtlasLoggerModule } from '@atlas-angular/logger';
import { SpinnersModule as MaiaSpinnersModule } from '@maia/spinners';

import { BackendConnector } from './backend/backend-connector';
import { EnvConfig } from './constant/config/config';
import { EsignatureModule } from './esignature/esignature.module';
import { DziFileUploaderModule } from './file-uploader/dzi-file-uploader.module';
import { FlowModule } from './flow/flow.module';
import { AppendAddressFieldsService } from './flow/services/append-address-fields.service';
import { BlobService } from './flow/services/blob.service';
import { AutocompleteFactoryService } from './flow/services/component-controllers/autocomplete-factory.service';
import { ContainerService } from './flow/services/container.service';
import { FieldPairTranslitControllerService } from './flow/services/field-pair-translit-controller.service';
import { FocusManagerService } from './flow/services/focus-manager.service';
import { FormGroupManagerFactoryService } from './flow/services/form-group-manager/form-group-manager-factory.service';
import { LoaderService } from './flow/services/loader.service';
import { PartyIdService } from './flow/services/party-id.service';
import { PolicyNumberValidatorService } from './flow/services/policy-number.service';
import { TransliterateService } from './flow/services/transliterate.service';
import { EgnValidatorService } from './flow/validation/egn/egn-validator.service';
import { EikValidatorService } from './flow/validation/eik/eik-validator.service';
import { LnchValidatorService } from './flow/validation/lnch/lnch-validator.service';
import { ModalsModule } from './modals/modals.module';
import { PaymentModule } from './payment/payment.module';
import { ScreenManagersModule } from './screen-managers/screen-managers.module';
import { EidentificationModule } from './eidentification/eidentification.module';

export const ENV = new InjectionToken<EnvConfig>('Global environment variables');

@NgModule({
  imports: [
    AtlasLoggerModule,
    ConnectorModule,
    FlowModule,
    MaiaSpinnersModule,
    DziFileUploaderModule,
    ModalsModule,
    PaymentModule,
    EsignatureModule,
    ScreenManagersModule,
    EidentificationModule,
  ],
  providers: [
    BackendConnector,
  ],
  exports: [
    FlowModule,
    DziFileUploaderModule,
    ModalsModule,
    PaymentModule,
    EsignatureModule,
    ScreenManagersModule,
    EidentificationModule,
  ],
})
export class CoreModule {
  public static forRoot(environment: any): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        AutocompleteFactoryService,
        FormGroupManagerFactoryService,
        FieldPairTranslitControllerService,
        FocusManagerService,
        LoaderService,
        BlobService,
        PartyIdService,
        ContainerService,
        TransliterateService,
        EikValidatorService,
        EgnValidatorService,
        LnchValidatorService,
        PolicyNumberValidatorService,
        AppendAddressFieldsService,
        {
          provide: ENV,
          useValue: environment
        }
      ],
    }
  }
}