import { Injectable, ComponentFactory, ComponentFactoryResolver, Injector } from '@angular/core';
import { ModalsModule } from '../modals.module';
import { localeValPipe } from '../../flow/pipes/localeVal.pipe';
import { LocalizationService } from '../../flow/services/localization.service';
import { SlideInController } from '@maia/slide-ins';
import { ContactSlideComponent } from '../contact-slide/contact-slide.component';
import { LoggerFactory } from '@atlas-angular/logger';
import { takeUntilDestroyed , UntilDestroy} from '@atlas-angular/rxjs';
import { Logger } from '@atlas/logger';
import { Observable } from 'rxjs';
import { CityDto } from '../../model/dto/CityDto';
import { ApiService } from '../../backend/api.service';

@Injectable({
  providedIn: ModalsModule
})
@UntilDestroy()
export class ContactSlideService {

  public readonly slideInOneFactory: ComponentFactory<ContactSlideComponent>;
  private _logger: Logger;

  constructor(
    private _slideInCtrl: SlideInController,
    componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    loggerFactory: LoggerFactory,
    public apiService: ApiService,
  ) {
    this.slideInOneFactory = componentFactoryResolver.resolveComponentFactory(ContactSlideComponent);
    this._logger = loggerFactory.createLogger('ContactSlideComponent');
  }


  public getPlaceOfResidence(q?: string, skipLoader: boolean = false): Observable<{ data: CityDto[] }> {
    let url = 'mtpl/cities';
    if (q) {
      url += '?q=' + q;
    }
    return this.apiService.get(url, undefined, undefined, skipLoader);
  }


  public openContacInfoSlideIn() {
    const lang = sessionStorage.getItem('lang');
    const slideInTitleValue = new localeValPipe(new LocalizationService()).transform('Contact Us',lang);

    this._slideInCtrl
      .prepare(this.slideInOneFactory, this._injector,
        {
          title: slideInTitleValue,
        },
        {
          withVisibleBackdrop: true,
          withClickableBackdrop: true,
        },
      )
      .pipe(takeUntilDestroyed(this))
      .subscribe({ error: this._logger.error });
  }

  public ngOnDestroy(): void {
  }
}
