import { Injectable } from '@angular/core';
import { PAYMENT_ERROR_CONFIG } from '../constant/config/config';

@Injectable({
  providedIn: 'root',
})
export class NavigatePathService {

  constructor() {
  }

  public getUserData() {
    let userData: any = sessionStorage.getItem('userData');
    if (userData) {
      userData = JSON.parse(userData)
    }
    return userData;
  }


  public getHomeUrl(): string{
    const userData = this.getUserData();
    if(userData){
      return PAYMENT_ERROR_CONFIG.GO_TO_HOME_LOGGED_USER_URL.toString();
    }else{
      return PAYMENT_ERROR_CONFIG.GO_TO_HOME_GUEST_URL.toString();
    }
  }


}
