import { Injectable } from '@angular/core';

import { ApiService } from '../../backend/api.service';
import { DataResponse } from '../../model/api/DataResponse';
import { EsignatureModule } from '../esignature.module';

@Injectable({
  providedIn: EsignatureModule
})
export class EsignatureService {

  constructor(
    private apiService: ApiService,
  ) { }

  public async hasValidsignature(pid: string, entityType: string): Promise<boolean> {
    let queryParams = [];
    let entityObj: string[] = [];
    entityObj.push('entityType');
    entityObj.push(entityType);
    queryParams.push(entityObj);

    const checkResponse = await this.apiService.getWithParams<DataResponse<boolean>>('e-signature/validate-user-esignature-by-pid/' + pid ,queryParams).toPromise();
    return checkResponse.data;
  }
}