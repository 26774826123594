import { Injectable } from '@angular/core';
import { ApiService } from '../../backend/api.service';

@Injectable()
export class BlobService {

    public constructor(public apiService: ApiService) { }

    public base64ToBlob(base64Data: string, contentType='', sliceSize=512) {
        const byteChars = atob(base64Data);
        const byteArray = [];

        for (let i = 0; i < byteChars.length; i += sliceSize) {
            const slice = byteChars.slice(i, i + sliceSize);

            const bytes = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytes[i] = slice.charCodeAt(i);
            }

            const byteData = new Uint8Array(bytes);
            byteArray.push(byteData);
        }

        const blob = new Blob(byteArray, {type: contentType});
        return blob;
    }

    public downLoadFileApi(queryParams:string[][]) {
      return this.apiService.getWithParams('mtpl/download-file', queryParams);
    }


}
