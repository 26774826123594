import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { EpayPaymentFormComponent } from './components/epay-payment-form/epay-payment-form.component';
import { VposPaymentFormComponent } from './components/vpos-payment-form/vpos-payment-form.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    VposPaymentFormComponent,
    EpayPaymentFormComponent,
  ],
  providers: [
  ],
  exports: [
    VposPaymentFormComponent,
    EpayPaymentFormComponent,
  ],
})
export class PaymentModule { }
