import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprDiamondSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprDiamond]',
  template: '<svg:path d="M8.35 23.48L6 20.68a4.21 4.21 0 0 1-.3-5L9.34 10a3.88 3.88 0 0 1 3.4-1.9h22.5a3.88 3.88 0 0 1 3.4 1.9l3.68 5.6a4.21 4.21 0 0 1-.3 5L25.68 39.05a2.49 2.49 0 0 1-3.48.2l-.21-.2-7-8" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M5.56 18.28h36.76"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M10.94 8.98l6.38 9.2"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M23.89 8.28l6.77 9.9"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M36.94 8.88l-6.28 9.3"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M24.09 8.28l-6.77 9.9"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M17.32 18.58l6.57 21.21"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M30.36 18.58l-6.57 21.21"></svg:path><svg:path d="M14.93 27.58l1.79.4-1.79.4a4.56 4.56 0 0 0-3.4 3.41l-.39 1.9-.4-1.9a4.54 4.54 0 0 0-3.39-3.41L5.56 28l1.79-.4a4.51 4.51 0 0 0 3.39-3.4l.4-1.8.39 1.8a4.54 4.54 0 0 0 3.4 3.38z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprDiamondSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprDiamondSvgComponent,
  ],
  exports: [
    InformationExprDiamondSvgComponent,
  ],
})
export class InformationExprDiamondSvgModule {}
