import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {
    public loadingIconSource = new BehaviorSubject(false);
    public currentLoadingIconStatus = this.loadingIconSource.asObservable();
    // public isLoading = new BehaviorSubject(false);
    public constructor() { }

    // Loading Icon
    public showLoadingIcon(display: boolean) {
        this.loadingIconSource.next(display);
    }

}
