import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';
import { CoreModule } from '@systemcode/core';

import { CoreServiceModule } from './core/core-service.module';
import { AuthService } from './core/services/auth.service';
import { MtplCalcService } from './core/services/mtpl-calc.service';
import { MtplProcessService } from './core/services/mtpl-process.service';
import { MTPLPriceReCalculationHelperService } from './core/services/price.calculation.helper.service';
import { MTPLPriceCalculationService } from './core/services/price.calculation.service';
import { StepResolverService } from './core/services/step.resolver';
import { NicknameGeneratorRoutingModule } from './nickname-generator-routing.module';
import { NicknameGeneratorComponent } from './nickname-generator.component';
import { AuthModule } from './screens/auth/auth.module';
import { MtplCalcModule } from './screens/mtpl-calc/mtpl-calc.module';
import { MtplPurchasePolicyModule } from './screens/mtpl-purchase-policy/mtpl-purchase-policy.module';
import { BASE_ROUTE, StarterService } from './starter.service';




export function routenicknamegenerator(path: string) {
  return {
    path,
    loadChildren: '@systemcode/nickname-generator#øNicknameGeneratorModule',
  };
}

@NgModule({
  imports: [
    CoreModule,
    NicknameGeneratorRoutingModule,
    MtplCalcModule,
    AuthModule,
    MtplPurchasePolicyModule,
    CoreServiceModule,
    
  ],
  providers: [
    // ApiService,
    AuthService,
    MtplCalcService,
    MtplProcessService,
    StepResolverService,
    MTPLPriceCalculationService,
    MTPLPriceReCalculationHelperService,
    
  ],
  declarations: [
    NicknameGeneratorComponent,
  ],
})
export class øNicknameGeneratorModule { }

@NgModule({
  providers: [StarterService],
})
export class NicknameGeneratorModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: NicknameGeneratorModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import NicknameGeneratorModule directly, use NicknameGeneratorModule.forPath() instead`);
    }
  }
}
