import {HttpRequest} from '@angular/common/http';
import {Injectable, OnDestroy, Inject} from '@angular/core';
import {PathResolver} from '@atlas-angular/connector';
import {LoggerFactory} from '@atlas-angular/logger';
import {Call} from '@atlas/convertor';
import {Logger} from '@atlas/logger';
import {
  isContextualHermesCall,
  isPublicHermesCall,
} from '@hermes/core';
import { ApiService } from '../../backend/api.service';
import { Subscription } from 'rxjs';
import { TALON_ACTION, EnvConfig } from '../../constant/config/config';
import { ENV } from '../../core.module';


@Injectable()
export class FileUploadPathResolver implements PathResolver, OnDestroy{
  private readonly logger: Logger;
  public subscription: Subscription;
  public fileUploadFor= TALON_ACTION.TALON;

  public constructor(_loggerFactory: LoggerFactory,public apiService: ApiService,
    @Inject(ENV) private env: EnvConfig,) {
    this.logger = _loggerFactory.createLogger('FileUploadPathResolver');

    this.subscription = this.apiService.chooseFileSubject.subscribe((result: any)=>{
        if(result){
          this.fileUploadFor = result;
        }
    })

  }


  public resolve(call: Call<any, any>): HttpRequest<null> | null {
    this.logger.info('resolving', JSON.stringify(call.identifier));
    if (isPublicHermesCall(call.identifier) || isContextualHermesCall(call.identifier)) {
      return this.createRequest();
    }
    return null;
  }

  private createRequest(): HttpRequest<null> {
    let url = '';
    if(this.fileUploadFor === TALON_ACTION.TALON){
      url = `${this.env.API_BASE_URL}${this.env.API_URL}mtpl/talon-image-upload`;
    } else if(this.fileUploadFor === TALON_ACTION.CLAIM_DOCUMENT){
      url = `${this.env.API_BASE_URL}${this.env.API_URL}claims/documents`;
    } else if(this.fileUploadFor === TALON_ACTION.CLAIM_PHOTOS) {
      url = `${this.env.API_BASE_URL}${this.env.API_URL}claims/images`;
    }

    return new HttpRequest('POST', url, null);
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
