import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileFuncCloudUploadSvgModule
 */
@Component({
  selector: 'svg[systemcodeFileFuncCloudUpload]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-cap="butt" data-color="color-2" fill="none" stroke-miterlimit="10" d="M12 16.798v-6.4" stroke-width="1.04"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M9.6 12.798l2.4-2.4 2.4 2.4" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M16 17.6h1.6c1.767 0 3.2-1.434 3.2-3.202a3.196 3.196 0 0 0-3.22-3.197 5.996 5.996 0 0 0-5.98-5.603 5.996 5.996 0 0 0-5.986 5.71 3.193 3.193 0 0 0-2.414 3.09A3.202 3.202 0 0 0 6.4 17.6H8" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFuncCloudUploadSvgComponent {
}

@NgModule({
  declarations: [
    FileFuncCloudUploadSvgComponent,
  ],
  exports: [
    FileFuncCloudUploadSvgComponent,
  ],
})
export class FileFuncCloudUploadSvgModule {}
