import { Component, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { ContactSlideService } from '../../../modals/services/contact-slide.service';

import { ApiService } from '../../../backend/api.service';
import { BORICA_INSIS_ERROR, CONFIGS, PAYMENT_ERROR_CONFIG , BTRUST_ESIGNATURE_PROCESS_CONFIG} from '../../../constant/config/config';
import { DocumentSigningStatusModel } from '../../../model/dto/e-sign/DocumentSigningStatusModel';
import { ESignEmitResult } from '../../../model/dto/e-sign/ESignEmitResult';
import { ESignReqestModel } from '../../../model/dto/e-sign/ESignReqestModel';
import { PaymentFailureDto } from '../../../model/dto/PaymentFailureDto';
import { NavigatePathService } from '../../../services/navigate-path.service';


@Component({
  selector: 'systemcode-common-esign-verification',
  templateUrl: './common-esign-verification.component.html',
  styleUrls: ['./common-esign-verification.component.scss']
})
export class CommonEsignVerificationComponent implements OnInit {

  public isSigningStarted: boolean = false;
  public isDocCheckStarted: boolean = false;

  public docIdArray: string[] = [];
  public docStatusArray: DocumentSigningStatusModel[] = [];

  public documentIdLength: number = 0;
  public inProgressCount: number = 0;
  public signedByClient: number = 0;
  public rejectedCount: number = 0;
  public isTimerCount: number = 0;

  public interVal: any;
  public timeOutObj: any;

  public paymentFailureObject: PaymentFailureDto;
  public reason: string;

  public eSignReqestModel: ESignReqestModel;

  @Input()
  public set eSignReqModel(eSignReqestModel : ESignReqestModel){
    console.log("##############common-esign-verification",eSignReqestModel)

    if(eSignReqestModel){
      this.eSignReqestModel = eSignReqestModel;
      this.startSigningProcessApi();
    }
  }

  @Input()
  public tryAgainLink: string;

  @Output()
  public emitESignResult = new EventEmitter<ESignEmitResult>();
  public goToHomeURL: string;

  public constructor(
    public apiService: ApiService,
    public _ngZone: NgZone,
    public navPathService: NavigatePathService,
    public contactSlideService: ContactSlideService,
  ) { }

  public ngOnInit() {
    this.goToHomeURL = this.navPathService.getHomeUrl();
    // this.startSigningProcessApi();
  }

  public async startSigningProcessApi() {
    try {
      const result = await this.apiService.startESigning(this.eSignReqestModel).toPromise();

      if (result.success) {
        this.isSigningStarted = true;
        const docArray = result.data;
        docArray.forEach((doc: any) => {
          this.docIdArray.push(doc.documentID);
        });
        await this.checkDocESignStatusApi();
      }else{
        this.updateErrorAndNavigation();
      }
    } catch (error) {
      this.reason = error;
      const errormsgObj = error.error;
      if (errormsgObj.success === false) {
        this.isSigningStarted = false;
      }
      this.updateErrorAndNavigation();
    }
  }

  public async checkDocESignStatusApi() {
    const docIdReq = {
      'documentIds': this.docIdArray,
    }

    try {
      const result = await this.apiService.checkDocIdEsignStatus(docIdReq, true).toPromise();

      this.isDocCheckStarted = true;
      if (result.success && result.hasOwnProperty('data') && result.data.length > 0) {
        this.parseDocStatusArray(result.data);
      } else {
        // not found
        this.isSigningStarted = false;
        this.updateErrorAndNavigation();
      }
    } catch (error) {
      this.reason = error;
      const errormsgObj = error.error;
      if (errormsgObj.success === false) {
        this.isDocCheckStarted = false;
      }
      this.updateErrorAndNavigation();
    }
  }

  public parseDocStatusArray(data: DocumentSigningStatusModel[]) {
    this.docStatusArray = [...data];
    let signedCount = 0;
    this.documentIdLength = this.docStatusArray.length;

    const hasError = data.find(doc => doc.signing_status === BORICA_INSIS_ERROR.ERROR) !== undefined;
    if (hasError) {
      this.clearTimer();
      this.updateErrorAndNavigation();
    } else {
      signedCount = data.filter(doc => doc.signing_status === BORICA_INSIS_ERROR.SIGNED).length;
      this.signedByClient = data.filter(doc => doc.signing_status === BORICA_INSIS_ERROR.SIGNED_BY_CLIENT).length;
      this.rejectedCount = data.filter(doc => doc.signing_status === BORICA_INSIS_ERROR.REJECTED).length;

      if (signedCount === this.docIdArray.length) {
        // all signed - success
        this.clearTimer();
        const eSignEmitResult = {
          status: CONFIGS.E_SIGN_SUCCESS,
          failureResponseData: undefined
        }
        this.emitESignResult.emit(eSignEmitResult);
      } else if (this.rejectedCount > 0){
        this.clearTimer();
        this.updateErrorAndNavigation();
      }
      else {
        this.isTimerCount++;
        if (this.isTimerCount === 1) {
          this.iniateTimerToCheckDocStatus();
        }
      }
    }
  }

  public iniateTimerToCheckDocStatus() {
    // Handle UI discrepancy
    this._ngZone.runOutsideAngular(() => {
      this.interVal = setInterval(() => {
        this.checkDocESignStatusApi();
      }, (BTRUST_ESIGNATURE_PROCESS_CONFIG.CHECK_BTRUST_ESIGNATURE_STATUS_CHECK_INTERVAL));

      this.timeOutObj = setTimeout(() => {
        this.handleTimeoutError();
      }, (BTRUST_ESIGNATURE_PROCESS_CONFIG.CHECK_BTRUST_ESIGNATURE_STATUS_TIMEOUT_INTERVAL));
    })
  }

  public handleTimeoutError() {

    this.inProgressCount = 0;
    this.signedByClient = 0;
    this.rejectedCount = 0;

    this.docStatusArray.forEach((doc: any, index: number) => {
      console.log(doc);

      if (doc.signing_status === BORICA_INSIS_ERROR.IN_PROGRESS) {
        this.inProgressCount++;
      } else if (doc.signing_status === BORICA_INSIS_ERROR.SIGNED_BY_CLIENT) {
        this.signedByClient++;
      } else if (doc.signing_status === BORICA_INSIS_ERROR.REJECTED) {
        this.rejectedCount++;
      }

      if (index === this.docStatusArray.length - 1) {
        console.log('documentIdLength---> ', this.documentIdLength, this.rejectedCount);
        this.clearTimer();
        this.updateErrorAndNavigation();
      }

    });
  }

  public updateErrorAndNavigation() {
    if (this.documentIdLength > 0 && (this.documentIdLength === this.inProgressCount)) {
      this.paymentFailureObject = {
        title: 'Policy not signed',
        content1: 'It appears you have declined to sign your policy or the session expired.',
        content2: 'Please contact our support for further assistance.',
        primaryButtonText: PAYMENT_ERROR_CONFIG.CONTACT_SUPPORT,
        primaryButtonLink: '',
        secondaryButtonText: PAYMENT_ERROR_CONFIG.GO_TO_HOME,
        secondaryButtonLink: this.goToHomeURL,
      }
    } else if (this.documentIdLength > 0 && this.rejectedCount > 0) {
      this.paymentFailureObject = {
        title: 'Policy was rejected',
        content1: 'Please try again later or contact our support for further assistance.',
        content2: '',
        primaryButtonText: PAYMENT_ERROR_CONFIG.CONTACT_SUPPORT,
        primaryButtonLink: '',
        secondaryButtonText: PAYMENT_ERROR_CONFIG.GO_TO_HOME,
        secondaryButtonLink: this.goToHomeURL,
      }
    }
    else if (this.documentIdLength > 0 && (this.documentIdLength === this.signedByClient)) {
      this.paymentFailureObject = {
        title: 'Well, that’s awkward...',
        content1: 'We couldn’t create your policy at the moment and your card was not charged.',
        content2: 'Please try again later or contact our support.',
        primaryButtonText: 'Try Again',
        primaryButtonLink: this.tryAgainLink,
        secondaryButtonText: PAYMENT_ERROR_CONFIG.CONTACT_SUPPORT,
        secondaryButtonLink: '',
      }
    } else if (this.documentIdLength === 0 && this.isSigningStarted === false) {
      this.paymentFailureObject = {
        title: 'Well, that’s awkward...',
        content1: 'We couldn’t find your document to initiate the signing process.',
        content2: 'Please try again later or contact our support.',
        primaryButtonText: PAYMENT_ERROR_CONFIG.CONTACT_SUPPORT,
        primaryButtonLink: '',
        secondaryButtonText: PAYMENT_ERROR_CONFIG.GO_TO_HOME,
        secondaryButtonLink: this.goToHomeURL,
      }
    }
    else if (this.isDocCheckStarted === false) {
      this.paymentFailureObject = {
        title: 'Policy not signed',
        content1: 'Due to some internal server problem, We couldn’t complete the signing process.',
        content2: 'Please try again later or contact our support.',
        primaryButtonText: PAYMENT_ERROR_CONFIG.CONTACT_SUPPORT,
        primaryButtonLink: '',
        secondaryButtonText: PAYMENT_ERROR_CONFIG.GO_TO_HOME,
        secondaryButtonLink: this.goToHomeURL,
      }
    }else{
      this.paymentFailureObject = {
        title: 'Policy not signed',
        content1: 'Due to some internal server problem, We couldn’t complete the signing process.',
        content2: 'Please try again later or contact our support.',
        primaryButtonText: PAYMENT_ERROR_CONFIG.CONTACT_SUPPORT,
        primaryButtonLink: '',
        secondaryButtonText: PAYMENT_ERROR_CONFIG.GO_TO_HOME,
        secondaryButtonLink: this.goToHomeURL,
      }
    }

    if(this.paymentFailureObject){
      const eSignEmitResult = {
        status: CONFIGS.E_SIGN_FAIL,
        failureResponseData: this.paymentFailureObject
      }

      this.emitESignResult.emit(eSignEmitResult);
    }

    // this.mtplPurchaseService.updateFailureMsgObject(this.paymentFailureObject);
    // this._ngZone.run(() => this.navigateTo(this.failureUrl));
  }

  public clearTimer() {
    clearTimeout(this.interVal);
    clearTimeout(this.timeOutObj);
  }

  public ngOnDestroy(): void {
    this.clearTimer();
  }

  public navigateToScreen() {
    this.contactSlideService.openContacInfoSlideIn();
  }

}

