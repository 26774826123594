import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule TransportationFuncCarSvgModule
 */
@Component({
  selector: 'svg[systemcodeTransportationFuncCar]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M19.77 10.27l-1.72-6.91H5.96l-1.73 6.91"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M7.68 17.18v3.46H4.23v-3.46"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M19.77 17.18v3.46h-3.45v-3.46"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M21.5 17.18h-19V12l1.73-1.73h15.54L21.5 12v5.18z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M5.95 13.73h1.73"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" d="M16.32 13.73h1.73"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportationFuncCarSvgComponent {
}

@NgModule({
  declarations: [
    TransportationFuncCarSvgComponent,
  ],
  exports: [
    TransportationFuncCarSvgComponent,
  ],
})
export class TransportationFuncCarSvgModule {}
