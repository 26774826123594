import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprNotepadCoinsSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprNotepadCoins]',
  template: '<svg:path id="svg0Jc8hov9/NIDLptK/zBRANs/srxa8rvbsS44LdlqRM0=" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M18.8 39.5H8.5v-33h8.3"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M29.1 6.5h8.3v16.9"></svg:path><svg:path id="svgJYW5fvB/vyQwUBWnKlvDNEmK3g3S/QbdeN41alBCA/0=" fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M18.8 34.8h-5.6V11.3h3.7"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M29.1 11.3h3.6v12.2"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M16.8 5v9.4h3.1c0-1.7 1.4-3.1 3.1-3.1 1.7 0 3.1 1.4 3.1 3.1h3.1V5H16.8z"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M30.4 31.2v2.9c0 1.3 1.9 2.3 4.4 2.3s4.4-1 4.4-2.3v-2.9"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M31.9 39.4c.9.4 1.9.6 2.9.5 2.5 0 4.4-1 4.4-2.3v-3"></svg:path><svg:ellipse fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" cx="34.8" cy="30.5" rx="4.4" ry="2.2"></svg:ellipse><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M23.2 37.7v3c0 1.2 1.9 2.2 4.4 2.2s4.3-1 4.3-2.2v-3"></svg:path><svg:ellipse fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" cx="27.6" cy="37.3" rx="4.4" ry="2.2"></svg:ellipse>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprNotepadCoinsSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprNotepadCoinsSvgComponent,
  ],
  exports: [
    InformationExprNotepadCoinsSvgComponent,
  ],
})
export class InformationExprNotepadCoinsSvgModule {}
