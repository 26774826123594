import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Boolean } from '@atlas/businesstypes';
import { ModalContentComponent, ModalControl } from '@maia/modals';

import { Subject } from 'rxjs';
import { CityDto } from '../../model/dto/CityDto';
import { FormGroupManagerFactoryService } from '../../flow/services/form-group-manager/form-group-manager-factory.service';

import { FormData } from './form-data.model';
import { ContactSlideService } from '../services/contact-slide.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'application-contat-slide',
  templateUrl: './contact-slide.component.html',
  styleUrls: ['./contact-slide.component.scss'],
})
export class ContactSlideComponent extends ModalContentComponent<void> implements OnInit {

  public lazyOptions = new Subject<CityDto[]>();
  public lazyValue: string;
  public _disabled = Boolean.FALSE;
  public list: any[];


  public stepForm = this.formFactory.createFormManager<FormData>({
    city: new FormControl(undefined, [Validators.required]),
  });

  public cityAutocomplete = this.stepForm.createAutocompleteWithServersideFiltering<CityDto>(
    'city',
    (q: string) => this.contactSlideServie.getPlaceOfResidence(q, true).pipe(map(res => res.data)),
  )

  public constructor(
    public control: ModalControl<void>,
    private formFactory: FormGroupManagerFactoryService,
    public contactSlideServie: ContactSlideService,
  ) {
    super();
  }

  public ngOnInit() {
  }

  public updateOptions(typeAhead: string) {
    if (this.list !== undefined) {
      this.lazyOptions.next(this.list.filter((rec) => (rec['title'].includes(typeAhead) || (rec['postal_code'].includes(typeAhead)))));
    }
  }

  public navigateToExternalurl() {
    window.open('https://www.dzi.bg/feedback/', "_blank");
  }

  public navigateToExternalLocationUrl(){
    window.open("https://www.dzi.bg/locations", "_blank");
  }
}
