import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionExprDownloadSvgModule
 */
@Component({
  selector: 'svg[systemcodeActionExprDownload]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M24 7.2v26.4" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M13.6 23.2L24 33.6l10.4-10.4" stroke-width="1.6"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M6.4 40.8h35.2" stroke-width="1.6"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionExprDownloadSvgComponent {
}

@NgModule({
  declarations: [
    ActionExprDownloadSvgComponent,
  ],
  exports: [
    ActionExprDownloadSvgComponent,
  ],
})
export class ActionExprDownloadSvgModule {}
