import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AlertFuncCheckSvgModule
 */
@Component({
  selector: 'svg[systemcodeAlertFuncCheck]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3" d="M4 13.6l4.8 4.8L20 4"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertFuncCheckSvgComponent {
}

@NgModule({
  declarations: [
    AlertFuncCheckSvgComponent,
  ],
  exports: [
    AlertFuncCheckSvgComponent,
  ],
})
export class AlertFuncCheckSvgModule {}
