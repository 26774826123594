export enum PaymentInformationID {
  VPOS = 1,
  EPAY,
}

export interface PaymentVPOSInformationDto {
  PAYMENT_URL: string
  MERCHANT: string
  TERMINAL: string
  MERCHANT_NAME: string
  MERCHANTE_URL: string
  email: string
  country: string
  merchGmt: string
}
