import { AbstractControl, ValidationErrors } from '@angular/forms';

export function nonCyrillicLetters(control: AbstractControl): ValidationErrors | null {
    const isValid = /[а-яА-Я]/.test((control.value === null || control.value === undefined) ? '' : control.value) === false;
    const validNames = /^[a-zA-z0-9'-]+([ ][a-zA-Z0-9'-]+)*$/.test(control.value);
    if (!isValid || !validNames) {
        return {
            nonCyrillicLetters: true,
        }
    } else {
        return null;
    }
}

export function nonCyrillicLettersCompany(control: AbstractControl): ValidationErrors | null {
    const isValid = /[а-яА-Я]/.test((control.value === null || control.value === undefined) ? '' : control.value) === false;
    if (!isValid) {
        return {
            nonCyrillicLetters: true,
        }
    } else {
        return null;
    }
}