import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprCreditCardSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprCreditCard]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 15.36h38"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5 22.27h38"></svg:path><svg:path d="M39.55 39.55H8.45A3.46 3.46 0 0 1 5 36.09V11.91a3.46 3.46 0 0 1 3.45-3.46h31.1A3.46 3.46 0 0 1 43 11.91v24.18a3.46 3.46 0 0 1-3.45 3.46z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M11.91 30.91h8.64"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M34.36 30.91h1.73"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprCreditCardSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprCreditCardSvgComponent,
  ],
  exports: [
    InformationExprCreditCardSvgComponent,
  ],
})
export class InformationExprCreditCardSvgModule {}
