import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileFuncDocumentCheckSvgModule
 */
@Component({
  selector: 'svg[systemcodeFileFuncDocumentCheck]',
  template: '<svg:path fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" d="M12.34 3.33v4.73h4.73"></svg:path><svg:path fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" d="M17.07 13.42V8.06l-4.73-4.73H2.89v17.34h7.8"></svg:path><svg:path fill="none" stroke="#9CCB65" stroke-linecap="round" stroke-linejoin="round" d="M21.23 16.13l-3.69 4.54"></svg:path><svg:path fill="none" stroke="#9CCB65" stroke-linecap="round" stroke-linejoin="round" d="M15 17.56l2.54 3.11"></svg:path>',

  styles: [
  ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileFuncDocumentCheckSvgComponent {
}

@NgModule({
  declarations: [
    FileFuncDocumentCheckSvgComponent,
  ],
  exports: [
    FileFuncDocumentCheckSvgComponent,
  ],
})
export class FileFuncDocumentCheckSvgModule {}
