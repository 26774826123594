export enum BoricaVposTransactionTypes{
  DIRECT_PAYMENT=1, // This transaction type is used when we want immediately to charge the user with certain amount (for example payment of instalments)

  RESERVE_AMOUNT=12, // This is used when first we reserve the amount in the user's account and in the second call to borica we actual finalize the payments
  AUTHORIZE_WITHDRAW=21, // This is used after Reserve Amount is successfully to complete the purchase

  // CHARGE_AMOUNT=23 // This is the second to finalize payment after we have already reserved the amount
  // REVERT=???? // Used to revert reserved amount
  // REFUND=???? // Used to refund the user

}
