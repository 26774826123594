import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule AlertFuncExclamationTriangleSvgModule
 */
@Component({
  selector: 'svg[systemcodeAlertFuncExclamationTriangle]',
  template: '<svg:g class="nc-icon-wrapper" transform="matrix(.8 0 0 .8 2.4 2.4)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path fill="none" stroke-miterlimit="10" d="M2 22L12 3l10 19z"></svg:path><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M12 11v4"></svg:path><svg:circle data-color="color-2" data-stroke="none" cx="12" cy="18" r="1" stroke="none"></svg:circle></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertFuncExclamationTriangleSvgComponent {
}

@NgModule({
  declarations: [
    AlertFuncExclamationTriangleSvgComponent,
  ],
  exports: [
    AlertFuncExclamationTriangleSvgComponent,
  ],
})
export class AlertFuncExclamationTriangleSvgModule {}
