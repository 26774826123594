import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { InstallmentData } from '../../shared/model/instalment/InstallmentData';
import { Boolean } from '@atlas/businesstypes';
import moment from 'moment';
import { localeValPipe } from '../../pipes/localeVal.pipe';
import { LocalizationService } from '../../services/localization.service'

@Component({
  selector: 'systemcode-due-instalment-common',
  templateUrl: './due-instalment-common.component.html',
  styleUrls: ['./due-instalment-common.component.scss'],
})
export class DueInstalmentCommonComponent implements OnInit {


  public instalmentsData: any[] = [];
  public tableColumns: any[] = [];
  public lang: string | null;
  public rowsSelected: any[][] = [];
  public headerSelected: any[] = [];
  public totalValueCummulative: any;
  public currency: any = 'BGN';
  public isSelectAllDue: boolean = false;
  public isSticky: boolean = false;
  public statusColorClass: string = '';

  public selectedInstalData: InstallmentData[] = [];

  public entityType: string;
  public paymentHeadingLabel: string;
  public totalLabel: string;

  public overallDueCount: number = 0;
  public localeValPipe = new localeValPipe(new LocalizationService())


  @Output() public callSelectionSubjectFunction = new EventEmitter();
  @Output() public callNavigationFunction = new EventEmitter();
  @Input() public data: any;
  @Input() public itsfor: string;
  public constructor() {this.lang = sessionStorage.getItem('lang'); }

  public ngOnInit() {
    this.lang = sessionStorage.getItem('lang');
    const data = this.data;
    if (data) {
      if (data.searchPolicy) {
        console.log('searchPolicy', data.searchPolicy);
        this.instalmentsData = data.searchPolicy.instalmentDueList;
        this.entityType = data.searchPolicy.type;
        this.paymentHeadingLabel = data.searchPolicy.paymentHeadingLabel;
        this.totalLabel = data.searchPolicy.totalLabel;
      }
    }

    this.totalValueCummulative = '0';
    this.tableColumns = [
      { id: 11, headerName: 'Instalment', headerValue: 'instalment' },
      { id: 21, headerName: 'Due date', headerValue: 'dueDate' },
      { id: 31, headerName: 'Status', headerValue: 'status' },
      { id: 41, headerName: 'Amount', headerValue: 'amount' },
      { id: 51, headerName: 'Currency', headerValue: 'currency' },
    ];

    this.instalmentsData.forEach((data: any, headerIndex: number) => {
      this.headerSelected.push({ tableIndexId: headerIndex, headerSelected: false });
      this.rowsSelected[headerIndex] = [];
      data.instalmentList.forEach((instal: any, instalIndex: number) => {
        this.rowsSelected[headerIndex].push({
          tableIndexId: headerIndex,
          rowIndexId: instalIndex,
          rowSelected: false,
          dueStatus: instal.status.toLowerCase(),
          policyData: data,
          selectedRowData: instal,
          enableStatus: (instalIndex === 0) ? false : true,
        });
      });
    });

    console.log('rowsSelected', this.rowsSelected);
    this.getDueCount();
    this.prePopulateOldData();
  }

    public prePopulateOldData() {

      const data = this.data;
      if (data) {
        if (data.installments) {
          const instalmentData = data.installments;
          this.totalValueCummulative = instalmentData.totalCumlativeAmt;
          const selectedDueList = instalmentData.selectedDueList;
          this.selectedInstalData = selectedDueList;
          this.isSelectAllDue = (instalmentData.isAllDue instanceof Boolean) ?
            instalmentData.isAllDue['internalValue'] : instalmentData.isAllDue;
          selectedDueList.forEach((selectedDue: any) => {
            this.instalmentsData.forEach((data: any, headerIndex: number) => {
              this.rowsSelected[headerIndex].forEach((row: any) => {
                row.rowSelected = (selectedDue.tableIndexId === row.tableIndexId && selectedDue.rowIndexId === row.rowIndexId) ?
                  selectedDue.rowSelected : row.rowSelected;
                row.enableStatus = (selectedDue.tableIndexId === row.tableIndexId && selectedDue.rowIndexId === row.rowIndexId) ?
                  selectedDue.enableStatus : row.enableStatus;
              });
            });
            console.log(this.rowsSelected);
          })
        }
      }
    }

   public checkDisableStatus(policyIndex: number, rowIndex: number) {
      let enable = this.rowsSelected[policyIndex][rowIndex].enableStatus;
      let instalmentNumber = this.rowsSelected[policyIndex][rowIndex].selectedRowData.instalmentNumber;
      let productCode = this.rowsSelected[policyIndex][rowIndex].policyData.productCode
      // if (rowIndex > 0) {
      //   const isPreRowSelected = this.rowsSelected[policyIndex][rowIndex - 1].rowSelected;
      //   if (isPreRowSelected['internalValue'] === true) {
      //     enable = false;
      //     this.rowsSelected[policyIndex][rowIndex].enableStatus = enable;
      //   }
        // else if (isPreRowSelected['internalValue'] === false){
        //   this.rowsSelected[policyIndex][rowIndex].rowSelected = isPreRowSelected;
        // }
     // }
      if (instalmentNumber == 1 && (productCode == '4600' || productCode == '4307' || productCode == '4308')) {
        enable = true;
        this.rowsSelected[policyIndex][rowIndex].enableStatus = enable;
      }
      return enable;
    }
    public getCellValue(row: any, column: any, rowIndex: number) {
      // const count = rowIndex + 1;
      let cellValue = row[column.headerValue] + '';
      if((column.headerValue === 'instalment')){
        cellValue = this.localeValPipe.transform('Instalment',this.lang)+ ' ' + row.instalmentNumber;
      }else if(column.headerValue === 'status'){
        cellValue = this.localeValPipe.transform(cellValue, this.lang);
      }
      // cellValue = (column.headerValue === 'instalment' && cellValue === '') ? 'Instalment ' + count : cellValue;
      // cellValue = (column.headerValue === 'status') ? cellValue : cellValue;
      if (column.headerValue === 'dueDate') {
        const momentVariable = moment(cellValue, 'YYYY-MM-DD');
        cellValue = momentVariable.format('DD/MM/YYYY');
      }
      cellValue = (column.headerValue === 'currency' && cellValue === 'BGL') ? 'BGN' : cellValue;
      return cellValue;
    }

    public getSelectedRow(isRowSelelcted: any) {
      const rSelected = (isRowSelelcted instanceof Boolean && isRowSelelcted.hasOwnProperty('internalValue'))
        ? isRowSelelcted['internalValue'] : isRowSelelcted;
      return rSelected;
    }

    // individual due selection
    public selectRow(tableIndexId: number, rowIndexId: number) {

      if (this.rowsSelected[tableIndexId][rowIndexId].enableStatus === false) {
        const isRowSelected = this.rowsSelected[tableIndexId][rowIndexId].rowSelected;
        const dueStatus = this.rowsSelected[tableIndexId][rowIndexId].dueStatus;

        // remove exsisting
        const deseletInstalDataPos = this.selectedInstalData.
          findIndex((sData: any) => (sData.tableIndexId === tableIndexId && sData.rowIndexId === rowIndexId));
        const removedData = (deseletInstalDataPos > -1) ? this.selectedInstalData.splice(deseletInstalDataPos, 1) : null;
        console.log('removedData', removedData, deseletInstalDataPos);

        // push into the array and checkbox validate
        if (isRowSelected instanceof Boolean && (isRowSelected['internalValue'] === true)) {
          this.selectedInstalData.push(this.rowsSelected[tableIndexId][rowIndexId]);
          console.log('rowIndexId', rowIndexId + 1, this.rowsSelected[tableIndexId].length, this.selectedInstalData)
          if (rowIndexId + 1 < this.rowsSelected[tableIndexId].length) {
            this.rowsSelected[tableIndexId][rowIndexId + 1].enableStatus = false;
          }
        }
        else if (isRowSelected instanceof Boolean && (isRowSelected['internalValue'] === false && (dueStatus === 'due' || dueStatus === 'upcoming'))) {
          this.isSelectAllDue = false;
          this.headerSelected[tableIndexId].headerSelected = isRowSelected;
          const sliceIndex = rowIndexId + 1;
          this.rowsSelected[tableIndexId].slice(sliceIndex, this.rowsSelected[tableIndexId].length).forEach((row: any, index: number) => {
            const modifyIndex = index + sliceIndex;
            this.rowsSelected[tableIndexId][modifyIndex].rowSelected = isRowSelected;
            this.rowsSelected[tableIndexId][modifyIndex].enableStatus = true;

            const deselect = this.selectedInstalData.
              findIndex((sData: any) => (sData.tableIndexId === tableIndexId && sData.rowIndexId === modifyIndex));
            const rData = (deselect > -1) ? this.selectedInstalData.splice(deselect, 1) : null;
            console.log('remove', rData, deselect);
          })
        }

        this.callSelectionSubjectFunction.emit({'selectedInstalData': this.selectedInstalData});
      }

    }

    // Select all selection
    public selectAll(policyIndex: number) {
      let dueCount = 0;
      const selectedHeader: Boolean = this.headerSelected[policyIndex].headerSelected;

      // update status
      this.rowsSelected[policyIndex].forEach((row: any, index: number) => {
        const selectedRow = (row.tableIndexId === policyIndex) ? row : null;
        this.rowsSelected[policyIndex][index].rowSelected = (selectedRow != null) ? selectedHeader : row.rowSelected;
        this.rowsSelected[policyIndex][index].enableStatus = (index === 0 || selectedHeader['internalValue'] === true) ? false : true;
        (row.dueStatus === 'due') ? dueCount++ : dueCount;
      });

      // remove exsisting
      this.rowsSelected[policyIndex].forEach((row: any, index: number) => {
        const deseletInstalDataPos = this.selectedInstalData.
          findIndex((sData: any) => (sData.tableIndexId === policyIndex));
        (deseletInstalDataPos > -1) ? this.selectedInstalData.splice(deseletInstalDataPos, 1) : null;
      });

      // push into the array and checkbox validate
      if (selectedHeader instanceof Boolean && (selectedHeader['internalValue'] === true)) {
        this.rowsSelected[policyIndex].forEach((row: any, index: number) => {
          this.selectedInstalData.push(row);
        });
      } else if (selectedHeader instanceof Boolean && (selectedHeader['internalValue'] === false && dueCount > 0)) {
        this.isSelectAllDue = false;
      }

      this.callSelectionSubjectFunction.emit({'selectedInstalData': this.selectedInstalData});
      // this.onSelectionChangeSubject.next();
    }

    public checkRowSelected(isRowSelelcted: any) {
      const rSelected = (isRowSelelcted instanceof Boolean && isRowSelelcted.hasOwnProperty('internalValue'))
        ? isRowSelelcted['internalValue'] : isRowSelelcted;
      return rSelected;
    }

    // get total due counts
    public getDueCount() {
      this.instalmentsData.forEach((data: any, policyIndex: number) => {
        data.instalmentList.forEach((row: any, index: number) => {
          if (row.status.toLowerCase() === 'due') {
            this.overallDueCount = this.overallDueCount + 1;
          }
        });
      });
    }

    // select all due
    public selectAllDue(selectAllDue: any) {

      // update status     
      this.instalmentsData.forEach((data: any, policyIndex: number) => {
        data.instalmentList.forEach((row: any, index: number) => {
          if ((data.productCode == "4600" || data.productCode == "4307" || data.productCode == "4308") && row.instalmentNumber == 1) {
            console.log("MTPL instalment1");
          }
          else{
          const selectedRow = (row.status.toLowerCase() === 'due') ? row : null;
          this.rowsSelected[policyIndex][index].rowSelected = (selectedRow != null) ?
            this.isSelectAllDue : this.rowsSelected[policyIndex][index].rowSelected;

          this.rowsSelected[policyIndex][index].enableStatus = (index === 0 || selectAllDue['internalValue'] === true && row.status.toLowerCase() === 'due') ? false : true;
        }
        });
      });

      // remove exsisting
      let findOneDueInstalmentCountPerPolicy = -1;
      this.instalmentsData.forEach((data: any, policyIndex: number) => {
        findOneDueInstalmentCountPerPolicy = -1;
        this.rowsSelected[policyIndex].forEach((row: any, index: number) => {
          const deseletInstalDataPos = this.selectedInstalData.
            findIndex((sData: any) => (sData.tableIndexId === row.tableIndexId && sData.rowIndexId === row.rowIndexId
              && sData.dueStatus.toLowerCase() === 'due'));
          (deseletInstalDataPos > -1) ? this.selectedInstalData.splice(deseletInstalDataPos, 1) : null;

          if (findOneDueInstalmentCountPerPolicy > -1 && row.dueStatus.toLowerCase() !== 'due'){
            const deseletOtherthanDue = this.selectedInstalData.
            findIndex((sData: any) => (sData.tableIndexId === row.tableIndexId && sData.rowIndexId === row.rowIndexId));
            (deseletOtherthanDue > -1) ? this.selectedInstalData.splice(deseletOtherthanDue, 1) : null;

            this.rowsSelected[policyIndex][index].rowSelected = (selectAllDue['internalValue'] === true) ? !selectAllDue : selectAllDue;
            this.rowsSelected[policyIndex][index].enableStatus = true;
          }
          if (deseletInstalDataPos > -1){
            findOneDueInstalmentCountPerPolicy = deseletInstalDataPos;
          }

        });
      });

      // push into the array and checkbox validate
      if (selectAllDue instanceof Boolean && (selectAllDue['internalValue'] === true)) {
        this.instalmentsData.forEach((data: any, policyIndex: number) => {
          this.rowsSelected[policyIndex].forEach((row: any, index: number) => {
            const selectedDueRow = (row.dueStatus.toLowerCase() === 'due') ? this.selectedInstalData.push(row) : null;
            console.log(selectedDueRow);
          })

        });
      }
      console.log('selectedInstalData', this.selectedInstalData)
      this.callSelectionSubjectFunction.emit({'selectedInstalData': this.selectedInstalData});

    }

    public calculateTotalCumlative() {
      console.log('selectedInstalData', this.selectedInstalData);
      this.totalValueCummulative = '0';
      let totalPayment: number = 0;
      this.selectedInstalData.forEach((element: any) => {
        totalPayment = totalPayment + element['selectedRowData'].amount;
      });
      this.totalValueCummulative = totalPayment + '';
    }

    public isInstalmentChose() {
      return (this.selectedInstalData != null && this.selectedInstalData.length > 0)
        ? false : true;
    }

    public navigateToNextScreen() {

      const instalmentStepData = {
        'totalCumlativeAmt': this.totalValueCummulative,
        'isAllDue': this.isSelectAllDue,
        'selectedDueList': this.selectedInstalData,
      }

      this.callNavigationFunction.emit({'instalmentStepData': instalmentStepData});
    }

    public getPolicyName(iData: any){
      console.log(iData)
      let policyName =  (iData.hasOwnProperty('productDescription') && iData.productDescription !== '' ) ? iData.productDescription : iData.policyName;
      console.log("policyname--------",policyName)
      policyName = (policyName !== '' || policyName !== null) ? policyName : iData.policyNo;
      return policyName
    }

}
