import { Injectable } from '@angular/core';
import {  Observable } from 'rxjs';
import { FLOW } from '../constant/config/config';
import { AuthBridgeTokenDto } from '../model/dto/auth-bridge/AuthBridgeTokenDto';
import { ApiService } from './api.service';
import { ApiResponseDto } from '../model/dto/auth-bridge/ApiResponseDto';



@Injectable()
export class AuthBridgeService {

  public constructor(public apiService: ApiService) { }


  public bridgeAuthApi(token: string): Observable<ApiResponseDto<AuthBridgeTokenDto>> {
    return this.apiService.post(`auth/bridge-authentication`, undefined, {'x-access-token': token});
  }


  public setFlowFrom(flow: string) {
    sessionStorage.setItem('flow', flow);
  }

  public setPlatform(platform: string) {
    sessionStorage.setItem('platform', platform);
  }

  public isUBBFlow(){
    const flow = sessionStorage.getItem('flow');
    return (flow === FLOW.UBB) ? true : false;
  }

  public setAuthBridgeData(data?: AuthBridgeTokenDto) {
    sessionStorage.setItem('AuthBridgeData', JSON.stringify(data))
  }


}
