import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CoreModule } from '@systemcode/core';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CoreModule,
    HttpClientModule,
  ],
  exports: [
    CommonModule,
    CoreModule,
    HttpClientModule,
  ],
})
export class MaiaModule { }
