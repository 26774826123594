import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'systemcode-common-btrust-notify',
  templateUrl: './common-btrust-notify.component.html',
  styleUrls: ['./common-btrust-notify.component.css']
})
export class CommonBtrustNotifyComponent implements OnInit {
  
  public heading: string;
  public lang: string|null;
  @Input() public productname: string;
  @Input() public btrustcontent: string;
  
  

  @Output()
  public cancel = new EventEmitter();

  @Output()
  public backtoPayment = new EventEmitter();

  public constructor() {
    this.lang = sessionStorage.getItem('lang');
   }

  public ngOnInit() {
    this.lang = sessionStorage.getItem('lang');

  //   if(this.productname === "MTPL" || this.productname === "Property")
  //   {
  //   this.heading = 'Purchase ' +this.productname +' Policy'
  //   }
  //   else{
  //     this.heading = this.productname +' Insurance'
  //   }
   }

  public backtoPaymentBtnFun() {
    this.backtoPayment.emit();
  }

  public cancelBtnFun() {
    this.cancel.emit();
  };


}
