import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerFactory } from '@atlas-angular/logger';
import { Logger } from '@atlas/logger';
import { ModalContentComponent, ModalControl } from '@maia/modals';

import { MtplCalcService } from '../../../core/services/mtpl-calc.service';
import { ConfirmationAlertDialogModel } from '../../../model/ConfirmationAlertDialogModel';

@Component({
  selector: 'systemcode-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css'],
})

export class NoticeComponent extends ModalContentComponent<boolean, ConfirmationAlertDialogModel> {

  public _logger: Logger;
  public alertMessage: string = '';
  public buttonType: string = '';
  public callbackReq: boolean = false;
  public navigationPath: string = '';
  public buttonText: string = '';
  public cancelButtonName: string = 'Back'
  public type: string = '';
  public lang: string | null;

  public constructor(
    private router: Router,
    public control: ModalControl<boolean, ConfirmationAlertDialogModel>,
    loggerFactory: LoggerFactory,
    public mtplService: MtplCalcService,
  ) {
    super();
    this.lang = sessionStorage.getItem('lang');
    this._logger = loggerFactory.createLogger('NoticeComponent');
    const data: ConfirmationAlertDialogModel | undefined = this.control.input;
    if (data !== undefined) {
      this.type = data.type;
      this.alertMessage = data.alertMessage;
      this.buttonText = data.buttonText
      this.buttonType = data.buttonType;
      this.callbackReq = data.callbackReq;
      this.navigationPath = data.navigatePathString;
      if (this.buttonText === 'Agree') {
        this.cancelButtonName = 'Cancel'
      }
    }
  }

  public continue() {
    if (this.callbackReq) {
      this.mtplService.sendEmailData({ sendEmail: true });
    }
    this.control.confirm(true);
    if (this.navigationPath) {
      this.mtplService.setSummaryData(true);
      this.router.navigateByUrl(this.navigationPath)
    }
  }

}
