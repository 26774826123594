import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncPencilSvgModule
 */
@Component({
  selector: 'svg[systemcodeActionFuncPencil]',
  template: '<svg:g class="nc-icon-wrapper" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.3"><svg:path data-color="color-2" fill="none" stroke-miterlimit="10" d="M4 20.8h16" stroke-width="1.04"></svg:path><svg:path data-cap="butt" fill="none" stroke-miterlimit="10" d="M12.8 6.4L16 9.6" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-miterlimit="10" d="M8.8 16.8l-4 .8.8-4L15.2 4l3.2 3.2-9.6 9.6z" stroke-width="1.04"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncPencilSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncPencilSvgComponent,
  ],
  exports: [
    ActionFuncPencilSvgComponent,
  ],
})
export class ActionFuncPencilSvgModule {}
