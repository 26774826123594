import { InputPhonenumberComponent, PhoneNumberValue } from '@maia/input-phonenumber';

export interface PhoneNumberValueControllerOptions {
  disableCountryCode: boolean
}

export class PhoneNumberValueController {

  public constructor(
    private component: InputPhonenumberComponent,
    options?: PhoneNumberValueControllerOptions,
  ) {
    if (options && options.disableCountryCode) {
      component.country.disable();
    }
  }

  public get value(): PhoneNumberValue {
    return {
      number: this.component.number.value,
      prefix: this.component.country.value.prefix,
    }
  }

  public set value(val: PhoneNumberValue) {
    this.component.number.setValue(val.number);
    if (!this.component.options) {
      setTimeout(() => {
        const selectedOption = this.component.options.find(o => o.id === val.prefix);
        if (selectedOption) {
          this.component.country.setValue(selectedOption.value)
        }
      })
    } else {
      const selectedOption = this.component.options.find(o => o.id === val.prefix);
      if (selectedOption) {
        this.component.country.setValue(selectedOption.value)
      }
    }
  }

}
