import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationIndiChecklistSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationIndiChecklist]',
  template: '<svg:g><svg:path d="M79.55 45H59.86" fill="none" stroke="#00aeef" stroke-linecap="round" stroke-miterlimit="8" stroke-width="2.6"></svg:path><svg:path d="M79.55 68.62H59.86" fill="none" stroke="#00aeef" stroke-linecap="round" stroke-miterlimit="8" stroke-width="2.6"></svg:path><svg:path d="M79.55 92.25H59.86" fill="none" stroke="#003665" stroke-linecap="round" stroke-miterlimit="8" stroke-width="2.6"></svg:path><svg:path d="M36.21 45l3.95 3.93L50 39.08" fill="none" stroke="#00aeef" stroke-linecap="square" stroke-miterlimit="8" stroke-width="2.6"></svg:path><svg:path d="M36.21 68.62l3.95 3.94L50 62.71" fill="none" stroke="#00aeef" stroke-linecap="square" stroke-miterlimit="8" stroke-width="2.6"></svg:path><svg:circle cx="42.12" cy="92.25" r="5.91" fill="none" stroke="#003665" stroke-linecap="square" stroke-miterlimit="8" stroke-width="2.6"></svg:circle><svg:path d="M73.61 17.84h12.58A7.25 7.25 0 0 1 93.58 25v0h0v72.4a7.59 7.59 0 0 1-7.58 7.1H29.81a7.27 7.27 0 0 1-7.39-7.13h0V25.23a7.26 7.26 0 0 1 7.13-7.39H42" fill="none" stroke="#003665" stroke-linecap="square" stroke-miterlimit="8" stroke-width="2.6"></svg:path><svg:path d="M43.26 11.5v17.69h8.85a5.9 5.9 0 1 1 11.79-.49 4 4 0 0 1 0 .49h8.84V11.5z" fill="none" stroke="#003665" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.6"></svg:path></svg:g>',

  styles: [
  ],

  host: {
    'id': 'svgU/DNKYaNi1u7Z6EkUvC+lpMA5J+UXVqdEIXyA0Q/GmU&#x3D;',
    'viewBox': '0 0 116 116',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationIndiChecklistSvgComponent {
}

@NgModule({
  declarations: [
    InformationIndiChecklistSvgComponent,
  ],
  exports: [
    InformationIndiChecklistSvgComponent,
  ],
})
export class InformationIndiChecklistSvgModule {}
