import { CheckPolicyStatusData } from '../../model/dto/CheckPolicyStatusResponse';

export class PolicyCreationError extends Error {
  constructor(
    public readonly data: CheckPolicyStatusData
  ) {
    super('Policy creation errro');
    Object.setPrototypeOf(this, PolicyCreationError.prototype);
  }
}
