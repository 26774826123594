import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class ContainerService {
    public containerUrl = new BehaviorSubject('');
    public containerUrl$: Observable<string> = this.containerUrl.asObservable();
    
    public constructor() { }

    public setContainerUrl(url: string) {
        this.containerUrl.next(url);
    }

}
