import { Component, OnInit, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { ModalContentComponent, ModalControl } from '@maia/modals';
import {TILE_HEIGHT_DEFAULT, TileHeight,} from '@maia/tiles';

import { VehicleTalonData } from '../../../talon-scanning/dto/VehicleTalonData';
import { TalonScanUploadService } from '../../../talon-scanning/services/talon-scan-upload.service'
import { ReplaySubject, Subscription } from 'rxjs';
import { FindDeviceService } from '../../../talon-scanning/services/find-device.service';


@Component({
  selector: 'systemcode-talon-slidein',
  templateUrl: './talon-slidein.component.html',
  styleUrls: ['./talon-slidein.component.scss']
})
export class TalonSlideinComponent extends ModalContentComponent<VehicleTalonData> implements OnInit, OnDestroy {

  protected destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public currentHeight: TileHeight = TILE_HEIGHT_DEFAULT;
  public imageSrc: string = '';
  public prim41Response: any; // TODO need to modify
  public prim41ResponseError: any; // TODO need to modify
  public showErroMsg: boolean =false;
  public loopCaptureImage: boolean =false;
  public showWebCam: boolean =false;
  public sub: Subscription;
  public isMobileDevice: boolean =false;
  public modeOfUploading: string;

  constructor(public control: ModalControl<VehicleTalonData>,
     private _talonScanUploadService: TalonScanUploadService,
     public _findDevice: FindDeviceService,
     private _cd: ChangeDetectorRef) {
    super();
    this._talonScanUploadService.scanResultSubject.next('')
   }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit() {
    this.isMobileDevice = this._findDevice.checkIsItMobileDevice();

    this.sub = this._talonScanUploadService.scanResultObserable
    .subscribe((result: any)=>{
      if(result && Object.keys(result).length > 0){
        if(result.regNo){
          this.loopCaptureImage = false;
          const talonData: VehicleTalonData = {
            ...result,
            // regNo: 'CA8805BX',
            // certNo: '000126281',
            // pid: '7112202241'
           }
           this.control.confirm(talonData);
        }else{
          this.showErroMsg = true;
          this.loopCaptureImage =true;
          this._cd.markForCheck();
        }
      }
    })
  }

  public uploadInputChange(e: any, mode: string): void{
    this.showErroMsg = false;
    this.modeOfUploading =mode;
    this.showErroMsg = this._talonScanUploadService.handleInputChange(e);
  }

    public capturedImage(capturedImage: string): void{
      this.imageSrc = capturedImage;
        this._talonScanUploadService.uploadImage(capturedImage, true);
     }

    public backToScanOptions(event: boolean): void{
        this.showWebCam =false;
        this.showErroMsg = false;
     }
}
