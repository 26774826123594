import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { EmptyDataList } from '../../../flow/shared/model/EmptyDataList';


@Component({
  selector: 'systemcode-no-due-instalment-common',
  templateUrl: './no-due-instalment-common.component.html',
  styleUrls: ['./no-due-instalment-common.component.scss'],
})
export class NoDueInstalmentCommonComponent implements OnInit {

  @Input() public inputRequestData: EmptyDataList;
  @Output() public callBackFromNoDue = new EventEmitter();
  // public errorID: string;
  public heading: string = '';
  public content: string = '';
  public contentDynamic: string = '';
  public buttonName: string = '';
  public buttonPath: string = '';
  public buttonType: string = 'secondary';
  public lang: string | null;
  public constructor(public router: Router) {this.lang = sessionStorage.getItem('lang');}

  public ngOnInit() {
    this.lang = sessionStorage.getItem('lang');
    const errData: any = this.inputRequestData;
    console.log(errData)
    this.heading = errData.heading;
    this.content = errData.content;
    this.contentDynamic = errData.contentDynamic;
    this.buttonName = errData.buttonName;
    this.buttonPath = errData.buttonPath;
    if (errData.buttonType){
      this.buttonType = errData.buttonType;
    }
  }

  public callBack() {
    this.callBackFromNoDue.emit({'path': this.buttonPath})
    // this.router.navigateByUrl(this.buttonPath);
  }

}
