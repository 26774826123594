import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { EmptyDataAlertModel } from '../../model/EmptyDataAlertModel';

@Component({
  selector: 'workflow-alert',
  templateUrl: './workflow-alert.component.html',
  styleUrls: ['./workflow-alert.component.scss'],
})
export class WorkflowAlertComponent {

  public lang=sessionStorage.getItem('lang')
  public isDataSet = false;

  public type = '';
  public imageUrl = '../../assets/img/invalid_link_data.svg';
  public headingAlertMessge = 'Link Expired';
  public alertMessage = '';
  public messgaeBarBgColor = '#EEF6FA';
  public messgaeTextColor = '#003665';
  public buttonText = 'Create a new policy';

  @Input()
  set data(value: EmptyDataAlertModel) {
    this.type = value.type;
    this.imageUrl = value.imageUrl;
    this.headingAlertMessge = value.headingAlertMessge;
    this.alertMessage = value.alertMessage;
    this.messgaeBarBgColor = value.messgaeBarBgColor;
    this.messgaeTextColor = value.messageTextColor;
    this.buttonText = value.buttonText;

    this.isDataSet = true;
  }

  @Output()
  navigate = new EventEmitter();

  public constructor(public router: Router) {}

  public navigatePath() {
    this.navigate.emit();
  }

}
