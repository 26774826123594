import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncBinSvgModule
 */
@Component({
  selector: 'svg[systemcodeActionFuncBin]',
  template: '<svg:g class="nc-icon-wrapper"><svg:path d="M18.42 9.593v9.629a1.605 1.605 0 0 1-1.606 1.604H7.186a1.605 1.605 0 0 1-1.605-1.604v-9.63" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M3.174 6.383h17.652"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M14.14 12v4.814" data-color="color-2"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M9.86 12v4.814" data-color="color-2"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.04" d="M8.79 6.383v-3.21h6.42v3.21"></svg:path></svg:g>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
    'data-color': 'color-2',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncBinSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncBinSvgComponent,
  ],
  exports: [
    ActionFuncBinSvgComponent,
  ],
})
export class ActionFuncBinSvgModule {}
