export enum RoutePaths {
  MTPL ='mtpl',
  MTPL_VEHICLE = 'vehicle',
  MTPL_VEHICLE_LIST = 'vehicle-list',
  MTPL_INSURING_PARTY = 'insuring-party',
  MTPL_POLICY_DETAILS = 'policy-details',
  MTPL_INSTALMENTS = 'instalments',
  MTPL_COVERS = 'covers',
  MTPL_CALC_SUMMARY = 'calc-summary',

  // Purchase Policy
  PURCHASE_POLICY = 'purchase-policy',
  MTPL_PERSONAL = 'personal',
  MTPL_FOREIGNER = 'foreigner',
  MTPL_COMPANY = 'company',
  MTPL_PEOPLE = 'people',
  MTPL_CONTACT = 'contact',
  MTPL_VEHICLE_OWNER ='vehicle-owner',
  MTPL_TALON ='talon',
  MTPL_DELIVERY = 'delivery',
  MTPL_PURCHASE_SUMMARY = 'summary',
  MTPL_PAYMENT = 'payment',

  MTPL_ESIGNATURE = 'e-signature',
  MTPL_ESIGN_NOTIFY ='e-signature-notification',
  MTPL_PAY_RESULT ='payment-result',
  MTPL_EDIT_DELIVERY_ADDRESS ='edit-delivery-address',
  PI_POLICY_CREATION_ERROR ='policy-creation-fail',


  TRAVEL ='travel',
  TRAVEL_INSURING_PARTY = 'insuring-party',

  //Property
  PROPERTY ='property-insurance',
  PROPERTY_INSURING_PARTY = 'insuring-party',
  PI_PROPERTY_OWNER = 'property-owner',

  //Travel
  TI = 'travel',
  TI_PINSURED_PEOPLE = 'pinsured-people',
}
