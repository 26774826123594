<hermes-messaging></hermes-messaging>
<!--<div maiaFillViewport style="overflow-y: hidden;">-->
<div maiaFillViewport *ngIf="!isIE">
    <bifrost-header  class="bifrost-header-height" style="position: fixed;z-index: 100;"
    classicAuthenticated (goBack)="goBack()" *ngIf="isHeaderVisible" (closeFlow)="close()"
    [class.hide-close-button]="! isCloseButtonVisible"
    [class.hide-back-button]="! isBackButtonVisible"
    >
    <img src="../assets/img/DZI-Logo.svg" maiaHeaderLayoutLogo>
    </bifrost-header>

    <div [ngClass]="{'maia-layout-m-t' : (isHeaderVisible === true)}">
      <maia-layout>
        <router-outlet></router-outlet>
      </maia-layout>
    </div>
</div>
<systemcode-browser-support-warning *ngIf="isIE"></systemcode-browser-support-warning>
<ng-container maiaModalContainer></ng-container>
