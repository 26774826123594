import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaymentFailureDto } from '../../../model/dto/PaymentFailureDto';
import { ApiService } from '../../../backend/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'systemcode-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit, OnDestroy {

  public notFoundData: PaymentFailureDto
  subscription: Subscription

  public constructor(public apiService: ApiService) {}

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public ngOnInit() {
    this.subscription = this.apiService.getNotFoundData().subscribe((result: PaymentFailureDto)=> this.notFoundData = result);
  }

}
