import { Injectable } from '@angular/core';
import { ApiService } from '../../backend/api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { TalonUploadApiRequest } from '../dto/TalonUploadApiRequest';
import { TALON_ACTION } from '../../constant/config/config';

@Injectable({
  providedIn: 'root'
})
export class TalonScanUploadService {


  public scanResultSubject =new BehaviorSubject({});
  public scanResultObserable: Observable<any> = this.scanResultSubject.asObservable();

  constructor(private _apiService: ApiService) { }

  public handleInputChange(e: any): boolean  {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    const pattern = /image-*/;
    const reader = new FileReader();
    if (!file.type.match(pattern)) {
      // this.showErroMsg = true;
      return true;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);

    return false;
  }

    private _handleReaderLoaded(e: any): void {
      let reader = e.target;
      const imageSrc: string = reader.result;

      this.uploadImage(imageSrc, false);
    }

    public async uploadImage(imageSrc: string, skipLoader: boolean ){
      const imageUploadReq: TalonUploadApiRequest = {
        'image': imageSrc,
        'source': TALON_ACTION.TALON_SOURCE,
      }
      try{
        const result : any =await this.uploadImageToPrimCustomApi(imageUploadReq, skipLoader).toPromise();
        this.scanResultSubject.next(result);
      }catch(error){
        console.log(error);
        this.scanResultSubject.next({inValid: true})
      }

      try{
        this.saveScannedTalonData(TALON_ACTION.BG_TALON, imageSrc);
      }catch(error){
        console.log(error);
      }
    }

    public uploadImageToPrimCustomApi(imageUploadReq: TalonUploadApiRequest, skipLoader?: boolean | false): Observable<any> {
      return this._apiService.post('scanning/talon', imageUploadReq, undefined, undefined, skipLoader);
    }

    public saveScannedTalonData(talon: string, imageSrc: string) {
      sessionStorage.setItem(talon, imageSrc);
    }

    public getScanedTalon(): any {
      return sessionStorage.getItem(TALON_ACTION.BG_TALON) ? sessionStorage.getItem(TALON_ACTION.BG_TALON): '';
    }

    public clearScanedTalonData() {
      sessionStorage.setItem(TALON_ACTION.BG_TALON, '');
      sessionStorage.removeItem(TALON_ACTION.BG_TALON);
    }

    public urlToBase64 = async (url: any) => {
      const res = await fetch(url);
      const blob = await res.blob();

      const result = await new Promise((resolve, reject) => {
        const reader: FileReader = new FileReader();
        reader.addEventListener("load", function () {
          resolve(reader.result);
        }, false);

        reader.onerror = () => {
          return reject(this);
        };
        reader.readAsDataURL(blob);
      })

      return result
    };
}
