import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule NavigationFuncLiveHelpSvgModule
 */
@Component({
  selector: 'svg[systemcodeNavigationFuncLiveHelp]',
  template: '<svg:path d="M21 14.8c.4-2.6.4-5.9-1.3-8.5-3-5-9.5-6.6-14.5-3.6s-6.6 9.5-3.6 14.5c.1.2.2.4.4.6 2 3.2 6 4.8 9.3 5 3.4.2 7.9-.5 12.7-5.2-.1 0-3.4-.2-3-2.8m-7.5.6c-.1.1-.2.1-.4.1H8.2c-.1 0-.3-.1-.3-.1-.1-.1-.2-.2-.2-.3 0-.3.2-.5.5-.5h4.9c.1 0 .3.1.4.1.1.1.1.2.1.3.1.2 0 .3-.1.4m2-2.9c-.1.1-.2.1-.4.1H6.3c-.1 0-.3-.1-.3-.1-.1-.1-.2-.2-.1-.3 0-.3.2-.5.5-.5h8.9c.1 0 .3.1.4.1.1.1.1.2.1.3-.2.2-.2.3-.3.4m0-3c-.1.1-.2.1-.4.1H6.3c-.2.1-.3 0-.4-.1-.1-.1-.1-.2-.1-.3 0-.1.1-.3.1-.3.1-.2.2-.2.4-.2h8.9c.1 0 .3.1.4.1.1.1.1.2.1.3-.1.2-.1.3-.2.4" transform="matrix(.8 0 0 .8 2.4 2.4)"></svg:path>',

  styles: [
    ':host { fill: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 24 24',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationFuncLiveHelpSvgComponent {
}

@NgModule({
  declarations: [
    NavigationFuncLiveHelpSvgComponent,
  ],
  exports: [
    NavigationFuncLiveHelpSvgComponent,
  ],
})
export class NavigationFuncLiveHelpSvgModule {}
