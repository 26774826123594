import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { PolicyDocDto } from '../../../model/dto/PolicyDocDto';
import { ClearSessionService } from '../../../services/clear-session.service';
import { FLOW } from '../../../constant/config/config';


@Component({
  selector: 'systemcode-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit, OnChanges {

  @Input()
  public instalmentsData: any[] = [];

  @Input()
  public transactionNo: any;

  @Input()
  public amount: any;

  @Input()
  public paymentSuccessMsg: any;

  @Output()
  public createProfile = new EventEmitter();

  @Input()
  public policyDocData: PolicyDocDto;

  @Input()
  public mtplConditions: any;

  @Output()
  public downloadPaymentReceipt:EventEmitter<string> = new EventEmitter();

  public currency = 'BGN'
  public lang: string | null;
  public policyNumber: string;
  public noOfIns = '№ ';
  public isLoggedIn: boolean;

  public constructor(public datepipe: DatePipe, public router: Router,
    public clearSessionService: ClearSessionService) { this.lang = sessionStorage.getItem('lang'); }

  public ngOnInit() {
    const userData: any = sessionStorage.getItem('userData');
    this.isLoggedIn = userData? true : false;
    
    this.lang = sessionStorage.getItem('lang');
    const mtpl = sessionStorage.getItem('mtpl');
    if(mtpl){
      let mtplObj = JSON.parse(mtpl);
      mtplObj.priceCache =[];
      const mtplString = JSON.stringify(mtplObj)
      sessionStorage.setItem('mtpl', mtplString);
    }

    console.log('instalmentsData ', this.instalmentsData, this.transactionNo, this.paymentSuccessMsg);
    if(this.instalmentsData && this.instalmentsData.length>0){
      this.policyNumber = this.instalmentsData[0].policyData.policyNo;
    }
  }

  ngOnChanges() {}

  public checkDate(dateString: any) {
    // TODO: due to date formate was updated in BE
    // const date = new Date(dateString);
    // if ((date instanceof Date && !isNaN(date.getTime()))) {
    //   const momentVariable = moment(dateString.toString(), 'YYYY-MM-DD HH:mm:ss');
    //   const stringvalue = momentVariable.format('DD/MM/YYYY');
    //   return stringvalue;
    // }
    //   const momentVariable = moment(dateString.toString(), 'YYYY-MM-DD HH:mm:ss');
    //   const stringvalue = momentVariable.format('DD/MM/YYYY');
    //   return stringvalue;
    return dateString;
  }

  public navigateTo(path: any){
    const flowData = sessionStorage.getItem('flow');
    const userData = sessionStorage.getItem('userData');
    if(flowData === FLOW.UBB){
      this.clearSessionService.clearAll();
    } else if(userData){
      this.clearSessionService.clearPolicySessionData();
    }
    this.router.navigateByUrl(path);
  }

  public downloadReceipt(){
    this.downloadPaymentReceipt.emit('paymentReceipt');
  }

  public getInstalmentNumbers(instalmentList: any[]){
    let instalments: string[] = []
    if(instalmentList && instalmentList.length>0){
      instalmentList.forEach((instalment: any)=>{
        if(instalment.selectedRowData)  {
          const instalmentNumber = Number(instalment.selectedRowData.instalmentNumber)
          if(instalmentNumber===0){
             instalments.push("Annex")
          }else{
            if(instalment.selectedRowData.instalment === 'Annex'){
              instalments.push("Annex")
            }else{
              instalments.push(this.noOfIns+instalment.selectedRowData.instalmentNumber)
            }
          }
        }
      })
    }
    return instalments.join(', ');
  }


}
