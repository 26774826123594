import { Inject, Injectable } from '@angular/core';
import { ENV } from '../../core.module';

import { EnvConfig } from '../../constant/config/config';

import { ApiService } from '../../backend/api.service';
import { DataResponse } from '../../model/api/DataResponse';
import { UnsuccessfulApiCall } from '../../model/api/UnsuccessfulApiCall';
import { PaymentInformationID, PaymentVPOSInformationDto } from '../../model/dto/PaymentInformation';
import { VposPaymentData } from '../model/vpos/vpos-payment-data';
import { VposTransactionSecurityData } from '../model/vpos/vpos-transaction-security-data';

@Injectable({
  providedIn: 'root'
})
export class VposService {
  public vposServiceEmail: string = '';
  constructor(
    private apiService: ApiService,
    @Inject(ENV) private env: EnvConfig,
  ) { 
    this.vposServiceEmail = this.env.VPOS_SERVICE_EMAIL;
  }

  public async getPaymentMethodInformation(): Promise<VposPaymentData> {
    const url = this.apiService.buildUrlWithParameters('vpos/get-payment-method-information', [PaymentInformationID.VPOS]);
    const response = await this.apiService.get<DataResponse<PaymentVPOSInformationDto>>(url).toPromise();
    console.log('Boricaaa== >>>>>>>>>>>>>>>>>',response);
    if (! response.success) {
      throw new UnsuccessfulApiCall();
    }

    return {
      paymentUrl: response.data.PAYMENT_URL,
      merchant: response.data.MERCHANT,
      terminal: response.data.TERMINAL,
      merchantName: response.data.MERCHANT_NAME,
      merchanteUrl: response.data.MERCHANTE_URL,
      email: this.vposServiceEmail,
      country: 'BG',
      merchGmt: '+02',
    }
  }

  public async getTransactionSecurityData(tokenReqData: { amount: number, trtype: number, order: number }): Promise<VposTransactionSecurityData> {
    const response = await this.apiService.post<DataResponse<VposTransactionSecurityData>>('vpos/get-borica-vpos-form-data', tokenReqData, {}).toPromise();

    if (! response.success) {
      throw new UnsuccessfulApiCall();
    }

    return response.data;
  }

}
