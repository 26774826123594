import { IMtplProcessStep } from '@systemcode/core';

export const steps: IMtplProcessStep[] = [
  {
    id: 'search-policy',
    title: 'Search Policy',
    routePath: '/policy-payment/search-policy',
  },
  {
    id: 'installments',
    title: 'Instalments',
    routePath: '/policy-payment/installments',
  },
  {
    id: 'delivery-address',
    title: 'Delivery',
    routePath: '/policy-payment/delivery-address',
  },
  {
    id: 'summary',
    title: 'Summary',
    routePath: '/policy-payment/summary',
  },
  {
    id: 'payment',
    title: 'Payment',
    routePath: '/policy-payment/payment',
  },
];
