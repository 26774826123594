export enum RoutePaths {
  PI = 'property-insurance',
  PURCHASE_POLICY = 'purchase-policy',
  PI_PROPERTY_DETAILS = 'property-details',
  PI_PROPERTY_LIST_DETAILS = 'property-list-details',
  PI_PROPERTY_PICK_TERM = 'property-pick-term',

  PI_POLICY_DETAILS = 'policy-details',
  PI_COVER_PACKAGE = 'cover-package',
  PI_ADD_ONS = 'add-ons',
  PI_CALCULATOR_SUMMARY = 'calculator-summary',
  // purchase policy
  PI_INSURING_PARTY = 'insuring-party',
  PI_CONTACT_DETAIL = 'contact-detail',
  PI_PEOPLE = 'people',
  PI_PROPERTY_OWNER = 'property-owner',
  PI_PROPERTY_ADDRESS = 'property-address',
  PI_BENEFICIARY = 'beneficiary',
  PI_SUMMARY = 'summary',
  PI_PAYMENTS = 'payment',
  PI_POLICY_CREATION_SUCCESS = 'payment-success',
  PI_POLICY_CREATION_ERROR = 'payment-error',
  PI_PAY_RESULT ='payment-result',

  PI_ESIGNATURE = 'e-signature',
  PI_ESIGN_NOTIFY ='e-signature-notification',

  PI_EIDENTIFICATION = 'e-identification-success',
  PI_EIDENTITY_NOTIFY ='e-identification-notification',
}
