import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule FileExprDocumentDownloadSvgModule
 */
@Component({
  selector: 'svg[systemcodeFileExprDocumentDownload]',
  template: '<svg:path d="M14.62 36.43v1.72c0 3.89-4.32 4.32-4.32 4.32h25.91a4.31 4.31 0 0 0 4.32-4.32v-1.72z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M25 4.47H6v33.68a4.32 4.32 0 0 0 4.32 4.32" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M12.89 13.11h11.99"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M12.89 20.02h11.99"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M12.89 26.93h15.55"></svg:path><svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M35.13 5.05v17.27"></svg:path><svg:path d="M41.18 16.28l-6 6-6-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileExprDocumentDownloadSvgComponent {
}

@NgModule({
  declarations: [
    FileExprDocumentDownloadSvgComponent,
  ],
  exports: [
    FileExprDocumentDownloadSvgComponent,
  ],
})
export class FileExprDocumentDownloadSvgModule {}
