import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ActionFuncCrossMiniSvgModule
 */
@Component({
  selector: 'svg[systemcodeActionFuncCrossMini]',
  template: '<svg:path d="M14 14l-4-4" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path><svg:path d="M10 14l4-4" fill="none" stroke-linecap="round" stroke-linejoin="round"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 24 24',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionFuncCrossMiniSvgComponent {
}

@NgModule({
  declarations: [
    ActionFuncCrossMiniSvgComponent,
  ],
  exports: [
    ActionFuncCrossMiniSvgComponent,
  ],
})
export class ActionFuncCrossMiniSvgModule {}
