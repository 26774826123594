import { animate, AnimationEvent, query, sequence, style, transition, trigger } from '@angular/animations';
import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import { Router } from '@angular/router';
import { coerceBooleanPrimitive } from '@atlas-angular/cdk/coercion';
import { Observable, Subject } from 'rxjs';

import { getTotalWeight, PublicStep, Step } from './step/step';

function clampPercentage(value: number): number {
  return Math.max(0, Math.min(100, value));
}

/**
 * Flow progress
 *
 * A flow progress contains a tree of steps.
 */
export interface FlowProgress {
  /**
   * Emits the active leaf step
   *
   * This immediately emits the active leaf step and it emits the new active step whenever it
   * changes.
   */
  readonly activeStep$: Observable<PublicStep | undefined>;
}

/**
 * Flow progress component
 *
 * @ngModule FlowProgressModule
 */
@Component({
  selector: 'maia-expanded-flow-progress',
  styleUrls: ['./flow-progress.component.scss'],
  templateUrl: './flow-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('iconAnim', [

      transition('active => done', [
        sequence([
          query('.step-icon-img-active', [
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0)' })),
          ]),
          query('.step-icon-img-done', [
            style({ transform: 'scale(0)' }),
            animate('0.2s ease-in', style({ transform: 'scale(1)' })),
          ]),
        ]),
      ]),
      transition('done => active', [
        sequence([
          query('.step-icon-img-done', [
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0)' })),
          ]),
          query('.step-icon-img-active', [
            style({ transform: 'scale(0)' }),
            animate('0.2s ease-in', style({ transform: 'scale(1)' })),
          ]),
        ]),
      ]),

      transition('todo => done', [
        sequence([
          query('.step-icon-img-todo', [
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0)' })),
          ]),
          query('.step-icon-img-done', [
            style({ transform: 'scale(0)' }),
            animate('0.2s ease-in', style({ transform: 'scale(1)' })),
          ]),
        ]),
      ]),
      transition('done => todo', [
        sequence([
          query('.step-icon-img-done', [
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0)' })),
          ]),
          query('.step-icon-img-todo', [
            style({ transform: 'scale(0)' }),
            animate('0.2s ease-in', style({ transform: 'scale(1)' })),
          ]),
        ]),
      ]),

      transition('todo => active', [
        sequence([
          query('.step-icon-img-todo', [
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0)' })),
          ]),
          query('.step-icon-img-active', [
            style({ transform: 'scale(0)' }),
            animate('0.2s ease-in', style({ transform: 'scale(1)' })),
          ]),
        ]),
      ]),
      transition('active => todo', [
        sequence([
          query('.step-icon-img-active', [
            style({ transform: 'scale(1)' }),
            animate('0.2s ease-in', style({ transform: 'scale(0)' })),
          ]),
          query('.step-icon-img-todo', [
            style({ transform: 'scale(0)' }),
            animate('0.2s ease-in', style({ transform: 'scale(1)' })),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class ExpandedFlowProgressComponent implements AfterContentInit, FlowProgress,
  AfterContentChecked {

  public get stateChanged(): Observable<void> {
    return this._stateChanged;
  }

  /**
   * The title of the active top-level step
   */
  public get stepTitle(): string | null {
    const active = this._getActiveToplevelStep();

    return active != null ? active.title : null;
  }

  /**
   * The total number of top-level steps
   */
  public get numberOfToplevelSteps(): number {
    return this._steps.length;
  }

  public get stepWidthPerc(): number {
    return 100 / this.numberOfToplevelSteps;
  }

  public get progressBarLeftMarginPerc(): string {
    return (this.stepWidthPerc / 2).toFixed(5) + '%';
  }

  public get progressBarBaseLeftMarginPerc(): string {
    return (this.stepWidthPerc / 2).toFixed(5) + '%';
  }

  public get progressBarWidthPerc(): string {
    return (this.stepWidthPerc * this.activeToplevelStepIndex).toFixed(5) + '%';
  }

  public get progressBarBaseWidthPerc(): string {
    return (this.stepWidthPerc * (this.numberOfToplevelSteps - 1)).toFixed(5) + '%';
  }

  /**
   * The index of the active top-level step
   */
  public get activeToplevelStepIndex(): number {
    return this._steps.toArray().findIndex(step => step.matches(this._activeLeafStep));
  }

  /**
   * The progress in the flow as percentage
   */
  public get progress(): number {
    const topLevelSteps = this._steps.toArray();
    const activeTopLevelStepIndex = topLevelSteps.findIndex(
      step => step.matches(this._activeLeafStep),
    );
    const activeTopLevelStep: Step | undefined = topLevelSteps[activeTopLevelStepIndex];

    const totalTopLevelStepWeight = getTotalWeight(topLevelSteps);
    const weightOfDoneSteps = getTotalWeight(topLevelSteps.filter(step => step.done));

    const weightOfActiveStep =
      activeTopLevelStep != null ? activeTopLevelStep.progress * activeTopLevelStep.weight : 0;

    // NaN is falsy, `|| 0` ensures we fallback to 0 if our calculation ends up being not a number
    // (e.g. when there are no steps we'd be passing in 0/0 which is NaN)
    return (
      clampPercentage(
        (weightOfDoneSteps + weightOfActiveStep) * (100 / totalTopLevelStepWeight)) ||
      0);
  }
  /**
   * When set true the step indicator will be hidden inside the template
   */
  @coerceBooleanPrimitive()
  @Input()
  public hideSteps = false;

  /**
   * When set true the fallout will be shown inside the template
   */
  @coerceBooleanPrimitive()
  @Input()
  public enableFallout = false;

  /**
   * When set true the fallout will be visble inside the template
   */
  @coerceBooleanPrimitive()
  @Input()
  public showFallout = false;

  /**
   * The top-level steps
   */
  @ContentChildren(Step)
  public _steps: QueryList<Step>;

  /**
   * The active leaf step, if any.
   *
   * There can only be one active leaf step, but there may be more than one step with the `active`
   * state, e.g. if the active leaf step is a substep of a multi-step both the active leaf step and
   * the multi-step will match `step.active === true`.
   */
  private _activeLeafStep?: Step = undefined;

  /**
   * Emits each step as it gets activated
   */
  @Output()
  public readonly activate = new EventEmitter<PublicStep>();

  public readonly activeStep$ = new Observable<PublicStep | undefined>(observer => {
    observer.next(this._activeLeafStep);
    return this.activate.subscribe(observer);
  });

  private _stateChanged: Subject<void> = new Subject();

  /**
   * Supress progress animation
   */
  public noAnim = false;

  public constructor(
    private router: Router,
    private readonly _cdr: ChangeDetectorRef,
  ) { }

  /**
   * Returns the leaf step that's active or the first enabled top-level step if no active step is
   * found
   */
  private _getActiveLeafStep(): Step | null {
    const active = this._activeLeafStep;

    if (active != null) {
      return active;
    }

    return this._steps.find(step => !step.disabled) || null;
  }

  /**
   * Returns the active top-level step or the first top-level step if no active step is found
   */
  private _getActiveToplevelStep(): Step | undefined {
    return this._steps.find(step => step.matches(this._activeLeafStep));
  }

  /**
   * Updates the state of all top-level steps.
   *
   * If there's no active step, all steps are marked todo.
   * If there is an active step, that step gets marked active. All steps that come before the active
   * step are marked done and all steps that come after it are marked todo.
   */
  private _updateStepStates(): void {
    if (this._steps == null) {
      return;
    }

    const active = this._getActiveLeafStep();

    if (active == null) {
      // all steps are disabled, nothing to do here
      return;
    }

    let activeFound = false;

    this._steps.forEach(step => {
      if (step.matches(active)) {
        activeFound = true;
        step.markActive(active);
        return;
      }

      if (activeFound) {
        step.markTodo();
      } else {
        step.markDone();
      }
    });
  }

  public updateActive(step: Step, active: boolean) {
    if (!active) {
      if (step !== this._activeLeafStep) {
        // step is already not active, ignore
        return;
      }

      this._activeLeafStep = undefined;
      this._updateStepStates();

      this.activate.emit();
      return;
    }

    if (step === this._activeLeafStep) {
      // step is already active, ignore
      return;
    }

    this._activeLeafStep = step;
    this._updateStepStates();
    this._cdr.markForCheck();

    this.activate.emit(step);
  }

  public updateSteps() {
    this.noAnim = true;
    this._cdr.markForCheck();
    setTimeout(() => this.noAnim = false);
  }

  public ngAfterContentInit(): void {
    this._updateStepStates();
  }

  public ngAfterContentChecked(): void {
    this._stateChanged.next();
  }

  public stepClick(step: Step) {
    if (step.done && step.route) {
      this.router.navigateByUrl(step.route)
    }
  }

  public fixAnimEnd(step: Step, event: AnimationEvent) {
    (event.element as HTMLElement).setAttribute('finalstate', event.toState);
  }
}
