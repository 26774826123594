<div class="warning-div">
    <img src="../../assets/img/alert-util-confirmation-warning--multicolor.svg" width="152px" height="152px">
    <label class="kdl-font-serif-grand text-center m-t-b-32">{{title | localeVal:lang}}</label>
    <label class="demo-kdl-font-normal-light warning-subtitle">{{content1 | localeVal:lang}}</label>
    <!-- <label class="demo-kdl-font-normal-light text-center">{{content2 | localeVal:lang}}</label> -->
    
    <!-- <maia-button-group style="text-align: center;">
        <button maiaButton *ngIf="primaryButtonText!=''" maiaButtonType="primary" class="m-t-b-32" (click)="navigateToScreen(primaryButtonLink)" >{{primaryButtonText  | localeVal:lang}}</button>
        <button maiaButton *ngIf="secondaryButtonText!=''" maiaButtonType="secondary" class="m-t-b-32" (click)="navigateToScreen(secondaryButtonLink)" >{{secondaryButtonText | localeVal:lang}}</button>
    </maia-button-group> -->
</div>