import { Injectable} from '@angular/core';
import { EsignatureService } from '../../esignature/services/esignature.service';
import { EpayPaymentFormComponent } from '../../payment/components/epay-payment-form/epay-payment-form.component';
import { PolicyProcessData } from '../../product-workflow/model/policy/PolicyProcessData';
import { IProductWorkflowService } from '../../product-workflow/model/product-workflow-service';
import { ScreenManagersModule } from '../screen-managers.module';
import { MissingSignatureError } from './model/missing-signature-error';
import { IPropertyPaymentScreen } from './property-payment-screen';
import { SomethingWentWrongService } from '../../modals/services/something-went-wrong.service';
import { PartyIdService } from '../../flow/services/party-id.service';
import { PartyType } from '../../model/PartyType';
import { BtrustAccountData } from '../../payment/model/btrust/btrust-account-data';
import { INSURANCE } from '../../constant/config/config';




@Injectable({
  providedIn: ScreenManagersModule
})
export class PaymentScreenEpayManagerService {

  screen: IPropertyPaymentScreen;
  productWorkflow: IProductWorkflowService;

  constructor(
    private esignatureService: EsignatureService,
    public somethingWentWrongService: SomethingWentWrongService,
    public partyIdService:PartyIdService,
  ) { }

  public async submitClick(paymentComponent: EpayPaymentFormComponent, lang: string, successUrl: string, failureUrl: string, epayRedirectUrl: string,isEidentificationRequired:boolean, productCode: number) {
      const pid = this.screen.getEsignatureHolderPID();

      if (! pid) {
        throw new Error('User ID not found');
      }

      const pidType = this.partyIdService.getType(pid);
      let entityType:string = '';

      if (! pidType) {
        throw new Error('User ID Type not found');
      }

      if(pidType === PartyType.PERSON){
        entityType = 'EGN'
      }else if(pidType === PartyType.FOREIGNER){
        entityType = 'LNC'
      }

      const hasValidsignature = await this.esignatureService.hasValidsignature(pid,entityType);
      const btrustAccountData: BtrustAccountData = {
        hasBtrustAccount:hasValidsignature
      }
      this.productWorkflow.setBtrustAccountData(btrustAccountData);

      if (isEidentificationRequired){
        this.submitEpay(paymentComponent, lang, successUrl, failureUrl, epayRedirectUrl);
      }else {
        if(productCode === INSURANCE.TRAVEL){
          this.submitEpay(paymentComponent, lang, successUrl, failureUrl, epayRedirectUrl);
        }else {
          if (hasValidsignature) {
            this.submitEpay(paymentComponent, lang, successUrl, failureUrl, epayRedirectUrl);
          } else {
            throw new MissingSignatureError();
          }
        }
      }
  }

  private async submitEpay(paymentComponent: EpayPaymentFormComponent, lang: string, successUrl: string, failureUrl: string, epayRedirectUrl: string) {
      const registerData = await this.screen.registerPolicyPayment();

      if (!registerData || !registerData.success || ! registerData.data || ! registerData.data.token) {
        //TODO: Add message to the user
        this.somethingWentWrongService.showInternalServerDialog();
        throw new Error('Invoice data not recieved correctly!');
      }

      const epayToken = registerData.data.token;

      const processId = registerData.data.policy_creation_process_id;

      const eIdentifyId = registerData.data.eIdentificationId;

      const policyProcessData: PolicyProcessData = {
        policy_creation_process_id: processId,
        eIdentificationId: eIdentifyId
      }

      this.productWorkflow.setPolicyProcessData(policyProcessData);
      this.productWorkflow.setVposData(undefined);
      this.productWorkflow.setEpayData(epayToken);
      this.productWorkflow.setInvoiceData({invoice: epayToken.invoice.toString(), amount: parseFloat(epayToken.amount), currency: epayToken.currency});

      await paymentComponent.submit({
        paymentUrl: epayRedirectUrl,
        lang,
        encode: epayToken.encoded,
        checksum: epayToken.checksum,
        epaySuccessUrl: successUrl,
        epayFailureUrl: failureUrl,
      });
  }

}
