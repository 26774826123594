import { AsyncSubject, BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export interface FilterDefinition<T> {
  field: keyof T;
  caseSensitive: boolean;
  searchMode: 'prefix' | 'any';
}

export class AutocompleteController<Record> {

  public readonly onLoad = new AsyncSubject<Record[]>();
  public readonly list = new BehaviorSubject<Record[]>([]);
  private onFilter = new BehaviorSubject<string>('');
  private options: Record[] = [];

  private _loaded: boolean = false;
  public get loaded(): boolean {
    return this._loaded;
  }

  private onLoadingSubject = new BehaviorSubject<boolean>(false);
  public loading: Observable<boolean> = this.onLoadingSubject;

  public constructor(
    private source: Observable<Record[]>,
    private destroyed$: Subject<any>,
    private filter: FilterDefinition<Record>,
    loading: boolean = false,
  ) {
    this.onLoadingSubject.next(loading);
  }

  public setDestroyed(destroyed$: Subject<any>) {
    this.destroyed$ = destroyed$;
  }

  public init() {
    this.source
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe((result) => {
        this.onLoad.next(result);
        this.onLoad.complete();
        this.options = result;
        this._loaded = true;
        this.onLoadingSubject.next(false);
      }, (error) => {
        console.log('errorMessage: ', error);
      });

    combineLatest(
      this.onLoad.pipe(
        tap(
          list => this.options = list,
        ),
      ),
      this.onFilter,
    )
      .pipe(
        takeUntil(this.destroyed$),
      )
      .subscribe(([list, filter]) => {
        this.processFiltering(list, filter);
      });
  }

  private processFiltering(list: Record[], filter: string) {
    if (filter === '') {
      this.list.next(list);
    } else {
      const lowerCaseFilterText = filter.toLowerCase();
      this.list.next(
        list.filter(rec => {
          let source = rec[this.filter.field] as any as string;
          if (this.filter.caseSensitive === false) {
            source = (source as any as string).toLocaleLowerCase();
          }

          if (this.filter.searchMode === 'prefix') {
            return source.indexOf(lowerCaseFilterText) === 0;
          } else
            if (this.filter.searchMode === 'any') {
              return source.indexOf(lowerCaseFilterText) >= 0;
            } else {
              return false;
            }
        }),
      )
    }
  }

  public filterTypeahead(typeAhead: string) {
    this.onFilter.next(typeAhead)
  }

  public getValueById(id: string): Record | undefined {
    return this.options.find((option: any) => option.id === id);
  }

  public getFieldById<K extends keyof Record>(id: string, field: K): Record[K] | undefined {
    const rec = this.options.find((option: any) => option.id === id);
    if (rec) {
      return rec[field];
    } else {
      return undefined;
    }
  }

  public getFieldByIdSafe<K extends keyof Record>(id: string, field: K, fallback: Record[K]): Record[K] {
    const rec = this.options.find((option: any) => option.id === id);
    if (rec) {
      return rec[field];
    } else {
      return fallback;
    }
  }
}
