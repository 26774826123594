import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule ObjectsExprContainerSvgModule
 */
@Component({
  selector: 'svg[systemcodeObjectsExprContainer]',
  template: '<svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M11.51 26.95v11.8"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M5.3 22.7h37.4V43H5.3z"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M23.98 26.95v11.8"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M17.75 26.95v11.8"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M30.22 26.95v11.8"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M36.45 26.95v11.8"></svg:path><svg:path d="M22.15 13A1.85 1.85 0 1 0 24 11.2V8.59" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M9.24 22.7L24 15.87l14.76 6.83"></svg:path><svg:path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6" d="M21.07 5h5.86v2.91h-5.86z"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectsExprContainerSvgComponent {
}

@NgModule({
  declarations: [
    ObjectsExprContainerSvgComponent,
  ],
  exports: [
    ObjectsExprContainerSvgComponent,
  ],
})
export class ObjectsExprContainerSvgModule {}
