import { Inject, ModuleWithProviders, NgModule, Optional } from '@angular/core';

import { B2cDashboardRoutingModule } from './b2c-dashboard-routing.module';
import { B2cDashboardComponent } from './b2c-dashboard.component';
import { B2cMaiaModule } from './b2c-maia.module';
import { B2CAuthGuard } from './guards/auth.guard';
import { ClaimsModule } from './screens/claims/claims.module';
import { CookiesModule } from './screens/cookies/cookies.module';
import { DashboardModule } from './screens/dashboard/dashboard.module';
import { HomeModule } from './screens/home/home.module';
import { PaymentsModule } from './screens/payments/payments.module';
import { PoliciesModule } from './screens/policies/policies.module';
import { SettingsModule } from './screens/settings/settings.module';
import { BASE_ROUTE, StarterService } from './starter.service';

export function routeb2cdashboard(path: string) {
  return {
    path,
    loadChildren: '@systemcode/b2c-dashboard#øB2cDashboardModule',
  };
}

@NgModule({
    imports: [
        B2cDashboardRoutingModule,
        B2cMaiaModule,
        DashboardModule,
        SettingsModule,
        PaymentsModule,
        PoliciesModule,
        ClaimsModule,
        HomeModule,
        CookiesModule,
    ],
  providers: [
    B2CAuthGuard,
  ],
  declarations: [
    B2cDashboardComponent,
  ],
})
export class øB2cDashboardModule { }

@NgModule({
  providers: [StarterService],
})
export class B2cDashboardModule {
  public static forPath(path: string): ModuleWithProviders<any> {
    return {
      ngModule: B2cDashboardModule,
      providers: [
        { provide: BASE_ROUTE, useValue: path },
      ],
    };
  }

  public constructor(@Optional() @Inject(BASE_ROUTE) baseRoute?: string) {
    if (baseRoute == null) {
      throw new Error(
        `Do not import B2cDashboardModule directly, use B2cDashboardModule.forPath() instead`);
    }
  }
}
