import { PolicyPaymentWorkflow } from '../../model/PolicyPaymentWorkflow';

export const workflows: { [name: string]: string[] } = {
  [PolicyPaymentWorkflow.POLICY_PAYMENT]: [
    'search-policy',
    'installments',
    'summary',
    'payment',
  ],
  [PolicyPaymentWorkflow.POLICY_PAYMENT_WITH_DELIVERY]: [
    'search-policy',
    'installments',
    'delivery-address',
    'summary',
    'payment',
  ],
  [PolicyPaymentWorkflow.POLICY_PAYMENT_FROM_DASHBOARD]: [
    'delivery-address',
    'summary',
    'payment',
  ],
  [PolicyPaymentWorkflow.POLICY_CASCO_PAYMENT_FROM_DASHBOARD]: [
    'summary',
    'payment',
  ],
};
