import { BehaviorSubject, merge, Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';

export class ServerSideAutocompleteController<Record> {

  private readonly onTypeahead = new Subject<string>();
  private readonly onListSet = new BehaviorSubject<Record[]>([]);

  public readonly list: Observable<Record[]> = merge(
    of([]), // init
    this.onListSet,
    this.onTypeahead.pipe(
      filter(() => this.typeaheadEnabled),
      filter(text => text.length >= 2),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchTerm => this.source(searchTerm))
    )
  );

  private _loaded: boolean = false;
  private typeaheadEnabled: boolean = true;
  public get loaded(): boolean {
    return this._loaded;
  }

  private onLoadingSubject = new BehaviorSubject<boolean>(false);
  public loading: Observable<boolean> = this.onLoadingSubject;

  public constructor(
    private source: (q: string) => Observable<Record[]>,
    loading: boolean = false,
  ) {
    this.onLoadingSubject.next(loading);
  }

  public setList(list: Record[]): void {
    this.onListSet.next(list);
  }

  public disableTypeahead() {
    this.typeaheadEnabled = false;
  }

  public enableTypeahead() {
    this.typeaheadEnabled = true;
  }

  public init() {
  }

  public async filterTypeahead(typeAhead: string) {
    this.onTypeahead.next(typeAhead);
  }

}
