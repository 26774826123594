import { Inject, Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ENV } from '../../core.module';
import { EnvConfig } from '../../constant/config/config';


@Injectable()
export class ClaimRouteGuard implements CanLoad {

  constructor(@Inject(ENV) private env: EnvConfig, private router: Router) {}

  public canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    const routeFlag = this.env.ROUTING.enableClaimsRouting;
    if(routeFlag === false) {
      this.router.navigateByUrl('/dashboard');
    }
    return routeFlag;
  }

}
