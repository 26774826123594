import { Injectable } from '@angular/core';
import { Date as AtlasDate, DateUtils } from '@atlas/businesstypes';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateFormatterService {


  constructor() { }

  normalizeDate(date: Date | AtlasDate | {internalValue: string} | undefined | null): Date | undefined {
    if (date === undefined || date === null
      ) {
      return undefined;
    }

    let jsDate: Date;

    if (date instanceof AtlasDate) {
      const s = DateUtils.serialize(date);
      jsDate = new Date(s.year, s.month, s.day);
    } else if (date instanceof Date) {
      jsDate = date;
    } else if (typeof date === 'object' && 'internalValue' in date) {
      const s = DateUtils.serialize(new AtlasDate(date['internalValue']));
      jsDate = new Date(s.year, s.month, s.day);
    } else {
      throw new Error('Unexpected date format');
    }

    return jsDate;
  }

  dateToISO(date: Date | AtlasDate | {internalValue: string} | undefined | null): string | undefined {
    const jsDate = this.normalizeDate(date);

    if (! jsDate) {
      return undefined;
    }

    return moment(jsDate).format('YYYY-MM-DD')
  }

  dateTimeToISO(date: Date): string | undefined {
    if (date === undefined) {
      return undefined;
    }

    return date.toISOString();
  }

  public getDDMMYYYYFomateDate(date: Date | AtlasDate | {internalValue: string} | undefined | null): string | undefined {
    const jsDate = this.normalizeDate(date);

    if (! jsDate) {
      return undefined;
    }

    return moment(jsDate).format('DD-MM-YYYY')
  }

  public getISO8601FomateDate(startDate: AtlasDate) {
    const serialisedDate = DateUtils.serialize(startDate);
    const day = serialisedDate.day;
    const month = serialisedDate.month + 1;
    const year = serialisedDate.year;
    return year.toString() + '-'
           + ((month.toString().length === 1) ? '0' + month : month) + '-'
           + ((day.toString().length === 1) ? '0' + day : day)
  }

  public getHr(): number | undefined {
    const jsDate = new Date();
    return moment(jsDate).hours()
  }

  public getMin(): number | undefined {
    const jsDate = new Date();
    return moment(jsDate).minutes()
  }

}
