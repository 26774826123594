import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { MtplProcessStep, StepResolverService as StepResolverServiceBase } from '@systemcode/core';

import { PaymentProcessService } from './payment-process.service';

@Injectable()
export class StepResolverService extends StepResolverServiceBase implements Resolve<MtplProcessStep | undefined> {

    public constructor(
        process: PaymentProcessService,
    ) {
        super(process)
    }

}
