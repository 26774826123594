import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule PlacesExprHouseTreeSvgModule
 */
@Component({
  selector: 'svg[systemcodePlacesExprHouseTree]',
  template: '<svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M5.856 39.268h36.286"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M16.6 17.86h4.776v4.774h-4.775V17.86z"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M5.856 20.246L18.989 9.502 32.12 20.246"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M16.6 34.572V27.41h4.776v7.162"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M8.84 22.037v12.535h20.296V22.037"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M36.614 34.997V22.555"></svg:path><svg:path fill="none" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" d="M33.737 28.43a4.708 4.708 0 0 0 2.88 1.04c3.053 0 5.529-3.094 5.529-6.912a7.612 7.612 0 0 0-2.042-5.321c.44-.93.665-1.945.66-2.972 0-3.054-1.857-5.53-4.147-5.53a3.894 3.894 0 0 0-3.271 2.167"></svg:path>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'version': '1.1',
    'x': '0',
    'y': '0',
    'viewBox': '0 0 48 48',
    'space': 'preserve',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlacesExprHouseTreeSvgComponent {
}

@NgModule({
  declarations: [
    PlacesExprHouseTreeSvgComponent,
  ],
  exports: [
    PlacesExprHouseTreeSvgComponent,
  ],
})
export class PlacesExprHouseTreeSvgModule {}
