import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PolicyNumberValidatorService } from '../../services/policy-number.service';
import { PolicyNumberType } from '../../../model/PolicyNumberType';

export function policyNumber(partyId: PolicyNumberValidatorService): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
        if (
            control.value && !partyId.getType(control.value)
        ) {
            return { invalidNumber: true };
        }
        return null;
    }
}

export function claimPolicyNumber(partyId: PolicyNumberValidatorService): ValidatorFn {
    return function (control: AbstractControl): ValidationErrors | null {
        if(control.value){
            const policyType = partyId.getType(control.value)
            if ( 
                policyType === undefined ||
                ( policyType===PolicyNumberType.PERSON || policyType===PolicyNumberType.FOREIGNER || policyType===PolicyNumberType.COMPANY )
            ) {
                return { invalidNumber: true };
            }
        }
        return null;
    }
}
