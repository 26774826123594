import { CommonModule, DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule as HermesCoreModule } from '@hermes/core';
import { FileUploaderModule } from '@hermes/file-uploader';
import { TransactionModule } from '@hermes/transaction';
import { ActionsModule } from '@maia/actions';
import { AdditionalInformationModule as MaiaAdditionalInformationModule } from '@maia/additional-information';
import { AvatarsModule as MaiaAvatarsModule } from '@maia/avatars';
import { ComparisonPanelModule as MaiaComparisonPanelModule } from '@maia/comparison-panel';
import { DatePickersModule } from '@maia/datepickers';
import { DisplaysModule } from '@maia/displays';
import { DividerModule as MaiaDividerModule } from '@maia/divider';
import { EmptyStateModule as MaiaEmptyStateModule } from '@maia/empty-state';
import { FlexGridModule as MaiaFlexGridModule } from '@maia/flex-grid';
import { FlowProgressWithRoutingModule } from '@maia/flow-progress';
import { FormsModule as MaiaFormsModule } from '@maia/forms';
import { HighlightPanelModule as MaiaHighlightPanelModule } from '@maia/highlight-panel';
//import { μCountryFlagsSvgModule, μHighlightPanelSvgModule } from '@maia/icons';
import { InputAutocompleteModule as MaiaInputAutocompleteModule } from '@maia/input-autocomplete';
import { InputPhonenumberModule as MaiaInputPhonenumberModule } from '@maia/input-phonenumber';
import { InputSelectModule as MaiaInputSelectModule } from '@maia/input-select';
import { MessagesModule as MaiaMessages } from '@maia/messages';
import { NameValueCollectionsModule as MaiaNameValueCollectionsModule } from '@maia/name-value-collections';
import { PanelsModule as MaiaPanelsModule } from '@maia/panels';
import { PopUpsModule as MaiaPopUpsModule } from '@maia/pop-ups';
import { PriceBlockModule as MaiaPriceBlockModule } from '@maia/price-block';
import { RadioButtonsModule as MaiaRadioButtonsModule } from '@maia/radio-buttons';
import { SelectModule as MaiaSelectModuke } from '@maia/select';
import { SelectTileModule as MaiaSelectTileModule } from '@maia/select-tile';
import { SlideInsModule as MaiaSlideInsModule } from '@maia/slide-ins';
import { SpinnersModule as MaiaSpinnersModule } from '@maia/spinners';
import { SwitchesModule } from '@maia/switches';
import { TablesModule as MaiaTablesModule } from '@maia/tables';
import { TermsAndConditionsModule } from '@maia/terms-and-conditions';
import { TextAreaModule as MaiaTextAreaModule } from '@maia/text-area';
import { TilesModule as MaiaTilesModule } from '@maia/tiles';
import { GenericSvgModule } from '@systemcode/icons';
import { TextListsModule as MaiaTextListsModule } from '@maia/text-lists';
import { ApiService } from '../backend/api.service';
import { AuthBridgeService } from '../backend/auth-bridge.service';
import { CommonBtrustNotifyComponent } from './components/common-btrust-notify/common-btrust-notify.component';
import { ActivePolicyComponent } from './components/common-policy/common-policy.component';
import { DeliveryDetailGuestComponent } from './components/delivery-detail-guest/delivery-detail-guest.component';
import { DueInstalmentCommonComponent } from './components/due-instalment-common/due-instalment-common.component';
import { ExpandedFlowProgressModule } from './components/expanded-flow-progress/flow-progress/flow-progress.module';
import {
  ExpandedFlowProgressWithRoutingModule,
} from './components/expanded-flow-progress/flow-progress/routing/flow-progress-with-routing.module';
import { MtplPolicyDetailsComponent } from './components/mtpl-policy-details/mtpl-policy-details.component';
import { NoDueInstalmentCommonComponent } from './components/no-due-instalment-common/no-due-instalment-common.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaymentFailureComponent } from './components/payment-failure/payment-failure.component';
import { PaymentRedirectComponent } from './components/payment-redirect/payment-redirect.component';
import { PaymentSuccessComponent } from './components/payment-success/payment-success.component';
import { CommonListenerDirective } from './directives/common-listener.directive';
import { DigitOnlyDirective } from './directives/digit-only.directive';
import { LimitToDirective } from './directives/limit-to.directive';
import { HttpErrorInterceptor } from './helpers/http.error.interceptor';
import { HttpLoaderInterceptor } from './helpers/http.loader.interceptor';
import { HttpResponseInterceptor } from './helpers/http.response.interceptor';
import { DEFAULT_TIMEOUT, HttpTimeoutInterceptor } from './helpers/http.timeout.interceptor';
import { HttpTokenInterceptor } from './helpers/http.token.interceptor';
import { nl2brPipe } from './pipes/nl2br.pipe';
import { LoaderComponent } from './shared/loader/loader.component';
import { SendEmailResultComponent } from './shared/send-email-result/send-email-result.component';
import { ToAtlasDatePipe } from './transformation/ToAtlasDate.pipe';
import { ButtonsModule } from '@maia/buttons';
import { localeValPipe } from './pipes/localeVal.pipe';
import { PolicyDocumentGridComponent } from './components/policy-document-grid/policy-document-grid.component';
import { PropertyViewPolicyComponent } from './components/property-view-policy/property-view-policy.component';
import { StampsModule as MaiaStampsModule } from '@maia/stamps';
import { TravelRouteGuard } from "../flow/guards/travel.route.guard";
import { PropertyRouteGuard } from "./guards/property.route.guard";
import { SafePipe } from './pipes/safeUrl.pipe';
import { ContainerComponent } from './components/container/container.component';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { ClaimRouteGuard } from './guards/claim.route.guard';
import { BrowserSupportWarningComponent } from './components/browser-support-warning/browser-support-warning.component';

@NgModule({
  declarations: [
    LoaderComponent,
    SendEmailResultComponent,
    CommonListenerDirective,
    ToAtlasDatePipe,
    DeliveryDetailGuestComponent,
    PaymentFailureComponent,
    PaymentSuccessComponent,
    DueInstalmentCommonComponent,
    NoDueInstalmentCommonComponent,
    ActivePolicyComponent,
    MtplPolicyDetailsComponent,
    DigitOnlyDirective,
    LimitToDirective,
    PaymentRedirectComponent,
    PageNotFoundComponent,
    CommonBtrustNotifyComponent,
    nl2brPipe,
    localeValPipe,
    SafePipe,
    PolicyDocumentGridComponent,
    PropertyViewPolicyComponent,
    ContainerComponent,
    BrowserSupportWarningComponent
  ],
  imports: [
    ActionsModule,
    AngularFormsModule,
    ButtonsModule,
    CommonModule,
    DatePickersModule,
    DisplaysModule,
    ExpandedFlowProgressModule,
    ExpandedFlowProgressWithRoutingModule,
    FlowProgressWithRoutingModule,
    GenericSvgModule,
    HermesCoreModule,
    HttpClientModule,
    MaiaAdditionalInformationModule,
    MaiaComparisonPanelModule,
    MaiaDividerModule,
    MaiaEmptyStateModule,
    MaiaFlexGridModule,
    MaiaFormsModule,
    MaiaHighlightPanelModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaInputSelectModule,
    MaiaMessages,
    MaiaNameValueCollectionsModule,
    MaiaPopUpsModule,
    MaiaPriceBlockModule,
    MaiaRadioButtonsModule,
    MaiaSelectModuke,
    MaiaSelectTileModule,
    MaiaSlideInsModule,
    MaiaSpinnersModule,
    MaiaTilesModule,
    ReactiveFormsModule,
    SwitchesModule,
    TermsAndConditionsModule,
    TransactionModule,
    //μHighlightPanelSvgModule,
    //μCountryFlagsSvgModule,
    MaiaTextAreaModule,
    MaiaPanelsModule,
    MaiaTablesModule,
    MaiaAvatarsModule,
    FileUploaderModule,
    ButtonsModule,
    MaiaTextListsModule,
    MaiaStampsModule,
    PdfJsViewerModule
  ],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
    [{ provide: HTTP_INTERCEPTORS, useClass: HttpTimeoutInterceptor, multi: true }],
    [{ provide: DEFAULT_TIMEOUT, useValue: 30000 }],
    ApiService,
    AuthBridgeService,
    TravelRouteGuard,
    PropertyRouteGuard,
    ClaimRouteGuard
  ],
  exports: [
    LoaderComponent,
    SendEmailResultComponent,
    CommonListenerDirective,
    ExpandedFlowProgressWithRoutingModule,
    ExpandedFlowProgressModule,
    CommonListenerDirective,
    DeliveryDetailGuestComponent,
    PaymentFailureComponent,
    PaymentSuccessComponent,
    BrowserSupportWarningComponent,
    PolicyDocumentGridComponent,
    PaymentRedirectComponent,
    ToAtlasDatePipe,
    DueInstalmentCommonComponent,
    NoDueInstalmentCommonComponent,
    ContainerComponent,
    ActivePolicyComponent,
    MtplPolicyDetailsComponent,
    DigitOnlyDirective,
    LimitToDirective,
    PageNotFoundComponent,
    CommonBtrustNotifyComponent,
    nl2brPipe,
    localeValPipe,
    SafePipe,
    ActionsModule,
    AngularFormsModule,
    ButtonsModule,
    CommonModule,
    DatePickersModule,
    DisplaysModule,
    ExpandedFlowProgressModule,
    ExpandedFlowProgressWithRoutingModule,
    FlowProgressWithRoutingModule,
    GenericSvgModule,
    HermesCoreModule,
    HttpClientModule,
    MaiaAdditionalInformationModule,
    MaiaComparisonPanelModule,
    MaiaDividerModule,
    MaiaEmptyStateModule,
    MaiaFlexGridModule,
    MaiaFormsModule,
    MaiaHighlightPanelModule,
    MaiaInputAutocompleteModule,
    MaiaInputPhonenumberModule,
    MaiaInputSelectModule,
    MaiaMessages,
    MaiaNameValueCollectionsModule,
    MaiaPopUpsModule,
    MaiaPriceBlockModule,
    MaiaRadioButtonsModule,
    MaiaSelectModuke,
    MaiaSelectTileModule,
    MaiaSlideInsModule,
    MaiaSpinnersModule,
    MaiaTilesModule,
    ReactiveFormsModule,
    SwitchesModule,
    TermsAndConditionsModule,
    TransactionModule,
    //μHighlightPanelSvgModule,
    //μCountryFlagsSvgModule,
    MaiaTextAreaModule,
    MaiaPanelsModule,
    MaiaTablesModule,
    MaiaAvatarsModule,
    FileUploaderModule,
    ButtonsModule,
    MaiaTextListsModule,
    MaiaStampsModule
  ],
  entryComponents: [
    SendEmailResultComponent,
  ],
})
export class FlowModule { }
