import { Injectable } from '@angular/core';

import { EpayPaymentFormComponent } from '../../payment/components/epay-payment-form/epay-payment-form.component';
import { VposPaymentFormComponent } from '../../payment/components/vpos-payment-form/vpos-payment-form.component';
import { IProductWorkflowService } from '../../product-workflow/model/product-workflow-service';
import { ScreenManagersModule } from '../screen-managers.module';
import { PaymentScreenEpayManagerService } from './payment-screen-epay-manager.service';
import { PaymentScreenVposManagerService } from './payment-screen-vpos-manager.service';
import { PolicyCreationStatusCheckService } from './policy-creation-status-check.service';
import { IPropertyPaymentScreen } from './property-payment-screen';

@Injectable(
  {providedIn: ScreenManagersModule}
)
export class PaymentScreenManagerService {

  constructor(
    private vpos: PaymentScreenVposManagerService,
    private epay: PaymentScreenEpayManagerService,
    private statusCheck: PolicyCreationStatusCheckService,
  ) { }

  init(productWorkflow: IProductWorkflowService, screen: IPropertyPaymentScreen) {
    this.vpos.productWorkflow = productWorkflow;
    this.vpos.screen = screen;

    this.epay.productWorkflow = productWorkflow;
    this.epay.screen = screen;

    this.statusCheck.productWorkflow = productWorkflow;
    this.statusCheck.screen = screen;
  }

  public async vposSubmitClick(paymentComponent: VposPaymentFormComponent, amount: number, isCardOwner: boolean, lang: string, productCode: number,isEidentificationRequired:boolean) {
    return await this.vpos.submitClick(paymentComponent, amount, isCardOwner, lang, productCode,isEidentificationRequired);
  }

  public async epaySubmitClick(paymentComponent: EpayPaymentFormComponent, lang: string, successUrl: string, failureUrl: string, epayRedirectUrl: string,isEidentificationRequired:boolean, productCode: number) {
    return await this.epay.submitClick(paymentComponent, lang, successUrl, failureUrl, epayRedirectUrl,isEidentificationRequired,productCode);
  }

  public async enterPolicyCreationStatusCheckMode() {
    return await this.statusCheck.enterPolicyCreationStatusCheckMode();
  }

}
