import {ChangeDetectionStrategy, Component, NgModule, ViewEncapsulation} from '@angular/core';

/**
 * @ngModule InformationExprShieldLockSvgModule
 */
@Component({
  selector: 'svg[systemcodeInformationExprShieldLock]',
  template: '<svg:path stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" fill="none" d="M17 23.12h14v8.75H17z"></svg:path><svg:path d="M20.5 23.12v-5.25a3.52 3.52 0 0 1 3.5-3.5h0a3.52 3.52 0 0 1 3.5 3.5v5.25" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:path d="M39.75 26.63a15.75 15.75 0 0 1-31.5 0V9.12L24 5.62l15.75 3.5z" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"></svg:path><svg:circle cx="24" cy="27.5" r="1.53"></svg:circle>',

  styles: [
    ':host { fill: currentColor; stroke: currentColor; }',
      ],

  host: {
    'viewBox': '0 0 48 48',
  },

  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InformationExprShieldLockSvgComponent {
}

@NgModule({
  declarations: [
    InformationExprShieldLockSvgComponent,
  ],
  exports: [
    InformationExprShieldLockSvgComponent,
  ],
})
export class InformationExprShieldLockSvgModule {}
